import styled from "styled-components";
import {
    BookingType
} from '../../types';

import {
    convertTimestampToDate
} from '../../utilities/convert';
import { Gap } from "../atoms";

const Container = styled.div`
    width: 100%;
    background: #F4F7F8;
    border-radius: 16px;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 12px;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: #191F28;
`;

const Contens = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`; 

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.4375px;
    color: #7D93A6;
    margin-top: 2px;
`;

const Content = styled.span`
    background-color: #FF4A8B;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    color: #FFFFFF;
    padding: 5px 8px;
`;

const ContentDisable = styled.span`
    background-color: #BFBFBF;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    color: #000000;
    padding: 5px 8px;
`;

interface AdvertisementBookingCardProps {
    booking: BookingType;
}

export function AdvertisementBookingCard({
    booking
}: AdvertisementBookingCardProps){
    function convertStateToString(state: number)
    {
        if(state === 1)
        {
            return '예약요청'
        }
        else if(state === 2)
        {
            return '예약완료'
        }
        else if(state === 3)
        {
            return '요청거절'
        }
        else if(state === 4)
        {
            return '고객취소'
        }
        else if(state === 5)
        {
            return '점주취소'
        }
        else if(state === 6)
        {
            return '이용완료'
        }
        else if(state === 7)
        {
            return '노쇼'
        }
        else if(state === 8)
        {
            return '예약철회'
        }
        else if(state === 9)
        {
            return '미응답취소'
        }
    }

    return (
        <Container>
            <RowWrapper>
                <Title>{convertTimestampToDate(booking.time)} 예약</Title>
            </RowWrapper>
            <Gap 
                gap={5}
            />
            <RowWrapper>   
                <Title>{booking.userName}({booking.userPhone})</Title>      
            </RowWrapper>
            <Gap 
                gap={10}
            />
            <RowWrapper>
                <Contens>
                    <Label>최종상태</Label>
                    {
                        booking.state === 6 ? <Content>{convertStateToString(booking.state)}</Content> :
                        <ContentDisable>{convertStateToString(booking.state)}</ContentDisable> 
                    }
                    
                </Contens>
            </RowWrapper>
        </Container>
    );
}