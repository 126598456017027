import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    height: 350px;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const Image = styled.img`

`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.25px;
    color: #000000;
    margin-top: 20px;
    text-align: center;
`;

interface BlockDayElementProps {
    name?: string | null;
}

export function BlockDayElement({
    name
}: BlockDayElementProps){
    return (
        <Container>            
            <Image 
                src={require('../../styles/assets/cancel.png')}
            />
            <Label>{name}<br/>오늘은 휴무일 입니다.</Label>
        </Container>
    )
}