// 고객 카드
import { useState } from "react";
import styled from "styled-components";

import {
    CustomerInfoType
} from '../../types';
import { 
    setComma 
} from "../../utilities/convert";

var BASE_URL = process.env.REACT_APP_TARGET_URL;

const Container = styled.div`
    width: 100%;
    height: 84px;
    background: #F4F7F8;
    border-radius: 12px;
    display: grid;
    grid-template-columns: 25% 70% 5%;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
`;

const ProfileDiv = styled.div`
    position: relative;
    width: 100%;
`;

const AltProfile = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* border: 1px solid #979797; */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const AltImage = styled.img`

`;

const Profile = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* border: 1px solid #979797; */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const Count = styled.span`
    position: absolute;
    left: calc(50% + 14px);
    top: calc(50% + 18px);
    transform: translate(-50%, -50%);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.5px;
    color: #FF4A8B;
    font-family: 'Spoqa Han Sans Neo';
    text-align: center;
    width: 35px;
`;

const ColWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`;

const InfoDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50%;
`;

const Name = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.2px;
    color: #333D4B;
    white-space : nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Phone = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.2px;
    color: #333D4B;
`;

const RecentDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50%;
`;

const Text = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #7D93A6;
    white-space : nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
`;

const Button = styled.img`
    height: 14px;
`;



interface CustomerCardProps {
    customerInfo: CustomerInfoType;
    onClick: () => void;
}

export function CustomerCard({
    customerInfo,
    onClick
}: CustomerCardProps){
    const [ hash, setHash ] = useState(Date.now()); 
    function cutMemo(memo: string){
        const memos = memo.split("\n");
        if(memo === '')
            return '';

        if(memos.length > 1)
            return memos[0]+"...";
        else
            return memo;
    }
    return (
        <Container
            onClick={onClick}
        >
            <ProfileDiv>
                {customerInfo.fileType === '' || customerInfo.fileType === null || customerInfo.fileType === undefined ?
                    <AltProfile>
                        <AltImage src={require('../../styles/assets/icon/user.png')}/>
                    </AltProfile>
                    :
                    <Profile src={BASE_URL + `/v1/customer/photo/${customerInfo.customerId}/?${hash}`} />
                }   
                {/* {customerInfo.photo} */}
                <Count>{customerInfo.count}회</Count>
            </ProfileDiv>
            <ColWrapper>
                <Wrapper>
                    <InfoDiv>
                        <Name>{customerInfo.name}</Name>
                        <Phone>{customerInfo.phone}</Phone>
                    </InfoDiv>
                    <RecentDiv>
                        <Text>{customerInfo.recentBooking.slice(0, 10)} / {customerInfo.recentMenu} </Text>
                        <Text>잔여 선불금 {setComma(customerInfo.remainPoint)}원</Text>
                    </RecentDiv>
                </Wrapper>
                <Wrapper>
                    <Text>{cutMemo(customerInfo.userMemo)}</Text>                
                </Wrapper>            
            </ColWrapper>           
            <Button src={require('../../styles/assets/icon/link.png')} />
        </Container>
    );
}