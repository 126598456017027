import styled from "styled-components"

const Container = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.bgColor};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 150px;
    padding-bottom: 150px;
`;

const Text = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 58px; 
    letter-spacing: -0.75px;
    text-align: center;
    color: #333333;
    @media only screen and (max-width: 768px) {
        font-size: 30px;
        line-height: 50px;
    }
`;

const Image1 = styled.img`
    @media only screen and (max-width: 768px) {
        width: 80%;
    }
`;

const Image2 = styled.img`
    @media only screen and (max-width: 768px) {
        width: 80%;
    }
`;


function Service(){
    return (
        <Container> 
            <Text>금요일11시는<br/>가장 유연한 예약관리를<br/>제공합니다.</Text>
            <Image1 src={require('../../../styles/assets/service_phone1.png')} />
            <Image2 src={require('../../../styles/assets/service_phone2.png')} />
        </Container>
    );
}

export default Service;