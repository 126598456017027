import styled from "styled-components";

import {
    CardInfo
} from '../../types'

const Container = styled.div`
    display: grid;
    width: 100%;
    background: #F4F7F8;
    border-radius: 16px;
    padding: 20px;
    grid-template-columns: 100%;
    gap: 12px;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const Key = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.4375px;
    color: #7D93A6;
`;

const Value = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.4375px;
    color: #191F28;
`;

interface InfoCardProps {
    infos: CardInfo []
}

export function InfoCard({
    infos
}: InfoCardProps){
    return (
        <Container> 
            {
                infos.map((info, index) => {
                    return (
                        <RowWrapper key={index}>
                            <Key>{info.key}</Key>
                            <Value>{info.value}</Value>
                        </RowWrapper>

                    );
                })
            }
        </Container>
    );
}