import { useRecoilValue } from "recoil";
import styled from "styled-components";

import { 
    storeState 
} from "../../stores";

import {
    SettingTime
} from '../molecules'


import {
    BookingType
} from '../../types'

import {
    confirmBookingRequest,
    rejectBookingRequest
} from '../../apis/BookingApi';

import { FormEvent, useState } from "react";
import { leadingZeros } from "../../utilities/convert";

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`;

const TouchableSection = styled.div`
    width: 100%;
    height: 20%;
    cursor: pointer;
`;

const Section = styled.div`
    width: 100%;
    height: 80%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
    overflow: auto;
    color: #000000;
    padding: 34px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 100;
`;

const CloseButton = styled.img`
    position: absolute;
    top: 20px;
    right: 15px;
    cursor: pointer;
`;

const Time = styled.div`

`;
const Button = styled.div`

`;

const Input = styled.input`

`;

const TextBox = styled.textarea`

`;

const Wrapper = styled.div`
    display: flex;
`;

const Text = styled.span`

`;

interface WaitBookingInfoModalProps {
    onClose: () => void;
    onChange: () => void;
    bookingInfo: BookingType;
}

export function WaitBookingInfoModal({
    onClose,
    onChange,
    bookingInfo    
}: WaitBookingInfoModalProps){

    const store = useRecoilValue(storeState);
    const [time, setTime] = useState<string>('');
    const [ message, setMessage ] = useState<string>(store.rejectMessage);

    console.log(JSON.stringify(bookingInfo))

    const confirmBooking = async () => {
        //validation
        //resrv time, bookingId
        const resrvTime = bookingInfo.requestDate + " " + time+":00";
        // const res = await confirmBookingRequest(bookingInfo.bookingId, resrvTime);
        onChange();
    }

    const rejectBooking = async () => {
        const res = await rejectBookingRequest(bookingInfo.bookingId, message);
        onChange();
    }

    const onMessageChange = (event: FormEvent<HTMLTextAreaElement>) => {
        const {
            currentTarget: {value},
        } = event;  

        setMessage(value);
    }

    return (
        <Container>
            <TouchableSection 
                onClick={()=> {
                    onClose()
                }}
            />
            <Section
                className="request"
            >
                <CloseButton src={require('../../styles/assets/btn_close_normal.png')}
                    onClick={() => {
                        onClose();
                    }}
                />
                {bookingInfo.menuName}({bookingInfo.duration})
                {
                    bookingInfo.bookingEtcInfoList.map((etc, i) => {
                        return(
                            <Text key={i}>{etc.contents}</Text>
                        );
                    })
                }
                {bookingInfo.requestDate}
                {bookingInfo.requestBookingTimeList.map((rtime, i) => {
                    return (
                        <Time key={i}>{rtime.startTime} ~ {rtime.endTime}</Time>
                    );
                 })}
                <Wrapper>
                    <SettingTime
                        time={time ? time : ''}
                        onSettingTime = {(hour: number, minute: number) => {
                            setTime(leadingZeros(hour.toString(), 2) + ":" + leadingZeros(minute.toString(), 2))
                        }}             
                        timeRange={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']}       
                    /> 
                    <Button
                        onClick={confirmBooking}
                    >수락</Button>
                </Wrapper>
                <Wrapper>
                    <TextBox                         
                        onChange={onMessageChange}
                        value={message} 
                    />
                    <Button
                        onClick={rejectBooking}
                    >거절</Button>
                </Wrapper>
            </Section>
        </Container>
    );
}