import styled from "styled-components";

import {
    Gap
} from '../../../atoms';

import {
    ScheduleSettingModal
} from '../../../modals';

import {
    DowScheduleType
} from '../../../../types';
import { useState } from "react";

const Container = styled.div`
    width: 100%;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 32px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.2px;
    color: #000000;
`;

const ColWrapper = styled.div`
    display: grid;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 22px;
    grid-template-columns: 1fr;
    width: 100%;
`;

const DowBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;
`;

const Dow = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.175px;
    color: #525558;
    span{
        margin-left: 12px;
        color: #9C27B0;
    }
`;

const Arrow = styled.img`
    margin-top: -2px;
    width: 6px;
`;

interface ScheduleSettingProps {
    dowScheduleList: DowScheduleType[];
    onChange: () => void;
}

export function ScheduleSetting({
    dowScheduleList,
    onChange
}: ScheduleSettingProps)
{
    const [ isSetting, setIsSetting ] = useState(false);
    const [ selectedDow, setSelectedDow ] = useState(0);
    return (
        <Container>
            {
                isSetting && dowScheduleList && <ScheduleSettingModal 
                    dowSchedule={dowScheduleList[selectedDow]}
                    onClose={() => {
                        setIsSetting(false);
                    }}
                    onSubmit={() => {
                        setIsSetting(false);
                        onChange();
                    }}
                />
            }
            <Title>스케줄</Title>
            <Gap 
                gap={31}
            />
            <ColWrapper>
                <DowBar
                    onClick={() => {
                        setSelectedDow(0);
                        setIsSetting(true);
                    }}
                >
                    <Dow>일요일 { dowScheduleList[0].active ? <span>{dowScheduleList[0].open} ~ {dowScheduleList[0].close}</span> : <span>(휴무일)</span>}</Dow>
                    <Arrow src={require('../../../../styles/assets/arrow_right.png')}/>
                </DowBar>
                <DowBar
                    onClick={() => {
                        setSelectedDow(1);
                        setIsSetting(true);
                    }}
                >
                    <Dow>월요일 { dowScheduleList[1].active ? <span>{dowScheduleList[1].open} ~ {dowScheduleList[1].close}</span> : <span>(휴무일)</span>}</Dow>
                    <Arrow src={require('../../../../styles/assets/arrow_right.png')}/>
                </DowBar>
                <DowBar
                    onClick={() => {
                        setSelectedDow(2);
                        setIsSetting(true);
                    }}
                >
                    <Dow>화요일 { dowScheduleList[2].active ? <span>{dowScheduleList[2].open} ~ {dowScheduleList[2].close}</span> : <span>(휴무일)</span>}</Dow>
                    <Arrow src={require('../../../../styles/assets/arrow_right.png')}/>
                </DowBar>
                <DowBar
                    onClick={() => {
                        setSelectedDow(3);
                        setIsSetting(true);
                    }}
                >
                    <Dow>수요일 { dowScheduleList[3].active ? <span>{dowScheduleList[3].open} ~ {dowScheduleList[3].close}</span> : <span>(휴무일)</span>}</Dow>
                    <Arrow src={require('../../../../styles/assets/arrow_right.png')}/>
                </DowBar>
                <DowBar
                    onClick={() => {
                        setSelectedDow(4);
                        setIsSetting(true);
                    }}
                >
                    <Dow>목요일 { dowScheduleList[4].active ? <span>{dowScheduleList[4].open} ~ {dowScheduleList[4].close}</span> : <span>(휴무일)</span>}</Dow>
                    <Arrow src={require('../../../../styles/assets/arrow_right.png')}/>
                </DowBar>
                <DowBar
                    onClick={() => {
                        setSelectedDow(5);
                        setIsSetting(true);
                    }}
                >
                    <Dow>금요일 { dowScheduleList[5].active ? <span>{dowScheduleList[5].open} ~ {dowScheduleList[5].close}</span> : <span>(휴무일)</span>}</Dow>
                    <Arrow src={require('../../../../styles/assets/arrow_right.png')}/>
                </DowBar>
                <DowBar
                    onClick={() => {
                        setSelectedDow(6);
                        setIsSetting(true);
                    }}
                >
                    <Dow>토요일 { dowScheduleList[6].active ? <span>{dowScheduleList[6].open} ~ {dowScheduleList[6].close}</span> : <span>(휴무일)</span>}</Dow>
                    <Arrow src={require('../../../../styles/assets/arrow_right.png')}/>
                </DowBar>                            
            </ColWrapper>
        </Container>
    );
}