import styled from "styled-components";
import { Header } from "./components/Header";
import { Stat } from "./components/Stat";

import {
    InfluencerAdvertisementResponseType
} from '../../types';

import { 
    getInfluencerAdvertisementPageRequest 
} from "../../apis/BookingApi";

import {
    InfluencerAdvertisementCard
} from '../../components/molecules';

import {
    InfluencerAdvertisementBookingModal
} from '../../components/modals'

import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { influencerState } from "../../stores";
import { useNavigate } from "react-router-dom";
import { Gap } from "../../components/atoms";

const Container = styled.div`
    width: 100%;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Section = styled.div`
    max-width: 500px;
    width: 500px;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;  
    letter-spacing: -0.25px;
    color: #000000;
`;

const ListWrapper = styled.div`
    width: 100%;
    height: calc(100vh - 250px);
    overflow-y: auto;
    margin-top: 40px;
`;


const TextButton =styled.span<{active: boolean}>`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: ${(props) => props.active ? '700' : '400'};
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.175px;
    color: #191F28;
    margin-left: 10px;
`;


const CenterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;  
`;

const More = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.175px;
    color: #9C27B0;
    cursor: pointer;
`;

const Icon = styled.img`
    width: 16px;
    margin-right: 8px;
`;

const Text = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.175px;
    color: #9C27B0;
`;

function InfluencerPage(){
    const [ influencer, setInfluencer ] = useRecoilState(influencerState);
    const [ list, setList ] = useState<InfluencerAdvertisementResponseType []>([]);
    const [ selectedAdvertisementInfo, setSelectedAdvertisementInfo ] = useState<InfluencerAdvertisementResponseType>();
    const [ viewModal, setViewModal ] = useState(false);

    // pagination
    const [ state, setState ] = useState(2);
    const [ curPage, setCurPage ] = useState(1);
    const [ totalPage, setTotalPage ] = useState(0);
    const navigate = useNavigate();
    // 진행중, 종료, 진행전
    // load list 
    // auth problem login
    
    const sendQuery =async (state: number, page: number) => {
        try{
            const res = await getInfluencerAdvertisementPageRequest(influencer.id, state, page - 1, 5, 'id');
            if(res.status === 200)
            {
                if(page === 0)
                {
                    setList([...res.data.content]);
                }
                else
                {
                    setList((prev) => [...prev, ...res.data.content]);
                }
                setTotalPage(res.data.totalPages);
            }
            else{
                navigate('/influencer/login')
            }
            
        }
        catch(err){
            navigate('/influencer/login')
        }
    }

    useEffect(() => {
        if(influencer.id === 0)
        {
            navigate('/influencer/login')
        }
    }, [])

    useEffect(() => {
        setList([]);
        setCurPage(1);
        sendQuery(state, 1);
    }, [state])
    
    return (
        <Container>
            <Section>
                {
                    viewModal &&
                    <InfluencerAdvertisementBookingModal
                        onClose={() => {
                            setSelectedAdvertisementInfo(undefined);
                            setViewModal(false);
                        }}
                        advertisement={selectedAdvertisementInfo}
                    />
                }
                <Header />
                <Stat />
                <Gap 
                    gap={20}
                />
                <RowWrapper>
                    <Title>광고리스트</Title>
                    <div>
                        <TextButton
                            active={state===1 ? true : false}
                            onClick={() => {      
                                setState(1);
                            }}
                        >
                            진행예정
                        </TextButton>

                        <TextButton
                            active={state===2 ? true : false}
                            onClick={() => {  
                                setState(2);  
                            }}
                        >
                            진행중
                        </TextButton>
                        <TextButton
                            active={state===3 ? true : false}
                            onClick={() => {  
                                setState(3);  
                            }}
                        >
                            종료
                        </TextButton>
                    </div>                
                </RowWrapper>
                <ListWrapper>
                {
                    list.length > 0 ? 
                    (
                        list.map((item, i) => {
                            return (
                                <InfluencerAdvertisementCard                                 
                                    key={i}
                                    advertisementInfo={item}
                                    onView={() => {
                                        setSelectedAdvertisementInfo(item);
                                        setViewModal(true);
                                    }}
                                />
                            )
                        })
                    )
                    :
                    (
                        <Text>내역이 없습니다.</Text>
                    )
                }
                {
                    totalPage > curPage &&
                    <CenterWrapper>
                        <More 
                            onClick={() => {
                                let npage = curPage + 1; 
                                setCurPage(npage);
                                sendQuery(state, npage);
                            }}
                        >
                            <Icon 
                                src={require('../../styles/assets/icon/down_arrow.png')}
                            />
                            더보기
                        </More>
                    </CenterWrapper>      
                }
            </ListWrapper>
            </Section>
            
        </Container>
    );
}

export default InfluencerPage;