// 이용완료
import styled from "styled-components";
import { useState } from "react";

import {
    PageButton
} from '../atoms';

import { 
    UserBookingInfoType 
} from "../../types";

import {
    InfoCard
} from '../molecules'

import {
    StoreInfoModal
} from '../modals';

const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 41px 16px;
    display: flex;
    flex-direction: column;
`;

const Gap = styled.div`
    width: 100%;
    height: 20px;
`;

const Logo = styled.img`
    width: 120px;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.25px;
    color: #000000;
    margin-top: 20px;
`;

const ImageWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
`;

const Image = styled.img`
    width: 206px;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const RowWrapperCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.5625px;
    color: #000000;
`;

const InfoIcon = styled.img`
    width: 16px;
    margin-left: 5px;
    cursor: pointer;
`;

interface UserCompleteComponentProps {
    completeInfo: UserBookingInfoType;
}

export function UserCompleteComponent({
    completeInfo
}: UserCompleteComponentProps){

    const [storeView, setStoreView] = useState(false);
    
    return (

        <Container>
            {
                storeView && <StoreInfoModal 
                    storeInfo={completeInfo.storeInfo}
                    scheduleList={completeInfo.scheduleList}
                    onClose={() => {
                        setStoreView(false);
                    }}
                />
            }
            <Logo 
                src={require('../../styles/assets/logo_new.png')}
            />
            <Title>{completeInfo.storeInfo.name}에서<br/>즐거우셨나요?</Title>
            <ImageWrapper>
                <Image 
                    src={require('../../styles/assets/user_complete_logo.png')}
                />
            </ImageWrapper>  

            <RowWrapper>
                <Label>{completeInfo.storeInfo.name}</Label>
                <InfoIcon src={require('../../styles/assets/info.png')} 
                    onClick={()=>{
                        setStoreView(true);
                    }}
                />
            </RowWrapper>
            <Gap />
            <InfoCard 
                infos={[
                    {key: '이용 시간', value: `${completeInfo.bookingInfo.time.slice(0, 4)}년${completeInfo.bookingInfo.time.slice(5, 7)}월${completeInfo.bookingInfo.time.slice(8, 10)}일 ${completeInfo.bookingInfo.time.slice(11, 13)}시${completeInfo.bookingInfo.time.slice(14, 16)}분`},
                  
                ]}            
            />
        </Container>
    );
}