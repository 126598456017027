import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    border-radius: 16px;
    height: 68px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #F4F7F8;
    padding-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

interface SelectedDateProps {
    requestDate: string;
}

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #7D93A6;
`;

const Text = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #000000;
    margin-left: 20px;
`;

export function SelectedDate({
    requestDate
}: SelectedDateProps){
    return (
        <>
        {
            requestDate !== '' &&
            <Container>
                <Wrapper>
                    <Label>예약 날짜</Label>
                    <Text>{requestDate}</Text>
                </Wrapper>
            </Container>
        }
        </>
    )
}