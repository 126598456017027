// auth callback page
import qs from 'qs';
import { API } from '../../apis/BookingApi'
import { useEffect } from "react";
import { 
    useLocation, 
    useNavigate 
} from "react-router-dom";

import { 
    setLocalStorage 
} from '../../utilities/localstorage';

import Const from '../../constant';

import {
    KakaoTokenType
} from '../../types';

var BASE_URL = process.env.REACT_APP_TARGET_URL;

function Login(){

    const location = useLocation();
    const navigate = useNavigate();

    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });

    const code = query.code;
    const state = query.state;

    const getKakaoToken = () => {
        API({
            method: 'post',
            params: {
                client_id: Const.REST_API_KEY,
                code,
                grant_type: 'authorization_code'
            },
            url: 'https://kauth.kakao.com/oauth/token',
            withCredentials: false,
        }).then((response) => {
            const { access_token: kkoAccessToken }: KakaoTokenType = response.data;
            //token localStorage에 저장 

            // get 가입 확인 kakao id 
            // get access_token 
            API({
                method: 'post',
                data: {
                    token: kkoAccessToken
                },
                url: BASE_URL + '/v1/check/user'
            }).then((response2) => {
                // 가입되지 않은 유저 페이지로 이동 

                if(response2.data.status === '200')
                {
                    const kakaoId = response2.data.data;
                    let formData = new FormData();
                    formData.append('grant_type', 'password');
                    formData.append('username', kakaoId);
                    formData.append('password', kakaoId);
                    API.defaults.withCredentials = true;

                    API({
                        method: 'post',                        
                        url: BASE_URL + '/oauth/token',
                        auth: { username: 'legitss', password: 'legitssPwd' },
                        data: formData,
                    })
                    .then((response3) => {
                        // token 
                        setLocalStorage('access_token', response3.data.access_token);
                        navigate(`${state}`);
                    })
                    .catch((err3) => {
                        navigate(`/error`);
                    })
                    
                }
                else
                {
                    navigate(`/`);
                }

            }).catch((err2) => {
                //token 만료 
                // console.log(err2)
            })
            
        }).catch((err) => {
            // console.log(err)
        })
    }

    useEffect(() => {
        if(code)
        {
            getKakaoToken();
        }
    }, [])


    return <></>;
}

export default Login;