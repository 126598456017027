import { useState } from "react";
import styled from "styled-components";

import {
    StoreType
} from '../../types';

import {
    uploadBannerFileRequest
} from '../../apis/BookingApi';

var BASE_URL = process.env.REACT_APP_TARGET_URL;

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`;

const TouchableSection = styled.div`
    width: 100%;
    height: 20%;
    cursor: pointer;
`;

const Section = styled.div`
    width: 100%;
    height: 80%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
    overflow: auto;
    color: #000000;
    padding: 54px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 100;
`;

const CloseButton = styled.img`
    position: absolute;
    top: 20px;
    right: 15px;
    cursor: pointer;
`;

const Image = styled.img`
    width: 100%;
`
const FileInput = styled.input`
    display: none;
`;

const UploadButtonLabel = styled.label`
    position: absolute;
    top: 20px;
    right: 40px;
`;

const UploadButton = styled.img`
    position: absolute;
    top: 0px;
    width: 20px;
`;


interface StoreBannerSettingModalProps {
    onClose: (change: boolean) => void;
    storeInfo: StoreType
}

export function StoreBannerSettingModal({
    onClose,
    storeInfo
}: StoreBannerSettingModalProps) {
    const [ hash, setHash ] = useState(Date.now());
    const [imgChange, setImageChange ] = useState(false);
    const [ loading, setLoading ] = useState(true);

    

    const onChange = async (e:React.ChangeEvent<HTMLInputElement>) => {
        const imgs = e.target.files;

        if(!imgs) return;

        const formData = new FormData();
        formData.append('file', imgs[0]);
        let type = '';
        if(imgs[0].type === 'image/png')
        {
            type = 'png';
        }
        else if(imgs[0].type === 'image/jpg')
        {
            type = 'jpg';
        }
        else if(imgs[0].type === 'image/jpeg')
        {
            type = 'jpg';
        }
        setImageChange(true);
        const res = await uploadBannerFileRequest(imgs, storeInfo.id, 1, type);
        if(res)
        {   
            setHash(Date.now());
        }        
    }

    return (
        <Container>
            <TouchableSection 
                onClick={() => {
                    onClose(imgChange)
                }}
            />
            <Section>
                <UploadButtonLabel htmlFor="file">
                    <UploadButton
                        src={require('../../styles/assets/upload.png')}
                    /> 
                </UploadButtonLabel>
                <FileInput type='file' 
                    accept='image/jpg,image/png,image/jpeg' 
                    name='profile_img' 
                    onChange={onChange}
                    id="file"
                >
                </FileInput>
                {/* <CloseButton src={require('../../styles/assets/btn_close_normal.png')}
                    onClick={onClose}
                /> */}
                <Image 
                    onLoad={() => {
                        setLoading(false);
                    }}
                    src={BASE_URL+`/v1/store/banner/read/${storeInfo.id}/1/?${hash}`}
                />
                {
                    loading && <div>로딩중</div>
                }
            </Section>
        </Container>
    );
}