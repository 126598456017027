import { useEffect, useState } from "react";
import { getLocalStorage, setLocalStorage } from "../../utilities/localstorage";
import {
    getInfluencerRequest
} from '../../apis/BookingApi';

import { 
    useLocation, 
    useNavigate 
} from "react-router-dom";

import { InfluencerType } from "../../types";
import { useRecoilState } from "recoil";
import { influencerState } from "../../stores";
import styled from "styled-components";


function Influencer() {
    // token 확인 
    // login page 
    // influencer adv list 
    // 누적수입
    // 이달 수입
    // 진행중, 진행예정, 종료
    const navigate = useNavigate();
    const [ influencer, setInfluencer ] = useRecoilState(influencerState);
    
    useEffect(() => {


        const getInfluencer =async () => {
            const res = await getInfluencerRequest();
            if(res.status === '200')
            {
                // console.log('info' + JSON.stringify(res));
                setInfluencer(res.data);
                navigate('page');
            }
            else
            {
                //go login
                // accesstoken 삭제                
                setLocalStorage('in_access_token');
                navigate('login')
            }
        }

        const access_token = getLocalStorage('in_access_token');
        if(access_token)
        {
            getInfluencer();
        }
        else
        {
            // go login 
            navigate('login')
        }
    }, [])

    return (
        <></>
    );
}

export default Influencer;