import { useState } from "react";
import styled from "styled-components";
var BASE_URL = process.env.REACT_APP_TARGET_URL;
// menu 1

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #FFFFFF;
`;

const MainImage = styled.img`
    width: 50vw;
    height: 50vw;
    border: .5px solid #FFFFFF;
`;

const MainImage2 = styled.div`
    width: 50vw;
    height: 50vw;
    border: .5px solid #FFFFFF;
`;

const ColWrapper = styled.div`
    width: 50vw;
    height: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const RowWapper = styled.div`
    width: 50vw;
    height: 25vw;
    display: flex;
`;

const SubImage = styled.img`
    width: 25vw;
    height: 25vw;
    border: .5px solid #FFFFFF;
`;

const AltImage = styled.div`
    width: 25vw;
    height: 25vw;
    border: .5px solid #FFFFFF;
`;

interface StoreImageGridProps{
    banner1: string | null;
    banner2: string | null;
    banner3: string | null;
    banner4: string | null;
    banner5: string | null;
    storeId: number;
}

export function StoreImageGrid({
    banner1,
    banner2,
    banner3,
    banner4,
    banner5,
    storeId
}: StoreImageGridProps){
    const [ hash, setHash ] = useState(Date.now());    

    return (
        <Container>
            {
                banner1 ? <MainImage                 
                    src={BASE_URL + `/v1/store/banner/read/${storeId}/1/?${hash}`}
                /> : <MainImage2 />
                
            }
            
            <ColWrapper>
                <RowWapper>
                    {
                        banner2 ? <SubImage 
                            src={BASE_URL + `/v1/store/banner/read/${storeId}/2/?${hash}`}
                        /> : <AltImage />
                    }
                    {
                        banner3 ? <SubImage 
                            src={BASE_URL + `/v1/store/banner/read/${storeId}/3/?${hash}`}
                        /> : <AltImage />
                    }
                </RowWapper>
                <RowWapper>
                    {
                        banner4 ? <SubImage 
                            src={BASE_URL + `/v1/store/banner/read/${storeId}/4/?${hash}`}
                        /> : <AltImage />
                    }
                    {
                        banner5 ? <SubImage 
                            src={BASE_URL + `/v1/store/banner/read/${storeId}/5/?${hash}`}
                        /> : <AltImage />
                    }
                </RowWapper>
            </ColWrapper>
            
        </Container>
    );
}