import styled from "styled-components";

const Container = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    flex-direction: column;
    height: 200px;
`;

const Bar = styled.div<{height: string; background: string}>`
    height: ${(props) => props.height}%;
    /* height: 100%; */
    width: 100%;
    background: ${(props) => props.background};
`;
const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.375px;
    color: #7D93A6;
`;

interface YBarProps {
    value: number;
    label: string;
    background: string;
}

export function YBar({
    value,
    label,
    background
}: YBarProps){
    return (
        <Container>
            <Bar 
                height={value.toString()}
                background={background}
            />
            <Label>{label}</Label>
        </Container>
    );
}