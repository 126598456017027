import { useEffect, useState } from "react";
import styled from "styled-components";

import {
    BookingType,
    BookingBlock,
    StoreTimeType,
    BreakTimeType,
    BreakBlock
} from '../../types';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: #FFFFFF;
    padding-top: 30px;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 40px;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
`;

const Available = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.175px;
    color: #7D93A6;
    margin-top: 11px;
`;

const Line = styled.div`
    width: 100%;
    height: 1px;
    background: #CEDDE2;
    margin-top: 28px;
    margin-bottom: 28px;
`;

const BlockWrapper = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 21vw 21vw 21vw 21vw;
    justify-content: flex-start;
    align-items: center;
    gap: 2vw;
`;

const SubTitle = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.175px;
    color: #9C27B0;
`;

const TimeDivision = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.25px;
    color: #191F28;
    margin-top: 28px;
    margin-bottom: 12px;
`;

const Block = styled.div<{available: boolean}>`
    width: 21vw;
    height: 21vw;
    background: ${(props) => props.available ? '#F6ECFF' : '#F4F7F8'};
    border: ${(props) => props.available ? '1px solid #9C27B0' : '1px solid #C8DDEF'};
    color: ${(props) => props.available ? '#0000000' : '#7D93A6'};
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
`;
const Time = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 3.5vw;
    line-height: 4.5vw;
    width: 100%;
    text-align: center;
`;



// data
// am - btimes, available(1시간 단위, 시간이 조금이라도 걸쳐있으면 예약가능 시간임)
// pm - btimes, available
//

interface ScheduleType {
    type: number; // 1: availabe, 2: break
    time: string;
}

interface BlockScheduleProps {
    name: string;
    open: string;
    close: string;
    selectedYear: number;
    selectedMonth: number;
    selectedDate: number;
    bookingData: BookingType [];  // 예약 데이터 
    breakTimes: BreakTimeType [];
    onBooking: (startTime: string) => void;
}

export function BlockSchedule({
    name,
    open,
    close,
    selectedYear,
    selectedMonth,
    selectedDate,
    bookingData,
    breakTimes,
    onBooking
}: BlockScheduleProps){

    const [ amSchedules, setAmSchedules ] = useState<ScheduleType[]>(
        [
            // {
            //     type: 2,
            //     time: "10:00"
            // },
            // {
            //     type: 2,
            //     time: "11:00"
            // }
        ]
    );
    const [ pmSchedules, setPmSchedules ] = useState<ScheduleType[]>(
        [
            {
                type: 1,
                time: "12:00"
            },
            {
                type: 1,
                time: "1:00"
            },
            {
                type: 1,
                time: "2:00"
            },
            {
                type: 2,
                time: "3:00"
            },
            {
                type: 2,
                time: "4:00"
            },
            {
                type: 1,
                time: "5:00"
            },
            {
                type: 1,
                time: "6:00"
            },
            {
                type: 1,
                time: "7:00"
            }
        ]
    );

    function calAm () {
        let cur = new Date();
        cur.setMinutes(0);
        cur.setSeconds(0)
 
        for(var i = 0; i < 12; i++)
        {
            let comp = new Date(selectedYear, selectedMonth, selectedDate, i);
            if(comp >= cur)
            {
                
            }
            else
            {
                // break; 만 채움
                for(var j = 0; j < breakTimes.length; j++)
                {
                    let compb = new Date(cur.getFullYear(), cur.getMonth(), cur.getDate(), i)
                    // let break = new Date(cur.getFullYear(), cur.getMonth(), cur.getDate(), breakTimes[j].start)
                    // alert(compb)
                }
            }
        }

    }

    function calPm () {
        let cur = new Date();
        cur.setMinutes(0);
        cur.setSeconds(0)
 
        for(var i = 12; i < 23; i++)
        {
            let comp = new Date(selectedYear, selectedMonth, selectedDate, i);
            if(comp >= cur)
            {
                
            }
            else
            {
                // break; 만 채움
            }
        }
    }

    useEffect(() => {
        // set am 
        // 영업시간 나누기 
        // open time < 현재시간 : opentime ~ 11시까지
        // open time > 현재시간 : 현재시간 ~ 11시까지
        // 시간별 for 문 돌면서
        // break time, 체크하여 type 변경, confirm time이 아닌 시간만 넣기 
        calAm();
        calPm();

        // set pm 
    }, [])

    return (
        <Container>
            <Title>{name}</Title>
            <Available>영업시간{open} ~ {close}</Available>
            <Line />
            <SubTitle>비어있는 스케줄을 확인하고 예약을 신청하세요.</SubTitle>
            <TimeDivision>오전</TimeDivision>
            <BlockWrapper>
                {
                    amSchedules.map((schedule, index) => {
                        return (
                            <Block key={index} available={schedule.type === 1 ? true : false}>{
                                schedule.type === 1 ? <Time>{schedule.time}</Time> : <><Time>{schedule.time}<br/>휴식</Time></>}
                            </Block>
                        );
                    })
                }
            </BlockWrapper>
            <TimeDivision>오후</TimeDivision>
            <BlockWrapper>
                {
                    pmSchedules.map((schedule, index) => {
                        return (
                            <Block key={index} available={schedule.type === 1 ? true : false}>{
                                schedule.type === 1 ? <Time>{schedule.time}</Time> : <><Time>{schedule.time}<br/>휴식</Time></>}
                            </Block>
                        );
                    })
                }
            </BlockWrapper>
        </Container>
    );
}