import styled from "styled-components";
import { FormEvent, useEffect, useState } from "react";

import {
    updateMenuRequest,
    uploadMenuImageFileRequest
} from '../../../../apis/BookingApi';

import {
    Gap,
    PageButton
} from '../../../atoms';

import { 
    MenuType 
} from "../../../../types";

import { 
    checkNumber 
} from "../../../../utilities/validation";

import {
    AlertModal
} from '../../../modals';

import {
    Loading
} from '../../../molecules';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`;

const TouchableSection = styled.div`
    width: 100%;
    height: 10%;
    cursor: pointer;
`;

const Section = styled.div`
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
    overflow: auto;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1000;
`;

const Title = styled.div`
    min-height: 60px;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #CEDDE2;
    padding-left: 5vw;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: #191F28;
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 40px 5vw;
`;

const RowWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.175px;
    color: #525558;
`;

const CenterWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
`;

const UploadButtonLabel = styled.label`
    background: #AFB8C0;
    width: 93px;
    height: 93px;
    border-radius: 12px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const MenuPhoto = styled.img`
    width: 100%;
    height: 100%;
    z-index: 2;
    border-radius: 12px;
`;

const UploadButton = styled.img`
    width: 200px;
`;

const FileInput = styled.input`
    display: none;
`;

const CloseButton = styled.img`
    position: absolute;
    top: 20px;
    right: 15px;
    cursor: pointer;
`;

const Input = styled.input`
    width: 100%;
    height: 48px;
    border-radius: 12px;
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    padding-left: 20px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #000000;
`;

const TextArea = styled.textarea`
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    width: 100%;
    height: 160px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #000000;
    padding: 20px;
    resize: none;
`;

const Image = styled.img`
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 3;
`;

const MenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
`;

const Delete = styled.img`
    width: 10px;
`;

interface MenuSettingModalProps {
    onClose: () => void;
    onSaveMenu: () => void;
    selectedMenu?: MenuType;
    storeId: number;
    mode: number;  // 1 add, 2 update
}

export function MenuSettingModal({
    onClose,
    selectedMenu,
    storeId,
    mode
}: MenuSettingModalProps){

    const [ hash, setHash ] = useState(Date.now());
    const [ preview, setPreview ] = useState<any>(null);
    const [ file, setFile ] = useState<any>(null);
    const [ fileType, setFileType ] = useState<string>('');
    const [ alertView, setAlertView ] = useState(false);
    const [ alertTitle, setAlertTitle ] = useState<string>();
    const [ alertDescription, setAlertDescription ] = useState<string>();
    const [ loading, setLoading ] = useState(false);

    const [ menu, setMenu ] = useState<MenuType>({
        id: 0,
        storeId: storeId,
        name: '',
        description: '',
        duration: NaN,
        orderNumber: 0,
        price: NaN,
        uploadImage: ''
    });
  
    const onNameChange = (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value},
        } = event;
        setMenu({...menu, name: value});
    }

    const onDescriptionChange = (event: FormEvent<HTMLTextAreaElement>) => {
        const {
            currentTarget: {value},
        } = event;
        setMenu({...menu, description: value});
    }

    const onDurationChange = (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value},
        } = event;

        if(Number(value) === 0)
        {
            setMenu({...menu, duration: NaN});
        }
        else
        {
            setMenu({...menu, duration: Number(value)});
        }       
    }

    const onPriceChange = (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value},
        } = event;
        
        if(Number(value) === 0)
        {
            setMenu({...menu, price: NaN});
        }
        else
        {
            setMenu({...menu, price: Number(value)});
        }
    }

    const onChange = async (e:React.ChangeEvent<HTMLInputElement>) => {
        const imgs = e.target.files;

        if(!imgs) return;

        setPreview(URL.createObjectURL(imgs[0]));
        setFile(imgs);
        const formData = new FormData();
        formData.append('file', imgs[0]);

        let type = '';
        if(imgs[0].type === 'image/png')
        {
            type = 'png';
            setFileType(type);
        }
        else if(imgs[0].type === 'image/jpg')
        {
            type = 'jpg';
            setFileType(type);
        }
        else if(imgs[0].type === 'image/jpeg')
        {
            type = 'jpg';
            setFileType(type);
        }       
    }

    const updateMenu = async () => {
        //menu validation
        
        if(menu.name === '')
        {
            setAlertTitle("서비스의 이름을 입력해주세요");
            setAlertView(true);
            return;
        }

        if(menu.description === '')
        {
            setAlertTitle("서비스의 설명을 입력해주세요");
            setAlertView(true);
            return;
        }

        if(!checkNumber(menu.duration.toString()))
        {
            setAlertTitle("서비스 소요시간을 입력해주세요");
            setAlertView(true);
            return;
        }
        
        if(!checkNumber(menu.price.toString()))
        {
            setAlertTitle("서비스 가격을 입력해주세요");
            setAlertView(true);
            return;
        }
       
        if(mode === 1 && file === null)
        {
            setAlertTitle("서비스의 사진을 업로드해주세요");
            setAlertView(true);
            return;
        }

        setLoading(true);

        const res = await updateMenuRequest(menu);
        if(res.status === 'success')
        {
            if(file != null)
            {
                const res2 = await uploadMenuImageFileRequest(file, res.data.id, fileType);
                if(res2.status === 'success')
                {
                    onClose();
                    setLoading(false);
                }
                else
                {
                    setLoading(false);
                }
            }
            else
            {
                setLoading(false);
                onClose();
            }
        }
    }

    useEffect(() => {
        if(selectedMenu)
        {
            setMenu(selectedMenu);
        }       
        else
        {

        }

    }, [])

    return (
        <Container>
            {
                loading && <Loading />
            }
            {
                alertView &&
                <AlertModal 
                    title={alertTitle}
                    description={alertDescription}
                    onCheck={() => {
                        setAlertTitle("");
                        setAlertView(false);
                    }}
                />
            }
            <TouchableSection 
                onClick={() => {
                    onClose()
                }}
            />
            <Section>
                <Title>메뉴 설정</Title>
                <Wrapper>
                    <Label>서비스 이름</Label>
                    <Gap 
                        gap={12}
                    />
                    <Input 
                        value={menu.name}
                        onChange={onNameChange}
                        placeholder="서비스 이름"
                    />
                    <Gap 
                        gap={20}
                    />
                    <Label>서비스 설명</Label>
                    <Gap 
                        gap={12}
                    />
                    <TextArea
                        value={menu.description}
                        onChange={onDescriptionChange}
                        placeholder="서비스 설명"
                    >
                    </TextArea>
                    <Gap 
                        gap={20}
                    />
                    <Label>서비스 소요시간 (분)</Label>
                    <Gap 
                        gap={12}
                    />
                    <Input 
                        type="number"
                        pattern="\d*"
                        value={ Number.isNaN(menu.duration) ? '' : menu.duration}
                        onChange={onDurationChange}
                    />
                    <Gap 
                        gap={20}
                    />
                    <Label>서비스 가격 (원)</Label>
                    <Gap 
                        gap={12}
                    />
                    <Input 
                        type="number" 
                        pattern="\d*"
                        value={ Number.isNaN(menu.price) ? '' : menu.price}
                        onChange={onPriceChange}
                    />
                    {/* <Gap 
                        gap={12}
                    />
                    <RowWrapper>
                        <input type="checkbox"/> 노출안함
                    </RowWrapper> */}
                    <Gap 
                        gap={20}
                    />
                    <Label>서비스 사진 업로드</Label>
                    <Gap 
                        gap={12}
                    />
                    <UploadButtonLabel htmlFor={`file`}>
                        <Image 
                            src={require('../../../../styles/assets/icon/cam.png')}
                        />
                        {
                            selectedMenu !== undefined || preview !== null ?
                            <MenuPhoto 
                                src={preview !== null ? preview : `/v1/menu/image/read/${selectedMenu?.id}?${hash}`}
                            /> :
                            ''
                        }
                    </UploadButtonLabel>
                    <FileInput type='file' 
                        accept='image/jpg,image/png,image/jpeg' 
                        name='profile_img' 
                        onChange={onChange}
                        id={`file`}
                    >
                    </FileInput>
                    <Gap 
                        gap={40}
                    />
                    <CenterWrapper>
                        <PageButton 
                            text="저장하기"
                            background="#9C27B0"
                            color="#FFFFFF"
                            onClick={updateMenu}
                        />
                    </CenterWrapper>
                </Wrapper>
            </Section>
        </Container>
    );
}