import React from 'react';
import Router from './pages';
function App() {
  return (
    <>
        <Router></Router>
    </>

  );
}

export default App;
