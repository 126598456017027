import { useState } from "react";
import styled from "styled-components";

import { 
    leadingZeros
} from "../../utilities/convert";

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0,0,0,0.2);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const ButtonSection = styled.div`
    width: 100%;
    height: calc((100vh - 200px) / 2);
`;

const RowSection = styled.div`
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const RowButtonSection = styled.div`
    width: calc((100vw - 240px) / 2);
    height: 100%;
`;

const Section = styled.div`
    width: 240px;
    height: 200px;
    border-radius: 20px;
    background-color: #fff;
    overflow: hidden;
    color: #000000;
    padding: 24px 16px;
    display: flex;   
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 100;
`;

const RowWrapper = styled.div<{margin: number}>`
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    margin-top: ${(props) => props.margin}px;
`;

const Hours = styled.div`
    width: 60px;
    height: 100px;
    white-space: nowrap;
    overflow: auto;
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;
    font-size: 20px;
    text-align: center;
    /* -ms-overflow-style: none;  */
    /* scrollbar-width: none; */
    ::-webkit-scrollbar {
        /* display: none; */
    }
`;

const Time = styled.div`
    width: 60px;
    height: 100px;
    white-space: nowrap;
    overflow: auto;
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;
    font-size: 20px;
    text-align: center;
    /* -ms-overflow-style: none; */
    /* scrollbar-width: none; */
    ::-webkit-scrollbar {
        /* display: none; */
    }
`;

const Element = styled.div<{selected: boolean}>`
    width: 60px;
    height: 25px;
    color: #BCBCBC;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 15px;
`;


const Button = styled.div`
    background-color: #560C7B;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 60px;
    border-radius: 15px;
`;

const Selected = styled.div`
    font-size: 20px;
`;

interface TimePickerProps {
    onSelectTime: (hour: number, minute: number) => void;
    hour: number;
    minute: number;
}

const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

export function TimePicker({
    onSelectTime,
    hour,
    minute
}: TimePickerProps){

    const [ selectedHour, setSelectedHour ] = useState(hour);
    const [ selectedMinute, setSelectedMinute ] = useState(minute);

    const selectTime = (hour: number, minute: number) => {
        onSelectTime(hour, minute)
    }

    return (
        <Container>
            <ButtonSection
                onClick={() => {
                    selectTime(selectedHour, selectedMinute);
                }}
            />
            <RowSection>
                <RowButtonSection
                    onClick={() => {
                        selectTime(selectedHour, selectedMinute);
                    }}
                />
                <Section>
                    <RowWrapper margin={0}>
                        <Hours>
                            {
                                hours.map((hour, index) => {
                                    return (
                                        <Element key={index}
                                            onClick={() => {
                                                setSelectedHour(hour);
                                            }}
                                            selected={selectedHour === hour ? true : false}
                                        >{hour}</Element>
                                    );
                                })
                            }
                    
                        </Hours>
                        <Time>
                            <Element
                                onClick={() => {
                                    setSelectedMinute(0);
                                }}
                                selected={selectedMinute === 0 ? true : false}
                            >00</Element>
                            <Element
                                onClick={() => {
                                    setSelectedMinute(10);
                                }}
                                selected={selectedMinute === 10 ? true : false}
                            >10</Element>
                            <Element
                                onClick={() => {
                                    setSelectedMinute(20);
                                }}
                                selected={selectedMinute === 20 ? true : false}
                            >20</Element>
                            <Element
                                onClick={() => {
                                    setSelectedMinute(30);
                                }}
                                selected={selectedMinute === 30 ? true : false}
                            >30</Element>
                            <Element
                                onClick={() => {
                                    setSelectedMinute(40);
                                }}
                                selected={selectedMinute === 40 ? true : false}
                            >40</Element>
                            <Element
                                onClick={() => {
                                    setSelectedMinute(50);
                                }}
                                selected={selectedMinute === 50 ? true : false}
                            >50</Element>                  
                        </Time>
                    </RowWrapper>
                    <RowWrapper margin={25}>
                        <Selected>{leadingZeros(selectedHour.toString(), 2)} : {leadingZeros(selectedMinute.toString(), 2)}</Selected>
                        <Button
                            onClick={() => {
                                selectTime(selectedHour, selectedMinute);
                            }}
                        >설정</Button>
                    </RowWrapper>
                </Section>
                <RowButtonSection 
                    onClick={() => {
                        selectTime(selectedHour, selectedMinute);
                    }}
                />
            </RowSection>
            <ButtonSection 
                onClick={() => {
                    selectTime(selectedHour, selectedMinute);
                }}
            />
        </Container>
    );
}