import styled from "styled-components";

import {
    OptionSelect
} from '../molecules';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`;

const TouchableSection = styled.div`
    width: 100%;
    height: calc(100vh - 420px);
    cursor: pointer;
`;

const Section = styled.div`
    width: 100%;
    height: 420px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
    overflow: auto;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 99;
`;

const Title = styled.div`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.266667px;
    color: #191F28;
    height: 56px;
    width: 100%;
    border-bottom: 1px solid #979797;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Gap = styled.div<{gap: number}>`
    height: ${(props) => props.gap}px;
`;


interface SearchModalProps{
    onClose: () => void;
    onSearch: (start: string, end: string, phone: string) => void;
    start: string;
    end: string;
    phone: string;
}

export function SearchModal({
    onClose,
    onSearch,
    start,
    end,
    phone
}: SearchModalProps){
    return (
        <Container>
            <TouchableSection 
                onClick={onClose}
            />
            <Section>
                <Title>조회 설정</Title>
                <Gap 
                    gap={32}
                />
                <OptionSelect 
                    start={start}
                    end={end}
                    phone={phone}
                    onSelect={onSearch}
                />                
            </Section>
        </Container>
    );
}

