import styled from "styled-components";

import { useRecoilValue } from "recoil";
import { storeState } from "../../../../stores";
import { useEffect, useState } from "react";

import {    
    getDateValidationRequest
} from '../../../../apis/BookingApi';
import { leadingZeros } from "../../../../utilities/convert";
import { TimePicker } from "../../TimePicker";

import {
    SelectBox,
    PageButton,
    Gap
} from '../../../atoms';

import {
    AlertModal
} from '../../../modals';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0,0,0,0.7);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`;

const TouchableSection = styled.div`
    width: 100%;
    height: calc(100vh - 431px);
    cursor: pointer;
`;

const Section = styled.div`
    width: 100%;
    height: 431px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
    overflow: auto;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1000;
`;

const Text = styled.div`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #1E1E1E;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 5vw;
    margin-top: 10px;
    margin-bottom: 10px;
`;

const Title = styled.div`
    min-height: 60px;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #CEDDE2;
    padding-left: 5vw;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: #191F28;
    margin-bottom: 10px;
`;


interface SelectDateModalProps {
    onClose: () => void;
    onOk: (year: number, month: number, day: number) => void;
    selectedYear: number;
    selectedMonth: number;
    selectedDay: number;
}

export function SelectDateModal({
    onClose,
    onOk,
    selectedYear,
    selectedMonth,
    selectedDay
}: SelectDateModalProps){

    const store = useRecoilValue(storeState);
    
    const [ year, setYear ] = useState<number>(selectedYear);
    const [ month, setMonth ] = useState<number>(selectedMonth);
    const [ day, setDay ] = useState<number>(selectedDay);    


    const [ isAlert, setIsAlert ] = useState(false);
    const [ alertTitle, setAlertTitle ] = useState<string>('');
    const [ alertDescription, setAlertDescription ] = useState<string>('');

    const [ viewConfirmModal, setViewConfirmModal ] = useState(false);

    const [ dayList, setDayList ] = useState<string []>([]);

    // 휴무일인지 체크 api 
    const checkDate = async () => {

        const requestDate: string = `${year}-${leadingZeros(month.toString(), 2)}-${leadingZeros(day.toString(), 2)}`;
        const res = await getDateValidationRequest(store.id, requestDate);
        if(res.status === 'success')
        {
            if(!res.data)
            {
                setAlertTitle("휴무일 입니다.");
                setAlertDescription("스케줄을 확인해주세요.")
                setIsAlert(true);
            }
            else
            {
                onOk(year, month, day);
            }
        }
    }

    useEffect(() => {
        // day list 
        const lastDay = new Date(year, month, 0).getDate();
        let list: string[] = [];
        for(let i = 0; i < lastDay; i++)
        {
            list.push((i+1).toString());
        }
        setDayList(list);

    }, [month])

    // select box 

    return (
        <Container>
            <TouchableSection
                onClick={onClose}
            />
            {
                isAlert && 
                <AlertModal 
                    title={alertTitle}
                    description={alertDescription}
                    onCheck={() => {
                        setAlertTitle('');
                        setAlertDescription('');
                        setIsAlert(false);
                    }}
                />
            }
            <Section className="request">
                <Title>예약 날짜 설정</Title>
                <Wrapper>
                    <Text>년도 선택</Text>
                    <SelectBox 
                        options={['2023', '2024', '2025', '2026']}
                        onSelect={(value) => {
                        setYear(Number(value)) 
                        }}
                        selectedValue={year.toString()}
                    />
                </Wrapper>               
                <Wrapper>
                    <Text>월 선택</Text>
                    <SelectBox 
                        options={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']}
                        onSelect={(value) => {
                            setMonth(Number(value))
                        }}
                        selectedValue={month.toString()}
                    />
                </Wrapper>
                <Wrapper>
                    <Text>일 선택</Text>
                    {
                        dayList.length > 0 &&
                        <SelectBox 
                            options={dayList}
                            onSelect={(value) => {
                                setDay(Number(value))
                            }}
                            selectedValue={day.toString()}
                        />
                    }                
                </Wrapper> 
                <Wrapper>
                    <PageButton 
                        text="설정완료"
                        background="#9C27B0"
                        color="#FFFFFF"
                        onClick={checkDate}
                    />       
                </Wrapper> 
            </Section>
        </Container>
    );
}