import styled from "styled-components";
import Header from "./Header";
import { useEffect, useState } from "react";

import { 
    leadingZeros, setComma 
} from "../../../utilities/convert";

import {
    SearchButton,
    TabButton,
    Gap
} from '../../../components/atoms';

import {
    SearchModal
} from '../../../components/modals';

import {
    BookingStatType,
    CustomerInfoType,
    ServiceStatType
} from '../../../types';

import {
    BookingStat,
    MenuStat,
    CardCashStat,
    StoreBookingList
} from '../../../components/organisms';

import {
    getStoreStatRequest
} from '../../../apis/BookingApi';

import { 
    storeState 
} from '../../../stores';
import { useRecoilValue } from "recoil";

const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
`;

const ListWrapper = styled.div`
    overflow-y: auto;
    width: 100%;
    height: calc(100vh - 290px);
`;

const ButtonWrapper = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 30% 30% 30%;
    gap: 11px;
    padding-left: 5vw;
    padding-right: 5vw;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    padding-left: 5vw;
    padding-right: 5vw;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.5625px;
    color: #191F28;
`;

const Revenue = styled.div`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.4375px;
    color: #7D93A6;
    height: 60px;
    margin-top: 13px;
`;

const Line = styled.div<{gap: number; background: string}>`
    height: ${(props) => props.gap}px;
    background: ${(props) => props.background};
`;

function List(){
    // search
    // tab button (예약 통계, 서비스 통계, 내역 보기)
    // page 
    const store = useRecoilValue(storeState);
    const [ mode, setMode ] = useState(0);
    const [ searchModal, setSearchModal ] = useState(false);
    const [ start, setStart ] = useState<string>('');
    const [ end, setEnd ] = useState<string>('');
    const [ phoneNumber, setPhoneNumber ] = useState<string>('');
    const [ bookingStatData, setBookingStatData ] = useState<BookingStatType>({
        totalBooking: 0,
        serviceComplete: 0,
        noshow: 0,
        bookingCancel: 0  
    });
    const [ serviceStatData, setServiceStatData ] = useState<ServiceStatType>({
        menuRatio: [
            {
                menuName: '메뉴',
                percent: "0",
            }
        ],
        paymentRatio: {
            cardAmount: 0,
            cashAmount: 0,
            usePoint: 0,
            revenue: 0
        }
    })

    const [ customerList, setCustomerList ] = useState<CustomerInfoType []>([]);
    
    const getStoreStat = async (start:string, end:string, phone:string) => {
        const res = await getStoreStatRequest(store.id, phone, start, end);
        if(res.status === 'success')
        {
            setBookingStatData(res.data.bookingStat);
            setServiceStatData(res.data.serviceStat)
        }
    }
      
    useEffect(() => {
        const getInitStoreStat = async () => {
            let cur = new Date();
            let start = cur.getFullYear() + "-" + leadingZeros((cur.getMonth() + 1).toString(), 2) + "-01 00:00:00";
            const lastDay = new Date(cur.getFullYear(), cur.getMonth() + 1, 0).getDate();
            let end = cur.getFullYear() + "-" + leadingZeros((cur.getMonth() + 1).toString(), 2) + "-" + lastDay + " 23:59:59";            
            
            const res = await getStoreStatRequest(store.id, 'aixk4hfk2dd', start, end)
            setStart(start);
            setEnd(end);
            setPhoneNumber("aixk4hfk2dd")
            if(res.status === 'success')
            {
                setBookingStatData(res.data.bookingStat);
                setServiceStatData(res.data.serviceStat);
            }
        }
        getInitStoreStat();
    }, [])

    return (
        <Container>
            {
                searchModal &&
                <SearchModal 
                    onClose={() => {
                        setSearchModal(false);
                    }}
                    onSearch={async (start, end, phone) => {
                        setSearchModal(false);
                        setStart(start);
                        setEnd(end);
                        setPhoneNumber(phone);
                        await getStoreStat(start, end, phone);
                    }}
                    start={start}
                    end={end}
                    phone={phoneNumber}
                />
            }
            <Header />
            <div style={{height: '96px'}}></div>
            <Wrapper>
                <SearchButton 
                    onClick={() => {
                        setSearchModal(true);
                    }}
                />
            </Wrapper>
            
            <Line 
                gap={40}
                background="#FFFFFF"
            />
            <ButtonWrapper>
                <TabButton 
                    text="예약 통계"
                    background={mode === 0 ? '#FF4A8B' : '#CEDDE2'}
                    color={mode === 0 ? '#FFFFFF' : '#525558'}
                    onClick={() => {
                        setMode(0)
                    }}
                />
                <TabButton 
                    text="서비스 통계"
                    background={mode === 1 ? '#FF4A8B' : '#CEDDE2'}
                    color={mode === 1 ? '#FFFFFF' : '#525558'}
                    onClick={() => {
                        setMode(1)
                    }}
                />
                <TabButton 
                    text="내역 보기"
                    background={mode === 2 ? '#FF4A8B' : '#CEDDE2'}
                    color={mode === 2 ? '#FFFFFF' : '#525558'}
                    onClick={() => {
                        setMode(2)
                    }}
                />
            </ButtonWrapper>   
            {/* graph */}
            {
                mode === 0 ? 
                    <Wrapper>
                        <BookingStat 
                            data={
                                bookingStatData
                            }
                        />
                    </Wrapper>                    
                :
                mode === 1 ? 

                    <ListWrapper>
                        <Wrapper>
                            <MenuStat 
                                datas={serviceStatData.menuRatio}
                            />                            
                        </Wrapper>
                        <Line 
                            gap={8}
                            background="#F2F2F2"
                        />
                        <Wrapper>
                            <CardCashStat 
                                data={
                                    serviceStatData.paymentRatio
                                }
                            />
                        </Wrapper>
                    </ListWrapper>
                : 
                    <>
                       <Wrapper>
                            <StoreBookingList 
                                start={start}
                                end={end}
                                phoneNumber={phoneNumber}
                            />
                        </Wrapper>
                    </>
            }
            
        </Container>
    );
}

export default List;