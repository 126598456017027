import { useEffect, useState } from "react";
import styled from "styled-components";

import {
    getMenuListRequest,
    deleteMenuRequest,
    updateMenuRequest,
    uploadMenuImageFileRequest
} from '../../apis/BookingApi';

import {
    CardButton,
    Gap
} from '../atoms';

import {
    ModalHeader,
    MenuSimpleCard
} from '../molecules';

import {
    MenuSettingModal
} from '../modals';

import {
    MenuType
} from '../../types';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background: #FFFFFF;
`;

const Section = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 5vw;
`;

const MenuListWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 12px;
    justify-content: flex-start;
    align-items: center;
    height: 80vh;
    overflow-y: auto;
    padding-top: 20px;
`;

interface MenuListModalProps {
    onClose: () => void;
    storeId: number;
}

export function MenuListModal({
    onClose,
    storeId
}: MenuListModalProps){
    // loading menu list 
    const [ menuList, setMenuList ] = useState<MenuType []>();
    const [ modal, setModal ] = useState(false);
    const [ selectedMenu, setSelectedMenu ] = useState<MenuType>();
    const [ mode, setMode ] = useState(1);

    const getMenus = async (storeId: number) => {
        const menuResponse = await getMenuListRequest(storeId);

        if(menuResponse.status === 'success')
        {
            setMenuList([...menuResponse.data]);
        }
    }

    const addMenu = async () => { 
        await getMenus(storeId);
    }

    const deleteMenu = async (menuId: number) => {
        const res = await deleteMenuRequest(menuId);
        if(res.status === 'success')
        {
            await getMenus(storeId);           
        }
    }
    
    const selectedMenuUpdate = async (menu: MenuType) => {
        setMode(2);
        setSelectedMenu(menu);
        setModal(true);
    }

    useEffect(() => {
        document.body.style.overflow = "hidden";
        getMenus(storeId);
        return () => {
            document.body.style.overflow = "unset";
        }
    }, [])

    return (
        <Container>
            {
                modal && 
                <MenuSettingModal 
                    onClose={() => {
                        setMenuList([])
                        getMenus(storeId);
                        setSelectedMenu(undefined);
                        setModal(false);

                    }}
                    selectedMenu={selectedMenu}
                    onSaveMenu={addMenu}
                    storeId={storeId}
                    mode={mode}
                />
            }
            <ModalHeader 
                title="메뉴 설정"
                onClose={onClose}
            />
            <Gap 
                gap={56}
            />
            <Section>                
                <MenuListWrapper>
                {
                    menuList?.map((menu, index) => {
                        return (
                            <MenuSimpleCard 
                                key={index}
                                menu={menu}
                                onDelete={() => {
                                    if(window.confirm("정말 삭제하시겠습니까?"))
                                    {
                                        deleteMenu(menu.id)
                                    }
                                }}
                                onUpdate={() => {
                                    selectedMenuUpdate(menu)
                                }}
                            />
                        );
                    })
                }
                </MenuListWrapper>  
                <Gap 
                    gap={20}
                />
                <CardButton 
                    text="메뉴 추가하기"
                    background="#FFFFFF"
                    color="#9C27B0"
                    onClick={() => {
                        setMode(1);
                        setModal(true);
                    }}
                />
            </Section>  
        </Container>
    );
}