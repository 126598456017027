import { useEffect, useState } from "react";
import styled from "styled-components";
var BASE_URL = process.env.REACT_APP_TARGET_URL;

const Container = styled.div`
    position: relative;
    width: 45vw;
    height: 45vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    flex-direction: column;
`;

const Image = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 20px;
`;

const Cover = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 5;
    top: 0;
    left: 0;
    background-color: rgba(1, 1, 1, 0.2);
    border-radius: 20px;
    border: 2px solid #9C27B0;
`;

const Icon = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    width: 35px;
    transform: translate(-50%, -50%);
`;

interface ImageButtonProps {
    imageId: number;
    selected: boolean;
    onClick: () => void;
}

export function ImageButton({
    imageId,
    selected,
    onClick
}: ImageButtonProps){
    const [ hash, setHash ] = useState(Date.now());
    
    useEffect(() => {
        if(selected)
            console.log("id: " + imageId)
    }, [selected])

    return (
        <Container
            onClick={onClick}
        >
            <Image 
                src={BASE_URL+`/v1/menu/image/read/${imageId}?${hash}`}
            />
            {
                selected ? <Cover /> : null
            }
            {
                selected ? <Icon src={require('../../styles/assets/check.png')}/> : null
            }
            
        </Container>
    );
}