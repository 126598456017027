import styled from "styled-components";
import { 
    MenuRatioType 
} from "../../types";

import {
    XBarGraphList,
    MenuRatio
} from '../molecules';

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 32px;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.5625px;
    color: #191F28;
`;

const Gap = styled.div`
    height: 20px;
`;

interface MenuStatProps {
    datas: MenuRatioType[]
}

export function MenuStat({
    datas
}: MenuStatProps){
    return (
        <Container>
            <Title>메뉴별 매출 비율</Title>
            <Gap/>
            <MenuRatio 
                datas={datas}
            />
            <Gap />
            <XBarGraphList 
                datas={                    
                    datas.map((data) => {
                        return (
                            data.percent
                        );
                    })                    
                }
            />
            
        </Container>
    );
}