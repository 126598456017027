import { useState } from "react";
import styled from "styled-components";
import { AlertModal } from "../../../modals";

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 40px 5vw;
    flex-direction: column;
`;

const FileInput = styled.input`
    display: none;
`;

const UploadButtonLabel = styled.label`
    background: #AFB8C0;
    width: 44vw;
    height: 60vw;
    border-radius: 12px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1vw;
`;

const Image = styled.img`
    width: 32px;
    z-index: 3;
`;

const Delete = styled.img`
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 3;
    background: #FFFFFF;
    border-radius: 50%;
    width: 25px;
`;

const PhotoWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const PreviewWrapper = styled.div`
    width: 44vw;
    height: 60vw;
    border-radius: 20px;
    position: relative;
    margin-right: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Preview = styled.img`
    width: 100%;
    height: auto;
    max-height: 60vw;
    border-radius: 20px;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.175px;
    color: #7D93A6;
    /* height: 55px; */
    overflow: hidden;
    white-space: pre-line;
    word-wrap:break-all;
    margin-top: 10px;
    margin-bottom: 10px;
`;

interface PhotoUploadProps {
    onSetUploadFiles: (files: any[]) => void;
    description: string | null;
}

export function PhotoUpload ({
    onSetUploadFiles,
    description
}: PhotoUploadProps) {

    const [ preview, setPreview ] = useState<any []>([]);    
    const [ files, setFiles ] = useState<any []>([]);
    const [ alertModal, setAlertModal ] = useState(false);

    const deletePhoto = (index: number) => {
        if(preview.length === 1)
        {
            setPreview([]);
            setFiles([]);
            onSetUploadFiles([]);
        }
        else
        {
            setPreview(preview.splice(index, 1))
            const local: any[] = files;
            setFiles(files.splice(index, 1))
            onSetUploadFiles(local.splice(index, 1));
        }
    }

    const onChange = async (e:React.ChangeEvent<HTMLInputElement>) => {
        const imgs = e.target.files;

        if(!imgs) return;

        setPreview([...preview, URL.createObjectURL(imgs[0])]);
        setFiles([...files, imgs]);
        const local: any[] = files;
        onSetUploadFiles([...local, imgs]);
        const formData = new FormData();
        formData.append('file', imgs[0]);

        let type = '';
        if(imgs[0].type === 'image/png')
        {
            type = 'png';
          
        }
        else if(imgs[0].type === 'image/jpg')
        {
            type = 'jpg';
           
        }
        else if(imgs[0].type === 'image/jpeg')
        {
            type = 'jpg';
            
        }  
        else
        {
            setAlertModal(true);
        }     
    }

    // preview delete 

    return (
        <Container>
            {
                alertModal && 
                <AlertModal 
                    title="업로드는 이미지 형식만 가능합니다."
                    onCheck={() => {
                        setAlertModal(false);
                    }}
                />
            }
            <Title>사진 업로드</Title>
            <Label>{description}</Label>
            <PhotoWrapper>
            {
                preview.map((img, index) => {
                    return(
                        <PreviewWrapper>
                            <Preview 
                                src={img !== null ? img : ''}
                            />
                            <Delete 
                                onClick={() => {
                                    deletePhoto(index)
                                }}
                                src={require('../../../../styles/assets/icon/x-mark.png')}
                            />
                        </PreviewWrapper>
                    );
                })
            }
            {
                preview.length < 2 &&
                <UploadButtonLabel htmlFor={`file`}>
                    <Image 
                        src={require('../../../../styles/assets/icon/plus.png')}
                    />
                    
                </UploadButtonLabel>
            }
            </PhotoWrapper>
            <FileInput type='file' 
                accept='image/jpg,image/png,image/jpeg' 
                name='profile_img' 
                onChange={onChange}
                id={`file`}
            >
            </FileInput>
        </Container>
    );
}