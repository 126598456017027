import styled from "styled-components";

import {
    MenuRatioType
} from '../../types';

const Container = styled.div`
    width: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 100%;
    gap: 12px;
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.4375px;
    white-space : nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
    color: #7D93A6;
`;

const Value = styled.span<{color: string}>`
    color: ${(props) => props.color};
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.2px;
`;

interface MenuRatioProps {
    datas: MenuRatioType [];
}

export function MenuRatio({
    datas
}: MenuRatioProps){
    return (
        <Container>
            {
                datas.length > 0 &&
                datas.map((data, index) => {
                    return (
                        <Wrapper key={index}>
                            <Label>
                                {data.menuName}
                            </Label>
                            <Value
                                color={index % 4 === 0 ? '#9C27B0' : index % 4 === 1 ? '#4DD0E1' : index % 4 === 2 ? '#F3A84F' : index % 4 === 3 ? '#CEDDE2' : ''}
                            >
                                {data.percent}%
                            </Value>
                        </Wrapper>
                    );
                })

            }
        </Container>
    );
}