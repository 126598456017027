import styled from "styled-components";

import {
    Gap
} from '../atoms';

import {
    EtcInformationType
} from '../../types';

const Container = styled.div`
    width: 100%;
    border-radius: 16px;
    background: #F4F7F8;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
`;

const RowWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.5625px;
    color: #191F28;
`;

const Delete = styled.img`
    width: 16px;
`;

const Contents = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.4375px;
    color: #191F28;
    white-space: pre-line;
    word-wrap:break-all;
`;

interface EtcInfoSimpleCardProps {
    etcInfo: EtcInformationType;
    onDelete: () => void;
    onUpdate: () => void;
}

export function EtcInfoSimpleCard({
    etcInfo,
    onDelete,
    onUpdate
}: EtcInfoSimpleCardProps){
    return (
        <Container>
            <RowWrapper>
                <Title>{etcInfo.title}</Title>
                <Delete 
                    src={require('../../styles/assets/icon/trash.png')}
                    onClick={onDelete}
                />
            </RowWrapper>
            <Gap 
                gap={10}
            />
            <RowWrapper>
                <Contents>{etcInfo.description}</Contents>
            </RowWrapper>
        </Container>
    );
}