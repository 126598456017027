import styled from "styled-components";
import {
    Gap
} from '../../../components/atoms'

const Container = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.bgColor};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 50px;
`;

const Text = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 52px;
    /* identical to box height, or 144% */
    letter-spacing: -0.5625px;
    color: #000000;
    @media only screen and (max-width: 768px) {
        font-size: 30px;
        line-height: 45px;
    }
`;

const SubText = styled.span`
   font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.3125px;
    color: #8E8E8E;
    text-align: center;
    margin-top: 10px;
    @media only screen and (max-width: 768px) {
        font-size: 15px;
        line-height: 26px;
    }
`;

const RowWrapper = styled.div`
    width: 1200px;    
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
`;

const ColWrapper = styled.div` 
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
`;

const Image = styled.img`
    margin-top: 40px;
    @media only screen and (max-width: 768px) {
        width: 70%;
    }
`;

function Ours(){
    return (
        <Container>        
            <Gap 
                gap={40}
            />
            <ColWrapper>
                <ColWrapper>
                    <Text>인스타 하세요?</Text>
                    <SubText>인스타 프로필에 예약 페이지로<br/> 바로 연결할 수 있는 URL을 제공해 드려요.</SubText>
                </ColWrapper>
                <Image src={require('../../../styles/assets/use1.png')} />
            </ColWrapper>
            <Gap 
                gap={100}
            />
            <ColWrapper>               
                <ColWrapper>
                    <Text>앱 설치 없이<br/>빠르고 간편하게</Text>
                    <SubText>앱 설치 없이 금요일 11시 카카오 채널을 통해<br/> 고객님들의 예약을 간편하게 확정하고 관리할 수 있어요.</SubText>
                </ColWrapper>
                <Image src={require('../../../styles/assets/use2.png')} />
            </ColWrapper>
            <Gap 
                gap={100}
            />
            <ColWrapper>                
                <ColWrapper>
                    <Text>업무가 편해집니다</Text>
                    <SubText>노트에 관리하던 고객관리 NO! 고객 관리는<br/>금요일 11시를 통해 편하게 해보세요.</SubText>
                </ColWrapper>
                <Image src={require('../../../styles/assets/use3.png')} />
            </ColWrapper>
            <Gap 
                gap={100}
            />
            <ColWrapper>                
                <ColWrapper>
                    <Text>시간을 소중하게</Text>
                    <SubText>하루하루 일정에 맞게 원하는 대로 스케줄링해 보세요.</SubText>
                </ColWrapper>
                <Image src={require('../../../styles/assets/use4.png')} />
            </ColWrapper>
        </Container>
    )
}

export default Ours;