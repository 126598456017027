import styled from "styled-components";
import { useEffect, useState } from "react";


import {
    Gap,
    PageButton
} from '../../../atoms';

import {
    InfoCard,
    EtcInfoCard,
    Loading,
    UserUploadPhotoGrid
} from '../../../molecules';

import {
    BookingType
} from '../../../../types'


import {
    getBookingDtailRequest
} from '../../../../apis/BookingApi';

import {
    convertTimestampToDate,
    convertTimestampToTime
} from '../../../../utilities/convert';

import {
    PhotoViewModal
} from '../../../modals';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0,0,0,0.9);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`;

const TouchableSection = styled.div`
    width: 100%;
    height: 40%;
    cursor: pointer;
`;

const Section = styled.div`
    width: 100%;
    min-height: 60%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
    overflow: auto;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 100;
`;

const Title = styled.div`
    min-height: 60px;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #CEDDE2;
    padding-left: 5vw;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: #191F28;
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 40px 5vw;
`;

const CenterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

interface BookingDetailModalProps {
    onClose: () => void;
    bookingId: number
}

export function BookingDetailModal({
    onClose,
    bookingId,
}: BookingDetailModalProps){

    const [ bookingInfo, setBookingInfo ] = useState<BookingType>();
    const [ selectedNumber, setSelectedNumber ] = useState(0);
    const [ photoView, setPhotoView ] = useState(false);

    useEffect(() => {
        const initLoadBookingInfo = async () => {
            const res = await getBookingDtailRequest(bookingId);
            if(res.status === 'success')
            {
                setBookingInfo(res.data);
            }
        }
        initLoadBookingInfo();
    }, [])

    return (
        <Container>
            <TouchableSection 
                onClick={onClose}
            />
            {
                photoView &&
                <PhotoViewModal 
                    bookingId={bookingId}
                    orderNumber={selectedNumber}
                    onClose={() => {
                        setSelectedNumber(0);
                        setPhotoView(false);
                    }}
                />
            }
            {
                bookingInfo && 
                <Section>
                    <Title>{bookingInfo.userName}님의 예약 상세 정보</Title>
                    <Wrapper>
                        <InfoCard 
                            infos={[
                                {key: '예약 요청일', value: `${convertTimestampToDate(bookingInfo.requestDate)}`},                                 
                                {
                                    key: '예약 요청시간',
                                    value: `${convertTimestampToTime(bookingInfo.time)}`,
                                },             
                                {key: '예약자 이름', value: `${bookingInfo.userName}`},
                                {key: '예약자 휴대폰번호', value: `${bookingInfo.userPhone}`},
                                {key: '요청서비스', value: `${bookingInfo.menuName}`}  
                            ]}
                        />
                        <Gap 
                            gap={12}
                        />
                        <EtcInfoCard 
                            infos={
                                bookingInfo.bookingEtcInfoList.map((etc, index) => {
                                    return (
                                        {key: etc.title, value: etc.contents}
                                    );
                                })                    
                            }
                        />
                        <Gap 
                            gap={12}
                        />
                        {
                            bookingInfo.photoCount > 0 &&
                            <UserUploadPhotoGrid 
                                bookingId={bookingInfo.bookingId}
                                count={bookingInfo.photoCount}
                                onPhotoViewModal={(bookingId, orderNumber) => {
                                    setSelectedNumber(orderNumber);
                                    setPhotoView(true);
                                }}
                            />
                        }
                        <Gap 
                            gap={20}
                        />                     
                    </Wrapper>
                </Section>
            }
            
        </Container>
    )
}