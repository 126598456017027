import styled from "styled-components";

import {
    ImageButton
} from '../atoms';

import {
    MenuType
} from '../../types';
import { useState } from "react";

const Container = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.175px;
    color: #000000;
    margin-top: 8px;
`;

interface ButtonGridProps {
    menus: MenuType [],
    onSelected: (id: number, name: string) => void;
}

export function ButtonGrid({
    menus,
    onSelected
}: ButtonGridProps){

    const [ selectedId, setSelectedId ] = useState(0);
    // 선택된 나머지는 모두 해제 
    const selectedMenu = (index: number) => {
        setSelectedId(menus[index].id);
        onSelected(menus[index].id, menus[index].name);
    }
    return (
        <Container>
            {
                menus.map((menu, index) => {
                    return ( 
                        <Wrapper
                            key={index}    
                        >
                            <ImageButton  
                                imageId={menu.id}    
                                selected={selectedId == menu.id ? true : false}
                                onClick={() => selectedMenu(index)}                
                            />
                            <Label>{menu.name}</Label>
                        </Wrapper>
                    )
                })
            }
        </Container>
    );
}