import styled from 'styled-components';

import {
    Gap
} from '../atoms';

import {
    MenuType
} from '../../types';
import { useState } from 'react';

var BASE_URL = process.env.REACT_APP_TARGET_URL;

const Container = styled.div`
    width: 100%;
    height: 88px;
    background: #F4F7F8;
    border-radius: 16px;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const ColWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 20px;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    letter-spacing: -0.175px;
    color: #4B9DFF;
    margin-left: -20px;
`;

const Name = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.2px;
    color: #333D4B;
`;

const TextButton = styled.span`
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
`;

const Image = styled.img`
    width: 48px;
    height: 48px;
    border-radius: 8px;
`;

interface MenuSimpleCardProps {
    menu: MenuType;
    onUpdate: () => void;
    onDelete: () => void;
}

export function MenuSimpleCard({
    menu,
    onUpdate,
    onDelete
}: MenuSimpleCardProps){
    const [ hash, setHash ] = useState(Date.now());
    
    return (
        <Container>
            <Image src={BASE_URL+`/v1/menu/image/read/${menu.id}?${hash}`}/>
            <ColWrapper>
                <Name>{menu.name}</Name>
                <Gap 
                    gap={17}
                />
                <RowWrapper>
                    <TextButton
                        onClick={onUpdate}
                    >수정하기</TextButton>
                    |
                    <TextButton
                        onClick={onDelete}
                    >삭제하기</TextButton>
                </RowWrapper>
            </ColWrapper>
        </Container>
    );
}