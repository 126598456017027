import { useEffect, useState } from "react";
import styled from "styled-components";

import {
    EtcInformationType
} from '../../types';

import {
    Gap,
    CardButton
} from '../atoms';

import {
    ModalHeader,
    EtcInfoSimpleCard
} from '../molecules';

import {
    EtcInfoSettingModal
} from '../modals';

import {
    getEtcInfoListRequest,
    updateEtcInfoRequest,
    deleteEtcInfoRequest
} from '../../apis/BookingApi'

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background: #FFFFFF;
`;

const Section = styled.div`
    display: flex;
    width: 100%;
    height: calc(100vh - 56px);
    overflow-y: auto;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 40px 5vw;
`;

const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-template-columns: 100%;
    gap: 12px;
    justify-content: center;
    align-items: center;
`;

const Wrapper = styled.div`
    height: 40px;
    margin-top: 20px;
`;

interface EtcInfoListModalProps {
    onClose: () => void;
    storeId: number;
}

export function EtcInfoListModal({
    onClose,
    storeId
}: EtcInfoListModalProps){
    const [ list, setList ] = useState<EtcInformationType []>();
    const [ etcModalView, setEtcModalView ] = useState(false); 
    const [ selectedEtcInfo, setSelectedEtcInfo ] = useState<EtcInformationType>();

    const getEtcInfo = async (storeId: number) => {
        const etcInfoResponse = await getEtcInfoListRequest(storeId);
        if(etcInfoResponse.status === 'success')
        {
            setList([...etcInfoResponse.data]);
        }
    }

    const deleteEtcInfo = async (etcInfoId: number) => {
        const res1 = await deleteEtcInfoRequest(etcInfoId);
        if(res1.status === 'success')
        {
            await getEtcInfo(storeId)
        }
        // const res2 = await getEtcInfo(storeId);
    }

    useEffect(() => {
        getEtcInfo(storeId);

        var add:HTMLDivElement =  document.getElementsByClassName('request')[0] as HTMLDivElement;        
        add.style.overflow = 'hidden';
       
        return () => {
            add.style.overflow = 'auto';
        }
    }, [])

    return (
        <Container>
            {
                etcModalView &&
                <EtcInfoSettingModal 
                    onClose={() => {
                        setEtcModalView(false);
                    }}
                    selectedInfo={selectedEtcInfo}
                    onSaveEtcInfo={() => {
                        getEtcInfo(storeId);
                        setEtcModalView(false);
                    }}
                    storeId={storeId}
                />
            }
            <ModalHeader 
                title="추가입력정보"
                onClose={onClose}
            />
            <Gap 
                gap={56}
            />
            <Section>
                <ListWrapper>
                    {
                        list?.map((info, index) => {
                            return (
                                <EtcInfoSimpleCard
                                    key={index}
                                    etcInfo={info}
                                    onUpdate={() => {}}
                                    onDelete={() => {
                                        deleteEtcInfo(info.id)
                                    }}
                                />
                            );
                        })
                    }
                </ListWrapper>
                <Wrapper>
                    <CardButton 
                        text="추가하기"
                        background="#FFFFFF"
                        color="#9C27B0"
                        onClick={() => {
                            setEtcModalView(true);
                        }}
                    />
                </Wrapper>
                
            </Section>
        </Container>
    );
}