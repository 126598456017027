import styled from "styled-components";



import {
    CustomerCardList
} from '../../../components/organisms'

const Container = styled.div`
    width: 100%;
`;

function Customer(){
    return (
        <Container>
            <CustomerCardList />
        </Container>
    );
}

export default Customer;