import styled from "styled-components";

const Bar = styled.div`
    width: 100%;
    height: 50px;
    background-color: #FFFFFF;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    z-index: 10;
`;

const Logo = styled.img`
    width: 120px;
`;

const CalendarButton = styled.img`
    width: 24px;
`;

const Button = styled.span`
    color: ${(props) => props.theme.textColor};
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    cursor: pointer;
    color: #FFFFFF;
`;

const RowWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    justify-content: center;
    align-items: center;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface HeaderProps {

}

function Header({
    
}:HeaderProps) {
    return (
        <Bar>
            <Logo src={require('../../../styles/assets/logo_new.png')} />
            <Wrapper>

            </Wrapper>
        </Bar>
    );
}

export default Header;