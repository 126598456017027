import styled from "styled-components";

const Button = styled.div<{
    background: string;
    color: string;
}>`
    width: 100%;
    height: 38px;
    background: ${(props) => props.background};
    color: ${(props) => props.color};
    cursor: pointer;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.266667px;
    border-radius: 23.5px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface TabButtonProps {
    text: string;
    background: string;
    color: string;
    onClick: () => void;
}

export function TabButton({
    text,
    background,
    color,
    onClick
}: TabButtonProps){
    return (
        <Button
            background={background}
            color={color}
            onClick={onClick}
        >{text}</Button>
    );
}