import styled from "styled-components";

import {
    BookingType
} from '../../types';

const Container = styled.div`

`;

// 조회 조건 
interface BookingListProps {
    start: string;
    end: string;
    phone: string;
}

export function BookingList({
    start,
    end,
    phone
}: BookingListProps){
    // 내역에 내역보기
    // infinite scroll 동작 
    return (
        <Container>
            
        </Container>
    );
}