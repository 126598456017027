import styled from "styled-components";

const Header = styled.div`
    width: 100%;
    height: 56px;
    border-bottom: .1px solid #979797;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5vw;
    padding-right: 5vw;
    position: fixed;
    z-index: 100;
    background: #FFFFFF;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.266667px;
    color: #191F28;
`;

const Back = styled.img`
    width: 24px;
`;

interface ModalHeaderProps {
    onClose: () => void;
    title: string;
}

export function ModalHeader({
    onClose,
    title
}: ModalHeaderProps){
    return (
        <Header>
            <Back 
                src={require('../../styles/assets/icon/back.png')}
                onClick={onClose}
            />
            <Title>{title}</Title>
            <div style={{width: '24px'}}></div>
        </Header>
    );
}