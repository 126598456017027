import { useEffect, useState } from "react";
import styled from "styled-components";

import {
    PageButton,
    SelectBox
} from '../atoms';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
`;

const ButtonSection = styled.div`
    width: 100%;
    height: calc((100vh - 268px) / 2);
`;

const RowSection = styled.div`
    width: 100%;
    height: 248px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Text = styled.div`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #1E1E1E;
    height: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const RowButtonSection = styled.div`
    width: 7vw;
    height: 100%;
`;

const Section = styled.div`
    width: 80vw;
    height: 248px;
    border-radius: 20px;
    background-color: #fff;
    overflow: hidden;
    color: #000000;
    padding: 20px 20px 40px 20px;
    display: flex;   
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    z-index: 100;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: #191F28;
`;

const SelectWrapper = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 35% 10% 35% 10%;
    gap: 10px;
`;


interface TimePickerModalProps{
    initTime: string | undefined;
    onSelectTime: (hour: string, minute: string) => void;
    onClose: () => void;
    timeRange: string [];
}

export function TimePickerModal({
    initTime,
    onSelectTime,
    onClose,
    timeRange
}:TimePickerModalProps){

    // 선택한 시간이 있으면 pick 
    const [ hour, setHour ] = useState<string>();
    const [ minute, setMinute ] = useState<string>();
    const [ distributer, setDistributer ] = useState<string>();
    
    function initTimeSetting(time: string | undefined){
       
        if(time !== undefined)
        {            
            let ss = time.split(':');
            if(ss.length > 1)
            {                
                let h = Number(ss[0]);
                let m = Number(ss[1]);
                
                if(h >= 12)
                {
                    setDistributer('오후');
                    // if(h > 12)
                    // {
                    //     h = h - 12;
                    // }
                }
                else
                {
                    setDistributer('오전');
                }

                setHour(h.toString());
                setMinute(m.toString());
            }
        }
        else
        {
            setHour("1");
            setMinute("0");
            setDistributer("오전");
        }
    }

    const selectTime = () => {     
        onSelectTime(hour as string, minute as string);
        // if(distributer === '오후')
        // {
        //     if(hour !== '12')
        //     {
        //         setHour((Number(hour) + 12).toString());
        //         onSelectTime((Number(hour) + 12).toString(), minute as string);
        //     }           
        //     else
        //     {
        //         onSelectTime(hour, minute as string);
        //     }
        // }
        // else
        // {
        //     onSelectTime(hour as string, minute as string);
        // }
    }

    useEffect(() => {
        var add:HTMLDivElement =  document.getElementsByClassName('request')[0] as HTMLDivElement;        
        add.style.overflow = 'hidden';
        initTimeSetting(initTime);
        if(initTime === undefined)
        {
            setHour("1");
            setMinute("00");
        }
        return () => {
            add.style.overflow = 'auto';
        }

    }, [])

    return (
        <Container>
            <ButtonSection
                onClick={onClose}
            />
            <RowSection>
                <RowButtonSection 
                    onClick={onClose}
                />
                <Section>
                    <Title>시간설정</Title>
                    <SelectWrapper>                        
                        <SelectBox 
                            options={timeRange}
                            onSelect={(value) => {
                                setHour(value)
                            }}
                            selectedValue={hour}
                        />   
                        <Text>시</Text>
                        <SelectBox 
                            options={['0', '10', '20', '30', '40', '50']}
                            onSelect={(value) => {
                                setMinute(value)
                            }}
                            selectedValue={minute}
                        />    
                        <Text>분</Text>                    
                        {/* <SelectBox 
                            options={['오전', '오후']}
                            onSelect={(value) => {
                                setDistributer(value)
                            }}
                            selectedValue={distributer}
                        />                                                */}
                    </SelectWrapper>                    
                    <PageButton 
                        text="설정완료"
                        background="#9C27B0"
                        color="#FFFFFF"
                        onClick={() => {
                            selectTime()
                        }}
                    />                    
                </Section>
                <RowButtonSection 
                    onClick={onClose}
                />
            </RowSection>
            <ButtonSection
                onClick={onClose}
            />
        </Container>
    );
}