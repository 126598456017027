import styled from "styled-components";

import { 
    storeState 
} from '../../../../stores';
import { useRecoilValue } from "recoil";

import Header from '../../../../pages/admin/customer/Header';

import {
    Gap
} from '../../../atoms';

import {
    CustomerInfoModal,
    AddCustomerModal
} from '../../../modals';

import {
    CustomerCard
} from '../../../molecules';

import {
    CustomerInfoType
} from '../../../../types';

import {
    searchStoreCustomerRequest
} from '../../../../apis/BookingApi';

import { FormEvent, useEffect, useState } from "react";

const Container = styled.div`
    width: 100%;
    height: 100vh;    
    padding-left: 5vw;
    padding-right: 5vw;
    /* align-items: flex-start;     */
`;

const Input = styled.input`
    width: 100%;
    position: absolute;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #AFB8C0;
    padding-left: 15px;
    z-index: 1;
`;

const SearchButton = styled.img`
    width: 20px;
    position: absolute;
    z-index: 2;
    right: 15px;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const RelativeWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    height: 100px;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
`;

const Wrapper = styled.div`
    width: 100%;
    height: calc(100vh - 250px);
    overflow-y: scroll;   
`;

const Filter = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #7D93A6;
`;

const CenterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;  
`;

const More = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.175px;
    color: #9C27B0;
    cursor: pointer;
`;

const TextButton =styled.span<{active: boolean}>`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: ${(props) => props.active ? '700' : '400'};
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.175px;
    color: #191F28;
    margin-left: 10px;
`;


const Icon = styled.img`
    width: 16px;
    margin-right: 8px;
`;

// 사용자 카드 로딩 
export function CustomerCardList(){
    const store = useRecoilValue(storeState);
    const [ infoModal, setInfoModal ] = useState(false);
    const [ addModal, setAddModal ] = useState(false);
    const [ selectedInfo, setSelectedInfo ] = useState<CustomerInfoType>({name: '', phone: '', userMemo: '', recentBooking: '', recentMenu: '', remainPoint: 0, customerId: 1, fileType: '', count: 0, noshow: 0, favorites: false},);
    const [ curPage, setCurPage ] = useState(1);
    const [ totalPage, setTotalPage ] = useState(0);
    const [ customerInfoList, setCustomerInfoList ] = useState<CustomerInfoType[]>([]);
    const [ keyword, setKeyword ] = useState<string>('aixk4hfk2dd');
    const [ favorites, setFavorites ] = useState<boolean>(false);

    function onKeywordChange (event: FormEvent<HTMLInputElement>){
        const {
            currentTarget: {value}
        } = event;

        setKeyword(value);
    }

    const getCustomerPage = async (page: number) => {
        setCurPage(page);
        let lkeyword = keyword;
        if(keyword === '')
        {
            setKeyword('aixk4hfk2dd');
            lkeyword = 'aixk4hfk2dd'
        }
        
        const res = await searchStoreCustomerRequest(store.id, '2000-01-01 00:00:00', '2999-12-31 23:59:59', page - 1, lkeyword, favorites);
        if(res.status === 'success')
        {
            if(page === 1)
            {
                setCustomerInfoList([...res.data.content]);
            }
            else
            {
                setCustomerInfoList((prev) => [...customerInfoList, ...res.data.content]);
            }
           
            setTotalPage(res.data.totalPages);
        }
    }

    useEffect(() => {
        const getInitCustomerPage = async () => {
            const res = await searchStoreCustomerRequest(store.id, '2000-01-01 00:00:00', '2999-12-31 23:59:59', curPage - 1, 'aixk4hfk2dd', favorites);
            setCurPage(1);
            if(res.status === 'success')
            {                
                setCustomerInfoList([...res.data.content]);
                setTotalPage(res.data.totalPages);
            }
        }
        getInitCustomerPage();
    }, [favorites])

    return (
        <Container className="request">
            {
                infoModal &&
                <CustomerInfoModal 
                    info={selectedInfo}
                    onClose={() => {
                        setCurPage(1);
                        getCustomerPage(1)
                        setInfoModal(false);
                    }}
                />
            }
            {
                addModal && 
                <AddCustomerModal 
                    onClose={() => {
                        setCurPage(1);
                        getCustomerPage(1)
                        setAddModal(false);
                    }}
                />
            }
            <Header 
                onAddCustomer={() => {
                    setAddModal(true)
                }}
            />
            <div style={{height: '96px'}}></div>
            <RowWrapper>
                <Title>고객관리</Title>
                <div>
                    <TextButton
                        active={!favorites ? true : false}
                        onClick={() => {      
                            setFavorites(false)
                        }}
                    >
                        모두보기
                    </TextButton>

                    <TextButton
                        active={favorites ? true : false}
                        onClick={() => {  
                            setFavorites(true)
                        }}
                    >
                        즐겨찾기
                    </TextButton>

                </div>              
            </RowWrapper>
            <RelativeWrapper>
                <Input placeholder="이름, 전화번호, 고객메모 검색" 
                    value={keyword === 'aixk4hfk2dd' ? '' : keyword}
                    onChange={onKeywordChange}
                />
                <SearchButton src={require('../../../../styles/assets/icon/search.png')} 
                    onClick={() => {
                        setCurPage(1);
                        getCustomerPage(1);
                    }}
                />
            </RelativeWrapper>           
            <Wrapper>
            {
                customerInfoList.map((customer, index) => {
                    return (
                        <CustomerCard 
                            key={index}
                            customerInfo={customer}
                            onClick={() => {
                                setSelectedInfo(customer);
                                setInfoModal(true);
                            }}
                        />
                    );
                })
                
            }   
            <Gap 
                gap={20}
            />      
            {   
                totalPage > curPage &&
                <CenterWrapper>
                    <More 
                        onClick={() => {
                            getCustomerPage(curPage + 1);
                        }}
                    >
                        <Icon 
                            src={require('../../../../styles/assets/icon/down_arrow.png')}
                        />
                        더보기
                    </More>
                </CenterWrapper>    
                
            }  
            <Gap 
                gap={40}
            />
            </Wrapper>            
        </Container>
    );
}