import { info } from "console";
import styled from "styled-components";

import {
    CardInfo
} from '../../types'

const Container = styled.div`
    display: grid;
    width: 100%;
    padding: 20px;
    background: #F4F7F8;
    border-radius: 16px;
    grid-template-columns: 100%;
    gap: 12px;
`;

const RowWrapper = styled.div`

`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.4375px;
    color: #7D93A6;
`;

const Contents = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.4375px;
    color: #191F28;
`;

interface EtcInfoCardProps {
    infos: CardInfo []
}

export function EtcInfoCard({
    infos
}:EtcInfoCardProps){
    return (
        <Container>
            {infos.length > 0 &&
                infos.map((info, index) => {
                    return (
                        <div key={index}>
                            <RowWrapper>
                                <Title>{info.key}</Title>
                            </RowWrapper>
                            <RowWrapper>
                                <Contents>{info.value}</Contents>
                            </RowWrapper>
                        </div>
                    );
                })
            }
        </Container>
    );
}