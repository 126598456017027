import { FormEvent } from "react";
import styled from "styled-components";

import {
    Gap
} from '../atoms';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 28px 5vw;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
`;

const Input = styled.input`
    width: 100%;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    padding-left: 20px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #000000;
`;

const TextArea = styled.textarea`
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    width: 100%;
    height: 160px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #000000;
    padding: 20px;
    resize: none;
`;

interface UserInfoEditProps {
    onNameChange: (name: string) => void;
    onPhoneChange: (phone: string) => void;
    onPointChange: (point: number) => void;
    onMemoChange: (memo: string) => void;
    name: string;
    phone: string;
    point: number;
    memo: string;
}

export function UserInfoEdit({
    onNameChange,
    onPhoneChange,
    onPointChange,
    onMemoChange,
    name,
    phone,
    point,
    memo
}: UserInfoEditProps){

    const nameChange = (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value}
        } = event

        onNameChange(value)
    }

    const phoneChange = (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value}
        } = event

        onPhoneChange(value)
    }

    const pointChange = (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value}
        } = event
        onPointChange(Number(value))
    }

    const memoChange = (event: FormEvent<HTMLTextAreaElement>) => {
        const {
            currentTarget: {value}
        } = event

        onMemoChange(value)
    }


    function deleteNumberZero(str: string)
    {
        return str.replace(/(^0+)/, "");
    }

    return (
        <Container>
            <Label>이름</Label>
            <Gap 
                gap={12}
            />
            <Input 
                value={name}
                onChange={nameChange}
            />
            <Gap 
                gap={20}
            />
            <Label>휴대폰번호</Label>
            <Gap 
                gap={12}
            />
            <Input 
                type="number"
                pattern="\d*"
                value={phone}
                onChange={phoneChange}
                placeholder="휴대폰번호 (- 제외)"
            />
            <Gap 
                gap={20}
            />
            <Label>잔여 선불금 (원)</Label>
            <Gap 
                gap={12}
            />
            <Input 
                type="number"
                pattern="\d*"
                value={deleteNumberZero(point.toString())}
                onChange={pointChange}
            />
            <Gap 
                gap={20}
            />
            <Label>메모</Label>
            <Gap 
                gap={12}
            />
            <TextArea 
                value={memo}
                onChange={memoChange}
            >
            </TextArea>
        </Container>
    )
}