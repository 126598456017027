import styled from "styled-components";

import {
    XBar
} from '../atoms';

const Container = styled.div`
    display: flex;
    width: 100%;
`;

interface XBarGraphListProps{
    datas: string []
}

export function XBarGraphList({
    datas
}: XBarGraphListProps) {
    return (
        <Container>
            {
                datas.length > 0 &&
                datas.map((data, index) => {
                    return(
                        <XBar 
                            key={index}
                            backgournd={index % 4 === 0 ? '#9C27B0' : index % 4 === 1 ? '#4DD0E1' : index % 4 === 2 ? '#F3A84F' : index % 4 === 3 ? '#CEDDE2' : ''}
                            value={data}
                        />
                    )                   
                })
            }
        </Container>
    );
}