// store admin page 
// setting menus ...

import { useEffect, useState } from "react";
import styled from "styled-components";
import qs from 'qs';

import {
    NavBar
} from "../../components/molecules";
import Const from '../../constant';

import { 
    useRecoilState
 } from "recoil";

import {
    storeState
} from '../../stores';

import Today from './today';
import Booking from "./booking";
import List from './stat';
import Customer from "./customer";
import Setting from './setting';


import {
    getStoreRequest
} from '../../apis/BookingApi';

import {
    getLocalStorage
} from '../../utilities/localstorage'

import { 
    useLocation, useParams
} from "react-router-dom";

const Container = styled.div`
    overflow: hidden;
`;

function Admin(){
    // selector store load 
    const [ view, setView ] = useState(0);
    const location = useLocation();
    const [ loading, setLoading ] = useState(true);
    const [ store, setStore ] = useRecoilState(storeState);

    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });

    const getStore = async () => {
        const res = await getStoreRequest();
        if(res.status === '200')
        {
            setStore(res.data);
            setLoading(false)
        }
        else
        {
            // alert("미가입 유저")
            window.open("https://friday11am.com")
        }
    }

    useEffect(() => {
        
        const access_token = getLocalStorage('access_token');

        if(access_token)
        {
            //get store info 
            //no auth
            //kakao_login
            getStore();
        }
        else
        {            
            const path = `${location.pathname}`;       
            window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${Const.REST_API_KEY}&redirect_uri=${Const.REDIRECT_URI}&response_type=code&prompt=login&state=${path}`;
        }

        const navigation = query.navigation;
        if(navigation !== undefined && navigation !== null)
        {
            setView(Number(navigation));
        }
  
        
    }, [])

    return (
        <Container> 
            {loading ? <></> :
            <>
                {
                    view === 0 ? (
                        
                        store && 
                        <Today />
                        
                    ) 
                    :
                    view === 1 ? (
                        <Booking />
                    )
                    : 
                    view === 2 ? (
                        <List />
                    )
                    :
                    view === 3 ? (
                        <Customer />
                    ) 
                    :
                    (
                        <Setting 
                            onStoreUpdate={() => {
                                getStore();
                            }}
                        />
                    )
                }
                <div style={{height: '70px'}}></div>
                <NavBar 
                    active={view}
                    onClick={(path) => {
                        setView(path)
                    }}
                />
            </>
            }            
        </Container>
    );
}

export default Admin;