import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { storeState } from "../../../../stores";

import styled from "styled-components"

import {
    addDailyBreakTimeRequest,
    getDailyBreakTimeListRequest
} from '../../../../apis/BookingApi';

import { 
    leadingZeros 
} from "../../../../utilities/convert";

import {
    DailyBreakTimeType
} from '../../../../types';

import {
    Gap,
    CardButton,
    PageButton
} from '../../../atoms';

import {
    SettingTime,
    ModalHeader
} from '../../../molecules';

import {
    ConfrimCheckModal,
    AlertModal
} from '../../../modals';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background: #FFFFFF;
    padding-bottom: 30px;
`;

const RowWrapper = styled.div<{marginTop: number}>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: ${(prosp) => prosp.marginTop}px;
`;


const BreakTimeSection = styled.div`
    width: 100%;
    background-color: #fff;
    overflow: auto;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    padding: 25px 5vw;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
`;

const Trash = styled.img`
    width: 20px;
    margin-left: 10px;
`;

const CenterWrapper = styled.div<{marginTop: number}>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: ${(prosp) => prosp.marginTop}px;
    padding: 0 5vw;
`;


interface AddDailyBreakTimeModalProps {
    onClose: () => void;
    requestDate: string;
}

export function AddDailyBreakTimeModal({
    onClose,
    requestDate
}: AddDailyBreakTimeModalProps){
    const store = useRecoilValue(storeState);
    const [ breakTimeList, setBreakTimeList ] = useState<DailyBreakTimeType []>([]);
    const [ isAlert, setIsAlert ] = useState(false);
    const [ alertTitle, setAlertTitle ] = useState<string>('');
    const [ alertDescription, setAlertDescription ] = useState<string>('');

    const [ isAlert2, setIsAlert2 ] = useState(false);
    const [ alertTitle2, setAlertTitle2 ] = useState<string>('');
    const [ alertDescription2, setAlertDescription2 ] = useState<string>('');

    const [ viewConfirmModal, setViewConfirmModal ] = useState(false);

    const setStartBreakTimeSetting = (index: number, hour: number, minute: number) => {
        let tBreakTimeList:DailyBreakTimeType [] = JSON.parse(JSON.stringify(breakTimeList));
        tBreakTimeList.map((breakTime, sindex) => {
            if(sindex === index)
            {
                
                if(breakTime.end !== '')
                {                    
                    const tempStart = leadingZeros(hour.toString(), 2) + ":" + leadingZeros(minute.toString(), 2) + ":00";
                    let start = new Date();
                    let end = new Date();
                    start.setHours(Number(tempStart.split(':')[0]))
                    start.setMinutes(Number(tempStart.split(':')[1]))        
                    end.setHours(Number(breakTime.end.split(':')[0]))
                    end.setMinutes(Number(breakTime.end.split(':')[1]))
                        
                    if(start < end)
                    {
                        breakTime.start = leadingZeros(hour.toString(), 2) + ":" + leadingZeros(minute.toString(), 2) + ":00";
                        //end - start 
                        let endTimeHour = Number(breakTime.end.slice(0, 2));
                        let endTimeMinute = Number(breakTime.end.slice(3, 5));
                        breakTime.duration = (endTimeHour * 60 + endTimeMinute) - (hour * 60 + minute);
                    }
                    else
                    {
                        setAlertTitle("시간설정 오류");
                        setAlertDescription("시작시간이 종료시간 뒤 입니다.")
                        setIsAlert(true);
                    }
                }
                else
                {
                    breakTime.start = leadingZeros(hour.toString(), 2) + ":" + leadingZeros(minute.toString(), 2) + ":00";
                }
            }
         });
         setBreakTimeList(tBreakTimeList);
    }

    const setEndBreakTimeSetting = (index: number, hour: number, minute: number) => {        
        let tBreakTimeList:DailyBreakTimeType [] = JSON.parse(JSON.stringify(breakTimeList));
        tBreakTimeList.map((breakTime, eindex) => {
            if(eindex === index)
            {                
                if(breakTime.start !== '')
                {
                    const tempEnd = leadingZeros(hour.toString(), 2) + ":" + leadingZeros(minute.toString(), 2) + ":00";
                    let start = new Date();
                    let end = new Date();
                    start.setHours(Number(breakTime.start.split(':')[0]))
                    start.setMinutes(Number(breakTime.start.split(':')[1]))        
                    end.setHours(Number(tempEnd.split(':')[0]))
                    end.setMinutes(Number(tempEnd.split(':')[1]))

                    if(start < end)
                    {
                        breakTime.end = leadingZeros(hour.toString(), 2) + ":" + leadingZeros(minute.toString(), 2) + ":00";
                        //end - start 
                        let startTimeHour = Number(breakTime.start.slice(0, 2));
                        let startTimeMinute = Number(breakTime.start.slice(3, 5));
                        breakTime.duration = (hour * 60 + minute) - (startTimeHour * 60 + startTimeMinute);;
                    }
                    else
                    {
                        setAlertTitle("시간설정 오류");
                        setAlertDescription("종료시간이 시작시간 이전 입니다.")
                        setIsAlert(true);
                    }
                }
                else{
                    breakTime.end = leadingZeros(hour.toString(), 2) + ":" + leadingZeros(minute.toString(), 2) + ":00";
                }
            }
        });
        setBreakTimeList(tBreakTimeList);
    }

    const deleteBreakTime = (index: number) => {
        let tBreakTimeList:DailyBreakTimeType [] = JSON.parse(JSON.stringify(breakTimeList));
        tBreakTimeList.splice(index, 1);
        setBreakTimeList(tBreakTimeList);
    }

    const addNewBreakTime = async () => {
        // 이미 비어있는게 있다면 추가 안됨 
        let tBreackTime: DailyBreakTimeType = {
            id: 0,
            storeId: store.id,
            date: requestDate,
            start: '',
            end: '',
            duration: 0
        }
        const addBreakTimeList:DailyBreakTimeType [] = JSON.parse(JSON.stringify(breakTimeList));
        
        addBreakTimeList.push(tBreackTime);
        setBreakTimeList(addBreakTimeList);
    }

    const updateSchedule = async () => {

        let breakListArr:DailyBreakTimeType[] = breakTimeList.filter(function(data){
            return data.end !== '' && data.start !== ''
        }) 
        const res = await addDailyBreakTimeRequest(breakListArr, store.id, requestDate);

        if(res.status === 'success')
        {
            // alert2
            setIsAlert2(true);
            setAlertTitle2("업데이트 되었습니다.");
        }
    }

    useEffect(() => {
        const getDailyBreakList = async () => {
            const res = await getDailyBreakTimeListRequest(store.id, requestDate);
            if(res.status === 'success')
            {
                setBreakTimeList([...res.data]);
            }
        }
        getDailyBreakList();
    }, [])

    return (
        <Container className="request">
            {
                isAlert &&
                <AlertModal 
                    title={alertTitle}
                    description={alertDescription}
                    onCheck={() => {
                        setIsAlert(false);
                    }}
                />
            }
            {
                isAlert2 &&
                <AlertModal 
                    title={alertTitle2}
                    description={alertDescription2}
                    onCheck={() => {
                        setIsAlert2(false);
                        onClose();
                    }}
                />
            }
            {
                viewConfirmModal && 
                <ConfrimCheckModal 
                    title={'변경한 정보가 있는지 확인해주세요.'}
                    description={'뒤로가기를 누를 경우 변경한 내역이 저장되지 않습니다.'}
                    onCheck={() => {
                        setViewConfirmModal(false);
                        onClose();
                    }}
                    onCancel={() => {
                        setViewConfirmModal(false);
                    }}
                />
            }
            <ModalHeader 
                title={`당일 휴식 설정`}
                onClose={() => {
                    setViewConfirmModal(true);
                }}
            />  
            <Gap 
                gap={56}
            />
            <BreakTimeSection>
                <Title>휴식시간 설정</Title>
                {
                    breakTimeList.length > 0 && 
                    breakTimeList.map((breakTime, index) => {
                        return (
                            <RowWrapper marginTop={20} key={index}>
                                <SettingTime
                                    time={breakTime.start ? breakTime.start : '시작시간'}
                                    onSettingTime = {(hour: number, minute: number) => {
                                        setStartBreakTimeSetting(index, hour, minute);
                                    }}     
                                    timeRange={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']}                           
                                />  
                                <div style={{width: '20px'}}/>
                                <SettingTime
                                    time={breakTime.end ? breakTime.end : '종료시간'}
                                    onSettingTime = {(hour: number, minute: number) => {
                                        setEndBreakTimeSetting(index, hour, minute);
                                    }}   
                                    timeRange={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']}                            
                                /> 

                                <Trash src={require('../../../../styles/assets/icon/trash.png')} 
                                    onClick={() => {
                                        deleteBreakTime(index);
                                    }}
                                />
                            </RowWrapper>
                        );
                    })
                }
                <CenterWrapper marginTop={22}>
                    <CardButton 
                        text="휴식시간 추가하기"
                        background="#FFFFFF"
                        color="#9C27B0"
                        onClick={addNewBreakTime}
                    /> 
                </CenterWrapper> 
            </BreakTimeSection>
            <CenterWrapper marginTop={22}>
                <PageButton 
                    text="업데이트"
                    background="#9C27B0"
                    color="#FFFFFF"
                    onClick={updateSchedule}
                />
            </CenterWrapper>
        </Container>
    );
}