import { useState } from "react";
import styled from "styled-components";


const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const Section = styled.div`
    width: 100%;
    height: 350px;
    border-radius: 20px;
    background-color: #fff;
    overflow: hidden;
    color: #000000;
    padding: 34px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 100;
`;

const CloseButton = styled.img`
    position: absolute;
    top: 15px;
    right: 15px;
    width: 15px;
    cursor: pointer;
`;

const ButtonSection = styled.div`
    width: 100%;
    height: calc((100vh - 350px) / 2);
`;

const Title = styled.span`

`;

const TextBox = styled.textarea`
    width: 100%;
    height: 100%;
`;

const Button = styled.div`

`;

interface CancelModalProps {
    title: string;
    message?: string;
    onSubmit: (message: string) => void;
    onClose: () => void;
    placeHolder: string;
}

export function CancelModal({
    title,
    message,
    onSubmit,
    onClose,
    placeHolder
}: CancelModalProps) {

    const [ cancelMessage, setCancelMessage ] = useState(message); 
    console.log("취소 메지시: " + message)
    return (
        <Container>
            <ButtonSection 
                onClick={() => {
                    onClose();
                }}
            />
            <Section>
                <CloseButton 
                    onClick={() => {
                        onClose();
                    }}
                src={require('../../styles/assets/btn_close_normal.png')} />   
                <Title>{title}</Title>             
                <TextBox
                    onChange={() => {

                    }}
                    value={cancelMessage}
                    placeholder={placeHolder}
                />
                <Button
                    onClick={() => {
                        if(cancelMessage)
                            onSubmit(cancelMessage);
                    }}
                >취소하기</Button>
            </Section>
            <ButtonSection 
                onClick={() => {
                    onClose();
                }}
            />
           
        </Container>
    );
}