// 저장 완료된 시간 card // delete 지원
import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    left: 20px;
    top: 283px;
    background: #F4F7F8;
    border-radius: 16px;
    padding-left: 20px;
    padding-right: 20px;
`;

const ColWrapper = styled.div`
    display: grid;
    grid-template-columns: 250px;
    gap: 20px;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const Icon = styled.img`
    width: 16px;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #7D93A6;
`;

const Time = styled.span`
    margin-left: 9px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #000000;
`;

interface SaveTimeProps {
    start: string;
    end: string;
    onDelete: () => void;
}

export function SaveTime({
    start,
    end,
    onDelete
}:SaveTimeProps){

    function timeView(time: string){
        return time.slice(0, 5);
    }

    return(
        <Container>        
            <RowWrapper>
                <Label>요청시간</Label>
                <Time>{timeView(start)}부터 {timeView(end)} 사이</Time>
            </RowWrapper> 
            <Icon 
                src={require('../../styles/assets/icon/trash.png')}
                onClick={onDelete}
            />
        </Container>
    );
}
