import styled from "styled-components";
import { useEffect, useRef, useState } from "react";

import { 
    UserBookingInfoType 
} from "../../types";

import {
    convertTimestampToDate,
    convertTimestampToTime
} from '../../utilities/convert';

import {
    userCancelRequest
} from '../../apis/BookingApi';

import {
    PageButton
} from '../atoms';

import {
    InfoCard,
    EtcInfoCard
} from '../molecules'

import {
    StoreInfoModal,
    MapModal,
    AlertModal,
    ConfrimCheckModal
} from '../modals';

const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 21px 5vw;
    display: flex;
    flex-direction: column;
`;

const Logo = styled.img`
    width: 120px;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.25px;
    color: #000000;
    margin-top: 20px;
`;

const ImageWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
`;

const Image = styled.img`
    width: 150px;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
`;

const RowWrapperSpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 18px;
`;

const RowWrapperCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 18px;
    width: 100%;
`;

const ColWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.5625px;
    color: #000000;
`;

const InfoIcon = styled.img`
    width: 16px;
    margin-left: 5px;
    cursor: pointer;
`;

const Gap = styled.div`
    width: 100%;
    height: 8px;
`;

const Text = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.175px;
    color: #000000;
    width: 75%;
    margin-left: 10px;
`;

const Icon = styled.img`

`;

const TextButton = styled.div`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    text-align: right;
    letter-spacing: -0.175px;
    color: #4B9DFF;
`;

interface UserConfirmComponentProps {
    confirmInfo: UserBookingInfoType
}


export function UserConfirmComponent({
    confirmInfo
}: UserConfirmComponentProps){

    const [storeView, setStoreView] = useState(false);
    const [mapView, setMapView] = useState(false);
    const [ viewAlertModal, setViewAlertModal ] = useState(false);
    const [ viewConfirmModal, setViewConfirmModal ] = useState(false);
    const [ alertTitle, setAlertTitle ] = useState<string>('');
    const [ alertDescription, setAlertDescription ] = useState<string>('');

    const container = useRef(null); 

    const userCancel = async () => {
        const res = await userCancelRequest(confirmInfo.bookingInfo.bookingId);
        if(res.data === 0)
        {
            window.location.reload();
        }
        else if(res.data === 1)
        {
            setAlertTitle('취소 가능 시간이 초과되어 취소할 수 없습니다');
            setAlertDescription(`${confirmInfo.storeInfo.name}에 직접 문의 부탁드립니다.`);
            setViewAlertModal(true);
        }
        else 
        {
            setAlertTitle('취소되지 않았습니다.');
            setAlertDescription('');
            setViewAlertModal(true);
        }
    }
      
    useEffect(() => {
        return () => {};
      }, []);

    return (
        <Container>
            {
                viewConfirmModal &&
                <ConfrimCheckModal 
                    title="정말 취소하시겠습니까?"
                    onCancel={() => {
                        setViewConfirmModal(false);
                    }}
                    onCheck={() => {
                        setViewConfirmModal(false);
                        userCancel();
                    }}
                />
            }
            {
                viewAlertModal &&
                <AlertModal 
                    title={alertTitle}
                    description={alertDescription}
                    onCheck={() => {
                        setAlertTitle('');
                        setAlertDescription('');
                        setViewAlertModal(false);
                    }}
                />
            }
            {
                storeView && <StoreInfoModal 
                    storeInfo={confirmInfo.storeInfo}
                    scheduleList={confirmInfo.scheduleList}
                    onClose={() => {
                        setStoreView(false);
                    }}
                />
            }
            {
                mapView && 
                <MapModal 
                    name={confirmInfo.storeInfo.name}
                    address={confirmInfo.storeInfo.jibunAddress}
                    onClose={()=>{
                        setMapView(false);
                    }}
                />
            }
            <Logo 
                src={require('../../styles/assets/logo_new.png')}
            />
            <Title>고객님의 방문을 기다리고 있어요!</Title>
            <ImageWrapper>
                <Image 
                    src={require('../../styles/assets/user_wait_logo.png')}
                />
            </ImageWrapper>        
            <RowWrapper>
                <Label>{confirmInfo.storeInfo.name}</Label>
                <InfoIcon src={require('../../styles/assets/info.png')} 
                    onClick={()=>{
                        setStoreView(true);
                    }}
                />
            </RowWrapper>
            <InfoCard 
                infos={[
                    {key: '예약 요청일', value: `${convertTimestampToDate(confirmInfo.bookingInfo.requestDate)}`},
                    {key: '예약 확정시간', value: `${convertTimestampToTime(confirmInfo.bookingInfo.time)}`},
                    {key: '예약자 이름', value: `${confirmInfo.bookingInfo.userName}`},
                    {key: '예약자 휴대폰번호', value: `${confirmInfo.bookingInfo.userPhone}`},
                    {key: '요청서비스', value: `${confirmInfo.bookingInfo.menuName}`}                    
                ]}            
            />
            <Gap />
            <EtcInfoCard 
                infos={
                    confirmInfo.bookingInfo.bookingEtcInfoList.map((etc, index) => {
                        return (
                            {key: etc.title, value: etc.contents}
                        );
                    })                    
                }
            />
            <RowWrapperSpaceBetween>
                <div>
                    <Icon 
                        src={require('../../styles/assets/icon/map_icn.png')}
                    />
                    <Text>{`${confirmInfo.storeInfo.roadAddress} ${confirmInfo.storeInfo.detailAddress}`}</Text>
                </div>                
                <TextButton
                    onClick={() => {
                        setMapView(true);
                    }}
                >지도보기</TextButton>
            </RowWrapperSpaceBetween>            
            <RowWrapperCenter>
                <PageButton 
                    text="예약취소"
                    background="#FFFFFF"
                    color="#000000"
                    onClick={() => {
                        setViewConfirmModal(true)
                    }}
                />
            </RowWrapperCenter>
        </Container>
    );
}