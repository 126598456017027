import styled from "styled-components";

import {
    PageButton,
    CardButton,
    TabButton,
    SelectBox
} from '../atoms'

const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    justify-content: center;
    align-items: flex-start;
    display: flex;
`;

const Section = styled.div`
    max-width: 600px;
    width: 600px;
    height: 100%;
    background-color: #FFFFFF;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
`;

const Wrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 32% 32% 32%;
    gap: 9px;
`;

function Atom(){
    return (
        <Container>
            <Section>
                <PageButton 
                    background="#9C27B0"
                    color="#FFFFFF"
                    text="수락할게요"
                    onClick={() => {
                        alert("click")
                    }}
                />

                <PageButton 
                    background="#FFFFFF"
                    color="#000000"
                    text="거절할게요"
                    onClick={() => {
                        alert("click")
                    }}
                />

                <CardButton 
                    background="#FFFFFF"
                    color="#000000"
                    text="거절할게요"
                    onClick={() => {
                        alert("click")
                    }}
                />

                <CardButton 
                    background="#CEDDE2"
                    color="#7D93A6"
                    text="확인완료"
                    onClick={() => {
                        alert("click")
                    }}
                />

                <TabButton 
                    text="예약 통계"
                    color="#FFFFFF"
                    background="#FF4A8B"
                    onClick={() => {
                        alert("click")
                    }}
                />

                <TabButton 
                    text="서비스 통계"
                    color="#525558"
                    background="#CEDDE2"
                    onClick={() => {
                        alert("click")
                    }}
                />
                <Wrapper>
                    
                </Wrapper>
                
            </Section>
        </Container>
    );
}

export default Atom;