import styled from "styled-components";

const Button = styled.div<{bgColor: string, width: string, height: string, color: string, fontSize: string, fontWeight: string}>`
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    color: ${(props) => props.color};
    background-color: ${(props) => props.bgColor};
    border-radius: ${(props) => props.theme.borderRadius}px;
    cursor: pointer;
    font-size: ${(props) => props.fontSize}px;
    line-height: ${(props) => props.fontSize}px;
    font-weight: ${(props) => props.fontWeight};
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface SubmitButtonProps {
    title: string;
    bgColor: string;
    color: string;
    width: string;
    height: string;
    fontSize: string;
    fontWeight: string;
    onClick: () => void;
}

export function SubmitButton ({
    title,
    bgColor,
    color,
    width,
    height,
    fontSize,
    fontWeight,
    onClick
}:SubmitButtonProps){
    return (
        <Button
            onClick={onClick}
            bgColor={bgColor}
            color={color}
            width={width}
            height={height}
            fontSize={fontSize}
            fontWeight={fontWeight}
        >{title}</Button>
    );
}