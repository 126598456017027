import styled from "styled-components";
import {
    BookingType
} from '../../types';

import {
    CardButton
} from '../atoms';

import { useRecoilValue } from 'recoil';

import { 
    storeState 
} from '../../stores';
import { useEffect } from "react";

const Container = styled.div`
    width: 100%;
    background: #F4F7F8;
    border-radius: 16px;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 12px;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const Wrapper = styled.div`

`;

const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const Contens = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`; 

const Icon = styled.img`
    margin-right: 8px;
    width: 12px;
`;

const InfoText = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.25px;
    color: #FF4A8B;
`;

const InfluencerText = styled.span`
    background-color: #FF4A8B;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    color: #FFFFFF;
    padding: 4px 5px;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.25px;
    color: #191F28;
`;

const Gap = styled.div<{gap: number}>`
    width: 100%;
    height: ${(props) => props.gap}px;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.4375px;
    color: #7D93A6;
`;

const Content = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.4375px;
    color: #191F28;
`;

interface RequestCardProps {
    bookingInfo: BookingType;
    onView: () => void; 
}

export function RequestCard({
    bookingInfo,
    onView
}: RequestCardProps){
    const store = useRecoilValue(storeState);

    function autoCancel(createdAt: string, autoCancelMinute: number) {
        const createDateTime = new Date(createdAt);
        const timeout = new Date(createDateTime.getTime() + autoCancelMinute * 60000);
        // console.log("created " + timeout)
        const cur = new Date();
        if(timeout < cur)
        {
            return false;
        }

        return true;
    }

    return (
        <Container>
            <RowWrapper>
                <Wrapper>
                    <Icon src={require('../../styles/assets/icon/warning.png')} />
                    {
                        bookingInfo.visitCount > 0 ? 
                        <InfoText>{bookingInfo.visitCount}회 방문 고객입니다 </InfoText>
                        :
                        <InfoText>처음 방문 고객입니다</InfoText>
                    }
                </Wrapper>
                <Wrapper>
                    {
                        bookingInfo.influencerId !== 0 ?
                        <InfluencerText>{bookingInfo.influencerName}</InfluencerText> : <></>
                    }
                </Wrapper>
            </RowWrapper>
            <Gap 
                gap={12}
            />
            <RowWrapper>
                <Title>{bookingInfo.userName}님의 예약요청 ({bookingInfo.userPhone})</Title>
            </RowWrapper> 
            <Gap 
                gap={20}
            />            
            <RowWrapper>
                <Contens>
                    <Label>요청메뉴</Label>
                    <Content>{bookingInfo.menuName}</Content>
                </Contens>
            </RowWrapper>
            <RowWrapper>
                <Contens>
                    <Label>요청일</Label>
                    <Content>{bookingInfo.requestDate}</Content>
                </Contens>
            </RowWrapper>
            <RowWrapper>
                {
                    bookingInfo.requestBookingTimeList.length > 0 &&
                    <Contens>
                        <Label>요청시간</Label>
                        <Content>{bookingInfo.requestBookingTimeList[0].startTime.slice(0, 5)} 부터</Content>
                    </Contens>
                }                
            </RowWrapper>
            {
                bookingInfo.state === 1 ?
                <Center>
                    { autoCancel(bookingInfo.createdAt ,store.autoCancelMinute) ? 
                        <CardButton 
                            text="요청보기"
                            background="#FFFFFF"
                            color="#9C27B0"
                            onClick={onView}
                        /> :
                        <CardButton 
                            text="자동취소"
                            background="#CEDDE2"
                            color="#9C27B0"
                            onClick={() => {

                            }}
                        />
                    }
                    
                </Center>    
                :
                bookingInfo.state === 2 ?
                <Center>
                    <CardButton 
                        text="요청수락 완료"
                        background="#CEDDE2"
                        color="#9C27B0"
                        onClick={() => {

                        }}
                    />
                </Center>
                :
                bookingInfo.state === 3 ?
                <Center>
                    <CardButton 
                        text="요청거절"
                        background="#CEDDE2"
                        color="#9C27B0"
                        onClick={() => {

                        }}
                    />
                </Center>   
                :
                bookingInfo.state === 4 ?
                <Center>
                    <CardButton 
                        text="고객취소"
                        background="#CEDDE2"
                        color="#9C27B0"
                        onClick={() => {

                        }}
                    />
                </Center>   
                :
                bookingInfo.state === 5 ?
                <Center>
                    <CardButton 
                        text="관리자 취소"
                        background="#CEDDE2"
                        color="#9C27B0"
                        onClick={() => {

                        }}
                    />
                </Center>   
                :
                bookingInfo.state === 6 ?
                <Center>
                    <CardButton 
                        text="이용 완료"
                        background="#CEDDE2"
                        color="#9C27B0"
                        onClick={() => {

                        }}
                    />
                </Center>   
                :
                bookingInfo.state === 7 ?
                <Center>
                    <CardButton 
                        text="노쇼"
                        background="#CEDDE2"
                        color="#9C27B0"
                        onClick={() => {

                        }}
                    />
                </Center>   
                :
                bookingInfo.state === 9 ?
                <Center>
                    <CardButton 
                        text="자동취소"
                        background="#CEDDE2"
                        color="#9C27B0"
                        onClick={() => {

                        }}
                    />
                </Center>   
                : null
            }     
                  
        </Container>
    );
}