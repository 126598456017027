import { BrowserRouter, Route, Routes } from "react-router-dom";

// /landing  
// /contact
// /admin  
// /auth/callback
// /booking/:name
import Landing from './landing';
import Contact from "./contact";
import Sign from './contact/Sign';

import Admin from './admin';
import Login from "./login";
import Request from "./customer/booking";
import Booking from './customer/user';

// atom preview
import Atom from '../components/atoms/Atom';
import Organism from '../components/organisms/Organism';

import Influencer from "./influencer";
import InfluencerLogin from "./influencer/Login";
import InfluencerPage from "./influencer/Page";

function Router(){
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Landing></Landing>} />   
                <Route path="/contact" element={<Contact></Contact>} />
                <Route path="/sign/callback" element={<Sign></Sign>} />
                <Route path="/admin" element={<Admin></Admin>} />
                <Route path="/auth/callback" element={<Login></Login>} />
                <Route path="/store/:storename" element={<Request></Request>} />
                <Route path="/user/:connectKey" element={<Booking></Booking>} />
                <Route path="/atom" element={<Atom></Atom>} />
                <Route path="/organism" element={<Organism></Organism>} />
                <Route path="/influencer" element={<Influencer></Influencer>} />
                <Route path="/influencer/login" element={<InfluencerLogin></InfluencerLogin>} />
                <Route path="/influencer/page" element={<InfluencerPage></InfluencerPage>} />
            </Routes>
        </BrowserRouter>
    );
}

export default Router;