import { FormEvent, useEffect, useState } from "react";
import styled from "styled-components";

import {
    PageButton
} from '../atoms';

import { 
    leadingZeros 
} from '../../utilities/convert';

import {
    Calendar
} from '../modals';

const Container = styled.div`   
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 5vw;
    padding-right: 5vw;
    flex-direction: column;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.5px;
    color: #191F28;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 9px;
    width: 100%;
`;

const MonthButton = styled.div`
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    width: 106px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const DateButton = styled.div`
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    width: 159px;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 14px;
`;

const Gap = styled.div<{gap: number}>`
    height: ${(props) => props.gap}px;
`;

const CalendarButton = styled.img`
    width: 20px;
`;

const Input = styled.input`
    width: 100%;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #AFB8C0;
    padding-left: 20px;
`;

interface OptionSelectProps {
    start: string;
    end: string;
    phone: string;
    onSelect: (start: string, end: string, phone: string) => void;
}

export function OptionSelect({
    start,
    end,
    phone,
    onSelect
}: OptionSelectProps){
    
    const [ selectedStart, setSelectedStart ] = useState(start);
    const [ selectedEnd, setSelectedEnd ] = useState(end);
    const [ phoneNumber, setPhoneNumber ] = useState(phone === 'aixk4hfk2dd' ? '' : phone);

    const [ startCalendarView, setStartCalendarView ] = useState(false);
    const [ endCalendarView, setEndCalendarView ] = useState(false);

    function onPhoneNumberChange (event:FormEvent<HTMLInputElement>)
    {
        const {
            currentTarget: {value}
        } = event;

        setPhoneNumber(value);
    }

    useEffect(() => {
       
    }, [])

    return (
        <Container>
            {
                startCalendarView && 
                <Calendar 
                    mode={1}
                    year={Number(selectedStart.slice(0, 4))}
                    month={Number(selectedStart.slice(5, 7)) - 1}
                    date={Number(selectedStart.slice(8, 10))}
                    onClose={() => {
                        setStartCalendarView(false);
                    }}
                    onSelect={(year, month, date) => {
                        let start = year + "-" + leadingZeros((month + 1).toString(), 2) + "-" + leadingZeros(date.toString(), 2) + " 00:00:00";
                        setSelectedStart(start);
                        setStartCalendarView(false);
                    }}
                />
            }
            {
                endCalendarView && 
                <Calendar 
                    mode={1}
                    year={Number(selectedEnd.slice(0, 4))}
                    month={Number(selectedEnd.slice(5, 7)) - 1}
                    date={Number(selectedEnd.slice(8, 10))}
                    onClose={() => {
                        setEndCalendarView(false);
                    }}
                    onSelect={(year, month, date) => {
                        let end = year + "-" + leadingZeros((month + 1).toString(), 2) + "-" + leadingZeros(date.toString(), 2) + " 23:59:59";
                        setSelectedEnd(end);
                        setEndCalendarView(false);
                    }}
                />
            }
            <Title>조회기간</Title>
            <Gap 
                gap={12}
            />
            {/* // <Wrapper>
            //     <MonthButton>1개월</MonthButton>
            //     <MonthButton>3개월</MonthButton>
            //     <MonthButton>6개월</MonthButton>
            // </Wrapper> */}
            <Gap 
                gap={12}
            />
            <Wrapper>
                <DateButton>
                    {selectedStart.slice(0, 10)}
                    <CalendarButton
                        src={require(`../../styles/assets/calendar_btn.png`)} 
                        onClick={() => {
                            setStartCalendarView(true);
                        }}
                    />
                </DateButton> ~
                <DateButton>
                    {selectedEnd.slice(0, 10)}
                    <CalendarButton
                        src={require(`../../styles/assets/calendar_btn.png`)} 
                        onClick={() => {
                            setEndCalendarView(true);
                        }}  
                    />
                </DateButton>
            </Wrapper>
            <Gap 
                gap={28}
            />
            <Title>고객 휴대폰 번호</Title>
            <Gap 
                gap={12}
            />
            <Input 
                placeholder="전화번호를 입력해주세요"
                value={phoneNumber}
                onChange={onPhoneNumberChange}
            />
            <Gap 
                gap={28}
            />
            <PageButton 
                text="조회하기"
                background="#9C27B0"
                color="#FFFFFF"
                onClick={() => {
                    if(phoneNumber === '')
                    {
                        onSelect(selectedStart, selectedEnd, 'aixk4hfk2dd')
                    }
                    else
                    {
                        //validation
                        onSelect(selectedStart, selectedEnd, phoneNumber)
                    }
                    
                }}
            />
        </Container>
    );
}