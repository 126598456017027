import styled from "styled-components";

interface ButtonProps {
    width: string;
    height: string;
    bgColor: string;
    radius: string;
    text: string;
    color: string;
    size: string;
    onClick: () => void;
}

const ButtonDiv = styled.div<{
    width: string,
    height: string,
    bgColor: string,
    radius: string,
    color: string
    size: string;
}>`
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    background-color: ${(props) => props.bgColor};
    border-radius: ${(props) => props.radius}px;
    color: ${(props) => props.color};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${(props) => props.size}px
`;

export function DefaultButton({
    width,
    height,
    bgColor,
    radius,
    text,
    color,
    size,
    onClick
}:ButtonProps){
    return <ButtonDiv
        width={width}
        height={height}
        bgColor={bgColor}
        radius={radius}
        color={color}
        size={size}
        onClick={() => {
            onClick()
        }}
    >{text}</ButtonDiv>;
}