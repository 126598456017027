import { useState } from "react";
import styled from "styled-components";

import {
    StoreType
} from '../../types';

import {
    StoreImageGrid,
    StoreDetail
} from '../molecules';

import {
    MapModal
} from '../modals';

const Container = styled.div`
    width: 100%;
    overflow: hidden;
    background-color: #FFFFFF;
`;

const Title = styled.div`
    color: #000000;
    font-weight: 500;
    background-color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 15px;
`;

const SubTitle = styled.span`
    color: #000000;
    font-weight: 400;
    background-color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 15px;
    margin-top: 5px;
`;

const RowWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
`;

const ImageWrapper = styled.div`
    width: 50%;
    height: 50vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: .1px solid #909090;
`;

const Image = styled.img`
    width: 100%;
`;

const MenuImageWrapper = styled.div`
    width: 100%;
    height: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: .1px solid #909090;
    border-radius: 10px;
`;

const MenuImage = styled.img`
    width: 90%;
`;

interface StoreInfoProps {
    storeInfo: StoreType;
}

export function StoreInfo({
    storeInfo,
}: StoreInfoProps){

    const [ mapView, setMapView ] = useState(false);
    return (
        <Container>         
            {
                mapView && 
                <MapModal 
                    name={storeInfo.name}
                    address={storeInfo.jibunAddress}
                    onClose={()=>{
                        setMapView(false);
                    }}
                />
            }
            <StoreImageGrid 
                banner1={storeInfo.banner1}
                banner2={storeInfo.banner2}
                banner3={storeInfo.banner3}
                banner4={storeInfo.banner4}
                banner5={storeInfo.banner5}
                storeId={storeInfo.id}
            />
            <StoreDetail 
                map={true}
                introduction={storeInfo.introduction}
                address={storeInfo.jibunAddress}
                phone={storeInfo.representativeNumber}
                onMapClick={() => {
                    setMapView(true)
                }}
            />
            {/* <Title>메뉴</Title>
            {
                menuList.length > 0 && 
                menuList.map((menu, index) => {
                    return (
                        <div key={index} style={{marginBottom: '20px'}}> 
                            <MenuImageWrapper>
                                <MenuImage 
                                    onLoad={() => {
                
                                    }}
                                    src={`/v1/menu/image/read/${menu.id}?${hash}`}
                                />
                            </MenuImageWrapper>
                            <SubTitle>{menu.name}</SubTitle>
                            <SubTitle>{menu.description}</SubTitle>
                            <SubTitle>{menu.duration}분 걸립니다</SubTitle>
                            <SubTitle>{menu.price}원</SubTitle>
                        </div>
                    );
                })
            } */}
        </Container>
    );
}