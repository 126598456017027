import styled from "styled-components";

import {
    BarSchedule
} from '../organisms';

const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    justify-content: center;
    align-items: flex-start;
    display: flex;
`;

const Section = styled.div`
    max-width: 600px;
    width: 600px;
    height: 100%;
    background-color: #FFFFFF;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
`;

function Organism(){
    return (
        <Container>
            <Section>
                <BarSchedule />
            </Section>
        </Container>
    );
}

export default Organism;