import { useState } from "react";
import styled from "styled-components";

var BASE_URL = process.env.REACT_APP_TARGET_URL;

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0,0,0,1);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const Section = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,1);
    overflow: auto;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    position: relative;
`;


const Image = styled.img`
    width: 100%;
`;

const Close = styled.img`
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: #FFFFFF;
    border-radius: 50%;
`;

interface PhotoViewModalProps {
    bookingId: number;
    orderNumber: number;
    onClose: () => void;
}

export function PhotoViewModal({
    bookingId,
    orderNumber,
    onClose
}: PhotoViewModalProps){
    const [ hash, setHash ] = useState(Date.now());    
    return (
        <Container>
            <Section>
                <Close
                    src={require('../../../../styles/assets/icon/x-mark.png')}
                    onClick={onClose}
                />

                <Image 
                    src={BASE_URL + `/v1/booking/photo/${bookingId}/${orderNumber}/?${hash}`}
                />
            </Section>
            
        
        </Container>
    )
}