import { useEffect, useState } from "react";
import styled from "styled-components";

import { 
    storeState 
} from '../../../../stores';
import { useRecoilValue } from "recoil";

import {
    createCustomerRequest,
    uploadCustomerPhoto,
    checkCustomerDupRequest
} from '../../../../apis/BookingApi';

import {
    Gap,
    PageButton,
} from '../../../atoms';

import {
    ModalHeader,
    PhotoEdit,
    UserInfoEdit,
    Loading
} from '../../../molecules';

import {
    AlertModal,
    ConfrimCheckModal
} from '../../../modals';

import {
    CustomerType
} from '../../../../types'
import { phoneCheck } from "../../../../utilities/validation";

const Container = styled.div`
    display: flex;
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background: #FFFFFF;
    z-index: 999;
    top: 0;
    left: 0;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-bottom: 40px;
`;

const Line = styled.div`
    width: 100%;
    height: 8px;
    background: #F2F2F2;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 5vw;
    margin-top: 12px;
`;

interface AddCustomerModalProps {
    onClose: () => void;
}

export function AddCustomerModal({
    onClose
}: AddCustomerModalProps){
    const store = useRecoilValue(storeState);
    const [ isAlert, setIsAlert ] = useState(false);
    const [ alertTitle, setAlertTitle ] = useState<string>('');
    const [ alertDescription, setAlertDescription ] = useState<string>('');
    const [ loading, setLoading ] = useState(false);
    const [ viewConfirmModal, setViewConfirmModal ] = useState(false);

    const [ customer, setCustomer ] = useState<CustomerType>({
        id: 0,
        storeId: store.id,
        phoneNumber: '',
        name: '',
        point: 0,
        userMemo: '',
        createdAt: '',
        updatedAt: '',
        fileType: ''
    });

    const [ photo, setPhoto ] = useState<any>(null);
    const [ fileType, setFileType ] = useState<string>('');

    const addCustomerInfo = async () => {

        // validation
        if(customer.name === '')
        {
            setAlertTitle("이름을 입력해주세요")
            setIsAlert(true);
            return;
        }

        if(!phoneCheck(customer.phoneNumber))
        {
            setAlertTitle('휴대폰번호를 정확하게 입력해주세요')
            setIsAlert(true);
            return;
        }

        // check dup 
        const dupRes = await checkCustomerDupRequest(store.id, customer.phoneNumber);
        if(dupRes.status === 'success')
        {
            if(dupRes.data)
            {

            }
            else
            {
                setAlertTitle("중복사용자");
                setAlertDescription("이미 같은 휴대폰번호를 사용하는 사용자가 등록되어있습니다.")
                setIsAlert(true);
                return;
            }
        }


        setLoading(true);
        const res = await createCustomerRequest(customer);
        if(res.status === 'success')
        {
            if(res.data !== null)
            {
                if(photo !== null)
                {
                    const res2 = await uploadCustomerPhoto(photo, res.data.id, fileType);
                    setLoading(false);
                }
                else
                {
                    setLoading(false);
                }
                onClose();
            }
            else
            {
                setIsAlert(true);
                setAlertTitle("이미 등록된 고객입니다.")
                setLoading(false);
            }
            
        }
    }
    
    useEffect(() => {
        var add:HTMLDivElement =  document.getElementsByClassName('request')[0] as HTMLDivElement;        
        add.style.overflow = 'hidden';       
        return () => {
            add.style.overflow = 'auto';
        }
    }, [])

    return (
        <Container>
            {
                loading && <Loading />
            }
            {
                viewConfirmModal && 
                <ConfrimCheckModal 
                    title={'변경한 정보가 있는지 확인해주세요.'}
                    description={'뒤로가기를 누를 경우 변경한 내역이 저장되지 않습니다.'}
                    onCheck={() => {
                        setViewConfirmModal(false);
                        onClose();
                    }}
                    onCancel={() => {
                        setViewConfirmModal(false);
                    }}
                />
            }
            {
                isAlert &&
                <AlertModal 
                    title={alertTitle}
                    description={alertDescription}
                    onCheck={() => {
                        setAlertTitle('');
                        setAlertDescription('');
                        setIsAlert(false);
                    }}
                />
            }
            <ModalHeader 
                title="고객 정보 등록"
                onClose={() => {
                    setViewConfirmModal(true);
                }}
            />
            <Gap 
                gap={0}
            />
            <PhotoEdit 
                fileType={customer.fileType}
                id={customer.id}
                onSaveFileChange={(file, type) => {
                    setPhoto(file);
                    setFileType(type);
                }}
            />
            <Line/>
            <UserInfoEdit 
                name={customer.name}
                phone={customer.phoneNumber}
                point={customer.point}
                memo={customer.userMemo}
                onNameChange={(name) => {
                    setCustomer({...customer, name: name})
                }}
                onPhoneChange={(phone) => {
                    setCustomer({...customer, phoneNumber: phone})
                }}
                onPointChange={(point) => {
                    setCustomer({...customer, point: point})
                }}
                onMemoChange={(memo) => {   
                    setCustomer({...customer, userMemo: memo})
                }}
            />
            <Wrapper>
                <PageButton 
                    text="등록하기"
                    background="#9C27B0"
                    color='#FFFFFF'
                    onClick={addCustomerInfo}
                />
            </Wrapper>
        </Container>
    );
}