import styled from "styled-components";
import { FormEvent } from "react";

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: #FFFFFF;
    padding: 40px 5vw;
`;

const RowWrapper = styled.div<{mgb: number}>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${(props) => props.mgb}px;
`;

const Input = styled.input`
    width: 100%;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #7D93A6;
    padding-left: 5vw;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
`;

const Info = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    letter-spacing: -0.21875px;
    color: #FF4A8B;
`;

const Description = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px; 
    letter-spacing: -0.175px;
    color: #7D93A6;
`;

interface InputPersonalInfoProps{
    phoneNumber: string;
    name: string;
    setPhoneNumber: (event: FormEvent<HTMLInputElement>) => void;
    setName: (event: FormEvent<HTMLInputElement>) => void;
}

export function InputPersonalInfo({
    phoneNumber,
    name,
    setPhoneNumber,
    setName
}:InputPersonalInfoProps){
    return (
        <Container>
            <RowWrapper
                mgb={12}
            >
                <Label>휴대전화번호</Label>
                <Info>필수</Info>
            </RowWrapper>
            <RowWrapper
                mgb={12}
            >
                <Description>
                    알림톡 발송을 위해 필수로 입력해주세요
                </Description>
            </RowWrapper>
            <RowWrapper
                mgb={20}
            >
                <Input 
                    pattern="\d*"
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    placeholder="휴대폰번호 (-제외)"
                    
                />
            </RowWrapper>            
            <RowWrapper
                mgb={12}
            >
                <Label>이름</Label>
                <Info>필수</Info>
            </RowWrapper>
            <Input 
                value={name}
                onChange={setName}
                placeholder="이름을 입력해주세요"
            />
        </Container>
    );
}