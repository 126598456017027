import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F4F7F8;
    border-radius: 16px;
    padding: 0 20px;
`;

const Date = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #000000;
`;

const Trash = styled.img`
    width: 20px;
`;

interface DateCardProps {
    onDelete: () => void;
    date: string;
}

export function DateCard({
    date,
    onDelete
}: DateCardProps){
    return (
        <Container>
            <Date>{date}</Date>
            <Trash 
                src={require('../../styles/assets/icon/trash.png')}
                onClick={onDelete}
            />
        </Container>
    );
}