import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    height: 56px;
    background: #F4F7F8;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5vw;
    padding-right: 5vw;
    margin-bottom: 10px;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.5px;
    color: #191F28;
`;

const Icon = styled.img`
    width: 8px;
`;


interface CustomerCardProps {
    name: string;
    phoneNumber: string;
    onSelect: () => void;
}

export function CustomerCard({
    name,
    phoneNumber,
    onSelect
}: CustomerCardProps) {
    return (
        <Container
            onClick={onSelect}
        >
            <Label>{name} / {phoneNumber}</Label>
            <Icon 
                src={require('../../../../../styles/assets/arrow_right.png')}
            />
        </Container>
    );
}