import styled from "styled-components";

import {
    MenuType
} from '../../types';

import {
    MenuCard
} from '../molecules';

const Container = styled.div`
    width: 100%;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 40px;
    padding-bottom: 40px;
    background: #FFFFFF;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: #191F28;
`;

const CardWrapper = styled.div`
    display: grid;
    grid-template-columns: 100%;
    gap: 8px;
    width: 100%;
    margin-top: 20px;
`;

interface StoreMenuInfoProps{
    menuList: MenuType [];
}

export function StoreMenuInfo({
    menuList
}: StoreMenuInfoProps){
    return (
        <Container>
            <Title>서비스</Title>
            <CardWrapper>
                {
                    menuList.length > 0 &&
                    menuList.map((menu, index) => {
                        return (
                            <MenuCard 
                                key={index}
                                menu={menu}
                            />
                        );
                    })
                }
            </CardWrapper>
            
        </Container>
    );
}