import { useEffect, useState } from "react";
import styled from "styled-components";

import { 
    leadingZeros 
} from "../../utilities/convert";

import {
    Gap 
} from '../atoms';

import {
    BookingType,
    BookingBlock,
    StoreTimeType,
    BreakTimeType,
    BreakBlock
} from '../../types';

const Container = styled.div`
    width: 100%;
    padding: 5vw;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 10px;
    background: #FFFFFF;
`;

const RightWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-top: 15px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.25px;
    color: #7D93A6;
`;

const Sample = styled.div`
    width: 33px;
    height: 28px;
    border-radius: 5px;
    background: #FF4A8B;
    margin-right: 10px;
`;

const TableWrapper = styled.div`
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-top: 10px;
    padding-bottom: 20px;
`;

const ColWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: relative;
    overflow: hidden;
`;

const BookingBar = styled.div<{left: number, width: number, continue: boolean}>`
    position: absolute;
    height: 60px;
    /* border-radius: 20px; */
    /* border-top-left-radius: ${(props) => !props.continue ? '20px' : '0'};
    border-bottom-left-radius:  ${(props) => !props.continue ? '20px' : '0'}; */
    border: 3px solid #FF4A8B;
    background-color: #FF4A8B;
    top: 50%;
    width: ${(props) => props.width}%;
    left: ${(props) => props.left}%;
    transform: translate(0, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    z-index: 3;
    cursor: pointer;
    color: #FFFFFF;
    font-weight: 400;
`;

const StoreTimeBlock = styled.div<{left: boolean, right: boolean}>`
    width: 20%;
    height: 40px;
    background-color: 'white';
    border-right: 1px solid #7D93A6;
    border-left: ${(props) => props.left ? '1px solid #7D93A6' : ''};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${(props) => props.theme.textColor};
    font-size: 12px;
    line-height: 16px;
    padding-left: 10px;
    z-index: 1;
    /* background-color: #FFFFFF; */
    opacity: 1;
`;

const StoreCloseTimeBlock = styled.div<{left: boolean}>`
    width: 20%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${(props) => props.theme.textColor};
    font-size: 12px;
    line-height: 16px;
    padding-left: 10px;
    border-radius: 0px;
    z-index: 1;
    background-color: #FF4A8B;
`;

const BreakBar = styled.div<{left: number, width: number, continue: boolean}>`
    position: absolute;
    height: 60px;
    /* border-radius: 5px;
    border-top-left-radius: ${(props) => !props.continue ? '5px' : '0'};
    border-bottom-left-radius:  ${(props) => !props.continue ? '5px' : '0'}; */
    border: 3px solid #FF4A8B;
    background-color: #FF4A8B;
    top: 50%;
    width: ${(props) => props.width}%;
    left: ${(props) => props.left}%;
    transform: translate(0, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    z-index: 3;
    cursor: pointer;
    color: #560C7B;
    font-weight: 500;
`;

const Time = styled.div`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.214286px;
    color: #525558;
    width: 20%;
    margin-bottom: 6px;
    margin-top: 18px;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
`;

const Available = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.175px;
    color: #7D93A6;
    margin-top: 11px;
`;


const SubTitle = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.175px;
    color: #9C27B0;
`;

const BookingButton = styled.div`
    width: 100%;
    height: 50px;
    background-color: #560C7B;
    border-radius: 10px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
`;

const LinkWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
`;
const LinkIcon = styled.img`
    width: 12px;
`;

const LinkLabel = styled.span`
    color: #9C27B0;
    margin-left: 5px;

    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: -0.175px;
`;  

interface UserScheduleTableProps {
    open: string;
    close: string;
    name: string;
    linkLabel: string;
    link: string;
    bookingData: BookingType [];
    breakTimes: BreakTimeType [];
    onBooking: (time: string) => void;
}

export function UserScheduleTable({
    open,
    close,
    name,
    linkLabel,
    link,
    bookingData,
    breakTimes,
    onBooking,
}: UserScheduleTableProps){
    const [ storeAvailableTimeList, setStoreAvailableTimeList ] = useState<StoreTimeType[][]>();  // 상점 운영 타임 
    const [ bookingBlockList, setBookingBlockList ] = useState<BookingBlock[]>([]);  
    const [ breakBlockList, setBreakBlockList ] = useState<BreakBlock[]>();

    const calStoreAvailableTimeList = (open: string, end: string) => {
        const now = new Date();

        let startHour = open.slice(0, 2);
        let startMinute = open.slice(3, 5);
        let start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), Number(startHour), Number(startMinute));

        let endHour = end.slice(0, 2);
        let endMinute = end.slice(3, 5);
        let close = new Date(now.getFullYear(), now.getMonth(), now.getDate(), Number(endHour), Number(endMinute));

        let tTimeList:StoreTimeType[][] = []; 
        let tTime:StoreTimeType[] = [];

        for(let i = 0; i < 24; i++)
        {
            if(start < close)
            {   
                let temp: StoreTimeType = {
                    time: leadingZeros(start.getHours().toString(), 2) + ":" + leadingZeros(start.getMinutes().toString(), 2)
                };
                tTime.push(temp);
            }           
            start.setHours(start.getHours() + 1);

            if((i+1) % 5 === 0)
            {
                if(tTime.length > 0)
                {
                    tTimeList.push(tTime);
                    tTime = [];
                }
            }

            if(i === 23)
            {
                if(tTime.length > 0)
                {
                    tTimeList.push(tTime);
                    tTime = [];
                }
            }
        }

        setStoreAvailableTimeList([]);
        setStoreAvailableTimeList(tTimeList);
        setBookingBlockList([]);
        setUserBookingBlock(Number(startHour), Number(startMinute), bookingData);
        // alert(JSON.stringify(breakTimes))
        setBreakBlockList([]);
        setStoreBreakBlock(Number(startHour), Number(startMinute), breakTimes);
        // set user booking block
    }


    const setStoreBreakBlock = (openHour: number, openMinute: number,  breakTimeList: BreakTimeType[]) => {
        let resultBlockList: BreakBlock[] = [];

        for(let i = 0; i < breakTimeList.length; i++)
        {
            // loop 계산 
            let startHour = Number(breakTimeList[i].start.slice(0, 2));
            let startMinute = Number(breakTimeList[i].start.slice(3, 5));
    
            let diffHour = 0;
            let diffMinute = 0;

            if(startMinute >= openMinute)
            {
                diffMinute = startMinute - openMinute;
            }
            else
            {
                diffMinute = startMinute + 60 - openMinute;
                startHour = startHour - 1; 
            }

            diffHour = startHour - openHour;
            // console.log("diff Hour: " + diffHour  + " diff Minute: " + diffMinute);
            // 1. start y position
            // open +(5 * n) 시간 이후라면 position = n
            let yPosition = 0;
            if(diffHour < 5)
            {
                yPosition = 0;
            }
            else if(diffHour >= 5)
            {
                let y = Math.floor(Number(diffHour / 5));
                yPosition = y;
            }            
            // console.log("time : " + bookingList[i].start + " yPosition: " + yPosition);

            // 2. start x position
            // time - open + (5 + n) / 10 * 3.333  
            let startPosition = (((startHour * 60) + diffMinute) - (((openHour + yPosition * 5) * 60))) / 10 * 3.333333333333333333;
            // alert(startHour)
            // console .log("start Position: " + startPosition)

            // 3. width
            // duration / 10 * 3.333
            let width = Number(breakTimeList[i].duration) /10 * 3.333333333333333333;
            // console.log("width: " + width)
   
            let checkTime = (openHour + (yPosition + 1) * 5) * 60;
            let endTime = (startHour * 60) + diffMinute + Number(breakTimeList[i].duration);
            // console.log("check Hour: " + checkTime + " endTime: " + endTime);

            if(endTime > checkTime)
            {
                let diffTime = Math.ceil((endTime - checkTime) / 300);
                
                for(let j = 0; j <= diffTime; j++)
                {
                    let block:BreakBlock = {
                        startXPosition: startPosition,
                        startYPosition: yPosition,
                        width: width,
                        continue: false,
                        text: '휴식'
                    }

                    if(j > 0)
                    {
                        block.startXPosition = 0;
                        block.startYPosition = block.startYPosition + j;
                        block.continue = true;
                    }

                    if(j === diffTime)
                    {
                        const diff = ((endTime - checkTime) / 10 * 3.333333333333333333) % 100;
                        block.width = diff;
                    }
                    
                    resultBlockList.push(block)
                }
            }
            else
            {
                let block:BreakBlock = {
                    startXPosition: startPosition,
                    startYPosition: yPosition,
                    width: width,
                    continue: false,
                    text: '휴식'
                }
                resultBlockList.push(block)
                // console.log("line: " + 1);
            }

            setBreakBlockList(resultBlockList);
        }
    }

    
    const setUserBookingBlock = (openHour: number, openMinute: number,  bookingList: BookingType[]) => {
        let resultBlockList: BookingBlock[] = [];

        for(let i = 0; i < bookingList.length; i++)
        {
            // loop 계산 
            let startHour = (Number(bookingList[i].time.slice(11, 13)) + 9) % 24;
            let startMinute = Number(bookingList[i].time.slice(14, 16));
            // console.log("start: " + bookingList[i].time.slice(14, 16))
            let diffHour = 0;
            let diffMinute = 0;

            if(startMinute >= openMinute)
            {
                diffMinute = startMinute - openMinute;
            }
            else
            {
                diffMinute = startMinute + 60 - openMinute;
                startHour = startHour - 1; 
            }

            diffHour = startHour - openHour;
            // 1. start y position
            // open +(5 * n) 시간 이후라면 position = n
            let yPosition = 0;
            if(diffHour < 5)
            {
                yPosition = 0;
            }
            else if(diffHour >= 5)
            {
                let y = Math.floor(Number(diffHour / 5));
                yPosition = y;
            }            
            // console.log("time : " + bookingList[i].start + " yPosition: " + yPosition);

            // 2. start x position
            // time - open + (5 + n) / 10 * 3.333  
            // let startPosition = ((startHour * 60) + startMinute - ((openHour + yPosition * 5) * 60) + openMinute)/ 10 * 3.333333333333333333;
            let startPosition = (((startHour * 60) + diffMinute) - (((openHour + yPosition * 5) * 60))) / 10 * 3.333333333333333333;

            // console .log("start Position: " + startPosition)

            // 3. width
            // duration / 10 * 3.333
            let width = Number(bookingList[i].duration) /10 * 3.333333333333333333;

            let checkTime = (openHour + (yPosition + 1) * 5) * 60;
            let endTime = (startHour * 60) + diffMinute + Number(bookingList[i].duration);
            // console.log("check Hour: " + checkTime + " endTime: " + endTime);

            if(endTime > checkTime)
            {
                let diffTime = Math.ceil((endTime - checkTime) / 300);
                // console.log("line: " + (diffTime + 1));
                // push line수 만큼 
                // 4. continue start
                // startYPosition: number;
                // startXPosition: number;
                // width: number;
                // continue: boolean;
                // text: string;
            
                // 층구분     
                for(let j = 0; j <= diffTime; j++)
                {
                    
                    let block:BookingBlock = {
                        bookingId: bookingList[i].bookingId,
                        startXPosition: startPosition,
                        startYPosition: yPosition,
                        width: width,
                        continue: false,
                        text: bookingList[i].menuName
                    }

                    if(j > 0)
                    {
                        block.startXPosition = 0;
                        block.startYPosition = block.startYPosition + j;
                        block.continue = true;
                    }

                    if((j) === diffTime)
                    {   
                        const diff = ((endTime - checkTime) / 10 * 3.333333333333333333) % 100;
                        block.width = diff;
                        // end - check (mode 300 )
                    }

                    resultBlockList.push(block)
                }
            }
            else
            {
                let block:BookingBlock = {
                    bookingId: bookingList[i].bookingId,
                    startXPosition: startPosition,
                    startYPosition: yPosition,
                    width: width,
                    continue: false,
                    text: bookingList[i].menuName
                }
                resultBlockList.push(block)
                // console.log("line: " + 1);
            }
            // console.log(resultBlockList)
            setBookingBlockList(resultBlockList);
        }
    }

    useEffect(() => {
        if(!open || !close)
        {
            console.log("영업일이 아닙니다")
        }
        else
        {
            calStoreAvailableTimeList(open, close)
        }
        // console.log("booking data: " + JSON.stringify(bookingData));
    }, [open, close, name, breakTimes, bookingData])

    return (
        <Container>
            <Gap 
                gap={20}
            />
            <Title>{name}</Title>
            {
                link !== '' && linkLabel !== '' &&
                <LinkWrapper
                    onClick={() => {
                        window.open(`${link}`, '_blank')
                    }}
                >
                    <LinkIcon src={require('../../styles/assets/icon/url.png')} /><LinkLabel>{linkLabel}</LinkLabel>
                </LinkWrapper>
                 
            }
            <Available>영업시간 {open} ~ {close}</Available>
            <Gap 
                gap={12}
            />
            <SubTitle>비어있는 스케줄을 확인하고 예약을 신청하세요</SubTitle>
            {/* <Sample src={require('../../styles/assets/sample.png')} /> */}
            <TableWrapper>            
            {
                storeAvailableTimeList && 
                storeAvailableTimeList.map((times, index1) => {
                    return(
                        <ColWrapper key={index1}>
                            <RowWrapper>
                            {
                                times.map((time, index2) => {
                                    return(
                                        <Time key={index2}>{time.time}</Time>
                                    );
                                })
                            }
                            </RowWrapper>
                            <RowWrapper>
                            {/* 예약시간 */}
                            {
                                bookingBlockList &&
                                bookingBlockList.map((block, index) => {
                                    return(                                        
                                        index1 === block.startYPosition && 
                                        <BookingBar key={index}
                                            width={block.width}
                                            left={block.startXPosition}
                                            continue={block.continue}
                                        ></BookingBar>                                        
                                    );
                                })
                            }
                            {/* 브레이크 */}
                            {
                                breakBlockList && 
                                breakBlockList.map((block, index) => {
                                    return (
                                        index1 === block.startYPosition && 
                                        <BreakBar key={index}
                                            width={block.width}
                                            left={block.startXPosition}
                                            continue={block.continue}
                                            onClick={() => {
                                               
                                            }}
                                        ></BreakBar>                
                                    );
                                })
                            }
                            {/* 운영시간 */}         
                            {                               
                                times.map((time, index2) => {
                                    return(
                                        <StoreTimeBlock 
                                            right={index2%5 === 4 ? true: false}
                                            left={index2%5 === 0 ? true : false}
                                            key={index2}
                                            onClick={() => {
                                                onBooking(time.time)
                                            }}
                                        >
                                        </StoreTimeBlock>
                                    );
                                })
                            }   
                            {  
                                storeAvailableTimeList.length - 1 === index1 &&
                                [...Array((5 - times.length ))].map((n, index) => {
                                    return (
                                        <StoreCloseTimeBlock 
                                            left={false}
                                            key={index}>                                                
                                        </StoreCloseTimeBlock>
                                    );
                                })
                            }           
                            </RowWrapper>                            
                        </ColWrapper>
                    );
                })
            }     
            <RightWrapper>
                <Sample />
                예약불가
            </RightWrapper>
            
            </TableWrapper>         
        </Container>
    );
}