import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FormEvent } from "react";

import Terms from '../../../../pages/common/Terms';
import Privacy from '../../../../pages/common/Privacy';

import {
    MenuType,
    EtcInformationType,
    RequestBookingTimeType,
    BookingRequestType,
    BookingEtcInfoType
} from '../../../../types'

import {
    DefaultButton, 
    PageButton,
    Gap
} from '../../../atoms'

import {
    ConfirmModal,
    AlertModal
} from '../../../modals';

import {
    Loading
} from '../../../molecules';

import {
    BookingTimeSelect,
    InputPersonalInfo,
    MenuSelect,
    InputEtcInfo,
    PhotoUpload
} from '../../../organisms'

import {
    addBookingRequest,
    uploadPhotoFileRequest
} from '../../../../apis/BookingApi'

import { leadingZeros } from "../../../../utilities/convert";
import { phoneCheck } from "../../../../utilities/validation";

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`;

const TouchableSection = styled.div`
    width: 100%;
    height: 10%;
    cursor: pointer;
    z-index: 1;
`;

const Section = styled.div`
    width: 100%;
    height: 90%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #FFFFFF;
    overflow: auto;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 100;
`;

const Header = styled.div`
    width: 100%;
    background: #FFFFFF;   
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #CEDDE2;
    padding-top: 26px;
    padding-bottom: 20px;
    padding-left: 5vw;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: #191F28;
`;

const Line = styled.div`
    width: 100%;
    border-bottom: 8px solid #F2F2F2;
`;

const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 40px;    
    padding-left: 5vw;
    padding-right: 5vw;
`;

const TextArea = styled.textarea`
    width: 100%;
    height: 100px;
    padding: 10px;
    font-size: 15px;
`;

const ColWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
`;

const Wrapper = styled.div`
    width: 100%;
    padding: 10px 5vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #A3AAB6;
`;

const TextButton = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    text-align: right;
    letter-spacing: -0.21875px;
    color: #FF4A8B;
    cursor: pointer;
    width: 120px;
`;

interface BookingRequestModalProps {
    onClose: () => void;
    onConfirm: () => void;
    year: number;
    month: number;
    date: number;
    menuList: MenuType [];
    etcInfoList: EtcInformationType [];
    storeId: number;
    uploadDescription: string | null;
    isUpload: boolean;
    startTime: string | null;
    advertisementId: number | undefined;
    influencerId: number | undefined;
}

export function BookingRequestModal({
    onClose,
    onConfirm,
    menuList,
    etcInfoList,
    storeId,
    year,
    month,
    date,
    startTime,
    isUpload,
    uploadDescription,
    advertisementId,
    influencerId
}: BookingRequestModalProps){
    const [ viewTerms, setViewTerms ] = useState(false);
    const [ viewPrivacy, setViewPrivacy ] = useState(false);
    const [ isAlert, setIsAlert ] = useState(false);
    const [ alertTitle, setAlertTitle ] = useState<string>('');
    const [ alertDescription, setAlertDescription ] = useState<string>('');

    const [ loading, setLoading ] = useState(false);

    const [ isConfirm, setIsConfirm ] = useState(false);
    const [ requestTimeList, setRequestTimeList ] = useState<RequestBookingTimeType[]>([
        {
            id: 0,
            bookingId: 0,
            startTime: startTime === null ? '' : startTime + ":00",
            endTime: '',
            orderNumber: 0
        }
    ]);
    const [ saveRequestTimeList, setSaveRequestTimeList ] = useState<RequestBookingTimeType[]>([]);
    const [ bookingEtcInfoList, setBookingEtcInfoList ] = useState<BookingEtcInfoType[]>([]);

    const [ selectedMenuId, setSelectedMenuId ] = useState<number>(0);
    const [ selectedMenuName, setSelectedMenuName ] = useState<string>('');
    const [ phone, setPhone ] = useState<string>('');
    const [ name, setName ] = useState<string>('');
    
    const [ selectedDate, setSelectedDate ] = useState(
        leadingZeros(year.toString(), 2) + "-" +
        leadingZeros(month.toString(), 2) + "-" + 
        leadingZeros(date.toString(), 2)
    )

    const [ files, setFiles ] = useState<any []>([]);

    const addNewBookingRequestTime = async () => {
        let requestBookingTime: RequestBookingTimeType = {
            id: 0,
            bookingId: 0,
            startTime: '',
            endTime: '',
            orderNumber: 0
        }

        //

        setRequestTimeList([...requestTimeList, requestBookingTime]);
    }

    
    const deleteSaveRequestList = (index: number) => {
        setSaveRequestTimeList(saveRequestTimeList.filter((time, ii) => ii !== index));
    }

    function onPhoneChange(event: FormEvent<HTMLInputElement>){
        const {
            currentTarget: {value}
        } = event;

        // 숫자 자리수 01063640638        

        setPhone(value);
    }

    function onNameChange(event: FormEvent<HTMLInputElement>){
        const {
            currentTarget: {value}
        } = event;
        setName(value);
    }

    const selectMenu = (menuId: number) => {
        setSelectedMenuId(menuId);
    }

    const onTextChange = (event: FormEvent<HTMLTextAreaElement>, index: number) => {
        const {
            currentTarget: {value},
        } = event;
        
        let tBookingEtcInfoList: BookingEtcInfoType[] = JSON.parse(JSON.stringify(bookingEtcInfoList));

        tBookingEtcInfoList.map((etc, eindex) => {
            if(eindex === index)
            {
                // alert(index)
                etc.contents = value
            }
        })
        setBookingEtcInfoList(tBookingEtcInfoList);
    }   

    const onRequest = () => {
        //validation
        // 1.
        // alert(JSON.stringify(bookingEtcInfoList))
        if(saveRequestTimeList.length === 0)
        {
            setAlertTitle("예약시간 입력오류")
            setAlertDescription("예약 요청 시간을 입력해주세요")
            setIsAlert(true);
            return;
        }
        
        if(!phoneCheck(phone))
        {
            setAlertTitle("휴대전화번호 기입 오류")
            setAlertDescription("입력하신 휴대전화번호를 확인해주세요.")
            setIsAlert(true);
            return;
        }

        if(name === '')
        {
            setAlertTitle("이름입력")
            setAlertDescription("예약하시는 분의 성함을 입력해주세요.")
            setIsAlert(true);
            return;
        }

        if(selectedMenuId === 0)
        {
            setAlertTitle("서비스 선택")
            setAlertDescription("예약하시는 서비스를 선택해주세요.")
            setIsAlert(true);
            return;
        }

        let etcValidationFlag = false;
        bookingEtcInfoList.map((etc, index) => {
            if(etc.contents === '')
            {
                setAlertTitle("추가 정보 입력")
                setAlertDescription("예약에 필요한 추가 정보를 모두 입력해주세요.")
                setIsAlert(true);
                etcValidationFlag = true;
            }
        })
        if(etcValidationFlag)
        {
            return;
        }
        

        setIsConfirm(true);
    }

    const requestBooking = async () => {
        
        setLoading(true);

        let timeListArr:RequestBookingTimeType [] = saveRequestTimeList.filter(function(data){
            return data.endTime !== '' && data.startTime !== ''
        }) 

        let bookingRequestData: BookingRequestType = {
            requestDate: selectedDate,
            requestTimeList: timeListArr,
            etcInfoList: bookingEtcInfoList,
            storeId: storeId,
            menuId: selectedMenuId,
            phone: phone,
            name: name,
            influencerId: influencerId ? influencerId : 0,
            advertisementId: advertisementId ? advertisementId : 0
        }

        const res = await addBookingRequest(bookingRequestData);

        if(res.status === 'success')
        {
            document.body.style.overflow = "unset";

            // 사진이 있다면 사진 업로드 
            if(files.length > 0)
            {
                await uploadPhoto(res.data);
            }

            setLoading(false);
            onConfirm();
        }
        else
        {
            setLoading(false);
        }
    }

    const uploadPhoto = async (bookingId: number) => {
        files.forEach(async (file, index) => {
            
            let type = '';
            if(file[0].type === 'image/png')
            {
                type = 'png';
            
            }
            else if(file[0].type === 'image/jpg')
            {
                type = 'jpg';
            
            }
            else if(file[0].type === 'image/jpeg')
            {
                type = 'jpg';
                
            }  
            
            const res = await uploadPhotoFileRequest(file, bookingId, index + 1, type, phone);
        })
    }

    const setStartRequestTimeSetting = (index: number, hour: number, minute: number) => {
         // end time < validation
        let tRequestTimeList:RequestBookingTimeType [] = JSON.parse(JSON.stringify(requestTimeList));
        let flag = false;
        tRequestTimeList.map((requestTime, sindex) => {
            if(sindex === index)
            {
                requestTime.startTime = leadingZeros(hour.toString(), 2) + ":" + leadingZeros(minute.toString(), 2) + ":00";      
                if(requestTime.endTime !== '')
                {
                    flag = true;
                }            
            }
        });
        setRequestTimeList(tRequestTimeList);

        if(flag)
        {
            saveBookingRequestList(index, tRequestTimeList)
        }
    }

    const setEndRequestTimeSetting = (index: number, hour: number, minute: number) => {

        // start time > validation
        let tRequestTimeList:RequestBookingTimeType [] = JSON.parse(JSON.stringify(requestTimeList));
        let flag = false;
        tRequestTimeList.map((requestTime, sindex) => {
            if(sindex === index)
            {
                requestTime.endTime = leadingZeros(hour.toString(), 2) + ":" + leadingZeros(minute.toString(), 2) + ":00";          
                if(requestTime.startTime !== '')
                {                   
                    flag = true;
                }             
            }
        });
        setRequestTimeList(tRequestTimeList);

        if(flag)
        {
            saveBookingRequestList(index, tRequestTimeList)
        }
    }

    const saveBookingRequestList = (index: number, requestTime: RequestBookingTimeType[]) => {
        // index에 있는 내용을 옮김         
        // request 는 삭제 
        let t: RequestBookingTimeType = JSON.parse(JSON.stringify(requestTime[index]));

        if(t.startTime === '' || t.endTime === '')
        {

        }
        else
        {
            let start = new Date();
            let end = new Date();

            start.setHours(Number(t.startTime.split(':')[0]))
            start.setMinutes(Number(t.startTime.split(':')[1]))

            end.setHours(Number(t.endTime.split(':')[0]))
            end.setMinutes(Number(t.endTime.split(':')[1]))

            if(start < end)
            {
                setSaveRequestTimeList([...saveRequestTimeList, t]);
                setRequestTimeList([...requestTimeList.slice(0, index-1), ...requestTimeList.slice(index+1, requestTimeList.length)]);
            }
            else{
                //Alert Modal  
                setAlertTitle("시간설정 오류");
                setAlertDescription("종료시간이 시작시간 이전 입니다.")
                setIsAlert(true);
                let tRequestTimeList:RequestBookingTimeType [] = JSON.parse(JSON.stringify(requestTimeList));
                let flag = false;
                tRequestTimeList.map((requestTime, sindex) => {
                    if(sindex === index)
                    {
                        requestTime.endTime = "";
                    }
                });
                setRequestTimeList(tRequestTimeList);
            }
        }
    }

    useEffect(() => {
        document.body.style.overflow = "hidden";

        let etcInfoData: BookingEtcInfoType[] = [];

        etcInfoList.map((etc, index) => {
            
            let requestEtcInfo: BookingEtcInfoType = {
                id: 0,
                bookingId: 0,
                title: etc.title,
                contents: '',
                orderNumber: index
            }
    
            // 시간대가 겹치는 구간이 없는지
    
           etcInfoData.push(requestEtcInfo);

        })

        setBookingEtcInfoList(etcInfoData);

        
    }, [etcInfoList])

    return (
        <Container>
            {
                loading && <Loading />
            }
            {
                viewTerms &&
                <Terms 
                    onClose={() => {
                        setViewTerms(false);
                    }}
                />
            }
            {
                viewPrivacy &&
                <Privacy 
                    onClose={() => {
                        setViewPrivacy(false);
                    }}
                />
            }
            {
                isAlert &&
                <AlertModal 
                    title={alertTitle}
                    description={alertDescription}
                    onCheck={() => {
                        setIsAlert(false);
                    }}
                />
            }
            {
                isConfirm &&
                <ConfirmModal 
                    onClose={() => {
                        document.body.style.overflow = "unset";
                        setIsConfirm(false);
                    }}
                    onConfirm={() => {
                        setIsConfirm(false);
                        requestBooking();
                    }}
                    title="입력하신 정보를 예약을 신청할까요?"
                    message=""
                    times={saveRequestTimeList}
                    name={name}
                    phone={phone}
                    menuName={selectedMenuName}
                />
            }
            <TouchableSection 
                onClick={() => {
                    document.body.style.overflow = "unset";
                    onClose()
                }}
            />
            <Section className="request">
                <Header>{selectedDate} 예약</Header> 
                <BookingTimeSelect 
                    requestBookingTimeList={requestTimeList}
                    saveRequestTileList={saveRequestTimeList}
                    addNewRequestBookingTime={addNewBookingRequestTime}
                    setStartRequestTimeSetting={setStartRequestTimeSetting}
                    setEndRequestTimeSetting={setEndRequestTimeSetting}
                    saveRequestBookingTime={() => {

                    }}
                    deleteSaveRequestList={deleteSaveRequestList}
                />             
                <Line />
                <InputPersonalInfo 
                    phoneNumber={phone}
                    name={name}
                    setPhoneNumber={onPhoneChange}
                    setName={onNameChange}
                />
                <Line />
                <MenuSelect 
                    menuList={menuList}
                    onSelected={(menuId, menuName) => {
                        setSelectedMenuId(menuId);
                        setSelectedMenuName(menuName);
                    }}
                />
                <Line />
                <InputEtcInfo 
                    etcInfoList={etcInfoList}
                    onChange={onTextChange}
                />     
                <Line />
                {
                    isUpload && 
                    <PhotoUpload 
                        description={uploadDescription}
                        onSetUploadFiles={(files: any[]) => {
                            setFiles(files)
                        }}
                    />
                }
                <Wrapper>
                    <Label>예약요청을 위해<br/>정보 제공 및 이용약관에 동의합니다.</Label> 
                    <ButtonWrapper>
                        <TextButton
                            onClick={() => {
                                setViewPrivacy(true);
                            }}
                        >개인정보처리방침</TextButton>      
                        <TextButton
                                onClick={() => {
                                    setViewTerms(true);
                                }}
                        >이용약관</TextButton> 
                   
                                          
                    </ButtonWrapper>
                    
                </Wrapper> 
                <Center>
                    <PageButton 
                        text="예약 요청하기"
                        background="#9C27B0"
                        color="#FFFFFF"
                        onClick={onRequest}
                    />
                </Center>       
                      
            </Section>
        </Container>
    );
}