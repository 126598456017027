import styled from "styled-components"

const Button = styled.div<{
    background: string;
    color: string;
    }>`
    width: 161px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.175px;
    background: ${(props) => props.background};
    color: ${(props) => props.color};
    border: ${(props) => props.background === '#FFFFFF' ? '1px solid #CEDDE2' : 'none'};
    cursor: pointer
`;

interface CardButtonProps{
    background: string;
    color: string;
    text: string;
    onClick: () => void;
}

export function CardButton({
    background,
    color,
    text,
    onClick
}:CardButtonProps){
    return (
        <Button
            background={background}
            color={color}
            onClick={onClick}
        >
            {text}
        </Button>
    );
}