import styled from "styled-components";

import {
    StoreAdvertisementResponseType
} from '../../../../types';

import {
    BookingType 
} from '../../../../types';

import {
    getAdvertisementBookingPageRequest
} from '../../../../apis/BookingApi';
import { useEffect, useState } from "react";
import { AdvertisementBookingCard } from "../../../molecules";

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`;

const TouchableSection = styled.div`
    width: 100%;
    height: 20%;
    cursor: pointer;
`;

const Section = styled.div`
    width: 100%;
    min-height: 80%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
    overflow: auto;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1000;
    padding-left: 5vw;
    padding-right: 5vw;
`;

const Text = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.175px;
    color: #9C27B0;
`;

const ListWrapper = styled.div`
    width: 100%;
    height: calc(100vh - 250px);
    overflow-y: scroll;
    margin-top: 40px;
`;

const CenterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;  
`;

const More = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.175px;
    color: #9C27B0;
    cursor: pointer;
`;

const Icon = styled.img`
    width: 16px;
    margin-right: 8px;
`;

interface AdvertisementBookingListModalProps {
    onClose: () => void;
    advertisement: StoreAdvertisementResponseType | undefined;
}

export function AdvertisementBookingListModal({
    onClose,
    advertisement
}: AdvertisementBookingListModalProps){

    //list loading 
    const [ list, setList ] = useState<BookingType[]>([]);

    const [ curPage, setCurPage ] = useState(1);
    const [ totalPage, setTotalPage ] = useState(0);
    const sendQuery = async (page: number) => {
        try{        
            const res = await getAdvertisementBookingPageRequest(advertisement?.advertisementId as number, page - 1, 5, 'id');
            if(page === 0)
            {
                setList([...res.data.content]);
            }
            else
            {
                setList((prev) => [...prev, ...res.data.content]);
            }
            setTotalPage(res.data.totalPages);          
        }
        catch(err) {
        }
        
    }
    
    useEffect(() => {
        setList([]);
        setCurPage(1);
        sendQuery(1);
    }, [])

    return (
        <Container>
            <TouchableSection 
                onClick={onClose}
            />
            {
                advertisement &&
                <Section>
                    <ListWrapper>
                    {
                        list.length > 0 ? 
                        (
                            list.map((item, i) => {
                                return (
                                    <AdvertisementBookingCard 
                                        key={i}
                                        booking={item}
                                    />
                                )
                            })
                        )
                        :
                        (
                            <Text>내역이 없습니다.</Text>
                        )
                    }
                    {
                        totalPage > curPage &&
                        <CenterWrapper>
                            <More 
                                onClick={() => {
                                    let npage = curPage + 1; 
                                    setCurPage(npage);
                                    sendQuery(npage);
                                }}
                            >
                                <Icon 
                                    src={require('../../../../styles/assets/icon/down_arrow.png')}
                                />
                                더보기
                            </More>
                        </CenterWrapper>      
                    }
                    </ListWrapper>
                </Section>
            }
        </Container>
    );
}