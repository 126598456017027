import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 14px;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
`;

const Text = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #191F28;
`;
const Icon = styled.img`
    width: 20px;
`;

interface SearchButtonProps {
    onClick: () => void;
}

export function SearchButton({
    onClick
}: SearchButtonProps){
    return (
        <Container
            onClick={onClick}
        >
            <Text>조회하기</Text>
            <Icon 
                src={require('../../styles/assets/icon/search.png')}
            />
        </Container>
    );
}