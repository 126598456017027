import styled from "styled-components";
import { useEffect, useState } from "react";

import { 
    getStoreAdvertisementPageRequest 
} from "../../../../apis/BookingApi";

import {
    StoreAdvertisementResponseType
} from '../../../../types';

import {
    AdvertisementCard
} from '../../../molecules';

import {
    AdvertisementBookingListModal
} from '../../../modals';

const Container = styled.div`
    width: 100%;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 30px;
`;

const ListWrapper = styled.div`
    width: 100%;
    height: calc(100vh - 250px);
    overflow-y: scroll;
    margin-top: 40px;
`;

const Text = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.175px;
    color: #9C27B0;
`;
const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;  
    letter-spacing: -0.25px;
    color: #000000;
`;

const TextButton =styled.span<{active: boolean}>`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: ${(props) => props.active ? '700' : '400'};
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.175px;
    color: #191F28;
    margin-left: 10px;
`;


const RowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const CenterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;  
`;

const More = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.175px;
    color: #9C27B0;
    cursor: pointer;
`;

const Icon = styled.img`
    width: 16px;
    margin-right: 8px;
`;


interface AdvertisementListProps {
    storeId: number;
}

export function AdvertisementList({
    storeId
}: AdvertisementListProps){
    
    const [ list, setList ] = useState<StoreAdvertisementResponseType[]>([]);
    const [ viewAdvertisementBookingListModal, setViewAdvertisementBookingListModal ] = useState(false);
    const [ selectedAdvertisementInfo, setSelectedAdvertisementInfo ] = useState<StoreAdvertisementResponseType>();

    // pagination
    const [ state, setState ] = useState(1);
    const [ curPage, setCurPage ] = useState(1);
    const [ totalPage, setTotalPage ] = useState(0);

    const sendQuery =async (state: number, page: number) => {
        try{
            const res = await getStoreAdvertisementPageRequest(storeId, state, page - 1, 5, 'id');
            if(page === 0)
            {
                setList([...res.data.content]);
            }
            else
            {
                setList((prev) => [...prev, ...res.data.content]);
            }
            setTotalPage(res.data.totalPages);
        }
        catch(err){

        }
    }

    useEffect(() => {
        setList([]);
        setCurPage(1);
        sendQuery(state, 1);
    }, [state])

    return (
        <Container>
            {
                viewAdvertisementBookingListModal &&
                <AdvertisementBookingListModal 
                    onClose={() => {
                        setSelectedAdvertisementInfo(undefined);
                        setViewAdvertisementBookingListModal(false);
                    }}
                    advertisement={selectedAdvertisementInfo}
                />
            }
            <RowWrapper>
                <Title>마케팅리스트</Title>
                <div>
                    <TextButton
                        active={state===1 ? true : false}
                        onClick={() => {      
                            setState(1);
                        }}
                    >
                        진행예정
                    </TextButton>

                    <TextButton
                        active={state===2 ? true : false}
                        onClick={() => {  
                            setState(2);  
                        }}
                    >
                        진행중
                    </TextButton>
                    <TextButton
                        active={state===3 ? true : false}
                        onClick={() => {  
                            setState(3);  
                        }}
                    >
                        종료
                    </TextButton>
                </div>                
            </RowWrapper>
            <ListWrapper>
                {
                    list.length > 0 ? 
                    (
                        list.map((item, i) => {
                            return (
                                <AdvertisementCard 
                                    key={i}
                                    advertisementInfo={item}
                                    onView={() => {
                                        setSelectedAdvertisementInfo(item);
                                        setViewAdvertisementBookingListModal(true);
                                    }}
                                />
                            )
                        })
                    )
                    :
                    (
                        <Text>내역이 없습니다.</Text>
                    )
                }
                {
                    totalPage > curPage &&
                    <CenterWrapper>
                        <More 
                            onClick={() => {
                                let npage = curPage + 1; 
                                setCurPage(npage);
                                sendQuery(state, npage);
                            }}
                        >
                            <Icon 
                                src={require('../../../../styles/assets/icon/down_arrow.png')}
                            />
                            더보기
                        </More>
                    </CenterWrapper>      
                }
            </ListWrapper>
        </Container>
    );
}