import { useState } from "react";
import styled from "styled-components";

import {
    uploadBannerFileRequest
} from '../../../../apis/BookingApi';

import {
    StoreType
} from '../../../../types';

import {
    StoreBannerSettingModal,
    StoreSettingModal
} from '../../../modals';

import {
    Gap,
    CardButton
} from '../../../atoms';

import {
    StoreImageGrid
} from '../../../molecules';

const Container = styled.div`
    width: 100%;
`;

const Section = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    padding-bottom: 32px;
`;

const Banner = styled.img`
    width: 120px;
    height: 120px;
    border-radius: 50%;
`;

const InfoWrapper = styled.div`
    width: calc(100% - 30px);
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 20px;
`;

const Info = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    padding-left: 10px;
    flex-direction: column;
`;

const InfoText = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.175px;
    color: #7D93A6;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.25px;
    color: #9C27B0;
`;

const Arrow = styled.img`

`;

const FileInput = styled.input`
    display: none;
`;

const UploadButtonLabel = styled.label`
    position: relative;
    top: 0;
    left: 0;
`;

const UploadButton = styled.div`
    position: absolute;
    width: 10px;
    background: red;
    text-align: center;
    top: -10px;
`;


interface StoreSettingProps {
    storeInfo: StoreType;
    onUpdate: () => void;
}

export function StoreSetting({
    storeInfo,
    onUpdate
}: StoreSettingProps){

    const [imgLoad, setImgLoad ] = useState(false);
    const [ hash, setHash ] = useState(Date.now());
    // const [ imageBanner, setImageBanner ] = useState(false);
    const [ isStoreSetting, setIsStoreSetting ] = useState(false);

    const onChange = async (e:React.ChangeEvent<HTMLInputElement>) => {
        const imgs = e.target.files;
        setImgLoad(true);

        if(!imgs) return;
        // console.log(imgs[0].type);

        const formData = new FormData();
        formData.append('file', imgs[0]);
        let type = '';
        if(imgs[0].type === 'image/png')
        {
            type = 'png';
        }
        else if(imgs[0].type === 'image/jpg')
        {
            type = 'jpg';
        }
        else if(imgs[0].type === 'image/jpeg')
        {
            type = 'jpg';
        }

        const res = await uploadBannerFileRequest(imgs, storeInfo.id, 1, type);
        if(res)
        {
            setImgLoad(false);
            setHash(Date.now());
        }        
    }

    return (
        <Container>
            {/* <UploadButtonLabel htmlFor="file">
                <UploadButton>업로드</UploadButton>
            </UploadButtonLabel>
            <FileInput type='file' 
                accept='image/jpg,image/png,image/jpeg' 
                name='profile_img' 
                onChange={onChange}
                id="file"
                
            >
            </FileInput> */}
            
            {/* {
                imageBanner && <StoreBannerSettingModal 
                    onClose={(change) => {
                        if(change)
                        {
                            setHash(Date.now());
                        }
                        setImgLoad(false);                        
                        setImageBanner(false);
                    }}
                    storeInfo={storeInfo}
                />
            } */}
            {
                isStoreSetting && <StoreSettingModal 
                    storeInfo={storeInfo}
                    onClose={(change) => {
                        if(change)
                        {
                            onUpdate();
                        }
                        setImgLoad(false); 
                        setIsStoreSetting(false);
                    }}
                />
            }
            <Section>
                {
                    // !imgLoad ? <Banner src={`/v1/store/banner/read/${storeInfo.banner1Img}?${hash}`}
                    //     onClick={() => {
                    //         setImgLoad(true);
                    //         setImageBanner(true);
                    //     }}
                    // /> :
                    // <></>
                    !imgLoad ?
                    <StoreImageGrid 
                        banner1={storeInfo.banner1}
                        banner2={storeInfo.banner2}
                        banner3={storeInfo.banner3}
                        banner4={storeInfo.banner4}
                        banner5={storeInfo.banner5}
                        storeId={storeInfo.id}
                    /> :
                    <></>
                }
                <Gap 
                    gap={25}
                />              
                <Title>{storeInfo.name}</Title>
                <Gap 
                    gap={16}
                />    
                <InfoText>{storeInfo.roadAddress}</InfoText>                
                <InfoText>{storeInfo.representativeNumber}</InfoText>           
                <Gap 
                    gap={20}
                />  
                <CardButton 
                    text="프로필 수정"
                    background="#FFFFFF"
                    color="#000000"
                    onClick={() => {
                        setImgLoad(true); 
                        setIsStoreSetting(true);
                    }}
                    />               
            </Section>
        </Container>
    );
}