import styled from "styled-components";
import { useEffect, useRef, useState } from "react";

import {
    convertTimestampToDate,
    convertTimestampToTime
} from '../../utilities/convert';

import { 
    UserBookingInfoType 
} from "../../types";

import {
    Gap
} from '../atoms';

import {
    InfoCard
} from '../molecules'

import {
    StoreInfoModal,
} from '../modals';


const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 41px 16px;
    display: flex;
    flex-direction: column;
`;

const Logo = styled.img`
    width: 120px;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.25px;
    color: #000000;
    margin-top: 20px;
`;

const SubTitle = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #1E1E1E;
`;

const ImageWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
`;

const Image = styled.img`
    width: 150px;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 28px;
`;

const RowWrapperSpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 18px;
`;

const RowWrapperCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 18px;
`;

const ColWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
`;

const Label = styled.span`
   font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.5625px;
    color: #000000;
`;

const Text = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.2px;
    color: #FF4A8B;
    margin-top: 12px;
    white-space: pre-line;
    word-wrap:break-all;
`;

const InfoIcon = styled.img`
    width: 16px;
    margin-left: 5px;
    cursor: pointer;
`;
const Button = styled.div`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    text-align: center;
    text-decoration-line: underline;
    color: #560C7B;
`;

interface UserNoShowComponentProps {
    noshowInfo: UserBookingInfoType
}


export function UserNoShowComponent({
    noshowInfo
}: UserNoShowComponentProps){
    const [storeView, setStoreView] = useState(false);    
    const container = useRef(null); 

    return (
        <Container>
            {
                storeView && <StoreInfoModal 
                    storeInfo={noshowInfo.storeInfo}
                    scheduleList={noshowInfo.scheduleList}
                    onClose={() => {
                        setStoreView(false);
                    }}
                />
            }
            <Logo 
                src={require('../../styles/assets/logo_new.png')}
            />  
            <Gap 
                gap={20}
            /> 
            <Title>고객님의 방문을 기다렸어요.</Title>
            <ImageWrapper>
                <Image 
                    src={require('../../styles/assets/user_cancel.png')}
                />
            </ImageWrapper>   
            <RowWrapper>
                <Label>{noshowInfo.storeInfo.name}</Label>
                <InfoIcon src={require('../../styles/assets/info.png')} 
                    onClick={()=>{

                    }}
                />
            </RowWrapper>
            <InfoCard 
                infos={[
                    {key: '예약 요청일', value: `${convertTimestampToDate(noshowInfo.bookingInfo.requestDate)}`},
                    {key: '예약 확정시간', value: `${convertTimestampToTime(noshowInfo.bookingInfo.time)}`},
                    {key: '예약자 이름', value: `${noshowInfo.bookingInfo.userName}`},
                    {key: '예약자 휴대폰번호', value: `${noshowInfo.bookingInfo.userPhone}`},
                    {key: '요청서비스', value: `${noshowInfo.bookingInfo.menuName}`}                    
                ]}            
            />           
        </Container>
    );
}