import { FormEvent, useEffect, useState } from "react";
import styled from "styled-components"

import { 
    storeState 
} from '../../../../stores';
import { useRecoilValue } from "recoil";

import {
    CustomerInfoType
} from '../../../../types';

import {
    searchStoreCustomerRequest
} from '../../../../apis/BookingApi'

import { CustomerCard } from "./component/CustomerCard";

import {
    Gap,
    CardButton
} from '../../../atoms';

import {
    AddSimpleCustomerModal
} from '../../../modals';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`;

const TouchableSection = styled.div`
    width: 100%;
    height: 20%;
    cursor: pointer;
    z-index: 1000;
`;

const Section = styled.div`
    width: 100%;
    height: 80vh;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #FFFFFF;
    overflow-y: auto;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 1000;
    padding-top: 30px;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 30px;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;


const ListWrapper = styled.div`
    width: 100%;
    height: 70vh;
    overflow-y: auto;
`;

const CenterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;  
`;

const More = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.175px;
    color: #9C27B0;
    cursor: pointer;
`;

const Icon = styled.img`
    width: 16px;
    margin-right: 8px;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.5px;
    color: #191F28;
`;

const TextButton =styled.span<{active: boolean}>`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: ${(props) => props.active ? '700' : '400'};
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.175px;
    color: #191F28;
    margin-left: 10px;
`;

const RelativeWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    height: 100px;
`;

const Input = styled.input`
    width: 100%;
    position: absolute;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #AFB8C0;
    padding-left: 15px;
    z-index: 1;
`;

const SearchButton = styled.img`
    width: 20px;
    position: absolute;
    z-index: 2;
    right: 15px;
`;


interface SelectCustomerModalProps {
    onClose: () => void;
    onSelect: (name: string, phoneNumber: string) => void;
}

export function SelectCustomerModal({
    onClose,
    onSelect
}: SelectCustomerModalProps){
    const store = useRecoilValue(storeState);
    const [ curPage, setCurPage ] = useState(1);
    const [ totalPage, setTotalPage ] = useState(0);
    const [ customerInfoList, setCustomerInfoList ] = useState<CustomerInfoType[]>([]);
    const [ viewSimpleCustomerAddModal, setViewSimpleCustomerAddModal ] = useState(false);
    const [ favorites, setFavorites ] = useState<boolean>(false);
    const [ keyword, setKeyword ] = useState<string>('aixk4hfk2dd');

    function onKeywordChange (event: FormEvent<HTMLInputElement>){
        const {
            currentTarget: {value}
        } = event;

        setKeyword(value);
    }


    const getCustomerPage = async (page: number) => {
        setCurPage(page);
 
        let lkeyword = keyword;
        if(keyword === '')
        {
            setKeyword('aixk4hfk2dd');
            lkeyword = 'aixk4hfk2dd'
        }
        

        const res = await searchStoreCustomerRequest(store.id, '2000-01-01 00:00:00', '2999-12-31 23:59:59', page - 1, lkeyword, favorites);
        if(res.status === 'success')
        {
            if(page === 1)
            {
                setCustomerInfoList([...res.data.content]);
            }
            else
            {
                setCustomerInfoList((prev) => [...customerInfoList, ...res.data.content]);
            }
           
            setTotalPage(res.data.totalPages);
        }
    }

    useEffect(() => {
  
        setKeyword('');
      
        
        const getInitCustomerPage = async () => {
            const res = await searchStoreCustomerRequest(store.id, '2000-01-01 00:00:00', '2999-12-31 23:59:59', curPage - 1, 'aixk4hfk2dd', favorites);
            setCurPage(1);
            if(res.status === 'success')
            {
                setCustomerInfoList([...res.data.content]);
                setTotalPage(res.data.totalPages);
            }
        }
        getInitCustomerPage();
    }, [favorites])

    return (
        <Container>
            {
                viewSimpleCustomerAddModal &&
                <AddSimpleCustomerModal 
                    onClose={() => {
                        //reload
                        setCurPage(1);
                        getCustomerPage(1)
                        setViewSimpleCustomerAddModal(false)
                    }}
                />
            }
            <TouchableSection 
                onClick={onClose}
            />
            <Section>
                
                <RowWrapper>
                <Label>고객 정보 추가</Label>
                <div>
                    <TextButton
                        active={!favorites ? true : false}
                        onClick={() => {      
                            setFavorites(false)
                        }}
                    >
                        모두보기
                    </TextButton>

                    <TextButton
                        active={favorites ? true : false}
                        onClick={() => {  
                            setFavorites(true)
                        }}
                    >
                        즐겨찾기
                    </TextButton>

                </div>              
            </RowWrapper>
            <Gap 
                gap={20}
            /> 
            <RelativeWrapper>
            <Input placeholder="이름, 전화번호, 고객메모 검색" 
                value={keyword === 'aixk4hfk2dd' ? '' : keyword}
                onChange={onKeywordChange}
            />
            <SearchButton src={require('../../../../styles/assets/icon/search.png')} 
                onClick={() => {
                    setCurPage(1);
                    getCustomerPage(1);
                }}
            />
            </RelativeWrapper>
                <ListWrapper>
                    {
                        customerInfoList.map((customer, index) => {
                            return (
                                <CustomerCard 
                                    key={index}
                                    name={customer.name}
                                    phoneNumber={customer.phone}
                                    onSelect={() => {
                                        onSelect(customer.name, customer.phone)
                                    }}
                                />
                            );
                        })
                        
                    } 
                    {   
                        totalPage > curPage &&
                        <CenterWrapper>
                            <Gap 
                                gap={10}
                            />      
                            <More 
                                onClick={() => {
                                    getCustomerPage(curPage + 1);
                                }}
                            >
                                <Icon 
                                    src={require('../../../../styles/assets/icon/down_arrow.png')}
                                />
                                더보기
                            </More>
                        </CenterWrapper>                        
                    }
                    <Gap 
                        gap={30}
                    />         
                    {
                        <CenterWrapper>
                            <CardButton 
                                text="고객 정보 추가하기"
                                background="#FFFFFF"
                                color="#9C27B0"
                                onClick={() => {
                                    setViewSimpleCustomerAddModal(true);
                                }}
                            />
                        </CenterWrapper>          
                    } 
                </ListWrapper>      
            </Section>
        </Container>
    );
}