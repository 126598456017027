
import { useCallback, useEffect, useState } from "react";
import {
    UserBookingInfoType
} from '../../types';

import {
    getUserBookingInfoRequest
} from '../../apis/BookingApi'

function useInfo(connectKey: string){
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(false);
    const [ info, setInfo ] = useState<UserBookingInfoType>();

    const sendQuery = useCallback(async () => {
        try{
            await setLoading(true);
            await setError(false);
            const res = await getUserBookingInfoRequest(connectKey);
            await setInfo(res.data);
            setLoading(false);
        }
        catch(err){
            setError(true);
        }
    }, [connectKey])

    useEffect(() => {
        sendQuery();
    }, [connectKey])

    return { loading, error, info }
}

export default useInfo;