import { useEffect, useRef } from "react";
import styled from "styled-components";

import {
    PageButton
} from '../atoms'

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`;

const TouchableSection = styled.div`
    width: 100%;
    height: calc(100% - 436px);
    cursor: pointer;
`;

const Section = styled.div`
    width: 100%;
    height: 536px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
    overflow: auto;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 999;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 20px;
    padding-bottom: 20px;
`;

const TitleWrapper= styled.div`
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.25px;
    color: #191F28;
`;

const MapWrapper = styled.div`
    width: 100%;
    height: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Map = styled.div`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
`;

const ButtonWrapper = styled.div`
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface MapModalProps {
    name: string;
    address: string;
    onClose: () => void;
}

export function MapModal({
    name,
    address,
    onClose
}: MapModalProps){

    const container = useRef(null); 
    
    useEffect(() => {
       
        var geocoder = new window.kakao.maps.services.Geocoder();
        // 주소로 좌표를 검색합니다
        geocoder.addressSearch(address, function(result:any, status:any) {                
            // 정상적으로 검색이 완료됐으면 
            if (status === window.kakao.maps.services.Status.OK) {
                const options = {
                    //지도를 생성할 때 필요한 기본 옵션
                    center: new window.kakao.maps.LatLng(result[0].y, result[0].x), //지도의 중심좌표.
                    level: 3, //지도의 레벨(확대, 축소 정도)
                };
                var map = new window.kakao.maps.Map(container.current, options); //지도 생성 및 객체 리턴

                var markerPosition  = new window.kakao.maps.LatLng(result[0].y, result[0].x);
                // 결과값으로 받은 위치를 마커로 표시합니다
                var marker = new window.kakao.maps.Marker({
                    position: markerPosition
                });

                marker.setMap(map);
                map.setDraggable(true);    
            } 
        });    
        document.body.style.overflow = "hidden";

        return () => {
            document.body.style.overflow = "unset";
        };
      }, []);
    return (
        <Container>
            <TouchableSection 
                onClick={onClose}
            />
            <Section>
                <TitleWrapper>
                    <Title>{name}</Title>
                </TitleWrapper>
                <MapWrapper>
                    <Map
                        className="map"
                        ref={container}
                    />
                </MapWrapper>
                <ButtonWrapper>
                    <PageButton 
                        text="닫기"
                        background="#FFFFFF"
                        color="#000000"
                        onClick={onClose}
                    />
                </ButtonWrapper>                
            </Section>
        </Container>
    );
}