import styled from "styled-components";

const Container = styled.div`
    width: 100%;
`;

interface WeekSelectButtonProps {

}

export function WeekSelectButton(){
    return (
        <Container>
            
        </Container>
    );
}