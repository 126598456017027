import { FormEvent } from "react";
import styled from "styled-components";

const Container = styled.select`
    width: 100%;
    height: 48px;
    border-radius: 12px;
    padding-left: 20px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #191F28;
`;

interface SelectBoxProps{
    options: string [];
    onSelect: (value: string) => void;
    selectedValue: string | undefined;
}

export function SelectBox({
    options,
    onSelect,
    selectedValue
}:SelectBoxProps){

    function handleChangeSelect(event: FormEvent<HTMLSelectElement>) {
        const {
            currentTarget: {value},
        } = event;

        onSelect(value);
    }
    return (        
        <>
        {
            selectedValue && 
            <Container onChange={handleChangeSelect}
                defaultValue={selectedValue}
            >
                {
                    options.length > 0 && 
                    options.map((option, index) => {
                        return(
                            <option onClick={() => {
                                alert(option)
                            }} key={index}                        
                            >{option}</option>
                        );
                    })
                }   
            </Container>    
        }       
        </>
        
    );
}