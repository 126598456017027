import { useState } from 'react';
import styled from 'styled-components';
import { phoneCheck } from "../../utilities/validation";

import {
    updateCustomerInfo,
    uploadCustomerPhoto
} from '../../apis/BookingApi';

import {
    Gap,
    PageButton,
} from '../atoms'

import {
    UpdateCustomerType,
    CustomerInfoType
} from '../../types';

import {
    PhotoEdit,
    UserInfoEdit,
    Loading
} from '../molecules';

import {
    AlertModal,
} from '../modals';

const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    /* padding-top: 40px; */
    overflow-y: auto;
    padding-bottom: 40px;
    margin-top: -40px;
`;

const Line = styled.div`
    width: 100%;
    height: 8px;
    background: #F2F2F2;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 5vw;
    margin-top: 12px;
`;


const DeleteButton = styled.div`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.25px;
    color: #ff4000;
    margin-top: 15px;
    cursor: pointer;
`;

interface CustomerEditProps {
    info: CustomerInfoType;
    onUpdate: () => void;
    onDelete: () => void;
}

export function CustomerEdit({
    info,
    onUpdate,
    onDelete
}: CustomerEditProps){

    // name, phone, remainPoint, UserMemo
    const [ name, setName ] = useState(info.name);
    const [ phone, setPhone ] = useState(info.phone);
    const [ point, setPoint ] = useState(info.remainPoint);
    const [ memo, setMemo ] = useState(info.userMemo);
    const [ photo, setPhoto ] = useState<any>(null);
    const [ fileType, setFileType ] = useState<string>('');
    const [ isAlert, setIsAlert ] = useState(false);
    const [ alertTitle, setAlertTitle ] = useState<string>('');
    const [ alertDescription, setAlertDescription ] = useState<string>('');
    const [ loading, setLoading ] = useState(false);

    const updateUserInfo = async () => {

         // validation
         if(name === '')
         {
             setAlertTitle("이름을 입력해주세요")
             setIsAlert(true);
             return;
         }
 
         if(!phoneCheck(phone))
         {
             setAlertTitle('휴대폰번호를 입력해주세요')
             setIsAlert(true);
             return;
         }


        // validation 
        // api
        let request:UpdateCustomerType = {
            customerId: info.customerId,
            phoneNumber: phone,
            name: name,
            point: point,
            userMemo: memo
        }
        setLoading(true);
        const res = await updateCustomerInfo(request);
        if(res.status === 'success')
        {
            if(res.data == 0)
            {
                if(photo !== null)
                {
                    const res2 = await uploadCustomerPhoto(photo, info.customerId, fileType);
                    setLoading(false);
                }
                else
                {
                    setLoading(false);
                }

                onUpdate();
            }
            else
            {
                setLoading(false);
                setIsAlert(true);
                setAlertTitle("이미 등록된 사용자 정보입니다.")
            }
            
        }
        
    }

    return (
        <Container>
            {
                loading && <Loading />
            }
             {
                isAlert &&
                <AlertModal 
                    title={alertTitle}
                    description={alertDescription}
                    onCheck={() => {
                        setIsAlert(false);
                    }}
                />
            }
            <PhotoEdit 
                fileType={info.fileType}
                id={info.customerId}
                onSaveFileChange={(file, type) => {
                    setPhoto(file);
                    setFileType(type);
                }}
            />
            <Line/>
            <UserInfoEdit 
                name={name}
                phone={phone}
                point={point}
                memo={memo}
                onNameChange={(name) => {
                    setName(name);
                }}
                onPhoneChange={(phone) => {
                    setPhone(phone);
                }}
                onPointChange={(point) => {
                    setPoint(point)
                }}
                onMemoChange={(memo) => {   
                    setMemo(memo)
                }}
            />
            <Wrapper>
                <PageButton 
                    text="업데이트"
                    background="#9C27B0"
                    color='#FFFFFF'
                    onClick={updateUserInfo}
                />
                <DeleteButton
                    onClick={onDelete}
                >고객 정보 삭제</DeleteButton>
            </Wrapper>
            
        </Container>
    );
}