import { useEffect, useState } from "react";
import styled from "styled-components";

import {
    CustomerInfoType,
    BookingSimpleType
} from '../../types'

import {
    getBookingPageByCustomerRequest
} from '../../apis/BookingApi';
 
import {
    Gap,
    CardButton
} from '../atoms'

import {
    InfoCard,
    BookingCard
} from '../molecules';

import {
    UserPaymentInfoModal
} from '../modals';

import { 
    setComma 
} from "../../utilities/convert";

var BASE_URL = process.env.REACT_APP_TARGET_URL;

const Container = styled.div`
    width: 100%;
    height: 100vh;
    overflow-y: scroll;  
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 80px;
    padding-left: 5vw;
    padding-right: 5vw;
`;

const Section = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

const Profile = styled.img`
    width: 120px;
    height: 120px;
    border-radius: 50%;
`;

const AltProfile = styled.div`
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: #F4F7F8;
`;

const Name = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.25px;
    color: #9C27B0;
`;

const RowWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
`;

const UserMemoCard = styled.div`
    width: 100%;
    background: #F4F7F8;
    border-radius: 16px;
    padding: 20px;
    min-height: 113px;
    white-space: pre-line;
    word-wrap:break-all;
`;

const UserMemo = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: -0.4375px;
    color: #191F28;
    white-space: pre-line;
    word-wrap:break-all;
`;

const BookingListWrapper = styled.div`
    width: 100%;
    padding-top: 10px;
`;

const FavoriteWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const Favorite = styled.img`
    width: 30px;
`;

interface CustomerInfoProps {
    info: CustomerInfoType;
    onModeChange: () => void;
    onChangeFavorites: () => void;
}

// profile image
// button
// info card
// memo card
// booking list 

// user booking Modal 

export function CustomerInfo({
    info,
    onModeChange,
    onChangeFavorites
}: CustomerInfoProps){

    const [ hash, setHash ] = useState(Date.now()); 
    const [ curPage, setCurPage ] = useState(1);
    const [ totalPage, setTotalPage ] = useState(0);
    const [ list, setList ] = useState<BookingSimpleType []>([]);
    const [ paymentViewModal, setPaymentViewModal ] = useState(false);
    const [ selectedBookingId, setSelectedBookingId ] = useState(0);

    useEffect(() => {
       
        //get customer booking page
        const initLoading = async () => {
            const res = await getBookingPageByCustomerRequest(info.customerId, curPage - 1);
            if(res.status === 'success')
            {
                setList((prev) => [...res.data.content]);
                setTotalPage(res.data.totalPages);
            }
        }
        initLoading();
    }, [info])

    return (
        <Container>
            <Section>
                <FavoriteWrapper>
                    <Favorite 
                        src={info.favorites ? require('../../styles/assets/icon/favorite_active.png') : require('../../styles/assets/icon/favorite_inactive.png')} 
                        onClick={onChangeFavorites}
                    />
                </FavoriteWrapper>
                
                {
                    paymentViewModal && 
                    <UserPaymentInfoModal 
                        selectedBookingId={selectedBookingId}
                        type={2}
                        onClose={() => {
                            setPaymentViewModal(false);
                        }}
                    />
                }
                {
                    info.fileType !== '' ? 
                    <Profile 
                    src={BASE_URL + `/v1/customer/photo/${info.customerId}/?${hash}`}
                    />
                    :
                    <AltProfile></AltProfile>
                }            
                <Gap 
                    gap={16} 
                />
                <Name>{info.name} 님</Name>
                <Gap 
                    gap={16} 
                />
                <CardButton 
                    text="정보 수정"
                    background="#FFFFFF"
                    color="#0000000"
                    onClick={onModeChange}
                />
                <Gap 
                    gap={30} 
                />
                <RowWrapper>
                    <Title>고객 정보</Title>
                </RowWrapper>
                <Gap 
                    gap={8} 
                />
                <InfoCard 
                    infos={[
                        {key: '휴대폰 번호', value: info.phone},
                        {key: '잔여 선불금', value: `${setComma(info.remainPoint)}원`},
                        {key: '누적 이용 횟수', value: `${info.count}회`},                        
                        {key: '노쇼 횟수', value: `${info.noshow}회`} 
                    ]}
                />
                <Gap 
                    gap={30} 
                />
                <RowWrapper>
                    <Title>메모</Title>
                </RowWrapper>
                <Gap 
                    gap={8} 
                />
                <UserMemoCard>
                    <UserMemo>
                        {info.userMemo}
                    </UserMemo>
                </UserMemoCard> 
                <Gap 
                    gap={30} 
                />
                <RowWrapper>
                    <Title>예약 내역</Title>
                </RowWrapper>
            </Section>
            
            {/* booking list */}
            <BookingListWrapper>
            {
                list.map((booking, index) => {
                    return (
                        <BookingCard 
                            key={index}
                            date={booking.requestDate}
                            menuName={booking.menuName}
                            price={booking.price}
                            state={booking.state}
                            onSelect={() => {
                                setSelectedBookingId(booking.bookingId);
                                setPaymentViewModal(true);
                            }}
                        />
                    );
                })
            }
            </BookingListWrapper>
        </Container>
    );
}