import { useState } from "react";
import styled from "styled-components";
var BASE_URL = process.env.REACT_APP_TARGET_URL;

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`;

const TouchableSection = styled.div`
    width: 100%;
    height: 10%;
    cursor: pointer;
`;

const Section = styled.div`
    width: 100%;
    height: 90%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
    overflow: auto;
    color: #000000;
    padding: 54px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 100;
`;

const Image = styled.img`
    width: 100%;
`;

const CloseButton = styled.img`
    position: absolute;
    top: 20px;
    right: 15px;
    cursor: pointer;
`;

interface StoreBannerModalProps {
    onClose: () => void;
    storeId: number;
}

export function StoreBannerModal({
    onClose,
    storeId
}: StoreBannerModalProps){
    const [ loading, setLoading ] = useState(true);
    const [ hash, setHash ] = useState(Date.now());
    
    return (
        <Container>
            <TouchableSection 
                onClick={onClose}
            />
            <Section>
                <CloseButton src={require('../../styles/assets/btn_close_normal.png')}
                    onClick={onClose}     
                />
                <Image 
                    onLoad={() => {
                        setLoading(false);
                    }}
                    src={BASE_URL+`/v1/store/banner/read/${storeId}?${hash}`}
                />
                {
                    loading && <div>로딩중</div>
                }
            </Section>
        </Container>
    );
}