import { FormEvent, useEffect, useState } from "react";
import styled from "styled-components";

import {
    getStorePhotoUploadInfoRequest,
    updatePhotoUploadRequest
} from '../../../../apis/BookingApi'

import {
    ModalHeader,
} from '../../../molecules';

import {
    PageButton,
    Gap
} from '../../../atoms'

import {
    ConfrimCheckModal,
    AlertModal
} from '../../../modals';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background: #FFFFFF;
`;

const Section = styled.div`
    display: flex;
    width: 100%;
    height: calc(100vh - 56px);
    overflow-y: auto;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 40px 5vw;
    position: relative;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
    margin-bottom: 10px;
`;

const Text = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
    margin-right: 20px;
`;

const ButtonWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5vw;
`;

const TextArea = styled.textarea`
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    width: 100%;
    height: 160px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #000000;
    padding: 20px;
    resize: none;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const Input = styled.input`
    width: 20px;
    height: 18px;
`;

interface PhotoUploadSettingModalProps {
    storeId: number;
    onClose: () => void;
}

export function PhotoUploadSettingModal({
    storeId,
    onClose
}: PhotoUploadSettingModalProps){

    // get store label, photo upload
    // 따로 로딩해준다

    const [ description, setDescription ] = useState<string>('');
    const [ upload, setUpload ] = useState<boolean>(false);

    const [ confirmTitle, setConfirmTitle ] = useState<string>('');
    const [ confirmView, setConfirmView ] = useState(false);
    const [ confirmDescription, setConfirmDescription ] = useState<string>('');

    const [ alertTitle, setAlertTitle ] = useState<string>('');
    const [ alertDescription, setAlertDescription ] = useState<string>('');
    const [ alertView, setAlertView ] = useState(false);

    const onDescriptionChange = (event: FormEvent<HTMLTextAreaElement>) => {
        const {
            currentTarget: {value}
        } = event;

        // const text = value.replace(/\n/g, "<br>")

        setDescription(value);
    }

    const onRadioChangeTrue = () => {
        setUpload(true);
    }

    const onRadioChangeFalse = () => {
        setUpload(false);
    }

    const updatePhotoUpdate = async () => {
        const res = await updatePhotoUploadRequest(storeId, upload, description);
        if(res.status === 'success')
        {
            setAlertTitle('완료되었습니다.')
            setAlertView(true);
        }
    }

    useEffect(() => {
        const initStoreUploadInfo = async () => {
            const res = await getStorePhotoUploadInfoRequest(storeId);

            if(res.status === 'success')
            {
                // alert(JSON.stringify(res.data))
                setDescription(res.data.description);
                setUpload(res.data.upload);
            }
        }

        initStoreUploadInfo();
    }, [])

    return (
        <Container>
            {
                confirmView &&
                <ConfrimCheckModal 
                    title={confirmTitle}
                    description={confirmDescription}
                    onCancel={() => {
                        setConfirmView(false);
                    }}
                    onCheck={() => {
                        setConfirmView(false);
                        updatePhotoUpdate();
                    }}
                />
            }
            {
                alertView && 
                <AlertModal 
                    title={alertTitle}
                    description={alertDescription}
                    onCheck={ () => {
                        setAlertView(false);
                        onClose();
                    }}
                />
            }
            <ModalHeader 
                title="사진 업로드 설정"
                onClose={onClose}
            />
            <Section>
                <Gap 
                    gap={56}
                />                                 
                <Label>예약 요청시 사진업로드 기능을 사용하시겠습니까?</Label>               
                <Wrapper>  
                    <Input 
                        type="radio"
                        checked={upload}
                        onChange={onRadioChangeTrue}
                    />
                    <Text
                        onClick={onRadioChangeTrue}
                    >네</Text>
                    <Input 
                        type="radio"
                        checked={!upload}
                        onChange={onRadioChangeFalse}
                    />
                    <Text
                        onClick={onRadioChangeFalse}
                    >아니오</Text>
                </Wrapper>
                <Gap 
                    gap={20}
                />
                <Label>사진 업로드에 대한 설명을 입력해주세요</Label>
                <TextArea
                    value={description}
                    onChange={onDescriptionChange}
                    placeholder="사진 업로드에 대한 설명을 입력해주세요"
                >
                </TextArea>
                <ButtonWrapper>
                    <PageButton 
                        text="업데이트"
                        background="#9C27B0"
                        color="#FFFFFF"
                        onClick={() => {
                            setConfirmTitle('업데이트 하시겠습니까?');
                            setConfirmView(true);
                        }}
                    />
                </ButtonWrapper>               
            </Section>
        </Container>
    );
}