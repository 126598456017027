import styled from 'styled-components';
import Header from './Header';

import {
    BookingRequestList
} from '../../../components/organisms';

const Container = styled.div`
    width: 100%;
    min-height: 100vh;
`;

function Booking(){
    return (
        <Container>
            <Header />
            <div style={{height: '96px'}}></div>
            <BookingRequestList 
            
            />
        </Container>
    );
}

export default Booking;