import { useEffect, useState } from "react";
import styled from "styled-components";

import {
    BlockDayType
} from '../../types';

import {
    Calendar
} from '../modals';

import { 
    useRecoilState
} from "recoil";

import {
    blockScheduleState
} from '../../stores';

import {
    getBlockDayListRequest,
    updateBlockDayRequest,
    deleteBlockDayRequest
} from '../../apis/BookingApi';

import { 
    leadingZeros 
} from "../../utilities/convert";

import {
    CardButton,
    Gap
} from '../atoms';

import {
    ModalHeader,
    DateCard
} from '../molecules';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background: #FFFFFF;
`;

const TouchableSection = styled.div`
    width: 100%;
    height: 20%;
    cursor: pointer;
`;

const Section = styled.div`
    width: 100%;
    background-color: #fff;
    overflow: auto;
    color: #000000;
    padding: 34px 5vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
`;


const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const BlockDay = styled.div`
    width: 100%;
`;

const DaysWrapper = styled.div`

`;

const Days = styled.div`

`;

const Delete = styled.img`
    width: 10px;
`;

interface BlockDaySettingModalProps {
    onClose: () => void;
    storeId: number;
}

export function BlockDaySettingModal({
    onClose,
    storeId
}: BlockDaySettingModalProps) {
    const [ blockDays, setBlockDays ] = useRecoilState(blockScheduleState);
    const [ isCalendar, setIsCalendar ] = useState(false);

    const cur = new Date();

    const getBlockDays =async (storeId: number) => {
        const blockDayResponse = await getBlockDayListRequest(storeId);

        if(blockDayResponse.status === 'success')
        {
            setBlockDays(blockDayResponse.data);
        }
    }

    const onNewBlockDay = async (year: number, month: number, date: number) => {
        let blockDay: BlockDayType = {
            id: 0,
            storeId: storeId,
            blockDay: year.toString() + "-" + leadingZeros(month.toString(), 2) + "-" + leadingZeros(date.toString(), 2),
            createdAt: ''
        }

        const response1 = await updateBlockDayRequest(blockDay);
        const response2 = await getBlockDays(storeId); 
    }

    const deleteBlockDay =async (blockDayId: number) => {
        const response = await deleteBlockDayRequest(blockDayId);
        const response2 = await getBlockDays(storeId); 
    }

    useEffect(() => {
        //update, load
        getBlockDays(storeId);
    }, [])

    return (
        <Container>
            <ModalHeader 
                title="휴가일 설정"
                onClose={onClose}
            />
            <Gap 
                gap={56}
            />
            <Section>
                {
                    isCalendar &&
                    <Calendar 
                        mode={1}
                        year={cur.getFullYear()}
                        month={cur.getMonth()}
                        date={cur.getDate()}
                        onClose={() => {
                            setIsCalendar(false);
                        }}
                        onSelect={(year, month, date) => {
                            onNewBlockDay(year, month + 1, date);
                            setIsCalendar(false);
                        }}
                    />
                }

                {
                    blockDays.length > 0 &&
                    blockDays.map((blockDay, index) => {
                        return (
                            // <Days key={index}>{blockDay.blockDay}
                            //     <Delete src={require('../../styles/assets/btn_close_normal.png')}
                            //         onClick={() => {
                            //             deleteBlockDay(blockDay.id);
                            //         }}
                            //     />
                            // </Days>
                            <BlockDay key={index}>
                                <DateCard 
                                    date={blockDay.blockDay}
                                    onDelete={() => {
                                        deleteBlockDay(blockDay.id);
                                    }}
                                />
                                <Gap 
                                    gap={12}
                                />
                            </BlockDay>
                            
                        );
                    })
                }
                <Wrapper>
                    <CardButton 
                        text="추가하기"
                        background="#FFFFFF"
                        color="#9C27B0"
                        onClick={() => {
                            setIsCalendar(true);
                        }}
                    />
                </Wrapper>
            </Section>  
        </Container>
    );
}