// 예약리스트 -> 요청확인 버튼 누를 경우 노출
import styled from "styled-components";
import { useState } from "react";

import {
    Gap,
    PageButton
} from '../../../atoms';

import {
    InfoCard,
    EtcInfoCard,
    Loading,
    UserUploadPhotoGrid
} from '../../../molecules';

import {
    BookingType
} from '../../../../types'

import {
    OkBookingModal, 
    RejectBookingModal,
    AlertModal,
    PhotoViewModal
} from '../../../modals';

import {
    confirmBookingRequest,
    rejectBookingRequest
} from '../../../../apis/BookingApi';

import {
    convertTimestampToDate
} from '../../../../utilities/convert';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`;

const TouchableSection = styled.div`
    width: 100%;
    height: 40%;
    cursor: pointer;
`;

const Section = styled.div`
    width: 100%;
    min-height: 60%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
    overflow: auto;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 100;
`;

const Title = styled.div`
    min-height: 60px;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #CEDDE2;
    padding-left: 5vw;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: #191F28;
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 40px 5vw;
`;

const CenterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

interface BookingRequestCheckModalProps {
    onClose: () => void;
    onConfirm: () => void;
    bookingInfo: BookingType | undefined;
}

export function BookingRequestCheckModal({
    onClose,
    onConfirm,
    bookingInfo,
}: BookingRequestCheckModalProps){

    const [ selectedNumber, setSelectedNumber ] = useState(0);
    const [ photoView, setPhotoView ] = useState(false);

    const [ viewOkModal, setViewOkModal ] = useState(false);
    const [ viewRejectModal, setViewRejectModal ] = useState(false);
    const [ viewAlertModal, setViewAlertModal ] = useState(false);

    const [ loading, setLoading ] = useState(false);

    // confirm request and close  
    const confirmRequest = async (start: string, duration: number) => {
       
        //request 
        // confirmBookingRequest
        // booking id, reservTime, duration 
        const resrvTime = bookingInfo?.requestDate + " " + start + ":00";
        if(bookingInfo !== undefined)
        {
            setLoading(true);
            const res = await confirmBookingRequest(bookingInfo.bookingId, resrvTime, duration);
            if(res.data as boolean)
            {   
                setLoading(false);
                onConfirm();
            }
            else
            {
                setLoading(false);
                setViewAlertModal(true);
            }
            
        }
    }

    const rejectRequest = async (message: string) => {

        if(bookingInfo !== undefined)
        {
            setLoading(true);
            const res = await rejectBookingRequest(bookingInfo.bookingId, message);
            if(res.data as boolean)
            {   
                setLoading(false);
                onConfirm();
            }
            else
            {
                setLoading(false);
                setViewAlertModal(true);
            }
        }
    }

    return (
        <Container>
            <TouchableSection 
                onClick={onClose}
            />
            {
                photoView &&
                <PhotoViewModal 
                    bookingId={bookingInfo?.bookingId as number}
                    orderNumber={selectedNumber}
                    onClose={() => {
                        setSelectedNumber(0);
                        setPhotoView(false);
                    }}
                />
            }
            {
                viewAlertModal && 
                <AlertModal 
                    title="예약을 찾을 수 없습니다"
                    onCheck={() => {
                        setViewAlertModal(false);
                        onConfirm();
                    }}
                />
            }
            {
                loading && <Loading />
            }
            {
                viewOkModal &&
                <OkBookingModal 
                    requestDate={bookingInfo?.requestDate as string}
                    buttonText="요청 수락"
                    title="예약 요청 수락"
                    onClose={() => {
                        setViewOkModal(false);
                    }}
                    onOk={confirmRequest}  
                    menuDuration={bookingInfo?.duration as number}
                    bookingId={bookingInfo?.bookingId as number}
                    isAdd={true}
                />
            }
            {
                viewRejectModal &&
                <RejectBookingModal 
                    presetMessage={bookingInfo?.rejectMessage as string}
                    onReject={rejectRequest}
                    onClose={() => {
                        setViewRejectModal(false);
                    }}
                />
            }
            {
                bookingInfo && 
                <Section>
                    <Title>{bookingInfo.userName}님의 예약요청</Title>
                    <Wrapper>
                        <InfoCard 
                            infos={[
                                {key: '예약 요청일', value: `${convertTimestampToDate(bookingInfo.requestDate)}`}, 
                                ...(
                                    bookingInfo.requestBookingTimeList.map((booking, index) => {
                                        return (
                                            {
                                                key: '예약 요청시간',
                                                value: `${booking.startTime.slice(0, 5)}부터 ${booking.endTime.slice(0,5)}까지`,
                                            }
                                        );
                                    })
                                ),                           
                                {key: '예약자 이름', value: `${bookingInfo.userName}`},
                                {key: '예약자 휴대폰번호', value: `${bookingInfo.userPhone}`},
                                {key: '요청서비스', value: `${bookingInfo.menuName}`}  
                            ]}
                        />
                        <Gap 
                            gap={12}
                        />
                        <EtcInfoCard 
                            infos={
                                bookingInfo.bookingEtcInfoList.map((etc, index) => {
                                    return (
                                        {key: etc.title, value: etc.contents}
                                    );
                                })                    
                            }
                        />
                        <Gap 
                            gap={12}
                        />
                        {
                            bookingInfo.photoCount > 0 &&
                            <UserUploadPhotoGrid 
                                bookingId={bookingInfo.bookingId}
                                count={bookingInfo.photoCount}
                                onPhotoViewModal={(bookingId, orderNumber) => {
                                    setSelectedNumber(orderNumber);
                                    setPhotoView(true);
                                }}
                            />
                        }
                        <Gap 
                            gap={20}
                        />
                        <CenterWrapper>
                            <PageButton 
                                text="수락할게요"
                                background="#9C27B0"
                                color="#FFFFFF"
                                onClick={() => {
                                    setViewOkModal(true);
                                }}
                            />
                        </CenterWrapper>
                        <Gap 
                            gap={12}
                        />
                        <CenterWrapper>
                            <PageButton 
                                text="거절할게요"
                                background="#FFFFFF"
                                color="#000000"
                                onClick={() => {
                                    setViewRejectModal(true)
                                }}
                            />
                        </CenterWrapper>
                    </Wrapper>
                </Section>
            }
            
        </Container>
    );
}