import { useEffect, useState } from "react";
import styled from "styled-components";

import {
    BookingType,
    BookingBlock,
    StoreTimeType,
    BreakTimeType,
    BreakBlock
} from '../../types';

interface BarScheduleProps {
    name: string;
    open: string;
    close: string;
    bookingData: BookingType [];
    breakTimes: BreakTimeType [];
    onBooking: () => void;
}

export function BarSchedule(){

    const [ storeAvailableTimeList, setStoreAvailableTimeList ] = useState<StoreTimeType[][]>();  // 상점 운영 타임 
    const [ bookingBlockList, setBookingBlockList ] = useState<BookingBlock[]>([]);  
    const [ breakBlockList, setBreakBlockList ] = useState<BreakBlock[]>();



    return null;
}