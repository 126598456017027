// 예약 payment 정보
import { FormEvent, useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";

import {
    Gap,
    PageButton
} from '../../../atoms';

import {
    PaymentResponseType,
    PaymentType
} from '../../../../types';

import {
    AdminBookingCancelModal,
    AlertModal,
    UpdateBookingModal,
    BookingDetailModal
} from '../../../modals';

import { 
    getPaymentRequest,
    updatePaymentRequest,
    completeBookingRequest,
    noshowBookingRequest,
    adminCancelRequest,
    updateUserMemoRequest,
    adminBookingDeleteRequest
} from "../../../../apis/BookingApi";

import { 
    setComma 
} from "../../../../utilities/convert";

import { 
    UserUploadPhotoGrid 
} from "../../../molecules";

var BASE_URL = process.env.REACT_APP_TARGET_URL;

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`;

const TouchableSection = styled.div`
    width: 100%;
    height: 10%;
    cursor: pointer;
`;

const Section = styled.div`
    width: 100%;
    min-height: 90%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
    overflow: auto;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 500;
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 20px 5vw;
`;

const Title = styled.div`
    min-height: 60px;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #CEDDE2;
    padding-left: 5vw;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: #191F28;
`;

const MenuWrapper = styled.div`
    display: flex;
    width: 100%;
    margin-top: 20px;
`;

const ColWrapper = styled.div`
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    gap: 0px;
`;

const MenuRowWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 10px;
`;

const RowWrapper = styled.div`
    display: grid;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 48px;
    grid-template-columns: 30% 60%;
    gap: 8%;
    position: relative;
`;

const MenuImage = styled.img`
    width: 71px;
    height: 71px;
    border-radius: 10px;
    margin-right: 16px;
`;

const MenuTitle = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.285714px;
    color: #7D93A6;
    margin-right: 16px;
    width: 100px;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.285714px;
    color: #191F28;
    /* width: 100px; */
`;

const Input = styled.input`
    width: 100%;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    padding-right: 20px;
    text-align: right;
`;

const TextArea = styled.textarea`
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    width: 100%;
    height: 160px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #000000;
    padding: 20px;
    resize: none;
`;

const Line = styled.div`
    width: 100%;
    height: 1px;
    background: #CEDDE2;
    margin: 20px 0;
`;

const Right = styled.div`
    width: 100%;
    text-align: right;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
`;

const InfoWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
`;

const Info = styled.img`
    margin-right: 8px;
    width: 15px;
    height: 15px;
`;

const CheckBox = styled.input`
    width: 28px;
    height: 18px;
`;

const InfoText = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: -0.25px;
    color: #9C27B0;
`;

const RadioWrapper = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
`;

const Radio = styled.div`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    /* identical to box height, or 112% */
    text-align: center;
    letter-spacing: -0.5px;
    color: #000000;
    width: 100%;
    text-align: left;
    input {
        margin-right: 8px;
    }
`;

const CenterWrapper = styled.div`
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    gap: 12px;
    justify-content: center;
    align-items: center;
`;

const TitleWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
`;

const Text = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
    margin-right: 12px;
`;

interface UserPaymentInfoModalProps {
    // paymentInfo: PaymentType;
    selectedBookingId: number;
    type: number;
    onClose: () => void;
}

export function UserPaymentInfoModal({
    selectedBookingId, 
    type,
    onClose
}: UserPaymentInfoModalProps){
    const [ bookingDetailView, setBookingDetailView ] = useState(false);

    const [ updateBookingModalView, setUpdateBookingModalView ] = useState(false);
    const [ cancelModalView, setCancelModalView ] = useState(false);
    const [ hash, setHash ] = useState(Date.now());
    const [ fixPrice, setFixPrice ] = useState(false);
    const [ data, setData ] = useState<PaymentResponseType>(
        {
            id: 0,
            bookingId: 0,
            photoCount: 0,
            customerId: 0,
            menuId: 0,
            menuName: '',
            userName: '',
            userPhone: '',
            price: 0,
            fixedPrice: NaN,
            remainPoint: 0,
            addPoint: NaN,
            usePoint: NaN,
            payType: 0,
            remainPrice: NaN,
            userMemo: '',
            curPoint: 0,
            createdAt: null,
            updatedAt: null,
            state:0
        }
    );

    const [ isAlert, setIsAlert ] = useState(false);
    const [ alertTitle, setAlertTitle ] = useState<string>('');
    const [ alertDescription, setAlertDescription ] = useState<string>('');

    const completeBooking = async () => {

        if(data.remainPrice !== 0 && data.payType === 0)
        {
            setAlertTitle("결제 수단을 선택해주세요");
            setAlertDescription("");
            setIsAlert(true);
            return;
        }

        if(window.confirm(("완료 상태로 변경하시겠습니까?")))
        {
            const payment:PaymentType = data;
            const res = await updatePaymentRequest(payment);
    
            if(res.status === 'success')
            {
                const res1 = await updateUserMemoRequest(data.customerId, data.userMemo);
                if(res1.status === 'success')
                {
                    const res2 = await completeBookingRequest(selectedBookingId);
                    if(res2.status === 'success')
                    {
                        onClose();
                    }
                }
            }
        }

       
    }
    const noShowBooking = async () => {
        if(window.confirm(("노쇼 상태로 변경하시겠습니까?")))
        {
            const res = await noshowBookingRequest(selectedBookingId);
        
            if(res.status === 'success')
            {
                const res1 = await updateUserMemoRequest(data.customerId, data.userMemo);
                if(res1.status === 'success')
                {
                    onClose();
                }             
            }
        }
        
    }


    function deleteNumberZero(str: string)
    {
        return str.replace(/(^0+)/, "");
    }

    const adminBookingCancel = async (message: string) => {
        
        const res = await adminCancelRequest(selectedBookingId, message);
        if(res.status === 'success')
        {
            const res1 = await updateUserMemoRequest(data.customerId, data.userMemo);
            if(res1.status === 'success')
            {
                onClose();
            }  
        }
    
        
    }

    const adminBookingDelete = async () => {
        const res = await adminBookingDeleteRequest(selectedBookingId);
        if(res.status === 'success')
        {   
            onClose();
        }
    }

    function onFixedPriceChange (event: FormEvent<HTMLInputElement>) {
        const {
            currentTarget: {value}
        } = event;
        

        if(Number(value) < 0)
        {
            setAlertTitle("서비스의 금액을 확인해주세요.");
            setAlertDescription("");
            setIsAlert(true);
            return;
        }

       
        setData({...data, fixedPrice: Number(value), remainPrice: Number(value) - data.usePoint})        
        
    }
    function onAddPointChange (event: FormEvent<HTMLInputElement>){
        const {
            currentTarget: {value}
        } = event;


        if(Number(value) < 0)
        {
            setAlertTitle("선불금 충전 금액을 확인해주세요.");
            setAlertDescription("");
            setIsAlert(true);
            return;
        }


        setData({...data, addPoint: Number(value)})
    } 
    function onUsePointChange (event: FormEvent<HTMLInputElement>){
        const {
            currentTarget: {value}
        } = event;

        if(Number(value) < 0)
        {
            setAlertTitle("선불금 사용 금액을 확인해주세요.");
            setAlertDescription("");
            setIsAlert(true);
            return;
        }

        if(data.remainPoint + data.addPoint - Number(value) < 0)
        {
            setAlertTitle("선불금 충전 잔액이 부족합니다.");
            setAlertDescription("");
            setIsAlert(true);
            return;
        }

        if(data.fixedPrice - Number(value) < 0)
        {
            setAlertTitle("선불금 사용금액이 서비스 가격 보다 많습니다.");
            setAlertDescription("");
            setIsAlert(true);
            return;
        }

        setData({...data, usePoint: Number(value), remainPrice: data.fixedPrice - Number(value)})
    }
    function onPaytypeChange (type: number)
    {
        setData({...data, payType: type})
    }
    function onUserMemoChange (event: FormEvent<HTMLTextAreaElement>){
        const {
            currentTarget: {value}
        } = event;
        setData({...data, userMemo: value})
    }

    function onFixPrice (event: FormEvent<HTMLInputElement>) {
        const {
            currentTarget: {value}
        } = event;

        // alert(value)
    }

    useEffect(() => {
        //load 
        const getPaymentData = async (bookingId: number) => {
            const res = await getPaymentRequest(bookingId);
            if(res.status === 'success')
            {
                const resData:PaymentResponseType = res.data; 
                setData(resData);
            }
        }
        getPaymentData(selectedBookingId);

        if(type === 1)
        {
            // 예약 완료 상태 
            // payment 데이터가 없음 
            // setData(dummyData);
        }
        else
        {
            // 결제 완료 상태
        }
    }, [selectedBookingId])

    return (
        <Container>
            {
                bookingDetailView && data.bookingId != 0 &&
                <BookingDetailModal 
                    onClose={() => {
                        setBookingDetailView(false);
                    }}
                    bookingId={data.bookingId}
                />
            }
            {
                isAlert &&
                <AlertModal 
                    title={alertTitle}
                    description={alertDescription}
                    onCheck={() => {
                        setIsAlert(false);
                    }}
                />
            }
            {
                cancelModalView &&
                <AdminBookingCancelModal 
                    onClose={() => {
                        setCancelModalView(false);
                    }}
                    onSubmit={async (message) => {
                        await adminBookingCancel(message);
                    }}
                />
            }
            {
                updateBookingModalView &&
                <UpdateBookingModal 
                    onClose={() => {
                        setUpdateBookingModalView(false)
                    }}
                    bookingId={data.bookingId}                    
                />
            }
            <TouchableSection 
                onClick={() => {
                    onClose()
                }}
            />
            <Section>
                {
                    data &&
                    <>
                        <Title>{data.userName}님 ({data.userPhone})</Title>

                        <Wrapper>
                            <TitleWrapper
                                onClick={() => {
                                    setBookingDetailView(true);
                                }}
                            >                               
                                <Text>예약 정보</Text>
                                <Info 
                                    src={require('../../../../styles/assets/icon/info_pink.png')}
                                />
                            </TitleWrapper>
                            
                            <MenuWrapper>
                                {/* <MenuImage 
                                    src={BASE_URL + `/v1/menu/image/read/${data.menuId}?${hash}`}
                                /> */}
                                <ColWrapper>
                                    <MenuRowWrapper>
                                        <MenuTitle>서비스명</MenuTitle> 
                                        <Label>{data.menuName}</Label>                                        
                                    </MenuRowWrapper>
                                    <MenuRowWrapper>
                                        <MenuTitle>가격</MenuTitle>
                                        <Label>{setComma(data.price)}원</Label>                                         
                                    </MenuRowWrapper>                                    
                                </ColWrapper>
                            </MenuWrapper>
                            
                            <Gap 
                                gap={15}
                            />
                            <RowWrapper>
                                <RowWrapper>
                                    <CheckBox type="checkbox" 
                                        onChange={({ target: { checked } }) => {
                                            if(!checked)
                                            {
                                                setData({...data, fixedPrice: data.price, remainPrice:data.price - data.usePoint})                                                  
                                            }
                                            setFixPrice(checked)
                                        }}
                                    />
                                    <Label>가격수정</Label>
                                </RowWrapper>
                                {
                                    fixPrice && 
                                    <Input 
                                        type="number"
                                        pattern="\d*"
                                        value={deleteNumberZero(data.fixedPrice.toString())}
                                        onChange={onFixedPriceChange}
                                        disabled={!fixPrice}
                                    />
                                }
                                
                            </RowWrapper>

                            {/* {
                                data.photoCount > 0 && 
                                <>
                                    <Gap 
                                        gap={20}
                                    />
                                    <UserUploadPhotoGrid 
                                        bookingId={data.bookingId}
                                        count={data.photoCount}   
                                    />
                                </>
                            } */}
                            <Line />
                            <Right>
                                선불금 사용 전 잔액 {setComma(data.remainPoint)}원
                            </Right>
                            <Gap 
                                gap={20}
                            />
                            <RowWrapper>
                                <Label>선불금 충전</Label>
                                <Input 
                                    type="number"
                                    pattern="\d*"
                                    value={deleteNumberZero(data.addPoint.toString())}
                                    onChange={onAddPointChange}
                                />
                            </RowWrapper>
                            <Gap 
                                gap={10}
                            />
                            <RowWrapper>
                                <Label>선불금 사용</Label>
                                <Input 
                                    type="number"
                                    pattern="\d*"
                                    value={deleteNumberZero(data.usePoint.toString())}
                                    onChange={onUsePointChange}
                                />
                            </RowWrapper>
                            {
                                data.state === 1 &&
                                <>
                                    <Gap 
                                        gap={10}
                                    />
                                    <Right>
                                        선불금 사용 후 잔액 {setComma(data.curPoint)}원
                                    </Right>
                                </>
                            }
                            <Line />
                            <InfoWrapper>
                                <Info 
                                    src={require('../../../../styles/assets/icon/info_pink.png')}
                                />
                                <InfoText>남은 결제금액 {setComma(data.remainPrice)}원에 대한 결제수단을<br/> 선택해주세요</InfoText>
                            </InfoWrapper>
                            <Gap 
                                gap={20}
                            />
                            <RadioWrapper>
                                <Radio>
                                    <input type="radio" checked={data.payType === 1 ? true : false} 
                                        onChange={() => {
                                            onPaytypeChange(1);
                                        }}
                                    />
                                    <Label
                                        onClick={() => {
                                            onPaytypeChange(1);
                                        }}
                                    >현금결제</Label>
                                </Radio>
                                <Radio>
                                    <input type="radio" checked={data.payType === 2 ? true : false}
                                        onChange={() => {
                                            onPaytypeChange(2);
                                        }}
                                    />
                                    <Label
                                        onClick={() => {
                                            onPaytypeChange(2);
                                        }}
                                    >카드결제</Label>
                                </Radio>                                
                            </RadioWrapper>
                            <Line />
                            <TextArea
                                placeholder="고객님의 특이사항을 기록하세요"
                                value={data.userMemo}
                                onChange={onUserMemoChange}
                            >
                            </TextArea>
                            <Line />
                            {
                                data.state === 0 &&
                                <CenterWrapper>
                                    <PageButton 
                                        text="이용완료"
                                        background="#9C27B0"
                                        color="#FFFFFF"
                                        onClick={completeBooking}
                                    />
                                    <PageButton 
                                        text="노쇼"
                                        background="#FFFFFF"
                                        color="#000000"
                                        onClick={noShowBooking}
                                    />
                                    <PageButton 
                                        text="예약취소"
                                        background="#FFFFFF"
                                        color="#000000"
                                        onClick={() => {
                                            if(window.confirm(("정말 예약을 취소하시겠습니까?")))
                                            {
                                                setCancelModalView(true);   
                                            }
                                        }}
                                    />
                                    <PageButton 
                                        text="예약변경"
                                        background="#FFFFFF"
                                        color="#000000"
                                        onClick={() => {
                                            setUpdateBookingModalView(true);
                                        }}
                                    />
                                    <PageButton 
                                        text="예약삭제"
                                        background="#FFFFFF"
                                        color="#000000"
                                        onClick={() => {
                                            if(window.confirm(("정말 예약을 삭제하시겠습니까?")))
                                            {
                                                adminBookingDelete();
                                            }
                                        }}
                                    />
                                </CenterWrapper>
                            }
                        </Wrapper>                     
                    </>
                }
            </Section>
        </Container>
    );
}