import { useState } from "react";
import styled from "styled-components";
import { Gap } from "../../../../atoms";
import { setComma } from "../../../../../utilities/convert";

var BASE_URL = process.env.REACT_APP_TARGET_URL;

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 15px;
`;

const Image = styled.img`
    width: 71px;
    height: 71px;
    border-radius: 12px;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.285714px;
    color: #7D93A6;
    width: 44px;
`;

const Text = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.285714px;
    color: #191F28;
    margin-left: 14px;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
const ColWrapper = styled.div`
    margin-left: 16px;
    padding-top: 5px;
`;


interface SelectedMenuProps {
    menuId: number,
    menuName: string;
    price: number;
}

export function SelectedMenu({
    menuId,
    menuName,
    price
}:SelectedMenuProps){
    const [ hash, setHash ] = useState(Date.now());   
    return (
        <Container>
            <Image 
                src={BASE_URL+`/v1/menu/image/read/${menuId}?${hash}`}
            />
            <ColWrapper>
                <RowWrapper>
                    <Label>메뉴명</Label>
                    <Text>{menuName}</Text>
                </RowWrapper>
                <Gap 
                    gap={16}
                />
                <RowWrapper>
                    <Label>가격</Label>
                    <Text>{setComma(price)}원</Text>
                </RowWrapper>
            </ColWrapper>
        </Container>
    );
}