import styled from "styled-components";

import { InfluencerAdvertisementResponseType } from "../../types";

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`;

const TouchableSection = styled.div`
    width: 100%;
    height: 20%;
    cursor: pointer;
`;

const Section = styled.div`
    width: 100%;
    min-height: 80%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
    overflow: auto;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1000;
    padding-left: 5vw;
    padding-right: 5vw;
`;


interface InfluencerAdvertisementBookingModalProps {
    onClose: () => void;
    advertisement: InfluencerAdvertisementResponseType | undefined;
}

export function InfluencerAdvertisementBookingModal({
    onClose,
    advertisement
}: InfluencerAdvertisementBookingModalProps) {

    //이번달


    return (
        <Container>            
            
            <TouchableSection 
                onClick={onClose}
            />
            {
                advertisement && 
                <Section>

                </Section>
            }
        </Container>
     
    );
}