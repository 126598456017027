import styled from "styled-components";

import {
    PageButton
} from '../../../atoms';

import {
    InfoCard
} from '../../../molecules';

import {
    RequestBookingTimeType
} from '../../../../types';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`;

const TouchableSection = styled.div`
    width: 100%;
    height: calc(100vh - 455px);
    cursor: pointer;
`;

const Section = styled.div`
    width: 100%;
    height: 455px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
    overflow: auto;
    color: #000000;
    padding: 26px 20px 41px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    z-index: 999;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.25px;
    color: #191F28;
`;

const ButtonWrapper = styled.div`
    display: grid;
    grid-template-columns: 100%;
    gap: 12px;
    width: 100%;
`;

const Info = styled.div`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`;

interface ConfirmModalProps {
    onClose: () => void;
    message: string;
    onConfirm: () => void;
    title: string;
    times: RequestBookingTimeType [];
    name: string;
    phone: string;
    menuName: string;
}

export function ConfirmModal({
    onClose,
    message,
    title,
    onConfirm,
    times,
    name,
    phone,
    menuName
}:ConfirmModalProps){
    return (
        <Container>
            <TouchableSection 
                onClick={onClose}
            />
            <Section>
                <Title>{title}</Title>
                {message}
                <InfoCard 
                    infos={[
                        {key: '예약 요청 시간', value: `${times[0].startTime}부터 ${times[0].endTime}까지` + (times.length > 1 ? `외 ${times.length - 1}건` : '')},
                        {key: '예약자 이름', value: name},
                        {key: '예약자 휴대폰번호', value: phone},
                        {key: '요청서비스', value: menuName}
                    ]}
                />
                <Info>
                    휴대폰번호로 예약 알림톡이 전송됩니다.<br/>
                    입력하신 휴대폰번호를 다시 한번 확인해주세요.
                </Info>
                <ButtonWrapper>
                    <PageButton
                        onClick={onConfirm}
                        text="이대로 신청할게요"
                        background="#9C27B0"
                        color="#FFFFFF"
                    />
                    <PageButton
                        onClick={onClose}
                        text="다시 입력할게요"
                        background="#FFFFFF"
                        color="#000000"
                    />
                </ButtonWrapper>
            </Section>            
        </Container>
    );
}