import styled from "styled-components";
import { leadingZeros } from "../../../utilities/convert";

const Bar = styled.div`
    width: 100%;
    height: 56px;
    background-color: #FFFFFF;
    /* background-color: #ECE8FE; */
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5vw;
    z-index: 10;

`;

const Logo = styled.img`
    width: 120px;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Button = styled.img`
    width: 20px;
    margin-left: 10px;
    margin-top: -3px;
    cursor: pointer;
`;

const RowWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    justify-content: center;
    align-items: center;
`;

interface HeaderProps {
    onAddCustomer: () => void;
}

function Header({
    onAddCustomer,
}:HeaderProps) {
    return (
        <Bar>
            <Logo src={require('../../../styles/assets/logo_new.png')} />
            <Wrapper>              
                <Button
                    src={require(`../../../styles/assets/icon/add_user.png`)} 
                    onClick={onAddCustomer}
                />
            </Wrapper>
        </Bar>
    );
}

export default Header;