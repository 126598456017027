import styled from 'styled-components';

import {
    InfluencerAdvertisementResponseType,
} from '../../types';

import { 
    Gap, 
    CardButton 
} from '../atoms';

import {
    convertTimestampToDate,
    setComma
} from '../../utilities/convert';

const Container = styled.div`
    width: 100%;
    background: #F4F7F8;
    border-radius: 16px;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 12px;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.25px;
    color: #191F28;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.4375px;
    color: #7D93A6;
`;

const Contents = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`; 

const Content = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.4375px;
    color: #191F28;
`;

const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

interface InfluencerAdvertisementCardProps {
    advertisementInfo: InfluencerAdvertisementResponseType;
    onView: () => void;
}

export function InfluencerAdvertisementCard({
    advertisementInfo,
    onView
}: InfluencerAdvertisementCardProps){

    return (
        <Container>
            <RowWrapper>
                <Title>{advertisementInfo.storeName}의 광고</Title>
            </RowWrapper>
            <Gap 
                gap={10}
            />
            <RowWrapper>
                <Contents>
                    <Label>광고 시작일</Label>
                    <Content>{convertTimestampToDate(advertisementInfo.startedAt)}</Content>
                </Contents>
            </RowWrapper>
            <Gap 
                gap={5}
            />
            <RowWrapper>
                <Contents>
                    <Label>광고 종료일</Label>
                    <Content>{convertTimestampToDate(advertisementInfo.endedAt)}</Content>
                </Contents>
            </RowWrapper>
            <Gap 
                gap={5}
            />
            <RowWrapper>
                <Contents>
                    <Label>광고 단가</Label>
                    <Content>완료건당 {setComma(advertisementInfo.cost)}원</Content>
                </Contents>
            </RowWrapper>
            <Gap 
                gap={5}
            />
            <RowWrapper>
                <Contents>
                    <Label>누적 광고 금액</Label>
                    <Content>{setComma(advertisementInfo.totalCost)}원</Content>
                </Contents>
            </RowWrapper>
            <Gap 
                gap={5}
            />
            {/* <Center> */}
                {/* <CardButton 
                    text="상세내역보기"
                    background="#FFFFFF"
                    color="#9C27B0"
                    onClick={onView}
                />
            </Center> */}
        </Container>
    );
}