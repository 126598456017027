import styled from "styled-components";
import { setComma } from "../../utilities/convert";

const Container = styled.div`
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 14px;
    padding-right: 14px;
    margin-bottom: 12px;
    background: #F4F7F8;
    border-radius: 12px;
    cursor: pointer;
`;

const UserIcon = styled.img`
    width: 12px;
`;

const ButtonIcon = styled.img`
    height: 12px;
`;  

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 600;
    font-size: 13.5px;
    line-height: 16px;
    letter-spacing: -0.5px;
    color: #191F28;
    margin-left: 15px;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

interface BookingCardProps {
    date: string;
    menuName: string;
    price: number;
    state: number;
    onSelect: () => void;
}

export function BookingCard({
    date,
    menuName,
    price,
    state,
    onSelect
}: BookingCardProps){

    function convertStateToString(state: number)
    {
        if(state === 1)
        {
            return '예약요청'
        }
        else if(state === 2)
        {
            return '예약완료'
        }
        else if(state === 3)
        {
            return '요청거절'
        }
        else if(state === 4)
        {
            return '고객취소'
        }
        else if(state === 5)
        {
            return '점주취소'
        }
        else if(state === 6)
        {
            return '이용완료'
        }
        else if(state === 7)
        {
            return '노쇼'
        }
        else if(state === 8)
        {
            return '예약철회'
        }
        else if(state === 9)
        {
            return '미응답취소'
        }
    }
    return (
        <Container
            onClick={onSelect}
        >        
            <Wrapper>
                <UserIcon 
                    src={require('../../styles/assets/icon/user.png')}
                />
                <Label>{date} / {menuName} / {setComma(price)}원 / {convertStateToString(state)}</Label>
            </Wrapper>                
            <ButtonIcon 
                src={require('../../styles/assets/arrow_right.png')}
            />
        </Container>
    );
}