import { useEffect, useState } from "react";
import styled from "styled-components"

import { 
    MenuType
} from "../../../../types";

import {
    getMenuListRequest
} from '../../../../apis/BookingApi'

import { MenuCard } from "./component/MenuCard";

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`;

const TouchableSection = styled.div`
    width: 100%;
    height: 20%;
    cursor: pointer;
    z-index: 1000;
`;

const Section = styled.div`
    width: 100%;
    height: 80%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #FFFFFF;
    overflow: auto;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 1000;
    padding-top: 20px;
    padding-left: 5vw;
    padding-right: 5vw;
`;

interface SelectMenuModalProps {
    onClose: () => void;
    onSelect: (menu: MenuType) => void;
    storeId: number;
}

export function SelectMenuModal({
    onClose,
    onSelect,
    storeId
}: SelectMenuModalProps){
    const [ menuList, setMenuList ] = useState<MenuType[]>([]);

    useEffect(() => {      
        
        // load menu list
        const loadoadMenuList = async () => {
            const res = await getMenuListRequest(storeId);
            if(res.status === 'success')
            {
                setMenuList(res.data)
            }
        }   
        loadoadMenuList();
    }, [])

    return (
        <Container>
            <TouchableSection 
                onClick={onClose}
            />
            <Section>
                {
                    menuList.length > 0 &&
                    menuList.map((menu, index) => {
                        return (
                            <MenuCard 
                                key={index}
                                id={menu.id}
                                name={menu.name}
                                price={menu.price}
                                duration={menu.duration}
                                onClick={() => {
                                    onSelect(menu);
                                }}
                            />                            
                        );
                    })
                }
            </Section>
        </Container>
    );
}