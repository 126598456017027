import { useEffect, useState } from "react";
import styled from "styled-components";

import {
    getBreakTimeListRequest,
    updateScheduleRequest,
    updateBreakTimeRequest
} from '../../apis/BookingApi'

import {
    DowScheduleType,
    BreakTimeType
} from '../../types'

import { 
    leadingZeros 
} from "../../utilities/convert";

import {
    PageButton,
    CardButton,
    Gap
} from '../atoms';

import {
    SettingTime,
    ModalHeader
} from '../molecules'

import {
    ConfrimCheckModal,
    AlertModal
} from '../modals';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background: #FFFFFF;
`;


const RowWrapper = styled.div<{marginTop: number}>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: ${(prosp) => prosp.marginTop}px;
`;

const CenterWrapper = styled.div<{marginTop: number}>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: ${(prosp) => prosp.marginTop}px;
    padding: 0 5vw;
`;

const AvailableTimeSection = styled.div`
    width: 100%;
    background-color: #fff;
    overflow: auto;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    padding: 20px 5vw;
`;

const BreakTimeSection = styled.div`
    width: 100%;
    background-color: #fff;
    overflow: auto;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    padding: 0 5vw;
`;

const Dow = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: #191F28;
    border-bottom: 1px solid #CEDDE2;
    padding: 0 5vw;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
`;

const Text = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.285714px;
    color: #191F28;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
`;

const Line = styled.div`
    width: 100%;
    height: 8px;
    background: #F2F2F2;    
`;

const Trash = styled.img`
    width: 20px;
    margin-left: 10px;
`;

interface ScheduleSettingModalProps {
    dowSchedule: DowScheduleType;
    onClose: () => void;
    onSubmit: () => void;
}

export function ScheduleSettingModal({
    dowSchedule,
    onClose,
    onSubmit
}: ScheduleSettingModalProps){
    const [ isTimeSetting, setIsTimeSetting] = useState(false);
    const [ breakTimeList, setBreakTimeList ] = useState<BreakTimeType []>([]);
    // 0없음, 1: open time, 2: close time, 3: break
    const [ settingTimeType, setSettingTimeType ] = useState(0);
    const [ storeOpen, setStoreOpen ] = useState<string>(dowSchedule.open);
    const [ storeClose, setStoreClose ] = useState<string>(dowSchedule.close);
    const [ active, setActive ] = useState<boolean>(dowSchedule.active);
    const [ viewConfirmModal, setViewConfirmModal ] = useState(false);
    const [ isAlert, setIsAlert ] = useState(false);
    const [ alertTitle, setAlertTitle ] = useState<string>('');
    const [ alertDescription, setAlertDescription ] = useState<string>('');

    // 월요일
    // 휴무일 지정
    // OPEN, CLOSE
    // 브레이크타임
    // START, END
    // 추가 가능 
    // validation
    // 삭제 가능 
    // 시간순으로 ordering 
    // X, 수정하기
    // 일요일과 같음 

    const settingStoreOpen = (open: string) => {
        
        if(storeClose !== '' && storeClose !== null)
        {
            let start = new Date();
            let end = new Date();

            start.setHours(Number(open.split(':')[0]))
            start.setMinutes(Number(open.split(':')[1]))

            end.setHours(Number(storeClose.split(':')[0]))
            end.setMinutes(Number(storeClose.split(':')[1]))

            if(start < end)
            {
                setStoreOpen(open);
            }
            else
            {
                setAlertTitle("시간설정 오류");
                setAlertDescription("시작시간이 종료시간 뒤 입니다.")
                setIsAlert(true);
            }
        }
        else
        {
            setStoreOpen(open);
        }
    }

    const settingStoreClose = (close: string) => {
        if(storeOpen !== '' && storeOpen !== null)
        {
            let start = new Date();
            let end = new Date();

            start.setHours(Number(storeOpen.split(':')[0]))
            start.setMinutes(Number(storeOpen.split(':')[1]))

            end.setHours(Number(close.split(':')[0]))
            end.setMinutes(Number(close.split(':')[1]))

            if(start < end)
            {
                setStoreClose(close);
            }
            else
            {
                setAlertTitle("시간설정 오류");
                setAlertDescription("종료시간이 시작시간이 이전 입니다.")
                setIsAlert(true);
            }
        }
        else
        {
            setStoreClose(close);
        }
    }

    const onActiveChange = () => {
        setActive(prev => !prev)
    }

    const updateSchedule = async () => {
        // schedule, break list update 
        let dowScheduleRequest: DowScheduleType = JSON.parse(JSON.stringify(dowSchedule));
        
        if(active)
        {
            if(storeOpen !== null && storeClose !== null)
            {
                dowScheduleRequest.open = storeOpen;
                dowScheduleRequest.close = storeClose;
                dowScheduleRequest.active = active;

                const res1 = await updateScheduleRequest(dowScheduleRequest);
            }
        }        
        else
        {
            dowScheduleRequest.open = storeOpen;
            dowScheduleRequest.close = storeClose;
            dowScheduleRequest.active = active;

            const res1 = await updateScheduleRequest(dowScheduleRequest);
        }

        let breakListArr:BreakTimeType[] = breakTimeList.filter(function(data){
            return data.end !== '' && data.start !== ''
        }) 
        
        const res2 = await updateBreakTimeRequest(breakListArr, dowSchedule.id);
        onSubmit();
    }

    const deleteBreakTime = (index: number) => {
        let tBreakTimeList:BreakTimeType [] = JSON.parse(JSON.stringify(breakTimeList));
        tBreakTimeList.splice(index, 1);
        setBreakTimeList(tBreakTimeList);
    }

    const setStartBreakTimeSetting = (index: number, hour: number, minute: number) => {
       let tBreakTimeList:BreakTimeType [] = JSON.parse(JSON.stringify(breakTimeList));
        tBreakTimeList.map((breakTime, sindex) => {
             if(sindex === index)
             {
                
                if(breakTime.end !== '')
                {
                    const tempStart = leadingZeros(hour.toString(), 2) + ":" + leadingZeros(minute.toString(), 2) + ":00";
                    let start = new Date();
                    let end = new Date();
                    start.setHours(Number(tempStart.split(':')[0]))
                    start.setMinutes(Number(tempStart.split(':')[1]))        
                    end.setHours(Number(breakTime.end.split(':')[0]))
                    end.setMinutes(Number(breakTime.end.split(':')[1]))
                    
                    if(start < end)
                    {
                        breakTime.start = leadingZeros(hour.toString(), 2) + ":" + leadingZeros(minute.toString(), 2) + ":00";
                        //end - start 
                        let endTimeHour = Number(breakTime.end.slice(0, 2));
                        let endTimeMinute = Number(breakTime.end.slice(3, 5));
                        breakTime.duration = (endTimeHour * 60 + endTimeMinute) - (hour * 60 + minute);
                    }
                    else
                    {
                        setAlertTitle("시간설정 오류");
                        setAlertDescription("시작시간이 종료시간 뒤 입니다.")
                        setIsAlert(true);
                    }
                }
                else
                {
                    breakTime.start = leadingZeros(hour.toString(), 2) + ":" + leadingZeros(minute.toString(), 2) + ":00";
                }
            }
        });
        setBreakTimeList(tBreakTimeList);
    }

    const setEndBreakTimeSetting = (index: number, hour: number, minute: number) => {        
        let tBreakTimeList:BreakTimeType [] = JSON.parse(JSON.stringify(breakTimeList));
        tBreakTimeList.map((breakTime, eindex) => {
             if(eindex === index)
             {                
                if(breakTime.start !== '')
                {
                    const tempEnd = leadingZeros(hour.toString(), 2) + ":" + leadingZeros(minute.toString(), 2) + ":00";
                    let start = new Date();
                    let end = new Date();
                    start.setHours(Number(breakTime.start.split(':')[0]))
                    start.setMinutes(Number(breakTime.start.split(':')[1]))        
                    end.setHours(Number(tempEnd.split(':')[0]))
                    end.setMinutes(Number(tempEnd.split(':')[1]))

                    if(start < end)
                    {
                        breakTime.end = leadingZeros(hour.toString(), 2) + ":" + leadingZeros(minute.toString(), 2) + ":00";
                        //end - start 
                        let startTimeHour = Number(breakTime.start.slice(0, 2));
                        let startTimeMinute = Number(breakTime.start.slice(3, 5));
                        breakTime.duration = (hour * 60 + minute) - (startTimeHour * 60 + startTimeMinute);;
                    }
                    else
                    {
                        setAlertTitle("시간설정 오류");
                        setAlertDescription("종료시간이 시작시간 이전 입니다.")
                        setIsAlert(true);
                    }
                }
                else{
                    breakTime.end = leadingZeros(hour.toString(), 2) + ":" + leadingZeros(minute.toString(), 2) + ":00";
                }
            }
        });
        setBreakTimeList(tBreakTimeList);
    }

    const addNewBreakTime = async () => {
        // 이미 비어있는게 있다면 추가 안됨 
        let tBreackTime: BreakTimeType = {
            id: 0,
            dowScheduleId: dowSchedule.id,
            start: '',
            end: '',
            duration: 0
        }
        const addBreakTimeList:BreakTimeType [] = JSON.parse(JSON.stringify(breakTimeList));
        
        addBreakTimeList.push(tBreackTime);
        setBreakTimeList(addBreakTimeList);
    }

    const getBreackList = async (dowScheduleId: number) => {

        const response = await getBreakTimeListRequest(dowScheduleId);
        setBreakTimeList(response.data);
    }

    function convertDowToString (dow: number){
        if(dow === 0)
        {
            return '일요일'
        }
        else if(dow === 1)
        {
            return '월요일'
        }
        else if(dow === 2)
        {
            return '화요일'
        }
        else if(dow === 3)
        {
            return '수요일'
        }
        else if(dow === 4)
        {
            return '목요일'
        }
        else if(dow === 5)
        {
            return '금요일'
        }
        else if(dow === 6)
        {
            return '토요일'
        }
    }

    useEffect(() => {
        getBreackList(dowSchedule.id);
    }, [])

    return (
        <Container className="request">
            {
                isAlert &&
                <AlertModal 
                    title={alertTitle}
                    description={alertDescription}
                    onCheck={() => {
                        setIsAlert(false);
                    }}
                />
            }
            {
                viewConfirmModal && 
                <ConfrimCheckModal 
                    title={'변경한 정보가 있는지 확인해주세요.'}
                    description={'뒤로가기를 누를 경우 변경한 내역이 저장되지 않습니다.'}
                    onCheck={() => {
                        setViewConfirmModal(false);
                        onClose();
                    }}
                    onCancel={() => {
                        setViewConfirmModal(false);
                    }}
                />
            }
            <ModalHeader 
                title={`${convertDowToString(dowSchedule.dow)} 영업 설정`}
                onClose={() => {
                    setViewConfirmModal(true);
                }}
            />   
            <Gap 
                gap={56}
            />
            <AvailableTimeSection>
                <Title>영업시간 설정</Title>
                <RowWrapper marginTop={22}>
                    <input type="checkbox" checked={!active}
                        onChange={onActiveChange}
                    />
                    <Text
                        onClick={onActiveChange}
                    >정기 휴무일 입니까?</Text>
                </RowWrapper>
                <RowWrapper marginTop={22}>
                    <Label>오픈시간</Label>                    
                </RowWrapper>
                <RowWrapper marginTop={12}>
                    <SettingTime
                        time={storeOpen ? storeOpen : '영업오픈시간설정'}
                        onSettingTime = {(hour: number, minute: number) => {
                            setActive(true);
                            // setStoreOpen(leadingZeros(hour.toString(), 2) + ":" + leadingZeros(minute.toString(), 2) )
                            settingStoreOpen(leadingZeros(hour.toString(), 2) + ":" + leadingZeros(minute.toString(), 2))
                        }}     
                        timeRange={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']}                   
                    />
                </RowWrapper>
                <RowWrapper marginTop={12}>
                    <Label>종료시간</Label>
                </RowWrapper>
                <RowWrapper marginTop={12}>                    
                    <SettingTime
                        time={storeClose ? storeClose : '영업종료시간설정'}
                        onSettingTime = {(hour: number, minute: number) => {
                            setActive(true);
                            // setStoreClose(leadingZeros(hour.toString(), 2) + ":" + leadingZeros(minute.toString(), 2) )
                            settingStoreClose(leadingZeros(hour.toString(), 2) + ":" + leadingZeros(minute.toString(), 2))
                        }}           
                        timeRange={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']} 
                    />
                </RowWrapper>
            </AvailableTimeSection>
            <Line />     
            <Gap 
                gap={20}
            />
            <BreakTimeSection>
                <Title>휴식시간 설정</Title>
                {
                    breakTimeList.length > 0 && 
                    breakTimeList.map((breakTime, index) => {
                        return (
                            <RowWrapper marginTop={20} key={index}>
                                <SettingTime
                                    time={breakTime.start ? breakTime.start : '시작시간'}
                                    onSettingTime = {(hour: number, minute: number) => {
                                        setStartBreakTimeSetting(index, hour, minute);
                                    }}     
                                    timeRange={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']}                           
                                />  
                                <div style={{width: '20px'}}/>
                                <SettingTime
                                    time={breakTime.end ? breakTime.end : '종료시간'}
                                    onSettingTime = {(hour: number, minute: number) => {
                                        setEndBreakTimeSetting(index, hour, minute);
                                    }}   
                                    timeRange={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']}                            
                                /> 

                                <Trash src={require('../../styles/assets/icon/trash.png')} 
                                    onClick={() => {
                                        deleteBreakTime(index);
                                    }}
                                />
                            </RowWrapper>
                        );
                    })
                }
                <CenterWrapper marginTop={22}>
                    <CardButton 
                        text="휴식시간 추가하기"
                        background="#FFFFFF"
                        color="#9C27B0"
                        onClick={addNewBreakTime}
                    /> 
                </CenterWrapper>    
            </BreakTimeSection>
            
   
            {/* <ButtonSection 
                onClick={onClose}
            /> */}
            <CenterWrapper marginTop={22}>
                <PageButton 
                    text="업데이트"
                    background="#9C27B0"
                    color="#FFFFFF"
                    onClick={updateSchedule}
                />
            </CenterWrapper>
        </Container>
    );
}