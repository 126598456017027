import { useEffect, useState } from "react";
import styled from "styled-components"

import {
    getStorePlanRequest,
    getStoreChargeInfoRequest,
    getStoreMessageSendPageRequest
} from '../../../../apis/BookingApi';

import {
    convertTimestampToHangulTime2,
    setComma,
} from '../../../../utilities/convert';

import {
    ChargeInfoType,
    MessageSendType
} from '../../../../types';

import {
    Gap
} from '../../../atoms';

import {
    ModalHeader
} from '../../../molecules';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background: #FFFFFF;
`; 

const Line = styled.div`
    width: 100%;
    height: 8px;
    background: #F2F2F2;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
`;

const PlanWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 31px 5vw;
    flex-direction: column;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.25px;
    color: #000000;
`;

const SubLabel = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.25px;
    color: #000000;
    span {
        font-weight: 700;
    }
`;

const YearMonth = styled.div`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: #191F28;
`;

const BeforeButton = styled.img`
    margin-right: 35px;
    cursor: pointer;
`;

const AfterButton = styled.img`
    margin-left: 35px;
    cursor: pointer;
`;

const ChargeWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 31px 5vw;
    flex-direction: column;
`;

const SelectDateWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const ListWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 31px 5vw;
    flex-direction: column;
    height: calc(100vh - 550px);
    overflow-y: auto;   
    padding-top: 30px;
`;

const CardWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const CenterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;  
`;

const More = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.175px;
    color: #9C27B0;
    cursor: pointer;
`;

const Icon = styled.img`
    width: 16px;
    margin-right: 8px;
`;

interface PlanCheckModalProps {
    onClose: () => void;
    storeId: number;
}

export function PlanCheckModal({
    onClose,
    storeId
}: PlanCheckModalProps){

    const [ plan, setPlan ] = useState<string>('');
    const cur = new Date(); 
    const [ selectedYear, setSelectedYear ] = useState<number>(cur.getFullYear());
    const [ selectedMonth, setSelectedMonth ] = useState<number>(cur.getMonth() + 1);
    const [ storeChargeInfo, setStoreChargeInfo ] = useState<ChargeInfoType>();
    const [ curPage, setCurPage ] = useState(1);
    const [ totalPage, setTotalPage ] = useState(0);
    const [ list, setList ] = useState<MessageSendType []>([]);

    function preMonth(){
        let y = 0, m = 0;
        if(selectedMonth === 1)
        {
            y = selectedYear as number - 1;
            m = 12;
            setSelectedMonth(12);
            setSelectedYear(selectedYear as number - 1);           
        }
        else
        {
            y = selectedYear as number;
            m = selectedMonth as number - 1
            setSelectedMonth(selectedMonth as number - 1);
            
        }
        getChargeInfo(y, m)
    }
    function nextMonth(){
        let y=0, m = 0;
        if(selectedMonth === 12)
        {
            y = selectedYear as number + 1;
            m = 1;
            setSelectedMonth(1);
            setSelectedYear(selectedYear as number + 1);
           
        }
        else
        {
            y = selectedYear as number;
            m = selectedMonth as number + 1;
            setSelectedMonth(selectedMonth as number + 1);            
        }
        getChargeInfo(y, m)
    }

    function convertState(state: number){
        if(state === 1)
        {
            return '결제대기'
        }
        else if(state === 2)
        {
            return '결제완료'
        }
        else if(state === 3)
        {
            return '기한만료'
        }
    }

    function convertSendType(type: number){
        if(type === 1)
        {
            return '관리자'
        }
        else if(type === 2)
        {
            return '고객'
        }
    }

    function convertMessageType(type:number){
        if(type === 1)
        {
            return '예약요청'
        }
        else if(type === 2)
        {
            return '수락'
        }
        else if(type === 3)
        {
            return '거절'
        }
        else if(type === 4)
        {
            return '취소'
        }
        else if(type === 5)
        {
            return '완료'
        }
        else if(type === 6)
        {
            return '노쇼'
        }
    }

    function convertTool(type: number)
    {
        if(type === 1)
        {
            return '알림톡'
        }
        else if(type === 2)
        {
            return '텔레그램'
        }       
    }

    const getChargeInfo = async (year: number, month: number) => {
        const res = await getStoreChargeInfoRequest(storeId, year, month);
        if(res.status === 'success')
        {
            setStoreChargeInfo(res.data);
        }
    }

    const getStoreMessageSendPage = async (year: number, month: number, page: number) => {
        setCurPage(page);
        const res = await getStoreMessageSendPageRequest(storeId, year, month, page - 1);
        if(res.status === 'success')
        {
            if(res.status === 'success')
            {
                if(page === 1)
                {
                    setList([...res.data.content]);
                }
                else
                {
                    setList((prev) => [...list, ...res.data.content]);
                }
               
                setTotalPage(res.data.totalPages);
            }
        }
    }

    useEffect(() => {
        // 요금제 확인 
        const getStorePlan = async () => {
            const res = await getStorePlanRequest(storeId);
            if(res.status === 'success')
            {
                setPlan(res.data);
            }
        }
        getStorePlan();
        getChargeInfo(selectedYear, selectedMonth);

        const initStoreMessagePage = async () => {
            const res = await getStoreMessageSendPageRequest(storeId, selectedYear, selectedMonth, curPage - 1); 
            if(res.status === 'success')
            {
                setList((prev) => [...res.data.content]);
                setTotalPage(res.data.totalPages);
            }
        }
        initStoreMessagePage();
    }, [selectedYear, selectedMonth])

    return (
        <Container>
            <ModalHeader 
                title="요금 확인"
                onClose={onClose}
            />
            <Gap 
                gap={56}
            />
            <PlanWrapper>
                <Title>요금제</Title>
                <Gap 
                    gap={8}
                />
                <Label>현재 사용중인 요금제는<br/>{plan}입니다.</Label>
                <Gap 
                    gap={8}
                />
                <SubLabel>변경을 원하시면 금요일11시 관리자에게 문의해주세요.</SubLabel>
            </PlanWrapper>
            <Line />
            <ChargeWrapper>
                <SelectDateWrapper>
                    <BeforeButton src={require('../../../../styles/assets/icon/expand_left_more.png')} 
                        onClick={preMonth}
                    />
                    <YearMonth>{selectedYear}.{selectedMonth}</YearMonth>
                    <AfterButton src={require('../../../../styles/assets/icon/expand_right_more.png')} 
                        onClick={nextMonth}
                    />                      
                </SelectDateWrapper>    
                <Gap 
                    gap={8}
                />
                <Title>청구 내역</Title> 
                {
                    storeChargeInfo &&
                    <>
                        <Gap 
                            gap={8}
                        />
                        <RowWrapper>
                            <SubLabel>{cur.getFullYear()}.{cur.getMonth() + 1} 실시간 사용 요금</SubLabel>
                            <SubLabel>{setComma(storeChargeInfo.realtimePrice)}원</SubLabel> 
                        </RowWrapper>
                        <Gap 
                            gap={8}
                        />
                        <RowWrapper>
                            <SubLabel>청구 요금</SubLabel>
                            <SubLabel><span>{setComma(storeChargeInfo.chargedPrice)}원</span> ({convertState(storeChargeInfo.chargeState)})</SubLabel>
                        </RowWrapper>
                        <Gap 
                            gap={8}
                        />
                        <RowWrapper>
                            <SubLabel>납부기한</SubLabel>
                            <SubLabel>{storeChargeInfo.limitDate}까지</SubLabel>
                        </RowWrapper>
                        <Gap 
                            gap={8}
                        />
                        <RowWrapper>
                            <SubLabel>국민은행 / 414301-01-181508 / 이승도(금요일11시)</SubLabel>
                        </RowWrapper>
                    </>
                }
                          
            </ChargeWrapper>
            <Line />
            <ListWrapper>
                <Title>상세 내역</Title> 
                <Gap 
                    gap={8}
                />
                {
                    list.length > 0 &&
                    list.map((info, index) => {
                        return (
                            <CardWrapper
                                key={index}
                            >
                                <Label>{index + 1}.&nbsp;
                                    {convertTimestampToHangulTime2(info.createdAt)}/
                                    {convertMessageType(info.messageType)}/
                                    ({convertSendType(info.sendType)})/
                                    {convertTool(info.toolType)}/
                                    {info.success ? '전송성공' : '전송실패'}/ 
                                    {info.price}원
                                </Label>
                                
                            </CardWrapper>

                        );
                    })
                }
                {   
                    totalPage > curPage &&
                    <CenterWrapper>
                        <More 
                            onClick={() => {
                                getStoreMessageSendPage(selectedYear, selectedMonth, curPage + 1);
                            }}
                        >
                            <Icon 
                                src={require('../../../../styles/assets/icon/down_arrow.png')}
                            />
                            더보기
                        </More>
                    </CenterWrapper>    
                    
                }  
            </ListWrapper>
        </Container>
    );
}