import { useState } from "react";
import styled from "styled-components";

import {
    Gap
} from '../../../../atoms';

import { 
    setComma 
} from "../../../../../utilities/convert";

var BASE_URL = process.env.REACT_APP_TARGET_URL;

const Container = styled.div`
    width: 100%;    
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F4F7F8;
    border-radius: 16px;
    height: 88px;
    margin-bottom: 12px;
    padding: 0 5vw;
    cursor: pointer;
`;

const Image = styled.img`
    width: 55px;
    height: 55px;
    border-radius: 8px;
`;

const Icon = styled.img`
    width: 10px;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: -0.2px;
    color: #333D4B;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

`;

const ColWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 16px;
`;

interface MenuCardProps {
    id: number;
    name: string;
    price: number;
    duration: number;
    onClick: () => void;
}

export function MenuCard({
    id,
    name,
    price,
    duration,
    onClick
}:MenuCardProps){

    const [ hash, setHash ] = useState(Date.now());   

    return (
        <Container
            onClick={onClick}
        >
            <Wrapper>
                <Image 
                    src={BASE_URL + `/v1/menu/image/read/${id}?${hash}`}
                />
                <ColWrapper>
                    <Label>
                        {name}
                    </Label>
                    <Gap 
                        gap={16}
                    />
                    <Label>
                        {setComma(price)}원 / {duration}분
                    </Label>
                </ColWrapper>
            </Wrapper>
            <Icon 
                src={require('../../../../../styles/assets/arrow_right.png')}
            />
        </Container>
    );
}