import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    height: 1000px;
    background: rgb(188,163,224);
    background: linear-gradient(180deg, rgba(188,163,224,1) 0%, rgba(214,195,237,1) 50%, rgba(225,212,245,1) 100%);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    @media only screen and (max-width: 768px) {
        height: 800px;
    }
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;
    /* identical to box height, or 100% */
    text-align: center;
    letter-spacing: -0.75px;
    color: #000000;
    margin-top: 172px;

    @media only screen and (max-width: 768px) {
        font-size: 40px;
        line-height: 48px;
    }
`;

const Image = styled.img`
    margin-top: 35px;

    @media only screen and (max-width: 768px) {
        width: 300px;
    }
`;

const Button = styled.div`
    z-index: 2;    
    background: #9C27B0;
    border-radius: 40px;
    width: 382px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.375px;
    color: #FFFFFF;
    margin-top: 40px;
    cursor: pointer;

    @media only screen and (max-width: 768px) {
        width: 262px;
        height: 55px;
        font-weight: 500;
        font-size: 20px;
    }
`;

const Image2 = styled.img`
    margin-top: 100px;
    @media only screen and (max-width: 768px) {
        width: 400px;
    }
`;


function Banner(){
    return (
        <Container>    
            <Title>가장 설레는 시간</Title>

            <Image src={require('../../../styles/assets/logo_landing.png')} />
            <Button 
                onClick={() => {
                    window.open('https://friday11am.com/contact')
                }}
            >금요일11시 시작하기</Button>
            <Image2 src={require('../../../styles/assets/banner_landing.png')} />   
        </Container>
    );
}

export default Banner;