import { useState, FormEvent } from "react";
import styled from "styled-components"

import {
    PageButton,
    Gap
} from '../../../atoms';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0,0,0,0.9);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`;

const TouchableSection = styled.div`
    width: 100%;
    height: calc(100vh - 341px);
    cursor: pointer;
`;

const Section = styled.div`
    width: 100%;
    height: 341px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
    overflow: auto;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1000;
`;

const Title = styled.div`
    min-height: 60px;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #CEDDE2;
    padding-left: 5vw;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: #191F28;
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 20px 5vw;
`;


const TextArea = styled.textarea`
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    width: 100%;
    height: 160px;
    resize: none;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #7D93A6;
    padding: 20px;    
`;

interface RejectBookingModalProps {
    onReject: (message: string) => void;
    onClose: () => void;
    presetMessage: string;
}

export function RejectBookingModal({
    onReject,
    onClose,
    presetMessage
}: RejectBookingModalProps){

    const [ message, setMessage ] = useState<string>(presetMessage);
    
    const onMessageChange = (event: FormEvent<HTMLTextAreaElement>) => {
        const {
            currentTarget: {value},
        } = event;
        setMessage(value);
    }

    const onSubmit = () => {
        if(message !== '')
        {
            onReject(message);
        }
    }

    return (
        <Container>
            <TouchableSection
                onClick={onClose}
            />
            <Section>
                <Title>예약요청 거절</Title>    
                <Wrapper>
                    <TextArea
                        placeholder="거절사유를 입력해주세요"
                        value={message}
                        onChange={onMessageChange}
                    >
                    </TextArea>
                    <Gap 
                        gap={30}
                    />
                    <PageButton 
                        text="요청 거절"
                        background="#9C27B0"
                        color="#FFFFFF"
                        onClick={onSubmit}
                    />
                </Wrapper>
            </Section>
        </Container>
    )
}