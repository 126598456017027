import styled from "styled-components";

import {
    YBar
} from '../atoms';

import {
    BookingStatType
} from '../../types';
import { useEffect } from "react";

const Container = styled.div`
    display: grid;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    grid-template-columns: 21% 21% 21% 21%;
    gap: 15px;
`;

interface YBarGraphListProps {
    data: BookingStatType
}

export function YBarGraphList({
    data
}:YBarGraphListProps){

    return (
        <Container>
            <YBar 
                label="총 예약신청"
                value={data.totalBooking /(data.totalBooking + data.serviceComplete + data.noshow + data.bookingCancel) * 100}
                background="#9C27B0"
            />
            <YBar 
                label="완료"
                value={data.serviceComplete /(data.totalBooking + data.serviceComplete + data.noshow + data.bookingCancel) * 100}
                background="#009FAF"
            />
            <YBar 
                label="노쇼"
                value={data.noshow /(data.totalBooking + data.serviceComplete + data.noshow + data.bookingCancel) * 100}
                background="#F26565"
            />
            <YBar 
                label="취소"
                value={data.bookingCancel /(data.totalBooking + data.serviceComplete + data.noshow + data.bookingCancel) * 100}
                background="#F3A84F"
            />
        </Container>
    );
}