import { useEffect, useState } from "react";
import styled from "styled-components";

import {
    AlertModal
} from '../modals';

//전체 검은 영역 
const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`;

const Section = styled.div`
    width: 100%;
    height: 511px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
    overflow: hidden;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 100;
`;

const ButtonSection = styled.div`
    width: 100%;
    height: calc(100vh - 550px);
`;


const TemplateRow = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
    height: 60px;
    padding-left: 10px;
`;

const RowWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    height: 64px;
    border-bottom: 1px solid #979797;
`;

const Row = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Year = styled.div`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: #191F28;
`;

const Month = styled.div`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.3px;
    color: #191F28;
`;

const BeforeButton = styled.img`
    cursor: pointer;
`;

const AfterButton = styled.img`
    margin-left: 35px;
    cursor: pointer;
`;

const CloseButton = styled.img`
    position: absolute;
    top: 15px;
    right: 15px;
    width: 15px;
    cursor: pointer;
`;

const DateElement = styled.div<{active: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 35px;
    height: 35px;
    background-color: ${(props) => props.active ? '#FF4A8B' : '#FFFFFF'} ;
    border-radius: ${(props) => props.active ? '50%' : '0'} ;
    color: ${(props) => props.active ? '#FFFFFF' : '#000000'};
`;

const PreDateElement = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #BBBBBB;
    cursor: pointer;
    width: 35px;
    height: 35px;
`;

const Dow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #BBBBBB;
    width: 30px;
    height: 30px;
`;

interface CalendarProps {
    onClose: () => void;
}

interface DateType {
    year: number;
    month: number;
    day: number;
}

interface CalendarProps {
    year: number;
    month: number;
    date: number;
    onSelect: (year: number, month: number, date: number) => void;
    onClose: () => void;
    mode: number; // 1: full select, 2: 오늘날짜 이전은 클릭 금지
}

export function Calendar({
    year,
    month, 
    date,
    onSelect,
    onClose,
    mode
}: CalendarProps){

    const [ targetMonth, setTargetMonth ] = useState<number>(0);
    const [ targetYear, setTargetYear ] = useState<number>(0);
    const [ targetDate, setTargetDate ] = useState<number>(0);
    const [ calendarList, setCalendarList ] = useState<DateType[][]>();
    const [ alertView, setAlertView ] = useState(false);
    const [ alertTitle, setAlertTitle ] = useState<string>('');
    const [ alertDescription, setAlertDescription ] = useState<string>('');

    const selectedPreDate = (date: number) => {
        if(targetMonth === 1)
        {
            onSelect(targetYear - 1, 11, date);
        }
        else
        {
            onSelect(targetYear, targetMonth - 2, date);
        }
    }

    const selectedDate = (date: number) => {
        if(mode === 1)
        {
            onSelect(targetYear, targetMonth - 1, date);
        }
        else
        {
            
            const cur = new Date();
            if(targetYear < cur.getFullYear())
            {
                //실패
                setAlertTitle('지난 날짜는 선택할 수 없습니다.');
                setAlertView(true);
            }
            else if(targetYear === cur.getFullYear())
            {
                if(targetMonth - 1 < cur.getMonth())
                {
                    // 실패 
                    setAlertTitle('지난 날짜는 선택할 수 없습니다.');
                    setAlertView(true);
                }
                else if(targetMonth - 1 === cur.getMonth())
                {
                    if(date < cur.getDate())
                    {
                        // 실패 
                        setAlertTitle('지난 날짜는 선택할 수 없습니다.');
                        setAlertView(true);
                    }
                    else
                    {
                        // 성공
                        onSelect(targetYear, targetMonth - 1, date);
                    }
                }
                else
                {
                    // 성공
                    onSelect(targetYear, targetMonth - 1, date);
                }
            }
            else
            {
                onSelect(targetYear, targetMonth - 1, date);
            }
        }
        
    }

    // 이전월 나머지 채우기 
    // 월
    // 시작요일
    // 다음월 나머지 채우기 

    function getDayOfWeek()
    {        
        const today = new Date(year, month, date);

        setTargetMonth(today.getMonth() + 1);
        setTargetYear(today.getFullYear());
        setTargetDate(today.getDate());
        getCalendar(today.getFullYear(), today.getMonth());
    }

    function nextMonth()
    {
        let y=0, m = 0;
        if(targetMonth === 12)
        {
            setTargetMonth(1);
            setTargetYear(targetYear as number + 1);
            y = targetYear as number + 1;
            m = 1;
        }
        else
        {
            setTargetMonth(targetMonth as number + 1);
            y = targetYear as number;
            m = targetMonth as number + 1;
        }
        getCalendar(y, m - 1);
    }

    function preMonth()
    {
        let y = 0, m = 0;
        if(targetMonth === 1)
        {
            setTargetMonth(12);
            setTargetYear(targetYear as number - 1);
            y = targetYear as number - 1;
            m = 12;
        }
        else
        {
            setTargetMonth(targetMonth as number - 1);
            y = targetYear as number;
            m = targetMonth as number - 1
        }
        getCalendar(y, m - 1);
    }

    function getCalendar(year: number, month: number)
    {
        // const week = ['일', '월', '화', '수', '목', '금', '토'];
        // const dayOfWeek = week[new Date(today.getFullYear(), today.getMonth(), 1).getDay()];
        var preMonth = 0;
        var preYear = 0;
        if(month != 1)
        {
            preMonth = month - 1;
        }
        else
        {
            preMonth = 12;
            preYear = year - 1;
        }
        const dayOfWeek = new Date(year, month, 1).getDay();
        const lastPreDay = new Date(preYear, preMonth + 1, 0).getDate();
        const lastDay = new Date(year, month + 1, 0).getDate();

        console.log(month)
        let start = 1;
        let calendarList_t: DateType[][] = [];
        for(var i = 0; i < 6; i++)
        {
            let dayList: DateType[] = [];
            for(var j = 0; j < 7; j++)
            {
                if(i === 0)
                {
                    start = lastPreDay - dayOfWeek + j + 1;                    
                    if(start > lastPreDay)
                    {
                        start = j - dayOfWeek + 1;
                    }

                    let day: DateType = { year: year, month: month, day: start};
                    dayList.push(day);
                }
                else
                {
                    const start_t = ++start;
                    if(start_t <= lastDay)
                    {
                        let day: DateType = { year: year, month: month, day: start_t};
                        dayList.push(day);
                    }
                }
            }
            if(dayList.length !== 0)
            {
                calendarList_t.push(dayList);
            }

        }
        setCalendarList(calendarList_t);
        // const lastDay = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
    }
    useEffect(() => {        
        getDayOfWeek();
    }, [])

    return (
        <Container>
            {
                alertView &&
                <AlertModal 
                    title={alertTitle}
                    description={alertDescription}
                    onCheck={() => {
                        setAlertTitle('');
                        setAlertView(false);
                    }}
                />
            }
            <ButtonSection 
                onClick={() => {
                    onClose();
                }}
            />
            <Section> 
                <RowWrapper>                    
                    <BeforeButton src={require('../../styles/assets/icon/expand_left_more.png')} 
                        onClick={preMonth}
                    />
                    <Row>
                        <Year>{targetYear}.</Year>
                        <Month>{targetMonth}</Month> 
                    </Row>
                    <AfterButton src={require('../../styles/assets/icon/expand_right_more.png')} 
                        onClick={nextMonth}
                    />                                                   
                </RowWrapper>                             
                <TemplateRow>
                    <Dow>일</Dow>
                    <Dow>월</Dow>
                    <Dow>화</Dow>
                    <Dow>수</Dow>
                    <Dow>목</Dow>
                    <Dow>금</Dow>
                    <Dow>토</Dow>
                </TemplateRow>
                {
                    calendarList && 
                    calendarList.map((week, index) => {
                        return (
                            <TemplateRow key={index}>
                                {
                                    week.map((day, index2) => {
                                        return (  
                                            index === 0 && day.day > 7 ?  
                                            <PreDateElement  key={index2}
                                                onClick={() => {
                                                    selectedPreDate(day.day)
                                                }}
                                            >{day.day}</PreDateElement> 
                                            : 
                                            <DateElement key={index2} active={targetDate === day.day && targetYear === year && targetMonth === month + 1 ? true : false}
                                                onClick={() => {
                                                    selectedDate(day.day)
                                                }}
                                            >{day.day}</DateElement>                                                                                                                   
                                        );
                                    })
                                }
                            </TemplateRow>
                        )
                    })
                }
            </Section>          
        </Container>
    );
}