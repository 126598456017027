import { useEffect, useState, FormEvent } from "react";
import styled from "styled-components"

import { 
    storeState 
} from '../../../../stores';
import { useRecoilValue } from "recoil";

import {
    createCustomerRequest,
    checkCustomerDupRequest
} from '../../../../apis/BookingApi';

import {
    CustomerType
} from '../../../../types'

import { phoneCheck } from "../../../../utilities/validation";

import {
    PageButton,
    Gap
} from '../../../atoms';

import {
    Loading
} from '../../../molecules';

import {
    AlertModal
} from '../../../modals';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0,0,0,0.9);
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`;

const TouchableSection = styled.div`
    width: 100%;
    height: 50vh;
    cursor: pointer;
    z-index: 2000;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.5px;
    color: #191F28;
`;

const Section = styled.div`
    width: 100%;
    height: 50vh;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #FFFFFF;
    overflow-y: auto;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 1000;
    padding-top: 20px;
    padding-left: 5vw;
    padding-right: 5vw;
`;

const Input = styled.input`
    width: 100%;
    height: 48px;
    border-radius: 12px;
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    padding-left: 20px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #000000;
`;

const ButtonWrapper = styled.div`
    width: 100%;
    padding-left: 5vw;
    padding-right: 5vw;
    position: absolute;
    bottom: 50px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface AddSimpleCustomerModalProps {
    onClose: () => void;
}

export function AddSimpleCustomerModal({
    onClose
}: AddSimpleCustomerModalProps) {
    const store = useRecoilValue(storeState);
    const [ isAlert, setIsAlert ] = useState(false);
    const [ alertTitle, setAlertTitle ] = useState<string>('');
    const [ alertDescription, setAlertDescription ] = useState<string>('');
    const [ loading, setLoading ] = useState(false);

    const [ customer, setCustomer ] = useState<CustomerType>({
        id: 0,
        storeId: store.id,
        phoneNumber: '',
        name: '',
        point: 0,
        userMemo: '',
        createdAt: '',
        updatedAt: '',
        fileType: ''
    });

    const addCustomerInfo = async () => {

        // validation
        if(customer.name === '')
        {
            setAlertTitle("이름을 입력해주세요")
            setIsAlert(true);
            return;
        }

        if(!phoneCheck(customer.phoneNumber))
        {
            setAlertTitle('휴대폰번호를 정확하게 입력해주세요')
            setIsAlert(true);
            return;
        }

        // check dup 
        const dupRes = await checkCustomerDupRequest(store.id, customer.phoneNumber);
        if(dupRes.status === 'success')
        {
            if(dupRes.data)
            {

            }
            else
            {
                setAlertTitle("중복사용자");
                setAlertDescription("이미 같은 휴대폰번호를 사용하는 사용자가 등록되어있습니다.")
                setIsAlert(true);
                return;
            }
        }


        setLoading(true);
        const res = await createCustomerRequest(customer);
        if(res.status === 'success')
        {
            onClose();
        }
    }

    function onNameChange(event: FormEvent<HTMLInputElement>){
        const {
            currentTarget: {value},
        } = event;

        setCustomer({...customer, name: value})
    }
    
    function onPhoneChange(event: FormEvent<HTMLInputElement>){
        const {
            currentTarget: {value},
        } = event;

        setCustomer({...customer, phoneNumber: value})
    }

    return (
        <Container>
            {
                loading && <Loading />
            }
            {
                isAlert &&
                <AlertModal 
                    title={alertTitle}
                    description={alertDescription}
                    onCheck={() => {
                        setAlertTitle('');
                        setAlertDescription('');
                        setIsAlert(false);
                    }}
                />
            }
            <TouchableSection 
                onClick={onClose}
            />
            <Section>
                <Label>고객 정보 추가</Label>
                <Gap 
                    gap={30}
                />
                <Input 
                    value={customer.name}
                    onChange={onNameChange}
                    placeholder="이름"
                />
                <Gap 
                    gap={10}
                />
                <Input 
                    type="number"
                    pattern="\d*"
                    value={customer.phoneNumber}
                    onChange={onPhoneChange}
                    placeholder="휴대폰번호 (- 제외)"
                />
                <ButtonWrapper>
                    <PageButton 
                        text="추가하기"
                        background="#9C27B0"
                        color="#FFFFFF"
                        onClick={addCustomerInfo}
                    />
                </ButtonWrapper>
                
            </Section>
        </Container>
    );
}