import styled from "styled-components";

import {
    PageButton,
    Gap
} from '../../atoms';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
`;

const ButtonSection = styled.div`
    width: 100%;
    height: calc((100vh - 268px) / 2);
`;

const RowSection = styled.div`
    width: 100%;
    height: 248px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const RowButtonSection = styled.div`
    width: 7vw;
    height: 100%;
`;

const Description = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: #191F28;
    margin-bottom: 12px;
`;


const Section = styled.div`
    width: 80vw;
    /* height: 248px; */
    border-radius: 20px;
    background-color: #fff;
    overflow: hidden;
    color: #000000;
    padding: 20px 20px 20px 20px;
    display: flex;   
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    z-index: 9999;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: #191F28;
`;

const ButtonWrapper = styled.div`
    display: grid;
    grid-template-columns: 47% 47%;
    gap: 6%;
    width: 100%;
`;

interface ConfirmCheckModalProps {
    onCancel: () => void;
    onCheck: () => void;
    title: string;
    description? : string;
}

export function ConfrimCheckModal({
    onCancel,
    onCheck,
    title,
    description
}: ConfirmCheckModalProps) {
    return (
        <Container>
            <ButtonSection
                onClick={onCancel}
            />
            <RowSection>
                <RowButtonSection 
                    onClick={onCancel}
                />
                <Section>
                    <Title>{title}</Title>
                    <Gap 
                        gap={15}
                    />
                    <Description>{description}</Description>
                    <Gap 
                        gap={15}
                    />
                    <ButtonWrapper>
                        <PageButton 
                            text="취소"
                            background="#9C27B0"
                            color="#FFFFFF"
                            onClick={onCancel}
                        />
                        <PageButton 
                            text="확인"
                            background="#9C27B0"
                            color="#FFFFFF"
                            onClick={onCheck}
                        />                        
                    </ButtonWrapper>
                </Section>
                <RowButtonSection 
                    onClick={onCancel}
                />
            </RowSection>
            <ButtonSection
                onClick={onCancel}
            />
        </Container>
    );
}