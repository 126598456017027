// 선택 Setting Time
// 완료 Selected Time
// list 
// save, add, delete 

import styled from "styled-components";

import {
    CardButton
} from '../../../atoms'

import {
    SettingTime,
    SaveTime
} from '../../../molecules'

import {
    RequestBookingTimeType
} from '../../../../types';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: #FFFFFF;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 30px;
    padding-bottom: 40px;
`;

const ColWrapper = styled.div<{mgt: number}>`
    width: 100%;
    display: flex;
    
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: ${(props) => props.mgt}px;
`;

const Grid = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    gap: 8px;

`;

const Line = styled.div`
    width: 100%;
    height: 1px;
    background: #CEDDE2;
    margin-bottom: 12px;
`;

const RowWrapper = styled.div`
    display: grid;
    grid-template-columns: 65% 32%;
    width: 100%;
    margin-bottom: 12px;
    gap: 8px;
`;

const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const Text = styled.div`
    font-size: 14px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 48px;
`;

const Label = styled.span<{top: string, bottom: string, size: string, color: string}>`
    font-size: ${(props) => props.size}px;
    margin-top: ${(props) => props.top}px;
    margin-bottom: ${(props) => props.bottom}px;
    color:  ${(props) => props.color};
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.25px;
`;

const Info = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    letter-spacing: -0.21875px;
    color: #FF4A8B;
`;

interface BookingTimeSelectProps {
    requestBookingTimeList: RequestBookingTimeType[];
    saveRequestTileList: RequestBookingTimeType[];
    addNewRequestBookingTime: () => void;
    saveRequestBookingTime: (index: number) => void;
    setStartRequestTimeSetting: (index: number, hour: number, minute: number) => void;
    setEndRequestTimeSetting: (index: number, hour: number, minute: number) => void;
    deleteSaveRequestList: (index: number) => void;
}

export function BookingTimeSelect({
    requestBookingTimeList,
    saveRequestTileList,
    addNewRequestBookingTime,
    saveRequestBookingTime,
    setStartRequestTimeSetting,
    setEndRequestTimeSetting,
    deleteSaveRequestList
}: BookingTimeSelectProps){
    return (
        <Container>
            <TitleWrapper>
                <Label top="10" bottom="12" size="16" color="#000000">예약요청 시간 입력 (시작시간)</Label> 
                <Info>필수</Info>
            </TitleWrapper>            
            <Grid>
            {
                saveRequestTileList.length > 0 &&
                saveRequestTileList.map((save, index) => {
                    return (                        
                        <SaveTime 
                            key={index}
                            start={save.startTime}
                            end={save.endTime}
                            onDelete={() => {            
                                deleteSaveRequestList(index);
                            }}
                        />                        
                    );
                })
            }
            </Grid>
            {
                requestBookingTimeList.length === 0 ? 
                
                <ColWrapper
                    mgt={12}
                >
                    <Line />
                    <CardButton
                        text="시간 추가하기"
                        background="#FFFFFF"
                        color="#9C27B0"
                        onClick={addNewRequestBookingTime}
                    />   
                </ColWrapper>     
                : 
                requestBookingTimeList.map((requestTime, index) => {
                    return (
                        <ColWrapper key={index}
                            mgt={12}
                        >
                            <RowWrapper>
                                <SettingTime
                                    time={requestTime.startTime ? requestTime.startTime : ''}
                                    onSettingTime = {(hour: number, minute: number) => {                                      
                                        setStartRequestTimeSetting(index, hour, minute)
                                    }}                                        
                                    placeHolder="원하는시간"
                                    timeRange={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']} 
                                /> <Text>부터</Text>                               
                            </RowWrapper>     
                            <RowWrapper>
                                <SettingTime
                                time={requestTime.endTime ? requestTime.endTime : ''}
                                onSettingTime = {(hour: number, minute: number) => {                                        
                                    setEndRequestTimeSetting(index, hour, minute);
                                }}                                   
                                placeHolder="원하는시간"
                                timeRange={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']} 
                                /> <Text>사이에 <br/>예약할 수 있나요?</Text>
                            </RowWrapper> 
                            {/* <ColWrapper
                                mgt={8}
                            >
                                <CardButton
                                    text="저장하기"
                                    background="#FFFFFF"
                                    color="#9C27B0"
                                    onClick={() => {
                                        saveRequestBookingTime(index)
                                    }}
                                />   
                            </ColWrapper>       */}
                        </ColWrapper>
                    );
                })             
            }
        </Container>
    );
}