// 사용자 wait
// booking 상태에 따라 

import { useParams } from "react-router-dom";
import styled from "styled-components";
import useInfo from "../../../components/hook/useInfo";
import { 
    UserAdminCancelComponent,
    UserCompleteComponent,
    UserConfirmComponent,
    UserUserCancelComponent,
    UserWaitComponent 
} from "../../../components/organisms";
import { UserAdminRejectComponent } from "../../../components/organisms/UserAdminRejectComponent";
import { UserNoShowComponent } from "../../../components/organisms/UserNoShowComponent";

const Container = styled.div`
    width: 100%;
    height: 100%;
`;


function Booking(){
    const { connectKey } = useParams();
    const { loading, error, info } = useInfo(connectKey as string);


    return (
        <Container>
            {loading && <p>loading...</p>}
            {error && <p>Error!</p>}
            {info && info.bookingInfo.state === 1 && <UserWaitComponent waitInfo={info} />}
            {info && info.bookingInfo.state === 2 && <UserConfirmComponent confirmInfo={info} />}
            {info && info.bookingInfo.state === 3 && <UserAdminRejectComponent rejectInfo={info} />}
            {info && info.bookingInfo.state === 4 && <UserUserCancelComponent cancelInfo={info} />}
            {info && info.bookingInfo.state === 5 && <UserAdminCancelComponent cancelInfo={info} />}
            {info && info.bookingInfo.state === 6 && <UserCompleteComponent completeInfo={info} />}
            {info && info.bookingInfo.state === 7 && <UserNoShowComponent noshowInfo={info} />}
        </Container>
    );
}

export default Booking;