import styled from "styled-components";

const Button = styled.div<{
    background: string; 
    color: string
}>`
    width: 100%;
    height: 52px;
    background: ${(props) => props.background};
    color: ${(props) => props.color};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    border: ${(props) => props.background === '#FFFFFF' ? '1px solid #CEDDE2' : 'none'};
    z-index: 11;
`;

interface PageButtonProps {
    text: string;
    background: string;
    color: string;
    onClick: () => void;
}

export function PageButton({
    text,
    background,
    color,
    onClick
}: PageButtonProps){
    return (
        <Button 
            background={background}
            color={color}
            onClick={onClick}
        >
            {text}
        </Button>
    );
} 