import styled from "styled-components";
import { FormEvent } from "react";

import {
    Gap
} from '../atoms';

import {
    EtcInformationType
} from '../../types'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 40px;
    padding-bottom: 20px;
`;

const ColWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    margin-bottom: 12px;
`;

const RowWrapper = styled.div<{mgb: number}>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${(props) => props.mgb}px;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
`;

const Info = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    letter-spacing: -0.21875px;
    color: #FF4A8B;
    margin-top: -2px;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.175px;
    color: #7D93A6;
    /* height: 55px; */
    overflow: hidden;
    white-space: pre-line;
    word-wrap:break-all;
`;

const TextArea = styled.textarea`
    width: 100%;
    height: 100px;
    padding: 10px;
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.25px;
    color: #7D93A6;
    resize: none;
`;

interface InputEtcInfoProps {
    etcInfoList: EtcInformationType[];
    onChange: (event: FormEvent<HTMLTextAreaElement>, index: number) => void;
}

export function InputEtcInfo({
    etcInfoList,
    onChange
}: InputEtcInfoProps){
    return (
        <Container>
            {
                etcInfoList.map((etc, index) => {
                    return(
                        <ColWrapper key={index}>     
                            <RowWrapper
                                mgb={10}
                            >
                                <Title>{etc.title}</Title>
                                <Info>필수</Info>
                            </RowWrapper>
                            <Label>{etc.description}</Label>
                            <Gap 
                                gap={10}
                            />
                            <TextArea
                                onChange={(event: FormEvent<HTMLTextAreaElement>) => {onChange(event, index)}}
                                placeholder="추가 정보를 입력해주세요"
                            ></TextArea>
                        </ColWrapper>
                    );
                })
            }
            
        </Container>
    );
}