// store contact page 
// kakao login, phone, name 

import { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Const from '../../constant';
import qs from 'qs';
import { 
    verifyTokenRequest,
    createUserAndStoreRequest
} from '../../apis/BookingApi';

import { 
    SubmitButton,
    Gap,
    PageButton
} from "../../components/atoms";

import {
    AddressInputModal
} from '../../components/modals';

import Terms from '../../pages/common/Terms';
import Privacy from '../../pages/common/Privacy';


const Container = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-x: hidden;
    overflow-y: hidden;
`;

const Section = styled.div`
    width: 100%;
    max-width: 600px;
    height: 1220px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;
    padding: 66px 5vw 40px 5vw;
`;

const Line = styled.div`
    width: 100%;
    height: 8px;
    background: #F2F2F2;
`;

const Input = styled.input`
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    width: 100%;
    height: 48px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #000000;
    padding-left: 20px;
`;

const Address = styled.div`
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    width: 100%;
    height: 48px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #000000;
    padding-left: 20px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
`;

const Logo = styled.img`
    width: 144.27px;
`;

const Kakao = styled.div`
    width: 228px;
    height: 52px;
    left: 75px;
    top: 180px;
    background: #FFDA00;
    border-radius: 26px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: #4D3136;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    cursor: pointer;
    margin-bottom: 40px;
`;


const KKakao = styled.div`
    width: 228px;
    height: 52px;
    left: 75px;
    top: 180px;
    background: #FFDA00;
    border-radius: 26px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: #4D3136;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
    cursor: pointer;
    margin-bottom: 40px;
`;


const Icon = styled.img`
    width: 20px;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.208333px;
    color: #000000;
    margin-left: 9.23px;
`;

const RowWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
`;

const CenterWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
`;

const TextButton = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    text-align: right;
    letter-spacing: -0.21875px;
    color: #FF4A8B;
    cursor: pointer;
    width: 120px;
`;

const Text = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #A3AAB6;
`;


function Contact() {
    const [ viewTerms, setViewTerms ] = useState(false);
    const [ viewPrivacy, setViewPrivacy ] = useState(false);
    
    const [ auth, setAuth ] = useState(false);

    const location = useLocation();
    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });
    const kakao_token = query.kakao_token;

    const authKakao = () => {
        const path = `${location.pathname}`;       
        window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${Const.REST_API_KEY}&redirect_uri=${Const.SIGN_REDIRECT_URI}&response_type=code&prompt=login&state=${path}`;
    }

    const reAuthKakao = () => {
        const path = `${location.pathname}`;       
        window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${Const.REST_API_KEY}&redirect_uri=${Const.SIGN_REDIRECT_URI}&response_type=code&prompt=login&state=${path}`;
    }

    const [ addressView, setAddressView ] = useState(false);

    const [ storeName, setStoreName ] = useState<string>('');
    const [ roadAddress, setRoadAddress ] = useState('도로명 주소');
    const [ jibunAddress, setJibunAddress ] = useState('지번 주소');
    const [ zoneCode, setZoneCode ] = useState('');
    const [ detailAddress, setDetailAddress ] = useState('');
    const [ phone, setPhone ] = useState<string>('');
    const [ representativeNumber, setRepresentativeNumber ] = useState<string>('');
    const [ realName, setRealName ] = useState<string>('');

    const onCompletePost = (data:any) => {
        setRoadAddress(data.roadAddress);
        setJibunAddress(data.jibunAddress);
        setZoneCode(data.zonecode);
        setAddressView(false);
    };

    const navigate = useNavigate();

    const verifyToken = async (kakao_token: string) => {
        const res = await verifyTokenRequest(kakao_token);
        if(res === 200)
        {
            // 인증 완료 
            alert("인증되었습니다.")
            setAuth(true);
        }   
        else if(res == 300)
        {
            // 이미 가입된 유저 
            alert("이미 가입하셨습니다.")
            setAuth(false);
        }  
        else 
        {
            // no auth 
            setAuth(false);
        }  
    }

    const createUserAndStore = async (kakao_token:string) => {
        if(storeName === '')
        {
            alert("상호명을 입력해주세요.")
            return false;
        }
        else if(realName === '')
        {
            alert("점주님 성함을 입력해주세요.")
            return false;
        }
        else if(roadAddress === '')
        {
            alert("주소를 입력해주세요.")
            return false;
        }
        else if(detailAddress === '')
        {
            alert("상세주소를 입력해주세요.")
            return false;
        }
        else if(phone === '')
        {
            alert("휴대폰번호를 입력해주세요.")
            return false;
        }
        else if(representativeNumber === '')
        {
            alert("대표번호를 입력해주세요.")
            return false;
        }

        const res = await createUserAndStoreRequest(kakao_token, storeName, realName, phone, roadAddress, jibunAddress, detailAddress, zoneCode, representativeNumber);
  
        if(res.data === 201)
        {
            alert("가입 신청이 완료되었습니다.")
            window.open("https://friday11am.com/admin")
        }
        else if(res.data === 300)
        {
            alert("이미 가입 신청이 완료되었습니다.")
            window.open("https://friday11am.com/admin")
        }
        else{
            alert("관리자에게 문의해주세요.")
        }
    }

    function onStoreNameChange(event: FormEvent<HTMLInputElement>){
        const {
            currentTarget: {value}
        } = event;

        setStoreName(value);
    }

    function onRealNameChange(event: FormEvent<HTMLInputElement>){
        const {
            currentTarget: {value}
        } = event;
        setRealName(value);
    }

    function onPhoneChange(event: FormEvent<HTMLInputElement>){
        const {
            currentTarget: {value}
        } = event;
        setPhone(value);
    }
    function onRepresentativeNumber(event: FormEvent<HTMLInputElement>){
        const {
            currentTarget: {value}
        } = event;
        setRepresentativeNumber(value);
    }

    function onDetailAddressChange(event: FormEvent<HTMLInputElement>){
        const {
            currentTarget: {value}
        } = event;
        setDetailAddress(value);
    }

    useEffect(() => {
        //access_tokne => validation
        if(kakao_token)
        {
            // console.log("API 확인")
            verifyToken(kakao_token as string);
        }
        else
        {
            // console.log("없음")
        }
    }, [])

    return (
        <Container>
            <Section>
                {
                    viewTerms &&
                    <Terms 
                        onClose={() => {
                            setViewTerms(false);
                        }}
                    />
                }
                {
                    viewPrivacy &&
                    <Privacy 
                        onClose={() => {
                            setViewPrivacy(false);
                        }}
                    />
                }
                {
                    addressView && 
                    <AddressInputModal 
                        onComplete={(data) => {
                            onCompletePost(data);
                        }}
                        onClose={() => {
                            setAddressView(false);
                        }}
                    />
                }
           
                <CenterWrapper>
                    <Logo 
                        src={require('../../styles/assets/logo_new.png')}
                    />
                    <Title>가입하기</Title>
                </CenterWrapper>
                <Gap 
                    gap={40}
                />
                {
                    !auth ?
                    <Kakao
                        onClick={authKakao}
                    >
                        <Icon 
                            src={require('../../styles/assets/icon/kakao.png')}
                        />
                        카카오톡으로 인증하기
                    </Kakao> :
                    <KKakao
                        onClick={authKakao}
                    >
                        재인증하기
                    </KKakao>
                }
                <Line/>      
                <Gap 
                    gap={40}
                /> 
                <RowWrapper>
                    <Label>상호명</Label>
                    <Gap 
                        gap={12}
                    />
                    <Input disabled={!auth}
                        onChange={onStoreNameChange}
                    />
                </RowWrapper>    
                <Gap 
                    gap={20}
                />
                <RowWrapper>
                    <Label>점주님성함</Label>
                    <Gap 
                        gap={12}
                    />
                    <Input disabled={!auth}
                        onChange={onRealNameChange}
                    />   
                </RowWrapper>
                <Gap 
                    gap={20}
                />
                <RowWrapper>
                    <Label>휴대폰번호</Label>
                    <Gap 
                        gap={12}
                    />
                    <Input disabled={!auth}
                        placeholder="알림톡에 사용됩니다. 정확하게 입력해주세요."
                        onChange={onPhoneChange}
                    />  
                </RowWrapper> 
                <Gap 
                    gap={20}
                />
                <RowWrapper>
                    <Label>상점대표번호</Label>
                    <Gap 
                        gap={12}
                    />
                    <Input disabled={!auth}
                        placeholder="상점정보에 노출되는 번호입니다."
                        onChange={onRepresentativeNumber}
                    />  
                </RowWrapper> 
                <Gap 
                    gap={20}
                />
                <RowWrapper>
                    <Label>주소</Label>
                    <Gap 
                        gap={12}
                    />
                    <Address 
                        onClick={() => {
                            if(auth)
                                setAddressView(true);
                        }}
                    >
                        {roadAddress}
                    </Address>                    
                    <Gap 
                        gap={5}
                    />
                    <Address
                        onClick={() => {
                            if(auth)
                                setAddressView(true);
                        }}
                    >
                        {jibunAddress}
                    </Address> 
                    <Gap 
                        gap={5}
                    />
                    <Input  disabled
                        placeholder="우편번호"
                        value={zoneCode}
                    />
                    <Gap 
                        gap={5}
                    />
                    <Input  disabled={!auth}
                        placeholder="상세주소"
                        value={detailAddress}
                        onChange={onDetailAddressChange}
                    />
                    
                </RowWrapper>
                <Gap 
                    gap={40}
                />
                <Wrapper>
                    <Text>가입을 위해<br/>정보 제공 및 이용약관에 동의합니다.</Text> 
                    <ButtonWrapper>
                        <TextButton
                            onClick={() => {
                                setViewPrivacy(true);
                            }}
                        >개인정보처리방침</TextButton>      
                        <TextButton
                            onClick={() => {
                                setViewTerms(true);
                            }}
                        >이용약관</TextButton> 
                   
                                          
                    </ButtonWrapper>
                    
                </Wrapper> 
                <Gap 
                    gap={40}
                />
                <PageButton 
                    text="가입하기"
                    background="#9C27B0"
                    color="#FFFFFF"
                    onClick={() => {
                        createUserAndStore(kakao_token as string);
                    }}
                />
            </Section>
        </Container>
    );
}

export default Contact;