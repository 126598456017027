import styled from "styled-components";

import { API } from "../../apis/BookingApi";
import {
    getLocalStorage,
    setLocalStorage
} from '../../utilities/localstorage';

import { FormEvent, useEffect, useState } from "react";
import { 
    useLocation, 
    useNavigate 
} from "react-router-dom";
import { CardButton, Gap } from "../../components/atoms";

var BASE_URL = process.env.REACT_APP_TARGET_URL;

const Container = styled.div`
    width: 100%;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Section = styled.div`
    max-width: 500px;
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;

`;

const Box = styled.div`
    width: 100%;
    height: 300px;
    background-color: #F3F5F8;
    display: flex;
    justify-content: center;
    flex-direction: column;;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
`;

const Input = styled.input`
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 5px;
    width: 100%;
    height: 48px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #000000;
    padding-left: 20px;
`;

const Image = styled.img`
    width: 200px;
`;


const TextButton =styled.span<{active: boolean}>`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: ${(props) => props.active ? '700' : '400'};
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.175px;
    color: #191F28;
    margin-left: 10px;
`;

function InfluencerLogin(){

    const navigate = useNavigate();
    const [ userName, setUserName ] = useState<string>('');
    const [ password, setPassword ] = useState<string>('');

    const onChangeUserName = (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value},
        } = event;

        setUserName(value);
    }

    const onChangePassword = (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value},
        } = event;
        
        setPassword(value);
    }

    const getToken = () => {

        if(userName === '')
        {
            alert("아이디를 입력해주세요.")
            return;
        }
        if(password === '')
        {
            alert("패스워드를 입력해주세요.")
            return;
        }

        let formData = new FormData();
        formData.append('grant_type', 'password');
        formData.append('username', userName);
        formData.append('password', password);
        API.defaults.withCredentials = true;

        API({
            method: 'post',
            url: BASE_URL + '/oauth/token',
            auth: { username: 'legitss', password: 'legitssPwd' },
            data: formData,

        }).then((response) => {
            setLocalStorage('in_access_token', response.data.access_token);
            navigate('/influencer');
            // 새로고침
        })
        .catch((err) => {
            // 잘못된 id, pw 입니다.
            alert("잘못된 ID, PASSWORD입니다.")
            setLocalStorage('in_access_token');
        })
    }

    useEffect(() => {
        
        const access_token = getLocalStorage('in_access_token');

        if(access_token)
        {
            navigate('/influencer')
        }
        
    }, [])

    return (
        <Container> 
            <Section>
                <Box>
                    <Image 
                        src={require('../../styles/assets/logo_new.png')}
                    />
                    <Gap 
                        gap={10}
                    />
                    <Input 
                        value={userName}
                        onChange={onChangeUserName}
                    />
                    <Gap 
                        gap={10}
                    />
                    <Input 
                        type="password"
                        value={password}
                        onChange={onChangePassword}
                    />
                    <Gap 
                        gap={10}
                    />
                    <CardButton 
                        background="#CEDDE2"
                        color="#000000"
                        text="로그인"
                        onClick={() => {
                            getToken();
                        }}
                    />
                </Box>
               
            </Section>
           
        </Container>
    );
}

export default InfluencerLogin;