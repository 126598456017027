// key를 qp로 전달 
import { useLocation, useNavigate } from "react-router-dom";
import qs from 'qs';
import Const from '../../constant';
import { API } from '../../apis/BookingApi'
import { useEffect } from "react";
import {
    KakaoTokenType
} from '../../types';

function Sign(){
    const location = useLocation();
    const navigate = useNavigate();

    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });

    const code = query.code;
    const state = query.state;

    const getKakaoToken = () => {
        API({
            method: 'post',
            params: {
                client_id: Const.REST_API_KEY,
                code,
                grant_type: 'authorization_code'
            },
            url: 'https://kauth.kakao.com/oauth/token',
            withCredentials: false

        }).then((response) => {
            const { access_token: kkoAccessToken }: KakaoTokenType = response.data;
            navigate(`${state}?kakao_token=${kkoAccessToken}`);
        }).catch((err) => {
            // console.log(err)
        })
    }

    useEffect(() => {
        if(code)
        {
            getKakaoToken();
        }
    }, [])

    return <></>;
}

export default Sign;