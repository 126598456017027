import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background: #F9FAFB;
    padding-top: 110px;
    padding-bottom: 10px;

    @media only screen and (max-width: 768px) {
        padding-top: 40px;
        padding-bottom: 10px;
    }
`;

const Text = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 52px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #333333;

    @media only screen and (max-width: 768px) {
        visibility: hidden;

    }
`;

const Text2 = styled.span`
    visibility: hidden;

    @media only screen and (max-width: 768px) {       
        font-family: 'Spoqa Han Sans Neo';
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 40px;
        text-align: center;
        letter-spacing: -0.5px;
        color: #333333;
        visibility: visible;
        margin-top: -200px;
    }
`;

const Image = styled.img`

`;

function Introduction(){
    return (
        <Container> 
            <Text>금요일11시는 <br/>점주님과 고객님 모두 설레는 시간을 만들어 가겠습니다.<br/>이제껏 경험 못 했던 예약 서비스로 우리의 일상은 새로워집니다.</Text>           
            <Text2>금요일11시는 <br/>점주님과 고객님 모두 <br/> 설레는 시간을 만들어 가겠습니다.<br/><br/>이제껏 경험 못 했던 <br/> 예약 서비스로 <br/> 우리의 일상은 새로워집니다.</Text2>           
            <Image src={require('../../../styles/assets/intro_image.png')} />
        </Container>
    );
}

export default Introduction;