import { useState } from "react";
import styled from "styled-components";
var BASE_URL = process.env.REACT_APP_TARGET_URL;

const Container = styled.div`
    width: 100%;
    height: 184px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 66px;
`;

const PhotoWrapper= styled.div`
    width: 120px;
    height: 120px;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Photo = styled.img`
    width: 120px;
    height: 120px;
    border-radius: 50%;
`;

const AltPhoto = styled.img`
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: #F2F2F2;
`;

const Icon = styled.img`
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
`;

const UploadButtonLabel = styled.label`
    position: absolute;
    right: 0;
    bottom: 0;
`;

const FileInput = styled.input`
    display: none;
`;

interface PhotoEditProps {
    fileType: string;
    id: number;
    onSaveFileChange: (file: any, type: string) => void;
}

export function PhotoEdit({
    fileType,
    id,
    onSaveFileChange
}:PhotoEditProps){

    const [ hash, setHash ] = useState(Date.now()); 
    const [ preview, setPreview ] = useState<any>(null);   
    
    const onChange1 = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const imgs = e.target.files;
        const formData = new FormData();
        if(!imgs) return;
        formData.append('file', imgs[0]);

        setPreview(URL.createObjectURL(imgs[0]));

        let type = '';

        if(imgs[0].type === 'image/png')
        {
            type = 'png';
        }
        else if(imgs[0].type === 'image/jpg')
        {
            type = 'jpg';
        }
        else if(imgs[0].type === 'image/jpeg')
        {
            type = 'jpg';
        }
        onSaveFileChange(imgs, type);
    }

    return (
        <Container>
            {fileType === '' || fileType === null || fileType === undefined ?
                <PhotoWrapper>
                    <AltPhoto src={preview} />
                    <UploadButtonLabel htmlFor="file">
                        <Icon src={require('../../styles/assets/icon/cam.png')} />
                    </UploadButtonLabel>
                    <FileInput type='file' 
                        accept='image/jpg,image/png,image/jpeg' 
                        name='profile_img' 
                        onChange={onChange1}
                        id="file"
                    ></FileInput>
                </PhotoWrapper>
                :
                <PhotoWrapper>
                    {
                        preview === null ? ( <Photo 
                            src={BASE_URL + `/v1/customer/photo/${id}/?${hash}`}
                        />):
                        <AltPhoto src={preview} />
                    }
                   
                    <UploadButtonLabel htmlFor="file2">
                        <Icon src={require('../../styles/assets/icon/cam.png')} />
                    </UploadButtonLabel>
                    <FileInput type='file' 
                        accept='image/jpg,image/png,image/jpeg' 
                        name='profile_img' 
                        onChange={onChange1}
                        id="file2"
                    ></FileInput>
                </PhotoWrapper>                
            }
        </Container>
    );
}