import styled from "styled-components";

import {
    PaymentRatioType
} from '../../types';

import {
    XBarGraphList
} from '../molecules';
import { 
    setComma 
} from "../../utilities/convert";

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-top: 32px;
    padding-bottom: 55px;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: -0.4375px;
    color: #7D93A6;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.5625px;
    color: #191F28;
`;

const Value = styled.span<{color: string}>`
    color: ${(props) => props.color};
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.2px;
    margin-left: 8px;
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;

const Gap = styled.div<{gap: number}>`
    height: ${(props) => props.gap}px;
`;

interface CardCashStatProps {
    data: PaymentRatioType
}

export function CardCashStat({
    data
}: CardCashStatProps){
    return (
        <Container>
            <Title>조회 기간 매출액</Title>
            <Gap 
                gap={32}
            />
           
            <Wrapper>
                <Label>카드 </Label><Value
                    color="#000000"
                >{setComma(data.cardAmount)}원</Value>
            </Wrapper>
            <Wrapper>                
                <Label>현금</Label> <Value
                    color="#000000"
                >{setComma(data.cashAmount)}원</Value>
            </Wrapper>
            <Wrapper>                
                <Label>선불금</Label> <Value
                    color="#000000"
                >{setComma(data.usePoint)}원</Value>
            </Wrapper>
            <Wrapper>                
                <Label>매출액</Label> <Value
                    color="#000000"
                >{setComma(data.revenue)}원</Value>
            </Wrapper>
            <Gap 
                gap={20}
            />
            {/* <XBarGraphList 
                datas={
                    ["0", "0"]
                }
            /> */}
        </Container>
    );
}