import styled from "styled-components";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background: #FFFFFF;
    padding-left: 5vw;
    padding-right: 5vw;
    width: 100%;
`;

const Title = styled.div`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    white-space: pre-line;
    word-wrap:break-all;
    padding-top: 15px;
    padding-bottom: 15px;
`;

const RowWrapper = styled.div`
    width: 100%;
    height: 78px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #CEDDE2;
`;

const Wrapper = styled.div`
    display: flex;
    width: 80%;
    justify-content: flex-start;
    align-items: center;
`;

const Icon = styled.img`
    width: 16px;
    height: 16px;
`;

const Label = styled.span`
    width: 100%;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.175px;
    color: #000000;
    margin-left: 10px;
`;

const Button = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    text-align: right;
    letter-spacing: -0.175px;
    color: #4B9DFF;
    cursor: pointer;
    width: 150px;
`;

const Tel = styled.a`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    text-align: right;
    letter-spacing: -0.175px;
    color: #4B9DFF;
    cursor: pointer;
`;

interface StoreDetailProps{
    introduction: string;
    address: string;
    phone: string;
    onMapClick: () => void;
    map: boolean
}

export function StoreDetail({
    introduction,
    address,
    phone,
    onMapClick,
    map
}: StoreDetailProps){
    return (
        <Container>
            <Title>{introduction}</Title>
            <RowWrapper>
                <Wrapper>
                    <Icon src={require('../../styles/assets/icon/map_icn.png')} />
                    <Label>
                        {address}
                    </Label>   
                </Wrapper>                           

                {
                    map ?
                    <Button
                        onClick={onMapClick}
                    >지도보기</Button>
                    :
                    <Button>
                            
                    </Button>
                }
            </RowWrapper>
            <RowWrapper>
                <Wrapper>
                    <Icon src={require('../../styles/assets/icon/tel_icn.png')} />
                    <Label>
                        {phone}
                    </Label>
                </Wrapper>
                
                <Tel
                    href={`tel:${phone}`}
                >전화하기</Tel>
            </RowWrapper>
            
        </Container>
    );
}