import { useEffect, useState } from "react";
import styled from "styled-components";

import { useRecoilValue } from 'recoil';

import { 
    storeState 
} from '../../stores';

import {
    BookingType
} from '../../types'

import {
    getWaitBookingPageRequest
} from '../../apis/BookingApi';

import {
    Gap
} from '../atoms';

import {
    RequestCard
} from '../molecules';

import {
    BookingRequestCheckModal
} from '../modals';
import { CardButton, PageButton } from "../atoms";

const Container = styled.div`
    width: 100%;
    padding-left: 5vw;
    padding-right: 5vw;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const RequestListWrapper = styled.div`
    width: 100%;
    height: calc(100vh - 250px);
    overflow-y: scroll;
    margin-top: 40px;
`;

const Booking = styled.div`
    width: 100%;
    height: 60px;
    border: 1px solid #560C7B;
    color: #000000;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;  
    letter-spacing: -0.25px;
    color: #000000;
`;

const TextButton =styled.span<{active: boolean}>`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: ${(props) => props.active ? '700' : '400'};
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.175px;
    color: #191F28;
    margin-left: 10px;
`;

const CenterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;  
`;

const More = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.175px;
    color: #9C27B0;
    cursor: pointer;
`;

const Icon = styled.img`
    width: 16px;
    margin-right: 8px;
`;

const Text = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.175px;
    color: #9C27B0;
`;

export function BookingRequestList() {
    const store = useRecoilValue(storeState);
    const [ list, setList ] = useState<BookingType[]>([]);
    const [ viewBookingRequestCheckModal, setViewBookingRequestCheckModal ] = useState(false);
    const [ selectedBookingInfo, setSelectedBookingInfo ] = useState<BookingType>();
    const [ state, setState ] = useState(1);
    const [ curPage, setCurPage ] = useState(1);
    const [ totalPage, setTotalPage ] = useState(0);

    const sendQuery = async (state: number, page: number) => {
        try{        
            const res = await getWaitBookingPageRequest(store.id, page - 1, 5, 'id', state);
            if(page === 0)
            {
                setList([...res.data.content]);
            }
            else
            {
                setList((prev) => [...prev, ...res.data.content]);
            }
            
            // setPage(prev => prev + 1);
            setTotalPage(res.data.totalPages);
            // alert(res.data.totalPages + " " + page)
            // alert(JSON.stringify(res))
            // if(res.data.content.length > 0)
            // {
                
            // }            
        }
        catch(err) {
        }
        
    }
    
    useEffect(() => {
        setList([]);
        setCurPage(1);
        sendQuery(state, 1);
    }, [state])

    return (
        <Container>
            {
                viewBookingRequestCheckModal &&
                <BookingRequestCheckModal 
                    onClose={() => {
                        setSelectedBookingInfo(undefined);
                        setViewBookingRequestCheckModal(false);
                    }}
                    onConfirm={() => {
                        setSelectedBookingInfo(undefined);
                        setViewBookingRequestCheckModal(false);
                        setList([]);
                        setCurPage(1);
                        sendQuery(state, 1);
                    }}
                    bookingInfo={selectedBookingInfo}
                />
            }
            <RowWrapper>
                <Title>예약리스트</Title>
                <div>
                    <TextButton
                        active={state===1 ? true : false}
                        onClick={() => {      
                            setState(1);
                        }}
                    >
                        미확인보기
                    </TextButton>

                    <TextButton
                        active={state===0 ? true : false}
                        onClick={() => {  
                            setState(0);  
                        }}
                    >
                        모두보기
                    </TextButton>

                </div>                
            </RowWrapper>
            {/* 리스트 */}
            
            <RequestListWrapper>
                {
                    list.length > 0 ?
                    (
                        list.map((item, i) => {
                            return (
                                <RequestCard 
                                    key={i}
                                    bookingInfo={item}
                                    onView={()=>{
                                        setSelectedBookingInfo(item);
                                        setViewBookingRequestCheckModal(true);
                                    }}
                                />
                            );
                        })          
                    )
                    
                    :
                    (
                        <Text>내역이 없습니다.</Text>
                    )
                }  
                
                 <Gap 
                    gap={20}
                />
                {
                    totalPage > curPage &&
                    <CenterWrapper>
                        <More 
                            onClick={() => {
                                let npage = curPage + 1; 
                                setCurPage(npage);
                                sendQuery(state, npage);
                            }}
                        >
                            <Icon 
                                src={require('../../styles/assets/icon/down_arrow.png')}
                            />
                            더보기
                        </More>
                    </CenterWrapper>      
                }
                       
            </RequestListWrapper>
           
        </Container>
    );
}