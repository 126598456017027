import { useEffect } from 'react';
import styled from 'styled-components';

import {
    StoreType,
    DowScheduleType
} from '../../types';

import {
    PageButton
} from '../atoms';

import {
    StoreImageGrid,
    StoreDetail
} from '../molecules';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`;

const TouchableSection = styled.div`
    width: 100%;
    height: calc(100vh - 599px);
    cursor: pointer;
`;

const Section = styled.div`
    width: 100%;
    height: 629px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
    overflow-y: auto;
    color: #000000;
    padding: 21px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 100;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.208333px;
    color: #000000;
    margin-left: 20px;
    margin-bottom: 19px;
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5vw;
    padding-right: 5vw;
`;

interface StoreInforModalProps {
    storeInfo: StoreType;
    scheduleList: DowScheduleType [];
    onClose: () => void;
}

export function StoreInfoModal({
    storeInfo,
    scheduleList,
    onClose
}: StoreInforModalProps){

    useEffect(() => {
        document.body.style.overflow = "hidden";
        
        return () => {
            document.body.style.overflow = "unset";
        }
    }, [])

    return (
        <Container>
            <TouchableSection 
                onClick={onClose}
            />
            <Section>                
                <Title>{storeInfo.name}</Title>
                <StoreImageGrid 
                    banner1={storeInfo.banner1}
                    banner2={storeInfo.banner2}
                    banner3={storeInfo.banner3}
                    banner4={storeInfo.banner4}
                    banner5={storeInfo.banner5}
                    storeId={storeInfo.id}
                />
                <StoreDetail 
                    map={false}
                    introduction={storeInfo.introduction}
                    address={storeInfo.jibunAddress}
                    phone={storeInfo.representativeNumber}
                    onMapClick={() => {
                        
                    }}
                />
                <Wrapper>
                    <PageButton
                        text='닫기'
                        background='#FFFFFF'
                        color='#000000'
                        onClick={onClose}
                    />
                </Wrapper>
            </Section>
        </Container>
    );
}