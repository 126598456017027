import { FormEvent, useState } from "react";
import styled from "styled-components";

import { 
    storeState 
} from '../../../../stores';

import { useRecoilValue } from "recoil";

import {
    getAdminBookingTimeCheckRequest,
    getAdminBookingTimeCheckManualRequest,
    checkUpdateBookingValidationRequest
} from '../../../../apis/BookingApi';

import {
    PageButton,
    Gap
} from '../../../atoms';

import {
    SettingTime
} from '../../../molecules';

import {
    leadingZeros
} from '../../../../utilities/convert';

import {
    AlertModal,
    ConfrimCheckModal
} from '../../../modals';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0,0,0,0.7);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`;

const TouchableSection = styled.div`
    width: 100%;
    height: calc(100vh - 341px);
    cursor: pointer;
`;

const Section = styled.div`
    width: 100%;
    height: 341px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
    overflow: auto;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1000;
`;

const Title = styled.div`
    min-height: 60px;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #CEDDE2;
    padding-left: 5vw;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: #191F28;
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 20px 5vw;
`;

const CenterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const InfoWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
`;

const Info = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.25px;
    color: #9C27B0;
`;

const InfoIcon = styled.img`
    width: 16px;
    margin-right: 8px;
`;

const Input = styled.input`
    width: 100%;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #7D93A6;
    padding-left: 20px;
`;

const MinuteWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
`;

const MinuteText = styled.span`
    position: absolute;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #000000;
    right: 20px;
`;

interface OkBookingModalProps {
    onOk: (start: string, duration: number) => void;
    onClose: () => void;
    menuDuration: number;
    title: string;
    buttonText: string;
    bookingId: number;
    requestDate: string;
    isAdd: boolean;
}

// update는 본인 예약 시간을 확인할 필요없음 (bookingId 있음)
// add 
// 예약 수락은 본인 예약 시간을 확인함 (bookingId 있음)
// 관리자 예약 등록은 본인 예약 시간을 확인할 필요없음(bookingId 없음)

export function OkBookingModal({
    onOk,
    onClose,
    menuDuration,
    title,
    buttonText,
    bookingId,
    requestDate,
    isAdd
}: OkBookingModalProps){
    const store = useRecoilValue(storeState);
    const [ startTime, setStartTime ] = useState<string>('');
    const [ minute, setMinute ] = useState(menuDuration);

    const [ isAlert, setIsAlert ] = useState(false);
    const [ alertTitle, setAlertTitle ] = useState<string>('');
    const [ alertDescription, setAlertDescription ] = useState<string>('');

    const [ viewConfirmModal, setViewConfirmModal ] = useState(false);
    const [ tempTime, setTempTime ] = useState<string>('');

    const [ viewConfirmDurationModal, setViewConfirmDurationModal ] = useState(false);
    const [ tempDuration, setTempDuration ] = useState<number>(-1);

    const onMinuteChange = (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value},
        } = event;

        if(startTime != '')
        {
            validBookingDurationCheck(Number(value))
        }
        else
        {
            setMinute(Number(value))
        }
    }

    const onSubmit = () => {
        if(minute === null || minute === undefined)
        {

        }
        else if(minute === 0)
        {

        }
        else
        {
            if(startTime !== '')
            {
                onOk(startTime, minute);
            }
        }
    }

    function deleteNumberZero(str: string)
    {
        return str.replace(/(^0+)/, "");
    }

    const validBookingTimeCheck = async (time: string) => {
        if(isAdd)
        {
            if(bookingId !== 0)
            {         
                const res = await getAdminBookingTimeCheckRequest(bookingId, time+":00", minute);
                if(res.status === 'success')
                {
                    if(res.data as number === 0)
                    {
                        setStartTime(time);
                    }
                    else if(res.data as number === 1)
                    {
                        setAlertTitle('예약 요청 수락 시간 수정');
                        setAlertDescription('고객님이 예약을 요청한 시간이 아닙니다.');
                        setIsAlert(true);
                        
                    }
                    else if(res.data as number === 2)
                    {
                        setTempTime(time);
                        setViewConfirmModal(true);
                    }
                    else
                    {
                        setAlertTitle('예약이 없습니다.');
                        setAlertDescription('고객센터에 문의해주세요.');
                        setIsAlert(true);
                    }
                }
                else
                {
                    // 고객센터에 문의해 주세요
                    setAlertTitle('고객센터에 문의해 주세요');
                    setAlertDescription('');
                    setIsAlert(true);
                }
            }
            else
            {
                const res = await getAdminBookingTimeCheckManualRequest(store.id, requestDate, time+":00", minute);
                if(res.status === 'success')
                {
                    if(res.data as number === 0)
                    {
                        setStartTime(time);
                    }
                    else if(res.data as number === 1)
                    {
                        setAlertTitle('예약 요청 수락 시간 수정');
                        setAlertDescription('고객님이 예약을 요청한 시간이 아닙니다.');
                        setIsAlert(true);
                        
                    }
                    else if(res.data as number === 2)
                    {
                        setTempTime(time);
                        setViewConfirmModal(true);
                    }
                    else
                    {
                        setAlertTitle('예약이 없습니다.');
                        setAlertDescription('고객센터에 문의해주세요.');
                        setIsAlert(true);
                    }
                }
                else
                {
                    // 고객센터에 문의해 주세요
                    setAlertTitle('고객센터에 문의해 주세요');
                    setAlertDescription('');
                    setIsAlert(true);
                }
            }
        } 
        else // update
        {
            const res = await checkUpdateBookingValidationRequest(store.id, bookingId, requestDate, time+":00", minute);
            if(res.status === 'success')
            {
                if(res.data as number === 0)
                {
                    setStartTime(time);
                }
                else if(res.data as number === 1)
                {
                    setAlertTitle('예약 요청 수락 시간 수정');
                    setAlertDescription('고객님이 예약을 요청한 시간이 아닙니다.');
                    setIsAlert(true);
                    
                }
                else if(res.data as number === 2)
                {
                    setTempTime(time);
                    setViewConfirmModal(true);
                }
                else
                {
                    setAlertTitle('예약이 없습니다.');
                    setAlertDescription('고객센터에 문의해주세요.');
                    setIsAlert(true);
                }
            }
            else
            {
                // 고객센터에 문의해 주세요
                setAlertTitle('고객센터에 문의해 주세요');
                setAlertDescription('');
                setIsAlert(true);
            }
        }
        
    }

    const validBookingDurationCheck = async (duration: number) => {
        if(isAdd)
        {
            if(bookingId !== 0)
            {
                // 0 이면 체크 불필요 
                const res = await getAdminBookingTimeCheckRequest(bookingId, startTime+":00", duration);
                if(res.status === 'success')
                {
                    if(res.data as number === 0)
                    {
                        setMinute(duration)
                    }
                    else if(res.data as number === 1)
                    {
                        setAlertTitle('예약 요청 수락 시간 수정');
                        setAlertDescription('고객님이 예약을 요청한 시간이 아닙니다.');
                        setIsAlert(true);                    
                    }
                    else if(res.data as number === 2)
                    {
                        setTempDuration(duration);
                        setViewConfirmDurationModal(true);
                        // setViewConfirmModal(true);
                    }
                    else
                    {
                        setAlertTitle('예약이 없습니다.');
                        setAlertDescription('고객센터에 문의해주세요.');
                        setIsAlert(true);
                    }
                }
                else
                {
                    // 고객센터에 문의해 주세요
                    setAlertTitle('고객센터에 문의해 주세요');
                    setAlertDescription('');
                    setIsAlert(true);
                }
            }
            else
            {
                const res = await getAdminBookingTimeCheckManualRequest(store.id, requestDate, startTime+":00", duration);
                if(res.status === 'success')
                {
                    if(res.data as number === 0)
                    {
                        setMinute(duration)
                    }
                    else if(res.data as number === 1)
                    {
                        setAlertTitle('예약 요청 수락 시간 수정');
                        setAlertDescription('고객님이 예약을 요청한 시간이 아닙니다.');
                        setIsAlert(true);                    
                    }
                    else if(res.data as number === 2)
                    {
                        setTempDuration(duration);
                        setViewConfirmDurationModal(true);
                        // setViewConfirmModal(true);
                    }
                    else
                    {
                        setAlertTitle('예약이 없습니다.');
                        setAlertDescription('고객센터에 문의해주세요.');
                        setIsAlert(true);
                    }
                }
                else
                {
                    // 고객센터에 문의해 주세요
                    setAlertTitle('고객센터에 문의해 주세요');
                    setAlertDescription('');
                    setIsAlert(true);
                }
            }
        } 
        else  // update
        {
            const res = await checkUpdateBookingValidationRequest(store.id, bookingId, requestDate, startTime+":00", duration);
            if(res.status === 'success')
            {
                if(res.data as number === 0)
                {
                    setMinute(duration)
                }
                else if(res.data as number === 1)
                {
                    setAlertTitle('예약 요청 수락 시간 수정');
                    setAlertDescription('고객님이 예약을 요청한 시간이 아닙니다.');
                    setIsAlert(true);
                    
                }
                else if(res.data as number === 2)
                {
                    setTempDuration(duration);
                    setViewConfirmDurationModal(true);
                }
                else
                {
                    setAlertTitle('예약이 없습니다.');
                    setAlertDescription('고객센터에 문의해주세요.');
                    setIsAlert(true);
                }
            }
            else
            {
                // 고객센터에 문의해 주세요
                setAlertTitle('고객센터에 문의해 주세요');
                setAlertDescription('');
                setIsAlert(true);
            }
       
        }
    }

    return(
        <Container>
            {
                viewConfirmDurationModal &&
                <ConfrimCheckModal 
                    title="시간이 겹치는 예약이 있습니다."
                    description="그래도 예약을 하시겠습니까?"
                    onCheck={() => {
                        setMinute(tempDuration)
                        setTempDuration(-1)
                        setViewConfirmDurationModal(false);
                    }}
                    onCancel={() => {
                        setTempDuration(-1);
                        setViewConfirmDurationModal(false);
                    }}
                />
            }
            {
                viewConfirmModal &&
                <ConfrimCheckModal 
                    title="시간이 겹치는 예약이 있습니다."
                    description="그래도 예약을 하시겠습니까?"
                    onCheck={() => {
                        setStartTime(tempTime);
                        setTempTime('');
                        setViewConfirmModal(false);
                    }}
                    onCancel={() => {
                        setTempTime('');
                        setViewConfirmModal(false);
                    }}
                />
            }
            {
                isAlert && 
                <AlertModal 
                    title={alertTitle}
                    description={alertDescription}
                    onCheck={() => {
                        setAlertTitle('');
                        setAlertDescription('');
                        setIsAlert(false);
                    }}
                />
            }
            <TouchableSection
                onClick={onClose}
            />
            <Section className="request">
                <Title>{title}</Title>
                <Wrapper>
                    <InfoWrapper>
                        <InfoIcon 
                            src={require('../../../../styles/assets/icon/info.png')}
                        />
                        <Info>예약시간을 입력해주세요</Info>
                    </InfoWrapper>
                    <Gap 
                        gap={30}
                    />
                    <SettingTime 
                        onSettingTime={(hour, minute) => {
                            //validation time 
                            validBookingTimeCheck(leadingZeros(hour.toString(), 2) + ":" + leadingZeros(minute.toString(), 2))
                        }}
                        time={startTime}
                        placeHolder="시작시간"
                        timeRange={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']} 
                    />
                    <Gap 
                        gap={12}
                    />
                    <MinuteWrapper>
                        <Input 
                            type="number" pattern="\d*"
                            placeholder="소요시간 (분)"     
                            value={deleteNumberZero(minute.toString())}          
                            onChange={onMinuteChange}         
                        />
                        <MinuteText>분</MinuteText>
                    </MinuteWrapper>
                    
                    <Gap 
                        gap={30}
                    />
                    <PageButton 
                        text={buttonText}
                        background="#9C27B0"
                        color="#FFFFFF"
                        onClick={onSubmit}
                    />
                </Wrapper>
            </Section>
        </Container>
    );
}