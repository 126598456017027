// 전화번호 검증

// 숫자 검증

export function checkNumber(str: string)
{
    let check = /^[0-9]+$/; 
    if (!check.test(str)) {
        return false;
    }
    else 
    {
        return true;
    }
}

// 문자 검증 
export function checkChar(str: string) {
    

}

// 한글 검증
export function hangulCheck(str: string){
    const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
    return korean.test(str);
}

// phone check
export function phoneCheck(str: string){
    const regPhone = /^01([0|1|6|7|8|9])([0-9]{4})([0-9]{4})$/;
    return regPhone.test(str);
}