export function leadingZeros(n:string, digits:number){
    var zero = '';
    n = n.toString();
    if (n.length < digits) {
        for (var i = 0; i < digits - n.length; i++)
            zero += 0;
    }
    return zero + n;
}

export function setComma (num: number)
{
    var regexp = /\B(?=(\d{3})+(?!\d))/g;
    return num.toString().replace(regexp, ',')
}

export function convertTimestampToDate(timestamp: string){  
    var add = new Date(timestamp).getTime();    
    var date = new Date(add);
    var month = date.getMonth() + 1;
   
    return date.getFullYear() + "년 " + leadingZeros(month.toString(), 2) + "월 " + leadingZeros(date.getDate().toString(), 2) + "일 ";
}

export function convertTimestampToTime(timestamp: string){  
    var add = new Date(timestamp).getTime();    
    var date = new Date(add);
    var month = date.getMonth() + 1;
    var text = '오전';
    var hour = date.getHours();
    if(date.getHours() >= 12)
    {
        text = '오후'

        if(hour != 12)
        {
            hour = hour - 12;
        }
    }
    return text + " " +
        leadingZeros(hour.toString(), 2) + "시 " + leadingZeros(date.getMinutes().toString(), 2) + "분";
}

export function convertTimestampToHangulTime(timestamp: string, addMinute: number){  
    var add = new Date(timestamp).getTime() + (addMinute * 60 * 1000);    
    var date = new Date(add);
    var month = date.getMonth() + 1;
    var text = '오전';
    var hour = date.getHours();
    if(date.getHours() >= 12)
    {
        text = '오후'

        if(hour != 12)
        {
            hour = hour - 12;
        }
    }
    return date.getFullYear() + "년 " + leadingZeros(month.toString(), 2) + "월 " + leadingZeros(date.getDate().toString(), 2) + "일 " + text + " " +
        leadingZeros(hour.toString(), 2) + "시 " + leadingZeros(date.getMinutes().toString(), 2) + "분";
}


export function convertTimestampToHangulTime2(timestamp: string){  
    var add = new Date(timestamp).getTime();    
    var date = new Date(add);
    var month = date.getMonth() + 1;
    var text = '오전';
    var hour = date.getHours();
    if(date.getHours() >= 12)
    {
        text = '오후'

        if(hour != 12)
        {
            hour = hour - 12;
        }
    }
    return leadingZeros(month.toString(), 2) + "." + leadingZeros(date.getDate().toString(), 2) + "";
}

export function viewTextArea(text: string)
{
    return text.replaceAll("<br>", "\r\n");
}

export function viewLeftZeroRemove(zero: number)
{
    var vVal = zero.toString();

    if (vVal.length > 1) {
        vVal = vVal.replace(/(^0+)/, "");

        if (vVal.length == 0) {
            vVal = '0';
        }
    } 

    return vVal;
}