// 점주 예약 취소 5
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import confirmAnimationData from '../../styles/lotties/51936-sad-tear.json';

import {
    convertTimestampToDate,
    convertTimestampToTime
} from '../../utilities/convert';

import { 
    UserBookingInfoType 
} from "../../types";

import {
    Gap
} from '../atoms';

import {
    InfoCard
} from '../molecules'

import {
    StoreInfoModal,
} from '../modals';

const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 41px 16px;
    display: flex;
    flex-direction: column;
`;

const Logo = styled.img`
    width: 120px;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.25px;
    color: #000000;
    margin-top: 20px;
`;

const ImageWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
`;

const Image = styled.img`
    width: 150px;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 28px;
`;

const RowWrapperSpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 18px;
`;

const RowWrapperCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 22px;
`;

const ColWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
`;

const Label = styled.span`
   font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.5625px;
    color: #000000;
`;

const Text = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.2px;
    color: #FF4A8B;
    margin-top: 12px;
    white-space: pre-line;
    word-wrap:break-all;
`;

const InfoIcon = styled.img`
    width: 16px;
    margin-left: 5px;
    cursor: pointer;
`;

const Button = styled.div`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    text-align: center;
    text-decoration-line: underline;
    color: #560C7B;
`;

interface UserAdminCancelComponentProps{
    cancelInfo: UserBookingInfoType;
}

export function UserAdminCancelComponent({
    cancelInfo
}: UserAdminCancelComponentProps){
    const [storeView, setStoreView] = useState(false);
    
    return (
        <Container>        
            {
                storeView && <StoreInfoModal 
                    storeInfo={cancelInfo.storeInfo}
                    scheduleList={cancelInfo.scheduleList}
                    onClose={() => {
                        setStoreView(false);
                    }}
                />
            }
            <Logo 
                src={require('../../styles/assets/logo_new.png')}
            />  
            <Gap 
                gap={20}
            />      
            <Title>{cancelInfo.storeInfo.name}에서 <br/>예약을 취소하였습니다.</Title>   
            <ImageWrapper>
                <Image 
                    src={require('../../styles/assets/user_cancel.png')}
                />
            </ImageWrapper>   
            <ColWrapper>
                <Label>취소사유</Label>
                <Text>{cancelInfo.bookingInfo.rejectMessage}</Text>
            </ColWrapper>
            <RowWrapper>
                <Label>{cancelInfo.storeInfo.name}</Label>
                <InfoIcon src={require('../../styles/assets/info.png')} 
                    onClick={()=>{
                        setStoreView(true);
                    }}
                />
            </RowWrapper>            
            <InfoCard 
                infos={[
                    {key: '예약 요청일', value: `${convertTimestampToDate(cancelInfo.bookingInfo.requestDate)}`},
                    {key: '예약 확정시간', value: `${convertTimestampToTime(cancelInfo.bookingInfo.time)}`},
                    {key: '예약자 이름', value: `${cancelInfo.bookingInfo.userName}`},
                    {key: '예약자 휴대폰번호', value: `${cancelInfo.bookingInfo.userPhone}`},
                    {key: '요청서비스', value: `${cancelInfo.bookingInfo.menuName}`}                    
                ]}            
            />
            {/* <RowWrapperCenter>
                <Button>다시 예약하기</Button>
            </RowWrapperCenter> */}
        </Container>
    );
}