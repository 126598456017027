import styled from "styled-components";
import { Gap } from "../../../../atoms";

const Container = styled.div`
    width: 100%;
    border-radius: 16px;
    height: 108px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #F4F7F8;
    padding-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 20px;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #7D93A6;
`;

const Text = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #000000;
`;

interface SelectedTimeProps {
    startTime: string;
    duration: number;
}

export function SelectedTime({
    startTime,
    duration
}: SelectedTimeProps){
    return (
        <>
            {
                startTime !== '' &&
                <Container>
                    <Wrapper>
                        <Label>시작시간</Label>
                        <Gap 
                            gap={20}
                        />
                        <Label>소요시간</Label>
                    </Wrapper>
                    
                    <Wrapper>
                        <Text>{startTime}</Text>
                        <Gap 
                            gap={20}
                        />
                        <Text>{duration}분</Text>
                    </Wrapper>
                </Container>
            }
        </>
    );
}