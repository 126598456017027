import styled from "styled-components";
import { 
    BookingStatType 
} from "../../types";

import {
    YBarGraphList,
    LabelGraphList
} from '../molecules';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-top: 50px;
`;

const Gap = styled.div`
    height: 20px;
`;

interface BookingStatProps {
    data: BookingStatType    
}

export function BookingStat({
    data
}: BookingStatProps){
    return (
        <Container>
            <LabelGraphList 
                data={data}
            />
            <Gap />
            <YBarGraphList 
                data={data}
            />
        </Container>
    );
}