// store 정보 
// 사용자 정보 

import {
    atom
} from 'recoil';

import {
    StoreType,
    DowScheduleType,
    BlockDayType,
    MenuType,
    EtcInformationType,
    InfluencerType
} from '../types'

export const storeState = atom<StoreType>({
    key: 'storeState',
    default: {
        id: 0,
        userId: 0,
        storeUrlName: '',
        name: '',
        roadAddress: '',
        jibunAddress: '',
        zoneCode: '',
        detailAddress: '',
        phone: '',
        representativeNumber: '',
        availableHoliday: false,
        createdAt: '',
        updatedAt: '',
        availableCancelMinute: 0,
        autoCancelMinute: 0,
        rejectMessage: '',
        introduction: '',
        banner1: '',
        banner2: '',
        banner3: '',
        banner4: '',
        banner5: '',
        linkLabel: '',
        link: '',
        photoUploadDescription: '',
        photoUpload: false
    }
})

export const influencerState = atom<InfluencerType>({
    key: 'influencerState',
    default: {
        id: 0,
        name: '',
        phone: '',
        userId: 0,
        createdAt: '',
        updatedAt: '',
        deletedAt: ''
    }
})

export const scheduleState = atom<DowScheduleType[]>({
    key: 'scheduleState',
    default: []
})

export const blockScheduleState = atom<BlockDayType[]>({
    key: 'blockScheduleState',
    default: []
})

export const menuState = atom<MenuType[]>({
    key: 'menuState',
    default: []
})

export const etcInfoState = atom<EtcInformationType[]>({
    key: 'etcInfoState',
    default: []
})

export const requestState = atom<number>({
    key: 'requestState',
    default: 0
})