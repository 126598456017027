import styled from "styled-components";

const BarContainer  = styled.div`
    width: 100%;
    height: 70px;
    background-color: #ffffff;
    color: #000000;
    position: fixed;
    bottom: 0;
    left: 0;
    display: grid;
    justify-content: space-around;
    align-items: center;
    grid-template-columns: 50px 50px 50px 50px 50px;
    gap: 10px;
    padding: 0px;
    border-top: 0.1px solid #BEBEBE;
`;

const Button = styled.span<{active: boolean}>`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    letter-spacing: -0.15px;
    cursor: pointer;
    color: ${(props) => props.active ? '#9C27B0' : '#7D93A6'};
    margin-top: 6px;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
`;

const Image = styled.img`
    width: 20px;
`;

interface NavBarProps {
    onClick: (path:number) => void;
    active: number
}

export function NavBar({
    onClick,
    active
}: NavBarProps){
    return (
        <BarContainer>
            <RowWrapper
                onClick={() => {onClick(0)}}
            >
                {
                    active === 0 ? <Image src={require('../../styles/assets/icon/today_active.png')}/>
                    : <Image src={require('../../styles/assets/icon/today_inactive.png')}/>
                }
                
                <Button active={active === 0 ? true : false}>오늘</Button>                
            </RowWrapper>
            <RowWrapper
                onClick={() => {onClick(1)}}
            >
                {
                    active === 1 ? <Image src={require('../../styles/assets/icon/booking_active.png')}/>
                    : <Image src={require('../../styles/assets/icon/booking_inactive.png')}/>
                }
                
                <Button active={active === 1 ? true : false}>예약</Button>                
            </RowWrapper>
            <RowWrapper
                onClick={() => {onClick(2)}}
            >
                {
                    active === 2 ? <Image src={require('../../styles/assets/icon/list_active.png')}/>
                    : <Image src={require('../../styles/assets/icon/list_inactive.png')}/>
                }
                
                <Button active={active === 2 ? true : false}>내역</Button>                
            </RowWrapper>
            <RowWrapper
                onClick={() => {onClick(3)}}
            >
                {
                    active === 3 ? <Image src={require('../../styles/assets/icon/user_active.png')}/>
                    : <Image src={require('../../styles/assets/icon/user_inactive.png')}/>
                }
                
                <Button active={active === 3 ? true : false}>고객</Button>                
            </RowWrapper>
            <RowWrapper
                onClick={() => {onClick(4)}}
            >
                {
                    active === 4 ? <Image src={require('../../styles/assets/icon/setting_active.png')}/>
                    : <Image src={require('../../styles/assets/icon/setting_inactive.png')}/>
                }                
                <Button active={active === 4 ? true : false}>설정</Button>
            </RowWrapper>          
        </BarContainer>
    );
}
