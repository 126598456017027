import styled from "styled-components";
import { leadingZeros } from "../../../utilities/convert";

const Bar = styled.div`
    width: 100%;
    height: 56px;
    background-color: #FFFFFF;
    /* background-color: #ECE8FE; */
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5vw;
    z-index: 10;

`;

const Logo = styled.img`
    width: 120px;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CalendarButton = styled.img`
    width: 20px;
    margin-left: 10px;
    margin-top: -3px;
    cursor: pointer;
`;

const Button = styled.span`
    color: ${(props) => props.theme.textColor};
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    cursor: pointer;
    color: #FFFFFF;
`;

const RowWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    justify-content: center;
    align-items: center;
`;

const Date = styled.span`
    color: #191F28;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
`;

interface HeaderProps {
    onCalender: () => void;
    year: number;
    month: number;
    date: number;
}

function Header({
    onCalender,
    year,
    month,
    date
}:HeaderProps) {
    return (
        <Bar>
            <Logo src={require('../../../styles/assets/logo_new.png')} />
            <Wrapper>
                <Date
                    onClick={onCalender}
                >{leadingZeros(year.toString(), 2)+'. '+leadingZeros(month.toString(), 2) + ". " + leadingZeros(date.toString(), 2) + "."}</Date>
                <CalendarButton
                    src={require(`../../../styles/assets/calendar_btn.png`)} 
                    onClick={onCalender}
                />
            </Wrapper>
        </Bar>
    );
}

export default Header;