import styled from "styled-components";

const Bar = styled.div<{width: string; background: string}>`
    width: ${(props) => props.width}%;
    height: 36px;
    background: ${(props) => props.background};
`;

interface XBarProps {
    value: string;
    backgournd: string;
}

export function XBar({
    value,
    backgournd
}: XBarProps){
    return <Bar 
        width={value.toString()}
        background={backgournd}
    />;
}