// loading 
import styled from "styled-components";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useState, CSSProperties } from "react";

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "#D05CE3",
  };

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0,0,0,0.7);
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export function Loading(){
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#D05CE3");

    return (
        <Container>
             <div className="sweet-loading">
                <PropagateLoader
                    color={color}
                    loading={loading}
                    cssOverride={override}
                    size={15}
                    speedMultiplier={1}
                />
            </div>
        </Container>
       
    );
}