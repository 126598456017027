import styled from "styled-components";
import { FormEvent, useState } from "react";

import {
    BookingType
} from '../../types';

import {
    completeBookingRequest,
    noshowBookingRequest,
    adminCancelRequest
} from '../../apis/BookingApi';

import {
    CancelModal
} from '../../components/modals';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`;


const TouchableSection = styled.div`
    width: 100%;
    height: 20%;
    cursor: pointer;
`;

const Section = styled.div`
    width: 100%;
    height: 80%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
    overflow: auto;
    color: #000000;
    padding: 34px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 100;
`;

const CloseButton = styled.img`
    position: absolute;
    top: 20px;
    right: 15px;
    cursor: pointer;
`;

const Input = styled.input`

`;

const Button = styled.div`


`;

interface ConfirmedBookingInfoModalProps {
    onClose: () => void;
    onChange: () => void;
    bookingInfo: BookingType;
    cancelMessage: string;
}

export function ConfirmedBookingInfoModal({
    onClose,
    bookingInfo,
    onChange,
    cancelMessage
}: ConfirmedBookingInfoModalProps){
    const [ cancelModalView, setCancelModalView ] = useState(false);

    const completeBooking = async () => {
        const res = await completeBookingRequest(bookingInfo.bookingId);
        onChange();
    }

    const noshowBooking = async () => {
        const res = await noshowBookingRequest(bookingInfo.bookingId);
        onChange();
    }

    const cancelBooking = async () => {
        const res = await adminCancelRequest(bookingInfo.bookingId, cancelMessage);
        onChange();
    }

    // const onRejectMessageChange = (event: FormEvent<HTMLInputElement>) => {
    //     const {
    //         currentTarget: {value},
    //     } = event;  

    //     setMessage(value);
    // }

    console.log(JSON.stringify(bookingInfo))

    return (
        <Container>
            {
                cancelModalView &&
                <CancelModal 
                    title="예약취소"
                    placeHolder="취소사유를 입력해주세요."
                    message={cancelMessage}
                    onSubmit={() => {
                        setCancelModalView(false);
                    }}
                    onClose={() => {
                        setCancelModalView(false);
                    }}
                />
            }
            
            <TouchableSection 
                onClick={()=> {
                    onClose()
                }}
            />
            <Section>
                <CloseButton src={require('../../styles/assets/btn_close_normal.png')}
                    onClick={() => {
                        onClose();
                    }}
                />
                {bookingInfo.userName}
                {bookingInfo.state}

                <Button
                    onClick={completeBooking}
                >완료</Button>
                <Button
                    onClick={noshowBooking}
                >노쇼</Button>

                <Button
                    onClick={() => {
                        //cancelBooking()
                        setCancelModalView(true);
                    }}
                >예약취소</Button>
            </Section>
        </Container>
    );
}