import { useState } from "react";
import styled from "styled-components";

import {
    EtcInfoSettingModal,
    EtcInfoListModal
} from '../../../modals';

const Container = styled.div`
    width: 100%;
    padding: 20px 5vw 20px 5vw;
`;

const RowWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.2px;
    color: #000000;
`;

const Arrow = styled.img`
    margin-top: -2px;
    width: 6px;
`;

interface EtcInfoSettingProps {
    storeId: number;
}

export function EtcInfoSetting({
    storeId
}:EtcInfoSettingProps){

    const [ isSetting, setIsSetting ] = useState(false);

    return (
        <Container>
            <RowWrapper
                onClick={() => {
                    setIsSetting(true);
                }}
            >
                <Title>추가 입력 정보 설정</Title>
                <Arrow src={require('../../../../styles/assets/arrow_right.png')}/>
            </RowWrapper>
            {
                isSetting && 
                <EtcInfoListModal 
                    storeId={storeId}
                    onClose={() => {
                        setIsSetting(false);
                    }}                  
                />
            }
        </Container>
    );
}