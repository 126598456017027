import styled from 'styled-components';
import { useEffect, useState } from 'react';

import {
    convertTimestampToDate
} from '../../utilities/convert';

import {
    userCancelRequest,
    timeoutCancelRequest
} from '../../apis/BookingApi';

import {
    Gap,
    PageButton
} from '../atoms';

import {
    InfoCard, UserUploadPhotoGrid
} from '../molecules'

import {
    UserBookingInfoType
} from '../../types';

import {
    convertTimestampToHangulTime
} from '../../utilities/convert';

import {
    StoreInfoModal,
    ConfrimCheckModal
} from '../modals';

const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 21px 5vw;
    display: flex;
    flex-direction: column;
`;

const Logo = styled.img`
    width: 120px;
`;

const ImageWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
`;

const Image = styled.img`
    width: 150px;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.25px;
    color: #000000;
    margin-top: 40px;
`;

const SubTitle = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #7D93A6;
    margin-top: 12px;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.5625px;
    color: #000000;
`;

const InfoIcon = styled.img`
    width: 16px;
    margin-left: 5px;
    cursor: pointer;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
`;

const RowWrapperSpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
`;

const RowWrapperCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 18px;
    width: 100%;
`;

const Button = styled.div`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    text-align: center;
    text-decoration-line: underline;
    color: #560C7B;
`;

interface UserWaitComponentProps {
    waitInfo: UserBookingInfoType
}

export function UserWaitComponent({
    waitInfo
}: UserWaitComponentProps){

    const [view, setView] = useState(false);
    const [ viewConfirmModal, setViewConfirmModal ] = useState(false);

    const userCancel = async () => {
        const res = await userCancelRequest(waitInfo.bookingInfo.bookingId);
        if(res.status === 'success')
        {
            // 취소 페이지로 이동
            window.location.reload();
        }
    } 

    const timeoutCancel = async () => {
        const res = await timeoutCancelRequest(waitInfo.bookingInfo.bookingId);
        if(res.status === 'success')
        {
            // 페이지 이동 
            window.location.reload();
        }
    }

    function checkTimeout(){
        const add = new Date(waitInfo.bookingInfo.createdAt).getTime() + (waitInfo.storeInfo.autoCancelMinute * 60 * 1000);    
        const timeout = new Date(add);
        const cur = new Date();
        
        if(cur > timeout)
        {
            timeoutCancel();   
        }

    }

    useEffect(() => {
        // 자동 취소일경우 취소 후 페이지 이동
        // 주기적으로 체크 
        const timer = setInterval(() => {
            checkTimeout()
        }, 10000);

        return () => clearInterval(timer);
    }, [])

    return (
        <Container>
            {
                viewConfirmModal &&
                <ConfrimCheckModal 
                    title="정말 취소하시겠습니까?"
                    onCancel={() => {
                        setViewConfirmModal(false);
                    }}
                    onCheck={() => {
                        setViewConfirmModal(false);
                        userCancel();
                    }}
                />
            }
            {
                view && <StoreInfoModal 
                    storeInfo={waitInfo.storeInfo}
                    scheduleList={waitInfo.scheduleList}
                    onClose={() => {
                        setView(false);
                    }}
                />
            }
            <Logo 
                src={require('../../styles/assets/logo_new.png')}
            />
            <Title>{waitInfo.storeInfo.name}에서<br/>예약확인중 입니다!</Title>
            <SubTitle>{convertTimestampToHangulTime(waitInfo.bookingInfo.createdAt, waitInfo.storeInfo.autoCancelMinute)}까지 상점에서 응답이 없는 경우 자동 취소됩니다.</SubTitle>
            {/* <Lottie 
                options={defaultOptions}
                height={150}
                width={150}
                isClickToPauseDisabled
            /> */}
            <ImageWrapper>
                <Image 
                    src={require('../../styles/assets/user_wait_logo.png')}
                />
            </ImageWrapper>            
            <RowWrapper>
                <Label>{waitInfo.storeInfo.name}</Label>
                <InfoIcon src={require('../../styles/assets/info.png')} 
                    onClick={()=>{
                        setView(true);
                    }}
                />
            </RowWrapper>
            <InfoCard 
                infos={[
                    {key: '예약 요청일', value: `${convertTimestampToDate(waitInfo.bookingInfo.requestDate)}`},
                    ...waitInfo.bookingInfo.requestBookingTimeList.map((request, index) => {
                        return (
                            {
                                key: '예약 요청시간',
                                value: `${request.startTime.slice(0, 5)}부터 ${request.endTime.slice(0,5)}까지`
                            }
                        );
                    }),                    
                    {key: '예약자 이름', value: `${waitInfo.bookingInfo.userName}`},
                    {key: '예약자 휴대폰번호', value: `${waitInfo.bookingInfo.userPhone}`},
                    {key: '요청서비스', value: `${waitInfo.bookingInfo.menuName}`}                    
                ]}            
            />
            {/* {
                waitInfo.bookingInfo.photoCount > 0 &&
                <>
                    <Gap 
                        gap={20}
                    />
                    <UserUploadPhotoGrid 
                        bookingId={waitInfo.bookingInfo.bookingId}
                        count={waitInfo.bookingInfo.photoCount}
                    />
                </>
            } */}
            <RowWrapperCenter>                
                <PageButton 
                    text="예약취소"
                    background='#FFFFFF'
                    color='#000000'
                    onClick={() => {
                        setViewConfirmModal(true);
                    }}
                />
            </RowWrapperCenter>
        </Container>
    );
}