import styled from "styled-components";

const Container = styled.div`
    height: 350px;
    width: 100%;
    background-color: #F9FAFB;
    color: #4C4C4C;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 60px;
    padding-left: 60px;
`;

const Logo = styled.img`

`;

const Wrapper = styled.div`
    flex-direction: column;
    display: flex;
`;
const Text = styled.span`
    margin-top: 13px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: -0.25px;
    color: #4C4C4C;
`;
const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.25px;
    color: #000000;
`;

function Footer(){
    return (
        <Container>
            {/* <Logo src={require('../../../styles/assets/logo.png')}/> */}
            <Wrapper>               
                <Title>금요일 11시</Title>
                <Text>대표자 이승도, 서울특별시 중구 수표로 37, 1층 A5호</Text>
                <Text>E-mail active.steach@gmail.com</Text>
                <Text>사업자등록번호 580-02-02658</Text>
                <Text>통신판매업 신고번호 2022-서울중구-1685호</Text>
                <Text>제휴 문의 010-8625-1119</Text>
            </Wrapper>
        </Container>
    );
}

export default Footer;