import styled from "styled-components";
import DaumPostcode from "react-daum-postcode";

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`;

const TouchableSection = styled.div`
    width: 100%;
    height: 40%;
    cursor: pointer;
    z-index: 1000;
`;

const Section = styled.div`
    width: 100%;
    height: 60%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #FFFFFF;
    overflow: auto;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 1000;
    padding-top: 20px;
`;

interface AddressInputModalProps {
    onComplete: (data:any) => void;
    onClose: () => void;
}

export function AddressInputModal({
    onComplete,
    onClose
}: AddressInputModalProps){
    return (
        <Container>
            <TouchableSection 
                onClick={onClose}
            />
            <Section>
                <DaumPostcode 
                    style={{
                        "display": "block",
                    }}
                    onComplete={onComplete}
                />
            </Section>
        </Container>
    );
}