// landing page 

import styled from "styled-components";
import Header from "./components/Header";
import Banner from "./components/Banner";
import Introduction from "./components/Introduction";
import Service from "./components/Service";
import Ours from "./components/Ours";
import Plans from "./components/Plans";
import Footer from "./components/Footer";
import { useEffect } from "react";

const Container = styled.div`
    height: 100vh;
`;

function Landing(){
    useEffect(() => {
        document.body.style.overflowY = 'auto';
    }, [])
    return (
        <Container>
            <Banner /> 
            <Introduction />          
            {/* <Plans /> */}
            
            <Service />
            <Ours />
            <Footer />
        </Container>
    );
}

export default Landing;