import styled from "styled-components";

import {
    PhotoViewModal
} from '../modals';
import { useState } from "react";

var BASE_URL = process.env.REACT_APP_TARGET_URL;



const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
`;

const Container = styled.div`

`;

const PhotoWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
`;

const Photo = styled.img`
    width: 44vw;
    min-height: 60vw;
    margin-right: 1vw;
    border-radius: 20px;
`;

interface UserUploadPhotoGridProps {
    bookingId: number;
    count: number;
    onPhotoViewModal: (bookingId: number, orderNumber: number) => void;
}

export function UserUploadPhotoGrid({
    bookingId,
    count,
    onPhotoViewModal
}: UserUploadPhotoGridProps){
    const [ hash, setHash ] = useState(Date.now());   
    const [ selectedNumber, setSelectedNumber ] = useState(0);
    const [ photoView, setPhotoView ] = useState(false);

    return (
        <Container>
            {/* {
                photoView &&
                <PhotoViewModal 
                    bookingId={bookingId}
                    orderNumber={selectedNumber}
                    onClose={() => {
                        setSelectedNumber(0);
                        setPhotoView(false);
                    }}
                />
            } */}
            <Title>첨부 사진</Title>
            <PhotoWrapper>
            {
                [...Array(count)].map((n, index) => {
                    return (
                        <Photo 
                            key={index}
                            src={BASE_URL + `/v1/booking/photo/${bookingId}/${index + 1}/?${hash}`}
                            onClick={() => {
                                onPhotoViewModal(bookingId, index + 1)
                            }}
                        />
                    );
                })
            }
            </PhotoWrapper>
            
        </Container>
    );
}