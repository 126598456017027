import { FormEvent, useEffect, useState } from "react";
import styled from "styled-components";

import {
    EtcInformationType
} from '../../types';

import {
    PageButton,
    Gap
} from '../atoms';

import {
    getEtcInfoListRequest,
    updateEtcInfoRequest,
    deleteEtcInfoRequest
} from '../../apis/BookingApi'

import {
    AlertModal
} from '../modals';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
`;

const TouchableSection = styled.div`
    width: 100%;
    height: 20%;
    cursor: pointer;
`;

const Section = styled.div`
    width: 100%;
    min-height: 80%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
    overflow: auto;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 1000;
`;

const Title = styled.div`
    min-height: 60px;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #CEDDE2;
    padding-left: 5vw;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: #191F28;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
`;


const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 40px 5vw;
`;

const CenterWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

const RowWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.175px;
    color: #525558;
`;

const CloseButton = styled.img`
    position: absolute;
    top: 20px;
    right: 15px;
    cursor: pointer;
`;

const Input = styled.input`
    width: 100%;
    height: 48px;
    border-radius: 12px;
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    padding-left: 20px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #000000;
`;

const TextArea = styled.textarea`
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    width: 100%;
    height: 160px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #000000;
    padding: 20px;
    resize: none;
`;


const EtcWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const Delete = styled.img`
    width: 10px;
`;

interface EtcInfoSettingModalProps {
    onClose: () => void;
    onSaveEtcInfo: () => void;
    storeId: number;
    selectedInfo?: EtcInformationType;

}

export function EtcInfoSettingModal({
    onClose,
    onSaveEtcInfo,
    storeId,
    selectedInfo,
}: EtcInfoSettingModalProps){
    const [ title, setTitle ] = useState('');
    const [ description, setDescription ] = useState('');
    const [ viewAlert, setViewAlert ] = useState(false);
    const [ alertTitle, setAlertTitle ] = useState<string>('');
    const [ alertDescription, setAlertDescription ] = useState<string>('');
    // const getEtcInfo = async (storeId: number) => {
    //     const etcInfoResponse = await getEtcInfoListRequest(storeId);
    //     if(etcInfoResponse.status === 'success')
    //     {
    //         setEtcInfos(etcInfoResponse.data);
    //     }
    // }

    const addNewEtcInfo = async () => {

        if(title === '')
        {
            setAlertTitle('추가 입력 정보 제목을 입력해주세요.');
            setAlertDescription('');
            setViewAlert(true);
            return;
        }
        if(description === '')
        {
            setAlertTitle('추가 입력 정보 설명을 입력해주세요.');
            setAlertDescription('');
            setViewAlert(true);
            return;
        }

        let data: EtcInformationType = {
            id: 0,
            storeId: storeId,
            title: title,
            description: description,
            orderNumber: 0
        }

        setTitle('');
        setDescription('');

        const res1 = await updateEtcInfoRequest(data);
        if(res1.status === 'success')
        {
            onSaveEtcInfo()
        }
        // const res2 = await getEtcInfo(storeId);
    }

    const deleteEtcInfo = async (etcInfoId: number) => {
        const res1 = await deleteEtcInfoRequest(etcInfoId);
        // const res2 = await getEtcInfo(storeId);
    }

    const onTitleChange = (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value},
        } = event;

        setTitle(value);
    }

    const onDescriptionChange = (event: FormEvent<HTMLTextAreaElement>) => {
        const {
            currentTarget: {value},
        } = event;

        setDescription(value);
    }

    useEffect(() => {
        // getEtcInfo(storeId);
        if(selectedInfo)
        {
            setTitle(selectedInfo.title);
            setDescription(selectedInfo.description);
        }
        else
        {
            // set new 
        }
    }, [])

    return (
        <Container>
            {
                viewAlert &&
                <AlertModal 
                    title={alertTitle}
                    description={alertDescription}
                    onCheck={() => {
                        setAlertTitle('');
                        setViewAlert(false);
                    }}
                />
            }
            <TouchableSection 
                onClick={() => {
                    onClose()
                }}
            />
            <Section>
                <Title>추가 입력정보 설정</Title>
                <Wrapper>
                    <Label>추가 입력 정보 제목</Label>
                    <Gap 
                        gap={12}
                    />
                    <Input 
                        value={title}
                        onChange={onTitleChange}
                        placeholder="추가 입력 정보의 제목 (예시: 원하는 색상)"
                    />
                    <Gap 
                        gap={20}
                    />
                    <Label>추가 입력 정보 설명</Label>
                    <Gap 
                        gap={12}
                    />
                    <TextArea
                        value={description}
                        onChange={onDescriptionChange}
                        placeholder="추가 입력 정보 설명을 입력해주세요"
                    >

                    </TextArea>
                    <Gap 
                        gap={40}
                    />
                    <CenterWrapper>
                        <PageButton 
                            text="저장하기"
                            background="#9C27B0"
                            color="#FFFFFF"
                            onClick={addNewEtcInfo}
                        />
                    </CenterWrapper>
                </Wrapper>
                {/* <EtcWrapper>
                    {
                        etcInfos.length > 0 &&
                        etcInfos.map((etc, index) => {
                            return (
                                <>
                                    <div>{etc.title}</div>
                                    <div>{etc.description}</div>
                                    <Delete src={require('../../styles/assets/btn_close_normal.png')}
                                        onClick={() => {
                                            deleteEtcInfo(etc.id)
                                        }}
                                    />
                                </>
                            );
                        })
                    }
                </EtcWrapper> */}
            </Section>
        </Container>
    );
}