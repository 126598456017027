import { useEffect, useState } from "react";
import styled from "styled-components";

import { 
    storeState 
} from '../../../../stores';
import { useRecoilValue } from "recoil";

import {
    BookingSimpleType
} from '../../../../types';

import {
    getStoreBookingPageRequest
} from '../../../../apis/BookingApi';

import {
    Gap
} from '../../../atoms';

import { 
    BookingSimpleCard
} from "../../../molecules";

import {
    UserPaymentInfoModal
} from '../../../modals';

const Container = styled.div`
    width: 100%;
    padding-top: 31px;
`;

const CardWrapper = styled.div`

`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.5625px;
    color: #191F28;
`;

const ListWrapper = styled.div`
    width: 100%;
    height: calc(100vh - 350px);
    overflow-y: auto;   
    padding-top: 10px;
    padding-bottom: 100px;
    margin-top: 20px;
`;

const CenterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;  
`;

const More = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.175px;
    color: #9C27B0;
    cursor: pointer;
`;

const Icon = styled.img`
    width: 16px;
    margin-right: 8px;
`;

interface StoreBookingListProps {
    start: string;
    end: string;
    phoneNumber: string;
}

export function StoreBookingList({
    start,
    end,
    phoneNumber
}: StoreBookingListProps){
    const store = useRecoilValue(storeState);
    const [ curPage, setCurPage ] = useState(1);
    const [ totalPage, setTotalPage ] = useState(0);
    const [ list, setList ] = useState<BookingSimpleType[]>([]);
    const [ selectedBookingId, setSelectedBookingId ] = useState(0);

    const getBookingList = async (page: number) => {
        setCurPage(page);
        const res = await getStoreBookingPageRequest(store.id, phoneNumber, start, end, page - 1)
        if(res.status === 'success')
        {
            if(page === 1)
            {
                setList([...res.data.content]);
            }
            else
            {
                setList((prev) => [...list, ...res.data.content]);
            }
           
            setTotalPage(res.data.totalPages);
        }
    }

    useEffect(() => {
        const initLoading = async () => {
            const res = await getStoreBookingPageRequest(store.id, phoneNumber, start, end, curPage - 1)
            if(res.status === 'success')
            {
                setList((prev) => [...res.data.content]);
                setTotalPage(res.data.totalPages);
            }
        }
        initLoading();
    }, [start, end, phoneNumber])

    return (
        <Container>
            {
                selectedBookingId > 0 &&
                <UserPaymentInfoModal
                    selectedBookingId={selectedBookingId}
                    type={2}
                    onClose={() => {
                        setCurPage(1);
                        getBookingList(1)
                        setSelectedBookingId(0)
                    }}
                />
            }
            <Label>예약 내역 </Label>
            <ListWrapper>
            {              
                list.length > 0 &&
                list.map((info, index) => {
                    return (
                            <CardWrapper
                                key={index}
                            >
                                <BookingSimpleCard 
                                    info={info}
                                    onSelect={() => {
                                        setSelectedBookingId(info.bookingId);
                                    }}
                                />
                                <Gap 
                                    gap={12}
                                />
                            </CardWrapper>                        
                    );
                })
              
            }
            {   
                totalPage > curPage &&
                <CenterWrapper>
                    <More 
                        onClick={() => {
                            getBookingList(curPage + 1);
                        }}
                    >
                        <Icon 
                            src={require('../../../../styles/assets/icon/down_arrow.png')}
                        />
                        더보기
                    </More>
                </CenterWrapper>    
                
            }  
            </ListWrapper>
            
        </Container>
    );
}