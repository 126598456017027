// store image grid ui + 카메라 버튼 

import { useState } from 'react';
import styled from 'styled-components';

import {
    StoreType
} from '../../types';

import {
    uploadBannerFileRequest
} from '../../apis/BookingApi';
var BASE_URL = process.env.REACT_APP_TARGET_URL;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #FFFFFF;
    padding-top: 20px;
    padding-bottom: 20px;
`;

const MainImage = styled.img`
    width: 50vw;
    height: 50vw;
    border: .5px solid #FFFFFF;
`;

const MainAltImage = styled.div`
    width: 50vw;
    height: 50vw;
    border: .5px solid #FFFFFF;
    background-color: #F4F7F8;
    position: relative;
    border-radius: 12px;
`;

const ColWrapper = styled.div`
    width: 50vw;
    height: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const RowWapper = styled.div`
    width: 50vw;
    height: 25vw;
    display: flex;
`;

const SubImage = styled.img`
    width: 25vw;
    height: 25vw;
    border: .5px solid #FFFFFF;
`;

const SubAltImage = styled.div`
    width: 25vw;
    height: 25vw;
    border: .5px solid #FFFFFF;
    background-color: #F4F7F8;
    position: relative;
    border-radius: 12px;
`;

const Icon = styled.img`
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
`;

const UploadButtonLabel = styled.label`
    position: absolute;
    right: 0;
    bottom: 0;
`;
 
const FileInput = styled.input`
    display: none;
`;

interface StoreImageUpdateGridProps {
    storeInfo: StoreType;
    setBanner1: (file: any) => void;
    setBanner2: (file: any) => void;
    setBanner3: (file: any) => void;
    setBanner4: (file: any) => void;
    setBanner5: (file: any) => void;
}

export function StoreImageUpdateGrid({
    storeInfo,
    setBanner1,
    setBanner2,
    setBanner3,
    setBanner4,
    setBanner5
}: StoreImageUpdateGridProps){

    const [ hash, setHash ] = useState(Date.now());   
    //preview
    const [ preview1, setPreview1 ] = useState<any>(null);
    const [ preview2, setPreview2 ] = useState<any>(null);
    const [ preview3, setPreview3 ] = useState<any>(null);
    const [ preview4, setPreview4 ] = useState<any>(null);
    const [ preview5, setPreview5 ] = useState<any>(null);

    const onChange1 = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const imgs = e.target.files;
        const formData = new FormData();
        if(!imgs) return;
        formData.append('file', imgs[0]);
        let type = '';

        if(imgs[0].type === 'image/png')
        {
            type = 'png';
        }
        else if(imgs[0].type === 'image/jpg')
        {
            type = 'jpg';
        }
        else if(imgs[0].type === 'image/jpeg')
        {
            type = 'jpg';
        }

        setPreview1(URL.createObjectURL(imgs[0]));
        setBanner1(imgs);

        // const res = await uploadBannerFileRequest(imgs, storeInfo.id, 1, type);
        // if(res)
        // {
        //     setHash(Date.now());
        // }        
    }

    const onChange2 = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const imgs = e.target.files;
        const formData = new FormData();
        if(!imgs) return;
        formData.append('file', imgs[0]);
        let type = '';

        if(imgs[0].type === 'image/png')
        {
            type = 'png';
        }
        else if(imgs[0].type === 'image/jpg')
        {
            type = 'jpg';
        }
        else if(imgs[0].type === 'image/jpeg')
        {
            type = 'jpg';
        }

        setPreview2(URL.createObjectURL(imgs[0]));
        setBanner2(imgs);

        // const res = await uploadBannerFileRequest(imgs, storeInfo.id, 2, type);
        // if(res)
        // {
        //     setHash(Date.now());
        // } 
    }

    const onChange3 = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const imgs = e.target.files;
        const formData = new FormData();
        if(!imgs) return;
        formData.append('file', imgs[0]);
        let type = '';

        if(imgs[0].type === 'image/png')
        {
            type = 'png';
        }
        else if(imgs[0].type === 'image/jpg')
        {
            type = 'jpg';
        }
        else if(imgs[0].type === 'image/jpeg')
        {
            type = 'jpg';
        }

        setPreview3(URL.createObjectURL(imgs[0]));
        setBanner3(imgs);

        // const res = await uploadBannerFileRequest(imgs, storeInfo.id, 3, type);
        // if(res)
        // {
        //     setHash(Date.now());
        // } 
    }

    const onChange4 = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const imgs = e.target.files;
        const formData = new FormData();
        if(!imgs) return;
        formData.append('file', imgs[0]);
        let type = '';

        if(imgs[0].type === 'image/png')
        {
            type = 'png';
        }
        else if(imgs[0].type === 'image/jpg')
        {
            type = 'jpg';
        }
        else if(imgs[0].type === 'image/jpeg')
        {
            type = 'jpg';
        }

        setPreview4(URL.createObjectURL(imgs[0]));
        setBanner4(imgs);

        // const res = await uploadBannerFileRequest(imgs, storeInfo.id, 4, type);
        // if(res)
        // {
        //     setHash(Date.now());
        // } 
    }

    const onChange5 = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const imgs = e.target.files;
        const formData = new FormData();
        if(!imgs) return;
        formData.append('file', imgs[0]);
        let type = '';

        if(imgs[0].type === 'image/png')
        {
            type = 'png';
        }
        else if(imgs[0].type === 'image/jpg')
        {
            type = 'jpg';
        }
        else if(imgs[0].type === 'image/jpeg')
        {
            type = 'jpg';
        }

        setPreview5(URL.createObjectURL(imgs[0]));
        setBanner5(imgs);

        // const res = await uploadBannerFileRequest(imgs, storeInfo.id, 5, type);
        // if(res)
        // {
        //     setHash(Date.now());
        // } 
    }

    return (
        <Container>
            {           
                <MainAltImage>
                    {
                        storeInfo.banner1 === null ? (
                            // preview 
                            <MainImage src={preview1} />
                        ) : (
                            // load
                            <MainImage 
                                src={preview1 !== null ? preview1 : BASE_URL + `/v1/store/banner/read/${storeInfo.id}/1?${hash}`}
                            />
                        )
                        
                    }
                                                      
                    <UploadButtonLabel htmlFor="file">
                        <Icon src={require('../../styles/assets/icon/cam.png')} />
                    </UploadButtonLabel>
                    <FileInput type='file' 
                        accept='image/jpg,image/png,image/jpeg' 
                        name='profile_img' 
                        onChange={onChange1}
                        id="file"
                    >
                    </FileInput>                   
                </MainAltImage>  
            }
            <ColWrapper>
                <RowWapper>
                    {                        
                        <SubAltImage>
                            {
                                storeInfo.banner2 === null ? (
                                    <SubImage 
                                        src={preview2}
                                    /> 
                                ) : (
                                    <SubImage 
                                        src={preview2 !== null ? preview2 : BASE_URL + `/v1/store/banner/read/${storeInfo.id}/2/?${hash}`}
                                    /> 
                                )
                            }
                            
                            <UploadButtonLabel htmlFor="file2">
                                <Icon src={require('../../styles/assets/icon/cam.png')} />
                            </UploadButtonLabel>
                            <FileInput type='file' 
                                accept='image/jpg,image/png,image/jpeg' 
                                name='profile_img' 
                                onChange={onChange2}
                                id="file2"
                            >
                            </FileInput>
                        </SubAltImage>
                        
                    }
                    {
                        <SubAltImage>
                            {
                                storeInfo.banner3 === null ? (
                                    <SubImage 
                                        src={preview3}
                                    /> 
                                ) : (
                                    <SubImage 
                                        src={preview3 !== null ? preview3 : BASE_URL + `/v1/store/banner/read/${storeInfo.id}/3/?${hash}`}
                                    /> 
                                )
                            }
                            
                            <UploadButtonLabel htmlFor="file3">
                                <Icon src={require('../../styles/assets/icon/cam.png')} />
                            </UploadButtonLabel>
                            <FileInput type='file' 
                                accept='image/jpg,image/png,image/jpeg' 
                                name='profile_img' 
                                onChange={onChange3}
                                id="file3"
                            >
                            </FileInput>
                        </SubAltImage>
                    }
                </RowWapper>    
                <RowWapper>
                    {    
                        <SubAltImage>
                            {
                                storeInfo.banner4 === null ? (
                                    <SubImage 
                                        src={preview4}
                                    /> 
                                ) : (
                                    <SubImage 
                                        src={preview4 !== null ? preview4 : BASE_URL + `/v1/store/banner/read/${storeInfo.id}/4/?${hash}`}
                                    />
                                )
                            }
                            
                            <UploadButtonLabel htmlFor="file4">
                                <Icon src={require('../../styles/assets/icon/cam.png')} />
                            </UploadButtonLabel>
                            <FileInput type='file' 
                                accept='image/jpg,image/png,image/jpeg' 
                                name='profile_img' 
                                onChange={onChange4}
                                id="file4"
                            >
                            </FileInput>
                        </SubAltImage>
                    }
                    {
                        <SubAltImage>
                            {
                                storeInfo.banner5 === null ? (
                                    <SubImage 
                                        src={preview5}                     
                                    /> 
                                ) : (
                                    <SubImage 
                                        src={preview5 !== null ? preview5 : BASE_URL + `/v1/store/banner/read/${storeInfo.id}/5/?${hash}`}                     
                                    /> 
                                )
                            }
                            <UploadButtonLabel htmlFor="file5">
                                <Icon src={require('../../styles/assets/icon/cam.png')} />
                            </UploadButtonLabel>
                            <FileInput type='file' 
                                accept='image/jpg,image/png,image/jpeg' 
                                name='profile_img' 
                                onChange={onChange5}
                                id="file5"
                            >
                            </FileInput>
                        </SubAltImage>
                    }
                </RowWapper>                             
            </ColWrapper>
        </Container>
    );
}