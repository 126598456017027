import { useEffect, useState } from "react";
import styled from "styled-components";

import { 
    leadingZeros 
} from "../../utilities/convert";

import {
    BookingType,
    BookingBlock,
    StoreTimeType,
    BreakTimeType,
    BreakBlock
} from '../../types'

const Container = styled.div`
    width: 100%;
    padding: 10px;
    overflow: hidden;
`;

const TableWrapper = styled.div`
    width: 100%;
    height: auto;
    border-radius: 10px;
`;

const ColWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: relative;
    overflow: hidden;
`;

const BookingBar = styled.div<{left: number, width: number, continue: boolean}>`
    position: absolute;
    height: 25px;
    border-radius: 20px;
    border-top-left-radius: ${(props) => !props.continue ? '20px' : '0'};
    border-bottom-left-radius:  ${(props) => !props.continue ? '20px' : '0'};
    border: 3px solid #ffffff;
    background-color: #ffffff;
    top: 50%;
    width: ${(props) => props.width}%;
    left: ${(props) => props.left}%;
    transform: translate(0, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    z-index: 3;
    cursor: pointer;
    color: #560C7B;
    font-weight: 500;
`;

const BreakBar = styled.div<{left: number, width: number, continue: boolean}>`
    position: absolute;
    height: 25px;
    border-radius: 20px;
    border-top-left-radius: ${(props) => !props.continue ? '20px' : '0'};
    border-bottom-left-radius:  ${(props) => !props.continue ? '20px' : '0'};
    border: 3px solid #dbe5c0;
    background-color: #dbe5c0;
    top: 50%;
    width: ${(props) => props.width}%;
    left: ${(props) => props.left}%;
    transform: translate(0, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    z-index: 3;
    cursor: pointer;
    color: #560C7B;
    font-weight: 500;
`;

const StoreTimeBlock = styled.div<{left: boolean}>`
    width: 20%;
    height: 55px;
    background-color: 'white';
    border-right: 2px solid #ffffff;
    border-top: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    border-left: ${(props) => props.left ? '2px solid #ffffff' : ''};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${(props) => props.theme.textColor};
    font-size: 12px;
    line-height: 16px;
    padding-left: 10px;
    border-radius: 0px;
    z-index: 1;
    background-color: #560C7B;
`;

const StoreCloseTimeBlock = styled.div<{left: boolean}>`
    width: 20%;
    height: 55px;
    background-color: 'white';
    border-right: 2px solid #ffffff;
    border-top: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    border-left: ${(props) => props.left ? '2px solid #ffffff' : ''};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${(props) => props.theme.textColor};
    font-size: 12px;
    line-height: 16px;
    padding-left: 10px;
    border-radius: 0px;
    z-index: 1;
    background-color: #918598;
`;

const Time = styled.div`
    width: 20%;
    font-size: 10px;
`;

const Title = styled.div`
    color: #DB5299;
    font-weight: 500;
    height: 45px;
    background-color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
`;

interface ScheduleTableProps {
    open: string;
    close: string;
    bookingData: BookingType[];
    breakTimeList: BreakTimeType [];
    onSelectBooking: (selectedIndex: number) => void;
}

export function ScheduleTable({
    open,
    close,
    bookingData,
    breakTimeList,
    onSelectBooking
}: ScheduleTableProps){

    const [ storeAvailableTimeList, setStoreAvailableTimeList ] = useState<StoreTimeType[][]>();  // 상점 운영 타임 
    const [ bookingBlockList, setBookingBlockList ] = useState<BookingBlock[]>();  // 예약 바 
    const [ breakBlockList, setBreakBlockList ] = useState<BreakBlock[]>();

    const calStoreAvailableTimeList = (open: string, end: string) => {
        const now = new Date();

        let startHour = open.slice(0, 2);
        let startMinute = open.slice(3, 5);
        let start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), Number(startHour), Number(startMinute));

        let endHour = end.slice(0, 2);
        let endMinute = end.slice(3, 5);
        let close = new Date(now.getFullYear(), now.getMonth(), now.getDate(), Number(endHour), Number(endMinute));
    
        let tTimeList:StoreTimeType[][] = []; 
        let tTime:StoreTimeType[] = [];

        for(let i = 0; i < 24; i++)
        {
            if(start < close)
            {   
                let temp: StoreTimeType = {
                    time: leadingZeros(start.getHours().toString(), 2) + ":" + leadingZeros(start.getMinutes().toString(), 2)
                };
                tTime.push(temp);
            }           
            start.setHours(start.getHours() + 1);

            if((i+1) % 5 === 0)
            {
                if(tTime.length > 0)
                {
                    tTimeList.push(tTime);
                    tTime = [];
                }
            }
        }

        setStoreAvailableTimeList(tTimeList);
        setUserBookingBlock(Number(startHour), Number(startMinute), bookingData);
        setStoreBreakBlock(Number(startHour), Number(startMinute), breakTimeList);
        // set user booking block
    }

    const setStoreBreakBlock = (openHour: number, openMinute: number,  breakTimeList: BreakTimeType[]) => {
        let resultBlockList: BreakBlock[] = [];

        for(let i = 0; i < breakTimeList.length; i++)
        {
            // loop 계산 
            let startHour = Number(breakTimeList[i].start.slice(0, 2));
            let startMinute = Number(breakTimeList[i].start.slice(3, 5));
            // let startHour = Number(breakTimeList[i].start.slice(11, 13)) + 9;
            // let startMinute = Number(breakTimeList[i].start.slice(14, 16));

            let diffHour = 0;
            let diffMinute = 0;

            if(startMinute >= openMinute)
            {
                diffMinute = startMinute - openMinute;
            }
            else
            {
                diffMinute = startMinute + 60 - openMinute;
                startHour = startHour - 1; 
            }

            diffHour = startHour - openHour;
            // console.log("diff Hour: " + diffHour  + " diff Minute: " + diffMinute);
            // 1. start y position
            // open +(5 * n) 시간 이후라면 position = n
            let yPosition = 0;
            if(diffHour < 5)
            {
                yPosition = 0;
            }
            else if(diffHour >= 5)
            {
                let y = Math.floor(Number(diffHour / 5));
                yPosition = y;
            }            
            // console.log("time : " + bookingList[i].start + " yPosition: " + yPosition);

            // 2. start x position
            // time - open + (5 + n) / 10 * 3.333  
            let startPosition = ((startHour * 60) + startMinute - (openHour + yPosition * 5) * 60) / 10 * 3.333333333333333333;
            // console .log("start Position: " + startPosition)

            // 3. width
            // duration / 10 * 3.333
            let width = Number(breakTimeList[i].duration) /10 * 3.333333333333333333;
            // console.log("width: " + width)
   
            let checkTime = (openHour + (yPosition + 1) * 5) * 60;
            let endTime = (startHour * 60) + startMinute + Number(breakTimeList[i].duration);
            // console.log("check Hour: " + checkTime + " endTime: " + endTime);

            if(endTime > checkTime)
            {
                let diffTime = Math.ceil((endTime - checkTime) / 300);
                
                for(let j = 0; j <= diffTime; j++)
                {
                    let block:BreakBlock = {
                        startXPosition: startPosition,
                        startYPosition: yPosition,
                        width: width,
                        continue: false,
                        text: '휴식'
                    }

                    if(j > 0)
                    {
                        block.startXPosition = 0;
                        block.startYPosition = block.startYPosition + 1;
                        block.continue = true;
                    }
                    resultBlockList.push(block)
                }
            }
            else
            {
                let block:BreakBlock = {
                    startXPosition: startPosition,
                    startYPosition: yPosition,
                    width: width,
                    continue: false,
                    text: '휴식'
                }
                resultBlockList.push(block)
                // console.log("line: " + 1);
            }

            setBreakBlockList(resultBlockList);
        }
    }

    const setUserBookingBlock = (openHour: number, openMinute: number,  bookingList: BookingType[]) => {
        let resultBlockList: BookingBlock[] = [];

        for(let i = 0; i < bookingList.length; i++)
        {
            // loop 계산 
            let startHour = Number(bookingList[i].time.slice(11, 13)) + 9;
            let startMinute = Number(bookingList[i].time.slice(14, 16));
            
            let diffHour = 0;
            let diffMinute = 0;

            if(startMinute >= openMinute)
            {
                diffMinute = startMinute - openMinute;
            }
            else
            {
                diffMinute = startMinute + 60 - openMinute;
                startHour = startHour - 1; 
            }

            diffHour = startHour - openHour;
            // console.log("diff Hour: " + diffHour  + " diff Minute: " + diffMinute);
            // 1. start y position
            // open +(5 * n) 시간 이후라면 position = n
            let yPosition = 0;
            if(diffHour < 5)
            {
                yPosition = 0;
            }
            else if(diffHour >= 5)
            {
                let y = Math.floor(Number(diffHour / 5));
                yPosition = y;
            }            
            // console.log("time : " + bookingList[i].start + " yPosition: " + yPosition);

            // 2. start x position
            // time - open + (5 + n) / 10 * 3.333  
            let startPosition = ((startHour * 60) + startMinute - (openHour + yPosition * 5) * 60) / 10 * 3.333333333333333333;
            // console .log("start Position: " + startPosition)

            // 3. width
            // duration / 10 * 3.333
            let width = Number(bookingList[i].duration) /10 * 3.333333333333333333;
            // console.log("width: " + width)
   
            let checkTime = (openHour + (yPosition + 1) * 5) * 60;
            let endTime = (startHour * 60) + startMinute + Number(bookingList[i].duration);
            // console.log("check Hour: " + checkTime + " endTime: " + endTime);

            if(endTime > checkTime)
            {
                let diffTime = Math.ceil((endTime - checkTime) / 300);
                // console.log("line: " + (diffTime + 1));
                // push line수 만큼 
                // 4. continue start
                // startYPosition: number;
                // startXPosition: number;
                // width: number;
                // continue: boolean;
                // text: string;
            
                
                for(let j = 0; j <= diffTime; j++)
                {
                    let block:BookingBlock = {
                        bookingId: bookingList[i].bookingId,
                        startXPosition: startPosition,
                        startYPosition: yPosition,
                        width: width,
                        continue: false,
                        text: bookingList[i].menuName
                    }

                    if(j > 0)
                    {
                        block.startXPosition = 0;
                        block.startYPosition = block.startYPosition + 1;
                        block.continue = true;
                    }
                    resultBlockList.push(block)
                }
            }
            else
            {
                let block:BookingBlock = {
                    bookingId: bookingList[i].bookingId,
                    startXPosition: startPosition,
                    startYPosition: yPosition,
                    width: width,
                    continue: false,
                    text: bookingList[i].menuName
                }
                resultBlockList.push(block)
                // console.log("line: " + 1);
            }

            setBookingBlockList(resultBlockList);
        }
    }


    useEffect(() => {
        calStoreAvailableTimeList(open, close)
    }, [])

    return (
        <Container>
            <Title>오늘 스케줄</Title>
            <TableWrapper>            
            {
                storeAvailableTimeList && 
                storeAvailableTimeList.map((times, index1) => {
                    return(
                        <ColWrapper key={index1}>
                            <RowWrapper>
                            {
                                times.map((time, index2) => {
                                    return(
                                        <Time key={index2}>{time.time}</Time>
                                    );
                                })
                            }
                            </RowWrapper>
                            <RowWrapper>
                            {/* 예약시간 */}
                            {
                                bookingBlockList &&
                                bookingBlockList.map((block, index) => {
                                    return(                                        
                                        index1 === block.startYPosition && 
                                        <BookingBar key={index}
                                            width={block.width}
                                            left={block.startXPosition}
                                            continue={block.continue}
                                            onClick={() => {
                                                onSelectBooking(block.bookingId)
                                            }}
                                        >{block.text}</BookingBar>                                        
                                    );
                                })
                            }
                            {/* 브레이크 */}
                            {
                                breakBlockList && 
                                breakBlockList.map((block, index) => {
                                    return (
                                        index1 === block.startYPosition && 
                                        <BreakBar key={index}
                                            width={block.width}
                                            left={block.startXPosition}
                                            continue={block.continue}
                                            onClick={() => {
                                               
                                            }}
                                        >{block.text}</BreakBar>                
                                    );
                                })
                            }
                            {/* 운영시간 */}                            
                            {
                                times.map((time, index2) => {
                                    return(
                                        <StoreTimeBlock 
                                            left={index2%5 === 0 ? true : false}
                                            key={index2}>
                                        </StoreTimeBlock>
                                    );
                                })
                            }
                            {  
                                storeAvailableTimeList.length - 1 === index1 &&
                                [...Array((5 - times.length ))].map((n, index) => {
                                    return (
                                        <StoreCloseTimeBlock 
                                            left={false}
                                            key={index}>
                                                
                                        </StoreCloseTimeBlock>
                                    );
                                })
                            }             
                            </RowWrapper>                            
                        </ColWrapper>
                    );
                })
            }     
            </TableWrapper>
        </Container>
    );
}