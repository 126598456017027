import styled from "styled-components"
import { 
    BookingStatType 
} from "../../types";

const Container = styled.div`
    display: grid;
    grid-template-columns: 45% 45%;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    gap: 18px;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
`;

const Label = styled.div`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.4375px;
    color: #7D93A6;
    height: 35px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
`;

const Value = styled.span<{color: string}>`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: ${(props) => props.color};
    margin-top: 8px;
`;

interface LabelGraphListProps {
    data: BookingStatType
}

export function  LabelGraphList({
    data
}: LabelGraphListProps){
    return (
        <Container>
            <Wrapper>
                <Label>총 예약 신청 수</Label>
                <Value
                    color="#9C27B0"
                >{data.totalBooking}명</Value>
            </Wrapper>
            <Wrapper>
                <Label>서비스 완료 수</Label>
                <Value
                    color="#009FAF"
                >{data.serviceComplete}건</Value>
            </Wrapper>
            <Wrapper>
                <Label>노쇼 수</Label>
                <Value
                    color="#F26565"
                >{data.noshow}건</Value>
            </Wrapper>
            <Wrapper>
                <Label>예약 취소 수 <br/>(자동취소 미포함)</Label>
                <Value
                    color="#F3A84F"
                >{data.bookingCancel}건</Value>
            </Wrapper>
        </Container>
    )
}