import { FormEvent, useEffect, useState } from "react";
import styled from "styled-components"
import { SelectedMenu } from "./component/SelectedMenu";
import { SelectedTime } from "./component/SelectedTime";
import { SelectedDate } from "./component/SelectedDate";

import { 
    storeState 
} from '../../../../stores';

import { useRecoilValue } from "recoil";

import {
    MenuType,
    AddBookingType,
    EtcInformationType,
    BookingEtcInfoType
} from '../../../../types';

import {
    getEtcInfoListRequest,
    addBookingScheduleRequest
} from '../../../../apis/BookingApi'

import {
    Gap,
    PageButton,
    CardButton
} from '../../../atoms';

import {
    ModalHeader,
    Loading,
    InfoCard
} from '../../../molecules';

import {
    OkBookingModal,
    AlertModal,
    ConfrimCheckModal,
    SelectMenuModal,
    SelectCustomerModal,
    SelectDateModal
} from '../../../modals';

import { 
    phoneCheck 
} from "../../../../utilities/validation";
import { leadingZeros } from "../../../../utilities/convert";


const Container = styled.div`
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    z-index: 999;
    top: 0;
    left: 0;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    padding-bottom: 50px;
`;

const Line = styled.div`
    width: 100%;
    height: 8px;
    background: #F2F2F2;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-top: 12px;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
    margin-top: 20px;
`;

const MenuSelectWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 30px 5vw;
    flex-direction: column;
`;

const TimeSelectWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 30px 5vw;
    flex-direction: column;
`;

const CustomerInfoWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 30px 5vw;
`;


const Center = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 5vw;
`;

const TextArea = styled.textarea`
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    width: 100%;
    height: 160px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #000000;
    padding: 20px;
    resize: none;
`;

interface AddBookingModalProps {
    onClose: () => void;
    requestDate: string;
}

export function AddBookingModal({
    onClose,
    requestDate
}: AddBookingModalProps){
    const [ addBooking, setAddBooking ] = useState<AddBookingType>({
        menuId: 0,
        duration: 0,
        requestDate: '',
        storeId: 0,
        userName: '',
        userPhone: '',
        etcList: []
    });

    const store = useRecoilValue(storeState);

    const [ etcList, setEtcList ] = useState<BookingEtcInfoType []> ([]);
    const [ menu, setMenu ] = useState<MenuType>();
    const [ time, setTime ] = useState<string>('');
    const [ duration, setDuration ] = useState<number>(0);

    const splitDate: string [] = requestDate.split('-');

    // request date 수정
    const [ selectedYear, setSelectedYear ] = useState<number>(Number(splitDate[0]));
    const [ selectedMonth, setSelectedMonth ] = useState<number>(Number(splitDate[1]));
    const [ selectedDay, setSelectedDay ] = useState<number>(Number(splitDate[2]));
   
    const [ selectMenuModalView, setSelectMenuModalView ] = useState(false);
    const [ selectTimeModalView, setSelectTimeModalView ] = useState(false);
    const [ selectCustomerModalView, setSelectCustomerModalView ] = useState(false);
    const [ selectDateModalView, setSelectDateModalView ] = useState(false);
    const [ viewConfirmModal, setViewConfirmModal ] = useState(false);

    const [ isAlert, setIsAlert ] = useState(false);
    const [ alertTitle, setAlertTitle ] = useState<string>('');
    const [ alertDescription, setAlertDescription ] = useState<string>('');
    const [ loading, setLoading ] = useState(false);
    
    function onEtcChange(index: number, event: FormEvent<HTMLTextAreaElement>){
        const {
            currentTarget: {value},
        } = event;

        const etcLocal: BookingEtcInfoType[] = JSON.parse(JSON.stringify(etcList))
        etcLocal.map((etc, i) => {
            if(i === index)
            {
                etc.contents = value;
            }
        })
        setEtcList(etcLocal);
    }

    const saveBooking = async () => {
        // name, phone, etclist, menu, time
        if(!menu)
        {
            setAlertTitle("서비스를 선택해주세요")
            setIsAlert(true);
            return;
        }      

        if(time === '')
        {
            setAlertTitle("예약시간을 설정해주세요")
            setIsAlert(true);
            return;
        }

        if(addBooking.userName === '' || addBooking.userPhone === '')
        {
            setAlertTitle("고객정보를 입력해주세요")
            setIsAlert(true);
            return;
        }

        if(!phoneCheck(addBooking.userPhone))
        {
            setAlertTitle("휴대폰번호를 정확하게 입력해주세요")
            setIsAlert(true);
            return;
        }

        setLoading(true);

        let addbooking: AddBookingType = {
            menuId: menu.id,
            duration: duration,
            // time: requestDate + " " +time + ":00",
            requestDate: `${leadingZeros(selectedYear.toString(), 2)}-${leadingZeros(selectedMonth.toString(), 2)}-${leadingZeros(selectedDay.toString(), 2)}`,
            storeId: store.id,
            userName: addBooking.userName,
            userPhone: addBooking.userPhone,
            etcList: etcList
        }
        const res = await addBookingScheduleRequest(addbooking, addbooking.requestDate + " " +time + ":00");

        if(res.status === 'success')
        {
            setLoading(false);
            onClose();
        }
        else
        {
            setLoading(false);
        }
    }

    useEffect(() => {
        const initStoreEtcInfo = async () => {
            const res = await getEtcInfoListRequest(store.id);
            if(res.status === 'success')
            {
                const etcLocal: BookingEtcInfoType[] = [];
                (res.data as EtcInformationType[]).map((etc, index) => {
                    let etct: BookingEtcInfoType = {
                        id: 0,
                        bookingId: 0,
                        title: etc.title,
                        contents: '',
                        orderNumber: index + 1
                    }
                    etcLocal.push(etct);
                })

                setEtcList(etcLocal);
            }
        } 
        initStoreEtcInfo();
    }, [])

    return (
        <Container>
            {
                loading && <Loading />
            }
            {
                viewConfirmModal && 
                <ConfrimCheckModal 
                    title={'변경한 정보가 있는지 확인해주세요.'}
                    description={'뒤로가기를 누를 경우 변경한 내역이 저장되지 않습니다.'}
                    onCheck={() => {
                        setViewConfirmModal(false);
                        onClose();
                    }}
                    onCancel={() => {
                        setViewConfirmModal(false);
                    }}
                />
            }
            {
                isAlert &&
                <AlertModal
                    title={alertTitle}
                    description={alertDescription} 
                    onCheck={() => {
                        setIsAlert(false);
                    }}
                />
            }
            {
                selectMenuModalView && 
                <SelectMenuModal 
                    storeId={store.id}
                    onClose={() => {
                        setSelectMenuModalView(false);
                    }}
                    onSelect={(menu) => {
                        setMenu(menu);
                        setSelectMenuModalView(false);
                    }}
                />
            }
            {
                selectDateModalView &&
                <SelectDateModal
                    selectedYear={selectedYear}
                    selectedMonth={selectedMonth}
                    selectedDay={selectedDay} 
                    onOk={(year: number, month: number, day: number) => {
                        setSelectedYear(year);
                        setSelectedMonth(month);
                        setSelectedDay(day);
                        setSelectDateModalView(false);
                    }}

                    onClose={() => {
                        setSelectDateModalView(false);
                    }}
                />
            }
            {
                selectCustomerModalView &&
                <SelectCustomerModal 
                    onClose={() => {    
                        setSelectCustomerModalView(false);
                    }}
                    onSelect={(name, phoneNumber) => {
                        setAddBooking({...addBooking, userName: name, userPhone: phoneNumber})
                        setSelectCustomerModalView(false);
                    }}  
                />
            }
            {
                selectTimeModalView && menu &&
                <OkBookingModal 
                    requestDate={`${leadingZeros(selectedYear.toString(), 2)}-${leadingZeros(selectedMonth.toString(), 2)}-${leadingZeros(selectedDay.toString(), 2)}`}
                    buttonText="설정완료"
                    title="예약 시간 설정"
                    menuDuration={menu?.duration}
                    onClose={() => {
                        setSelectTimeModalView(false)
                    }}
                    onOk={(start, duration) => {
                        setSelectTimeModalView(false);
                        setTime(start)
                        setDuration(duration)
                    }}
                    bookingId={0}
                    isAdd={true}
                />
            }
            <ModalHeader 
                title="예약 등록"
                onClose={() => {
                    setViewConfirmModal(true);
                }}
            />
            <Wrapper>
                <Gap 
                    gap={36}
                />
                <MenuSelectWrapper>
                    <Title>서비스</Title>
                    {
                        menu &&
                        <SelectedMenu 
                            menuId={menu.id}
                            menuName={menu.name}
                            price={menu.price}
                        />
                    }                   
                    <Center>
                        <CardButton 
                                text="서비스 선택하기"
                                background="#FFFFFF"
                                color="#9C27B0"
                                onClick={() => {
                                    setSelectMenuModalView(true);
                                }}
                            />
                    </Center>                    
                </MenuSelectWrapper>                
                <Line />
                <TimeSelectWrapper>
                    <Title>예약 날짜</Title>
                    {
                        <SelectedDate 
                            requestDate={`${leadingZeros(selectedYear.toString(), 2)}년 ${leadingZeros(selectedMonth.toString(), 2)}월 ${leadingZeros(selectedDay.toString(), 2)}일`}
                        />
                    }
                    <Center>
                        <CardButton 
                            text="날짜 설정하기"
                            background="#FFFFFF"
                            color="#9C27B0"
                            onClick={() => {
                                setSelectDateModalView(true);
                            }}
                        />
                    </Center>  
                </TimeSelectWrapper>
                <Line />
                <TimeSelectWrapper>
                    <Title>예약 시간</Title>
                    {
                        <SelectedTime 
                            startTime={time}
                            duration={duration}
                        />
                    }
                    <Center>
                        <CardButton 
                            text="시간 설정하기"
                            background="#FFFFFF"
                            color="#9C27B0"
                            onClick={() => {
                                setSelectTimeModalView(true)
                            }}
                        />
                    </Center>  
                </TimeSelectWrapper>
                <Line />
                <CustomerInfoWrapper>
                    <Title>고객 정보</Title>                  
                    <Gap 
                        gap={10}
                    />
                    {
                        addBooking.userName !== '' && addBooking.userPhone !== '' &&
                        <>
                            <InfoCard 
                                infos={[
                                    {key: '이름', value: addBooking.userName},
                                    {key: '휴대폰번호', value: addBooking.userPhone}
                                ]}
                            />
                            <Gap 
                                gap={10}
                            />
                        </>
                        
                    }
                    <Gap 
                        gap={10}
                    />
                    <Center>
                        <CardButton 
                                text="고객 정보 입력"
                                background="#FFFFFF"
                                color="#9C27B0"
                                onClick={() => {
                                    setSelectCustomerModalView(true);
                                }}
                            />
                    </Center>
                </CustomerInfoWrapper>
                <Line />
                <CustomerInfoWrapper>
                    <Title>추가 입력 정보</Title>   
                    {
                        etcList.length > 0 &&
                        etcList.map((etc, index) => {
                            return (
                                <Wrapper
                                    key={index}
                                >
                                    <Label>{etc.title}</Label>
                                    <Gap 
                                        gap={12}
                                    />
                                    <TextArea
                                        onChange={(event) =>{
                                            onEtcChange(index, event)
                                        }}
                                        value={etc.contents}
                                    ></TextArea>
                                </Wrapper>                                
                            );
                        })
                    }
                    
                </CustomerInfoWrapper>
                <Center>
                    <PageButton 
                        text="업데이트"
                        background="#9C27B0"
                        color="#FFFFFF"
                        onClick={saveBooking}
                    />
                </Center>                
            </Wrapper>
        </Container>
    );
}