import styled from "styled-components";

const Container = styled.div<{gap: number}>`
    height: ${(props) => props.gap}px;
`;

interface GapProps {
    gap: number;
}

export function Gap({
    gap
}: GapProps){
    return (
        <Container
            gap={gap}
        / >
    )
}