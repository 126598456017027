import { useEffect, useState } from "react";
import styled from "styled-components";
import { getLocalStorage, setLocalStorage } from "../../../utilities/localstorage";
import {
    getInfluencerRequest
} from '../../../apis/BookingApi';

import { 
    useNavigate 
} from "react-router-dom";

const Container = styled.div`
    height: 56px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Image = styled.img`
    width: 150px;
`;

const Text = styled.span`
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    cursor: pointer;
`;

// logo, logout 
export function Header(){
    const navigate = useNavigate();
    const [ isLogin, setIsLogin ] = useState(false);

    const getInfluencer =async () => {
        const res = await getInfluencerRequest();
        if(res.status === '200')
        {
            // console.log('info' + JSON.stringify(res));
            setIsLogin(true);
        }
        else
        {
            //go login
            // accesstoken 삭제
            setIsLogin(false);   
            setLocalStorage('in_access_token');             
            navigate('/influencer/login')
        }
    }

    useEffect(() => {
        const access_token = getLocalStorage('in_access_token');
        if(access_token)
        {
            setIsLogin(true);
        }
        else
        {
            // go login 
            setIsLogin(false);    
            setLocalStorage('in_access_token');
            navigate('/influencer/login')
        }
    }, [])

    return (
        <Container>
            <Image 
                src={require('../../../styles/assets/logo_new.png')}
            />
            {
                isLogin &&
                <Text
                    onClick={() => {
                        setIsLogin(false);    
                        setLocalStorage('in_access_token');
                        navigate('/influencer/login')
                    }}
                >로그아웃</Text>
            }
            
        </Container>
    );
}