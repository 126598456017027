import { useEffect, useState } from "react";
import styled from "styled-components";

import {
    Gap
} from '../../../atoms';

import {
    ModalHeader
} from '../../../molecules';

import {
    CustomerInfo,
    CustomerEdit
} from '../../../organisms';

import {
    CustomerInfoType
} from '../../../../types'

import {
    getCustomerInfoRequest,
    deleteCustomerRequest,
    changeFavoriteCustomerRequest
} from '../../../../apis/BookingApi';

import {
    ConfrimCheckModal
} from '../../../modals';

const Container = styled.div`
    display: flex;
    position: fixed;
    width: 100%;
    min-height: 100vh;
    background: #FFFFFF;
    z-index: 999;
    top: 0;
    left: 0;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
`;

//info , edit
interface CustomerInfoProps {
    onClose: () => void;
    info: CustomerInfoType;
}

export function CustomerInfoModal({
    onClose,
    info,
}: CustomerInfoProps){

    // 0: view, 1: edit
    const [ mode, setMode ] = useState(0);
    const [ customer, setCustomer ] = useState<CustomerInfoType>();
    const [ viewConfirmModal, setViewConfirmModal ] = useState(false);

    // get customer info
    const getCustomerInfo = async () => {
        const res = await getCustomerInfoRequest(info.customerId)
        if(res.status === 'success')
        {
            setCustomer(res.data);
        }
    }

    const changeFavoriteCustomer = async () => {
        
        const res = await changeFavoriteCustomerRequest(info.customerId)
        if(res.status === 'success')
        {         
            getCustomerInfo();
        }
    }

    const deleteCustomer = async () => {
        
        if(!window.confirm("정말 삭제하시겠습니까?"))
        {
            
        }
        const res = await deleteCustomerRequest(info.customerId);
        if(res.status === 'success')
        {
            onClose();
        }
    }


    useEffect(() => {
        const getCustomer = async () => {
            const res = await getCustomerInfoRequest(info.customerId)
            if(res.status === 'success')
            {
                setCustomer(res.data);
            }
        }
        getCustomer();
    }, [])

    return (
        <Container>
            {
                viewConfirmModal && mode !== 0 && 
                <ConfrimCheckModal 
                    title={'변경한 정보가 있는지 확인해주세요.'}
                    description={'뒤로가기를 누를 경우 변경한 내역이 저장되지 않습니다.'}
                    onCheck={() => {
                        setViewConfirmModal(false);
                        setMode(0)
                    }}
                    onCancel={() => {
                        setViewConfirmModal(false);
                    }}
                />
            }
            <ModalHeader 
                title={
                    mode === 0 ? '고객 정보' : '고객 정보 수정'
                }
                onClose={() => {
                    mode === 0 ? onClose() : setViewConfirmModal(true)
                }}
            />
            <Gap 
                gap={56}
            />
            {
                customer &&
                mode === 0 ?
                <CustomerInfo 
                    info={customer}
                    onModeChange={() => {
                        setMode(1)
                    }}
                    onChangeFavorites={changeFavoriteCustomer}
                />
                :
                customer &&
                <CustomerEdit 
                    info={customer}
                    onUpdate={() => {
                        getCustomerInfo();
                        setMode(0)
                        // update, close 
                    }}
                    onDelete={deleteCustomer}
                />
            }
        </Container>
    );
}