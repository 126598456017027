import styled from "styled-components";
import { ModalHeader } from "../../../molecules";
import { AdvertisementList } from "../../../organisms";
import { Gap } from "../../../atoms";

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background: #FFFFFF;
`; 

interface AdvertisementModalProps {
    onClose: () => void;
    storeId: number;
}

export function AdvertisementModal({
    onClose,
    storeId
}: AdvertisementModalProps){
    return (
        <Container>
            <ModalHeader 
                title="마케팅"
                onClose={onClose}
            />
            <Gap 
                gap={56}
            />
            <AdvertisementList 
                storeId={storeId}
            />
        </Container>
    );
}