import styled from 'styled-components';

import {
    convertTimestampToHangulTime
} from '../../utilities/convert'

import {
    BookingSimpleType
} from '../../types';

const Container = styled.div`
    width: 100%;
    background: #F4F7F8;
    border-radius: 12px;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px 0 12px;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const Icon = styled.img`
    margin-right: 7px;
    width: 12px;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 600;
    font-size: 12.5px;
    line-height: 16px;
    letter-spacing: -0.5px;
    color: #191F28;
`;

const Arrow = styled.img`
    width: 5px;
`;

interface BookingSimpleCardProps {
    onSelect: () => void;
    info: BookingSimpleType;
}

export function BookingSimpleCard({
    onSelect,
    info
}: BookingSimpleCardProps){
    return (
        <Container
            onClick={onSelect}
        >
            <Wrapper>
                <Icon 
                    src={require('../../styles/assets/icon/user.png')}
                />
                <Label>{info.name}님 / 
                    {info.state === 1 ? ' 신청 ' : 
                    info.state === 2 ? ' 수락 ' :
                    info.state === 3 ? ' 거절 ' :
                    info.state === 4 ? ' 고객취소 ' :
                    info.state === 5 ? ' 관리자취소' : 
                    info.state === 6 ? ' 이용완료 ' : 
                    info.state === 7 ? ' 노쇼 ' : 
                    info.state === 8 ? ' 예약철회 ' :
                    info.state === 9 ?  '미응답취소 ' : ''} /&nbsp;  
                    {convertTimestampToHangulTime(info.time, 0)}
                </Label>
            </Wrapper>
            <Arrow 
                src={require('../../styles/assets/arrow_right.png')}
            />
        </Container>
    );
}