import { FormEvent, useEffect, useState } from "react";
import styled from "styled-components";
import DaumPostcode from "react-daum-postcode";

import {
    updateStoreInfoRequest,
    dupCheckStoreUrlNameRequest,
    uploadBannerFileRequest
} from '../../apis/BookingApi'

import {
    Gap,
    PageButton
} from '../atoms';

import {
    ModalHeader,
    StoreImageUpdateGrid,
    Loading
} from '../molecules';

import {
    AddressInputModal,
    ConfrimCheckModal,
    AlertModal
} from '../modals';

import {
    StoreType
} from '../../types';

import {
    viewTextArea,
    viewLeftZeroRemove
} from '../../utilities/convert';

import { hangulCheck } from "../../utilities/validation";

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 80;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background: #FFFFFF;
    overflow-y: scroll;
    overflow-x: hidden;
`;

const Section = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    padding-left: 5vw;
    padding-right: 5vw;
`;

const ColWrapper = styled.div`
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: flex-start;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
`;

const Info = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.175px;
    color: #9C27B0;
`;

const Input = styled.input`
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    width: 100%;
    height: 48px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #000000;
    padding-left: 20px;
`;

const UrlWrapper = styled.div`
    display: grid;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    grid-template-columns: 215px 80px 20%;
`;

const UrlInput = styled.input`
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    width: calc(100vw - 215px - 10vw);
    height: 48px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #000000;
    padding-left: 10px;
`;

const UrlText = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #000000;
`;

const Address = styled.div`
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    width: 100%;
    height: 48px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #000000;
    padding-left: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const TextArea = styled.textarea`
    background: #FFFFFF;
    border: 1px solid #AFB8C0;
    border-radius: 12px;
    width: 100%;
    height: 160px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
    color: #000000;
    padding: 20px;
    resize: none;
`;

const Line = styled.div`
    width: 100%;
    height: 8px;
    background: #F2F2F2;
`;

interface StoreSettingModalProps {
    onClose: (change: boolean) => void;
    storeInfo: StoreType
}

export function StoreSettingModal({
    onClose,
    storeInfo
}: StoreSettingModalProps) {
    const [ hash, setHash ] = useState(Date.now());
    const [imgChange, setImageChange ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const [ banner1, setBanner1 ] = useState<any>(null);
    const [ banner2, setBanner2 ] = useState<any>(null);
    const [ banner3, setBanner3 ] = useState<any>(null);
    const [ banner4, setBanner4 ] = useState<any>(null);
    const [ banner5, setBanner5 ] = useState<any>(null);

    const [ name, setName ] = useState(storeInfo.name);
    const [ storeUrlName, setStoreUrlName ] = useState(storeInfo.storeUrlName === null ? '' : storeInfo.storeUrlName);
    const [ isDup, setisDup ] = useState<string>('');
    const [ isUrlUse, setIsUrlUse ] = useState(storeInfo.storeUrlName === null ? false : true);
    const [ phone, setPhone ] = useState(storeInfo.phone === null ? '' : storeInfo.phone);
    const [ representativeNumber, setRepresentativeNumber ] = useState(storeInfo.representativeNumber === null ? '' : storeInfo.representativeNumber);
    const [ roadAddress, setRoadAddress ] = useState(storeInfo.roadAddress === null ? '' : storeInfo.roadAddress);
    const [ jibunAddress, setJibunAddress ] = useState(storeInfo.jibunAddress === null ? '' : storeInfo.jibunAddress);
    const [ zoneCode, setZoneCode ] = useState(storeInfo.zoneCode === null ? '' : storeInfo.zoneCode);
    const [ detailAddress, setDetailAddress ] = useState(storeInfo.detailAddress === null ? '' : storeInfo.detailAddress);
    const [ availableCancelMinute, setAvailableCancelMinute ] = useState(storeInfo.availableCancelMinute);
    const [ autoCancelMinute, setAutoCancelMinute ] = useState(storeInfo.autoCancelMinute);
    const [ rejectMessage, setRejectMessage ] = useState(storeInfo.rejectMessage === null ? '' : storeInfo.rejectMessage);
    const [ introduction, setIntroduction ] = useState(storeInfo.introduction === null ? '' : storeInfo.introduction);
    const [ linkLabel, setLinkLabel ] = useState(storeInfo.linkLabel === null ? '' : storeInfo.linkLabel);
    const [ link, setLink ] = useState(storeInfo.link === null ? '' : storeInfo.link);

    const [ confirmTitle, setConfirmTitle ] = useState<string>('');
    const [ confirmDescription, setConfirmDescription ] = useState<string>('');
    const [ viewConfirmModal, setViewConfirmModal ] = useState(false);
    const [ viewAlertModal, setViewAlertModal ] = useState(false);
    const [ alertTitle, setAlertTitle ] = useState<string>('');
    const [ alertDescription, setAlertDescription ] = useState<string>('');

    const [ addressView, setAddressView ] = useState(false);
    const [ change, setChange ] = useState(false);
    const onSubmit = async () => {

        if(autoCancelMinute < 5)
        {
            setAlertTitle('자동 취소 최소 값은 5입니다');
            setAlertDescription('');
            setViewAlertModal(true);
            setAutoCancelMinute(5);
            return;
        }

        if(linkLabel === '' && link !== '')
        {
            setAlertTitle('외부 주소명을 입력해주세요.');
            setAlertDescription('외부 주소에 대한 이름을 입력해주세요');
            setViewAlertModal(true);
            return;
        }

        if(link === '' && linkLabel !== '')
        {
            setAlertTitle('외부 주소를 입력해주세요.');
            setAlertDescription('외부 주소명에 대한 주소를 입력해주세요');
            setViewAlertModal(true);
            return;
        }
        
        // update store 
        setChange(true);        
        let tstoreInfo:StoreType = JSON.parse(JSON.stringify(storeInfo));
        tstoreInfo.name = name;
        tstoreInfo.storeUrlName = storeUrlName;
        tstoreInfo.phone = phone;
        tstoreInfo.representativeNumber = representativeNumber;
        tstoreInfo.roadAddress = roadAddress;
        tstoreInfo.jibunAddress = jibunAddress;
        tstoreInfo.zoneCode = zoneCode;
        tstoreInfo.availableCancelMinute = availableCancelMinute;
        tstoreInfo.autoCancelMinute = autoCancelMinute;
        tstoreInfo.rejectMessage = rejectMessage;
        tstoreInfo.introduction = introduction;
        tstoreInfo.linkLabel = linkLabel;
        tstoreInfo.link = link;
        setLoading(true);

        // if banner1 change
        if(banner1 !== null)
        {
            //change
            const uploadRes = await imgFileUpload(banner1, 1);
            tstoreInfo.banner1 = `${storeInfo.id}_1.${getFileType(banner1)}`;
        }
        // if banner2 change
        if(banner2 !== null)
        {
            //change
            const uploadRes = await imgFileUpload(banner2, 2);
            tstoreInfo.banner2 = `${storeInfo.id}_2.${getFileType(banner2)}`;
        }
        // if banner3 change
        if(banner3 !== null)
        {
            //change
            const uploadRes = await imgFileUpload(banner3, 3);
            tstoreInfo.banner3 = `${storeInfo.id}_3.${getFileType(banner3)}`;
        }
        // if banner4 change
        if(banner4 !== null)
        {
            //change
            const uploadRes = await imgFileUpload(banner4, 4);
            tstoreInfo.banner4 = `${storeInfo.id}_4.${getFileType(banner4)}`;
        }
        // if banner5 change
        if(banner5 !== null)
        {
            //change
            const uploadRes = await imgFileUpload(banner5, 5);
            tstoreInfo.banner5 = `${storeInfo.id}_5.${getFileType(banner5)}`;
        }

        const res = await updateStoreInfoRequest(tstoreInfo);

        if(res.status === 'success')
        {
            setLoading(false);
            onClose(true);
        }
        else
        {
            setLoading(false);
        }
    }

    const getFileType = (imgs: any) =>
    {
        let type = '';

        if(imgs[0].type === 'image/png')
        {
            type = 'png';
        }
        else if(imgs[0].type === 'image/jpg')
        {
            type = 'jpg';
        }
        else if(imgs[0].type === 'image/jpeg')
        {
            type = 'jpg';
        }

        return type;
    }

    const imgFileUpload = async (imgs: any, order: number) => {
        let type = '';

        if(imgs[0].type === 'image/png')
        {
            type = 'png';
        }
        else if(imgs[0].type === 'image/jpg')
        {
            type = 'jpg';
        }
        else if(imgs[0].type === 'image/jpeg')
        {
            type = 'jpg';
        }

        const res = await uploadBannerFileRequest(imgs, storeInfo.id, order, type);
        if(res.status === 'success')
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    const dupCheck = async (storeUrlName: string, storeId: number) => {
        if(storeUrlName.length > 2)
        {
            if(hangulCheck(storeUrlName))
            {
                setisDup('한글을 사용할 수 없습니다.');
                setIsUrlUse(false);
            }
            else
            {
                const response = await dupCheckStoreUrlNameRequest(storeUrlName, storeId);
                if(response.data === 200)
                {
                    setisDup('사용 가능합니다.');
                    setIsUrlUse(true);
                }
                else
                {
                    setisDup('이미 사용중 입니다.');
                    setIsUrlUse(false);
                } 
            }
            
        }
        else
        {
            setisDup('2자이상 입력해주세요.');
            setIsUrlUse(false);
        }
       
        
    }

    const onChangeName = (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value},
        } = event;

        setName(value);
    }

    const onChangeStoreUrlName = async (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value},
        } = event;

        setStoreUrlName(value);
        await dupCheck(value, storeInfo.id);
    }

    const onChangePhone = (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value},
        } = event;

        setPhone(value);
    }

    const onChangeRepresentativeNumber = (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value},
        } = event;

        setRepresentativeNumber(value);
    }

    const onChangeAvailableCancelMinute = (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value},
        } = event;
        console.log(value)
        setAvailableCancelMinute(Number(value));
    }

    const onChangeAutoCancelMinute = (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value},
        } = event;
        setAutoCancelMinute(Number(value));        
    }

    const onChangeRejectMessage = (event: FormEvent<HTMLTextAreaElement>) => {
        const {
            currentTarget: {value},
        } = event;

        setRejectMessage(value);
    }

    const onChangeIntroduction = (event: FormEvent<HTMLTextAreaElement>) => {
        const {
            currentTarget: {value},
        } = event;
        
        console.log(value)
        // /r/n -> <br/>
        const text = value.replace(/\n/g, "<br>")
        setIntroduction(value);
    }

    const onChangeLinkLabel = (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value},
        } = event;

        setLinkLabel(value);
    }

    const onChangeLink = (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value},
        } = event;

        setLink(value);
    }

    const onChangeDetailAddress = (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value},
        } = event;

        setDetailAddress(value);
    }

    const onCompletePost = (data:any) => {
        setRoadAddress(data.roadAddress);
        setJibunAddress(data.jibunAddress);
        setZoneCode(data.zonecode);
        setAddressView(false);
    };

      useEffect(() => {
        document.body.style.overflow = "hidden";

        return () => {
            document.body.style.overflow = "unset";
        };
    }, [])

    return (
        <Container>
            {
                loading && <Loading />
            }
            {
                viewAlertModal &&
                <AlertModal 
                    title={alertTitle}
                    description={alertDescription}
                    onCheck={() => {
                        setAlertTitle('');
                        setAlertDescription('');
                        setViewAlertModal(false);
                    }}
                />
            }
            {
                viewConfirmModal && 
                <ConfrimCheckModal 
                    title={confirmTitle}
                    description={confirmDescription}
                    onCheck={() => {
                        setViewConfirmModal(false);
                        onClose(change);
                    }}
                    onCancel={() => {
                        setViewConfirmModal(false);
                    }}
                />
            }
            {
                addressView && 
                <AddressInputModal 
                    onComplete={(data) => {
                        onCompletePost(data);
                    }}
                    onClose={() => {
                        setAddressView(false);
                    }}
                />
                // <span>
                //     <DaumPostcode
                //         style={{
                //             "display": "block",
                //             "position": "absolute",
                //             "top": "20%",
                //             "width": "100%",
                //             "padding": "7px",
                //             "zIndex": 200, 
                //             "left": "0"
                //         }}
                //         onComplete={onCompletePost}
                //     />
                // </span>
            }
            <ModalHeader 
                title="프로필 수정"
                onClose={() => {
                    // onClose(change);
                    setConfirmTitle('변경한 정보가 있는지 확인해주세요.')
                    setConfirmDescription('뒤로가기를 누를 경우 변경한 내역이 저장되지 않습니다.')
                    setViewConfirmModal(true);
                }}
            />               
            <Section>
                <Gap 
                    gap={56}
                />                
                <StoreImageUpdateGrid 
                    storeInfo={storeInfo}
                    setBanner1={setBanner1}
                    setBanner2={setBanner2}
                    setBanner3={setBanner3}
                    setBanner4={setBanner4}
                    setBanner5={setBanner5}
                />        
                <Line />
                <RowWrapper>
                    <Gap 
                        gap={28}
                    />
                    <Label>상호명</Label>
                    <Gap 
                        gap={12}
                    />
                    <Input 
                        value={name} 
                        onChange={onChangeName}
                    />
                     <Gap 
                        gap={20}
                    />               
                    <Label
                        onClick={async () => {                            
                            await navigator.clipboard.writeText(`https://friday11am.com/store/${storeUrlName}`);
                            alert("복사되었습니다.")
                        }}
                    >사용자 공개 주소 (클립보드에 복사)</Label>
                    <Gap 
                        gap={12}
                    />
                    <UrlWrapper>
                        <UrlText>
                            https://friday11am.com/store/
                        </UrlText>
                        <UrlInput 
                            value={storeUrlName} 
                            onChange={onChangeStoreUrlName}
                        />
                    </UrlWrapper>
                    
                    <Gap 
                        gap={8}
                    />
                    <Info>{!isUrlUse ? `❌ ${isDup}` : `✔️ ${isDup}`}</Info>
                    {/* <div
                        onClick={() => {
                            dupCheck(storeUrlName, storeInfo.id);
                        }}
                    >중복체크</div> */}
                    <Gap 
                        gap={20}
                    />               
                    <Label>대표번호</Label>                    
                    <Gap 
                        gap={12}
                    />
                    <Input 
                        value={representativeNumber} 
                        onChange={onChangeRepresentativeNumber}
                    />
                    <Gap 
                        gap={20}
                    />               
                    <Label>알림톡 수신용 휴대폰번호</Label>
                    <Gap 
                        gap={12}
                    />
                    <Input 
                        value={phone} 
                        onChange={onChangePhone}
                    />
                    <Gap 
                        gap={20}
                    />   
                    <Label>주소</Label>
                    <Gap 
                        gap={12}
                    />
                    <Address 
                        onClick={() => {
                            setAddressView(true);
                        }}
                    >{roadAddress}</Address>
                    <Gap 
                        gap={5}
                    />
                    <Address 
                        onClick={() => {
                            setAddressView(true);
                        }}
                    >{jibunAddress}</Address>                   
                    <Gap 
                        gap={5}
                    />
                    <Address 
                        onClick={() => {
                            setAddressView(true);
                        }}
                    >{zoneCode}</Address>                 
                    <Gap 
                        gap={5}
                    />
                    <Input 
                        value={detailAddress} 
                        placeholder="상세주소"
                        onChange={onChangeDetailAddress}
                    />               
                    <Gap 
                        gap={20}
                    />
                    <Label>외부 주소명</Label>
                    <Gap 
                        gap={12}
                    />
                    <Input 
                        value={linkLabel}
                        placeholder="카카오톡, 블로그등"
                        onChange={onChangeLinkLabel}
                    />
                    <Gap 
                        gap={20}
                    />
                    <Label>외부 주소</Label>
                    <Gap 
                        gap={12}
                    />
                    <Input 
                        value={link}
                        placeholder="https://"
                        onChange={onChangeLink}
                    />
                    <Gap 
                        gap={20}
                    />
                    <Label>소개</Label>
                    <Gap 
                        gap={12}
                    />
                    <TextArea
                        value={introduction}
                        onChange={onChangeIntroduction}
                    >
                    </TextArea>
                    <Gap 
                        gap={20}
                    />                    
                  
                    <Label>예약 확정 후 취소 가능 시간 (분)</Label>
                    <Gap 
                        gap={12}
                    />
                    <Input 
                        type="number" pattern="\d*"
                        value={viewLeftZeroRemove(availableCancelMinute)} 
                        onChange={onChangeAvailableCancelMinute}
                    />
                    <Gap 
                        gap={20}
                    />
                    <Label>미수락시 예약신청 자동 취소 시간 (분)</Label>
                    <Gap 
                        gap={12}
                    />
                    <Input 
                        type="number" pattern="\d*"
                        value={viewLeftZeroRemove(autoCancelMinute)} 
                        onChange={onChangeAutoCancelMinute}
                    />
                    <Gap 
                        gap={20}
                    />
                    <Label>예약 거절 메시지 설정</Label>
                    <Gap 
                        gap={12}
                    />                  
                     <TextArea
                        value={rejectMessage}
                        onChange={onChangeRejectMessage}
                    >
                    </TextArea>
                    <Gap 
                        gap={40}
                    />
                    <PageButton 
                        text="업데이트"
                        background="#9C27B0"
                        color="#FFFFFF"
                        onClick={onSubmit}
                    /> 
                    <Gap 
                        gap={21}
                    />
                </RowWrapper>  
                
            </Section>
           
      
                
        </Container>
    );
}