import styled from "styled-components";

import {
    MenuType
} from '../../types'

import {
    ButtonGrid
} from '../molecules'

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding-left: 5vw;
    padding-right: 5vw;
    background: #FFFFFF;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 40px;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: #000000;
    margin-bottom: 17px;
`;

const Info = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    letter-spacing: -0.21875px;
    color: #FF4A8B;
    margin-top: -18px;
`;

const RowWrapper = styled.div<{mgb: number}>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${(props) => props.mgb}px;
`;


interface MenuSelectProps {
    menuList: MenuType [];
    onSelected: (selectedId: number, name: string) => void;
}

export function MenuSelect({
    menuList,
    onSelected
}: MenuSelectProps){
    return (
        <Container>
             <RowWrapper
                mgb={12}
            >
                <Title>서비스를 선택해주세요</Title>
                <Info>필수</Info>
            </RowWrapper>

            {
                menuList.length > 0 &&
                <ButtonGrid 
                    menus={menuList}
                    onSelected={onSelected}
                />
            }
        </Container>
    );
}