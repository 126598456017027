import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useFetch from "../hook/useFetch";
import useFetch2 from "../hook/useFetch2";

import {
    BookingType
} from '../../types'

import {
    WaitBookingInfoModal
} from '../modals';

import { time } from "console";

const Container = styled.div`
    width: 100%;
    padding: 10px;
`;

const RequestListWrapper = styled.div`
    width: 100%;
    height: 500px;
    padding-bottom: 210px;
    overflow-y: scroll;
`;

const Booking = styled.div`
    width: 100%;
    height: 60px;
    border: 1px solid #560C7B;
    color: #000000;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
`;

const Title = styled.div`
    color: #DB5299;
    font-weight: 500;
    height: 45px;
    background-color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
`;

interface RequestListProps {
    storeId: number;
    onChange: () => void;
}

export function RequestList({
    storeId,
    onChange
}: RequestListProps){
    const [ waitBookingInfoModal, setWaitBookingInfoModal ] = useState(false);
    const [ selectedBookingData, setSelectedBookingData ] = useState<BookingType>();
    
    const [page, setPage] = useState(0);   
    const [state, setState] = useState(0); 
    const { loading, error, list } = useFetch(storeId, page);
    const { loading2, error2, list2 } = useFetch2(storeId, page);
    const loader = useRef(null);
    
    const selectBookingData = (index: number) => {
        setSelectedBookingData((list as BookingType[])[index]);
        setWaitBookingInfoModal(true);
    }
    
    const handleObserver = useCallback((entries: any) => {
        const target = entries[0];
        if (target.isIntersecting) {
            setPage((prev) => prev + 1);
        }
    }, []);

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: "20px",
            threshold: 0
        };

        const observer = new IntersectionObserver(handleObserver, option);
        if (loader.current) observer.observe(loader.current);
    }, [handleObserver])
    
    return (
        <Container>
            {
                selectedBookingData && selectedBookingData.bookingId != 0 && waitBookingInfoModal && <WaitBookingInfoModal 
                    bookingInfo={selectedBookingData}
                    onClose={() => {
                        setWaitBookingInfoModal(false);
                        setSelectedBookingData({...selectedBookingData, bookingId: 0})
                        
                    }}
                    onChange={() => {
                        setWaitBookingInfoModal(false);
                        setSelectedBookingData({...selectedBookingData, bookingId: 0})
                        setPage(0)
                        onChange();
                    }}
                />
            }
            <Title>예약 요청 리스트</Title>
            <RequestListWrapper>
            {                
                list.map((item, i) => {
                    return (
                        <Booking key={i}
                            onClick={() => {
                                selectBookingData(i);
                            }}
                        >{item.userPhone}님의 ({item.menuName}) 예약 요청 {item.requestDate}</Booking>
                    );
                })                
            }
            {
                loading && <p>loading...</p>
            }
            {error && <p>Error!</p>}
            <div ref={loader} />    
            </RequestListWrapper>
        </Container>
    );
}